import React from 'react';
import './comapreAndRate.scss';
import BreadCrumb from '../../../GlobalComponent/BreadCrumb/BreadCrumb';
import ImgHeader from '../../../GlobalComponent/ImgHeader/ImgHeader';
// import {Container, Row, Col, Carousel} from 'react-bootstrap';
// import { Container, Row, Col, Button, Collapse, Table, Modal } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from "prop-types";
import * as navigationActions from '../../../../actions/navigation';
import * as actions from "../../../../actions/program-and-activities/programs/compareAndRate";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import Translate from '../../../GlobalComponent/translate/translate';
import { Link } from "react-router-dom";
import { BASE_URL } from '../../../../constants/config';
// import { t } from 'i18next';

class CompareAndRate extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            // pageID: null,
            menuOpen: false,
            menuOpen1: false,
            arrowDown: 'icon-down-dir',
            arrowUp: 'icon-up-dir',
            arrow: null,
            arrowDown1: 'icon-down-dir',
            arrowUp1: 'icon-up-dir',
            arrow1: null,
            filtersArray: [],
            filtersToBeRendered: null,
            tableToBeRendered: null,
            modalVisibility: false,
            cards: [
                {
                    icon: 'icon-board',
                    title: 'Title1',
                    hover: [
                        {
                            name: 'btn1',
                            link: '/compare-and-rate/categories/1'
                        },
                        {
                            name: 'btn2',
                            link: '/compare-and-rate/categories/2',
                        },
                        {
                            name: 'btn3',
                            link: '/compare-and-rate/categories/3',
                        },
                    ]
                },
                {
                    icon: 'icon-board',
                    title: 'Title2',
                    hover: [
                        {
                            name: 'btn1',
                            link: '/compare-and-rate/categories/4'
                        },
                        {
                            name: 'btn2',
                            link: '/compare-and-rate/categories/5',
                        },
                        {
                            name: 'btn3',
                            link: '/compare-and-rate/categories/6',
                        },
                    ]
                },
                {
                    icon: 'icon-board',
                    title: 'Title1',
                    hover: [
                        {
                            name: 'btn1',
                            link: '/compare-and-rate/categories/1'
                        },
                        {
                            name: 'btn2',
                            link: '/compare-and-rate/categories/2',
                        },
                        {
                            name: 'btn3',
                            link: '/compare-and-rate/categories/3',
                        },
                    ]
                },
                {
                    icon: 'icon-board',
                    title: 'Title1',
                    hover: [
                        {
                            name: 'btn1',
                            link: '/compare-and-rate/categories/1'
                        },
                        {
                            name: 'btn2',
                            link: '/compare-and-rate/categories/2',
                        },
                        {
                            name: 'btn3',
                            link: '/compare-and-rate/categories/3',
                        },
                    ]
                },
            ],
            routes: [],
            comparisonProducts: [
                {
                    name: 'Product 1',
                    img: 'https://www.snb.com/stuff/contentmgr/files/0/39deaa2de12c64deca866c0cce11045c/misc/personal_credit_card.png',
                    description: [
                        {
                            filterName: 'Filter1',
                            value: 'asd'
                        },
                        {
                            filterName: 'Filter2',
                            value: 'qwe'
                        }
                    ]
                },
                {
                    name: 'Product 2',
                    img: 'https://www.snb.com/stuff/contentmgr/files/0/39deaa2de12c64deca866c0cce11045c/misc/personal_credit_card.png',
                    description: [
                        {
                            filterName: 'Filter3',
                            value: 'asd'
                        },
                        {
                            filterName: 'Filter2',
                            value: 'qwe'
                        }
                    ]
                },
                {
                    name: 'Product 3',
                    img: 'https://www.snb.com/stuff/contentmgr/files/0/39deaa2de12c64deca866c0cce11045c/misc/personal_credit_card.png',
                    description: [
                        {
                            filterName: 'Filter1',
                            value: 'asd'
                        },
                        {
                            filterName: 'Filter2',
                            value: 'qwe'
                        }
                    ]
                },
            ],

            availableProducts: [
                {
                    name: 'New Product 1',
                    img: 'https://www.snb.com/stuff/contentmgr/files/0/39deaa2de12c64deca866c0cce11045c/misc/personal_credit_card.png',
                    description: [
                        {
                            filterName: 'Filter1',
                            value: 'asd'
                        },
                        {
                            filterName: 'Filter2',
                            value: 'qwe'
                        }
                    ]
                },
                {
                    name: 'New Product 2',
                    img: 'https://www.snb.com/stuff/contentmgr/files/0/39deaa2de12c64deca866c0cce11045c/misc/personal_credit_card.png',
                    description: [
                        {
                            filterName: 'Filter3',
                            value: 'asd'
                        },
                        {
                            filterName: 'Filter2',
                            value: 'qwe'
                        }
                    ]
                },
                {
                    name: 'New Product 3',
                    img: 'https://www.snb.com/stuff/contentmgr/files/0/39deaa2de12c64deca866c0cce11045c/misc/personal_credit_card.png',
                    description: [
                        {
                            filterName: 'Filter1',
                            value: 'asd'
                        },
                        {
                            filterName: 'Filter2',
                            value: 'qwe'
                        }
                    ]
                },
            ]
        }

        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.toggleCollapse1 = this.toggleCollapse1.bind(this);
        this.handleFilters = this.handleFilters.bind(this);
        this.renderTable = this.renderTable.bind(this);
        this.renderFilters = this.renderFilters.bind(this);
        this.deleteProductfromComparison = this.deleteProductfromComparison.bind(this);
        this.addProductToComparison = this.addProductToComparison.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hanldeBranch = this.hanldeBranch.bind(this);
    }

    componentDidMount() {
        // step to add
        this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
            }
        )
        var id = this.props.location.pathname;
        id = id.split('/')[3];
        this.setState({ ...this.state, loading: true });
        this.props.actions.getAllMultiCategoriesWithEachSub()
            .then(() => {
                this.setState({ ...this.state, loading: false });
            }).catch(() => {
            });


        this.setState({
            pageID: id,
            arrow: this.state.arrowDown,
            arrow1: this.state.arrowDown1
        }, () => {
        });

        this.renderFilters();
        this.props.actions.removeToBeComparedObjects();
        this.props.actions.removeDiableObjectCompareBtn();
    }

    // step to add
    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }

    toggleCollapse() {
        if (this.state.arrow === this.state.arrowDown) {
            this.setState({
                menuOpen: true,
                arrow: this.state.arrowUp
            });
        } else {
            this.setState({
                menuOpen: false,
                arrow: this.state.arrowDown
            });
        }

    }

    toggleCollapse1() {
        if (this.state.arrow1 === this.state.arrowDown1) {
            this.setState({
                menuOpen1: true,
                arrow1: this.state.arrowUp1
            });
        } else {
            this.setState({
                menuOpen1: false,
                arrow1: this.state.arrowDown1
            });
        }

    }

    handleFilters = event => {
        let tempArray = this.state.filtersArray;
        if (event.target.checked) {
            tempArray.push(event.target.value);
        } else {
            for (var x = 0; x < tempArray.length; x++) {
                if (tempArray[x] === event.target.value) {
                    tempArray.splice(x, 1);
                    break;
                }
            }
        }
        this.setState({
            filtersArray: tempArray
        }, () => {
            this.renderTable();
        })
    }

    renderFilters() {
        let tempArray1 = [];
        let tempArray = [];
        var i = 0;
        this.state.comparisonProducts.map((value) => {
            return (
                value.description.map((value1) => {
                    let filterName = value1.filterName;
                    if (!tempArray1.includes(filterName)) {
                        tempArray1.push(filterName);
                        let element = <span>
                            <input type="checkbox" id={'c' + i} className='chk-btn' value={filterName} onClick={this.handleFilters} />
                            <label htmlFor={'c' + i} >{filterName}</label>
                        </span>
                        i++;
                        tempArray.push(element);
                    }
                })
            )
        });
        this.setState({
            filtersToBeRendered: tempArray,
        })
    }

    renderTable() {
        let elementsArray = [];
        let allFilters = [];
        this.state.comparisonProducts.map((value) => {
            return (
                value.description.map((value1) => {
                    let tempFilterName = value1.filterName;
                    if (!allFilters.includes(tempFilterName)) {
                        allFilters.push(tempFilterName);
                    }
                })
            )
        })
        for (var x = 0; x < this.state.filtersArray.length; x++) {
            let elementRow = [];
            let filter = this.state.filtersArray[x];
            if (allFilters.includes(filter)) {
                elementRow.push(filter);
                for (var y = 0; y < this.state.comparisonProducts.length; y++) {
                    let productDesc = this.state.comparisonProducts[y].description;
                    let productFilters = [];
                    for (var z = 0; z < productDesc.length; z++) {
                        let tempFilter = productDesc[z];
                        productFilters.push(tempFilter.filterName);
                    }
                    if (productFilters.includes(filter)) {
                        for (var w = 0; w < productDesc.length; w++) {
                            let temp = productDesc[w];
                            if (temp.filterName === filter) {
                                elementRow.push(temp.value);
                                break;
                            }
                        }
                    } else {
                        elementRow.push("");
                    }
                }
            }

            let rowToBeRendered = <tr>
                {elementRow.map((value, index) => {
                    return (
                        <td key={index}>{value}</td>
                    )
                })}
            </tr>;
            elementsArray.push(rowToBeRendered);
        }

        this.setState({
            tableToBeRendered: elementsArray,
        })
    }

    deleteProductfromComparison = event => {
        let tempArray = this.state.comparisonProducts;
        tempArray.splice(event.target.value, 1);
        this.setState({
            comparisonProducts: tempArray,
        }, () => {
            this.renderFilters();
            this.renderTable();
        })
    }

    addProductToComparison() {
        let newObject = {
            name: 'Product 3',
            img: 'https://www.snb.com/stuff/contentmgr/files/0/39deaa2de12c64deca866c0cce11045c/misc/personal_credit_card.png',
            description: [
                {
                    filterName: 'Filter1',
                    value: 'asd'
                },
                {
                    filterName: 'Filter2',
                    value: 'qwe'
                }
            ]
        };

        let tempArray = this.state.comparisonProducts;
        tempArray.push(newObject);

        this.setState({
            comparisonProducts: tempArray,
        }, () => {
            this.closeModal();
            this.renderFilters();
            this.renderTable();
        })
    }

    closeModal() {
        this.setState({
            modalVisibility: false,
        })
    }

    showModal() {
        this.setState({
            modalVisibility: true,
        })
    }
    hanldeBranch(branch) {
        // console.log(branch)
        let targetId;
        if (branch.multi_categories_many.length > 0) {
            if (branch.multi_categories_many[0].multi_categories_many.length > 0) {
                if (branch.multi_categories_many[0].multi_categories_many.length > 0) {
                    targetId = branch.multi_categories_many[0].id;
                }
            }
            else {
                // targetId = branch.multi_categories_many[0].id;
                targetId = branch.id;
            }
        }
        else {
            targetId = branch.id;
        }
        return targetId;
    }

    render() {

        // console.log(" end compare and rate")

        return (
            /* // step to add */
            this.props.page &&
            <div className="comapreAndRate-container landing-container">
                {/* {this.state.loading &&
                    <div className="over-lay" >
                        {t('loading')}
                    </div>
                } */}
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                <Container>
                    <Row>
                        {this.props.categories.map((value, index) => {
                            return (
                                <Col lg={4} key={index}>
                                    <div className="customCard ml-1">
                                        <Row><Col xs={12}><img src={BASE_URL + '/uploads/' + JSON.parse(value.image)[0].download_link}></img></Col></Row>
                                        <Row><Col xs={12}><p>{this.props.lng === "ar" ? value.name : value.name_en}</p></Col></Row>
                                        <div className="customCardContent">
                                            <Row className="btnRow">
                                                {
                                                    value.multi_categories_many.sort((value1, value2) => {
                                                        if (value1.name.length < value2.name.length) return -1;
                                                        if (value1.name.length > value2.name.length) return 1;
                                                        return 0;

                                                    }).map((value1, index1) => {
                                                        return (
                                                            <div key={index1} className="innerBtn">
                                                                <Link to={"/compare-and-rate/category/general-tips/" + value1.id + '/' + this.hanldeBranch(value1)}>{this.props.lng === "ar" ? value1.name : value1.name_en}</Link>
                                                                {/* <Link to={"/compare-and-rate/category/general-tips/" + value1.id + '/' + this.hanldeBranch(value1)}>{this.props.lng === "ar" ? value1.name : value1.name_en}</Link> */}
                                                                {/*  */}
                                                            </div>
                                                        )
                                                    })}
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </div>
        )
    }
}

CompareAndRate.propTypes = {
    actions: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    lng: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        categories: state.compare_and_rate.categories,
        lng: state.user.lng,
        page: state.navigation.page
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompareAndRate)
