/* eslint-disable import/no-named-as-default */
// import * as PusherPushNotifications from '@pusher/push-notifications-web'
// import Pusher from 'pusher-js';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CustomNavbar from "./CustomNavbar";
import { connect } from "react-redux"
import PostForm from "./GlobalComponent/PostForm/PostForm";
import Footer from "./Footer";
import SideDrawer from './GlobalComponent/DrowerNavigation/SideDrawer'
import Backdrop from './GlobalComponent/Backdrop/Backdrop'
import NotFoundPage from "./NotFoundPage";
import PropTypes from "prop-types";
import React from "react";
// import { hot } from "react-hot-loader";

import { userLogout } from '../utils/auth.helpers';
import { USER_LOGOUT } from './../constants/actionTypes';
import { store } from './../index';

import HomePage from "./HomePage/HomePage";

// import Association from "./Association/Association";
import AboutPage from "./Association/AboutPage/AboutPage";
import Members from './Association/Members/Members';
// import MembersStatic from "./Association/Members/MembersStatic";
import Organize from './Association/organize/organize';
import Board from "./Association/board/board";
import Specialization from './Association/Specialization/Specialization';

// import InformationalCenter from './InformationalCenter/InformationalCenter';
import news from './InformationalCenter/news/news';
import SingleCard from './InformationalCenter/news/SingleCard/singleCard';
import Data from './InformationalCenter/data/data';
import AnnualReport from './InformationalCenter/annualReports/annualReports'
import MediaInterview from "./InformationalCenter/mediaInterview/meidaInterview";
// import ProgramsAndActivities from './ProgramsAndActivities/ProgramsAndActivities';
// import Programs from './ProgramsAndActivities/programs/programs';
// import Initiatives from './ProgramsAndActivities/initiatives/initiatives';
import PlatformsAndApplications from './ProgramsAndActivities/platformsAndApplications/platformsAndApplications'
import ComprehensiveGuide from './ProgramsAndActivities/initiatives/comprehensiveGuide/comprehensiveGuide';
import TakeScience from './ProgramsAndActivities/programs/takeScience/takeScience';
import Application from './ProgramsAndActivities/platformsAndApplications/fatwrty/fatwrty';
import ConsumerProtectionAward from './ProgramsAndActivities/initiatives/consumerProtectionAward/consumerProtectionAward';
import Recognize from './ProgramsAndActivities/programs/recognize/recognize';
// import SingleArticale from './InformationalCenter/singleArticale/singleArticale';
import ConsumerConsultant from './ProgramsAndActivities/programs/ConsumerConsultant/ConsumerConsultant';
import Quizes from './ProgramsAndActivities/programs/quizes/quizes';
import Motamaken from './ProgramsAndActivities/programs/motamken/Motamaken';
import MotamakenSection from './ProgramsAndActivities/programs/motamken/MotamakenSection';
import MotamakenSingle from './ProgramsAndActivities/programs/motamken/MotamakenSingle/MotamakenSingle'
import LegalAdvocacy from './ProgramsAndActivities/programs/LegalAdvocacy/LegalAdvocacy';
import Authentication from './Authentication/Authentication';
import ForgotPasswordAuth from './Authentication/forgotPasswordAuth';
import WorldDays from "./ProgramsAndActivities/worldDays/worldDays";
import WorldDayPage from "./ProgramsAndActivities/worldDays/worldDayPage/worldDayPage";
// import ServiceProductPage from "./ProgramsAndActivities/programs/compareAndRate/serviceProductPage/serviceProductPage";
import ContactContainer from './ContactUs/contactUsContainer';
// import VolunteerContainer from './ContactUs/Volunteer/volunteerContainer';
// import TawzeefContainer from './ContactUs/El-tawzeef/tawzeefContainer';
// import JobApplyContainer from './ContactUs/El-tawzeef/JobApplyContainer';
// import VolunteerApplyContainer from './ContactUs/Volunteer/volunteerApplyContainer';
import SingleJob from './ContactUs/El-tawzeef/singleJob';
import CompareAndRate from "./ProgramsAndActivities/programs/compareAndRate/compareAndRate";
import CategoryPage from './ProgramsAndActivities/programs/compareAndRate/categoryPage';
import ComparePage from './ProgramsAndActivities/programs/compareAndRate/comparePage';
import GeneralTips from './ProgramsAndActivities/programs/compareAndRate/generalTips';
import GuideBookPage from "./ProgramsAndActivities/initiatives/comprehensiveGuide/GuideBookPage/guideBookPage";
import ShareYourRating from './ProgramsAndActivities/programs/compareAndRate/shareYourRating';
// import MainComponent from "./SCAM/MainComponent";
import siteMap from "./GlobalComponent/SiteMap/sitemap";
import UserProfile from "./userProfile/userProfile";
import FAQ from "./Footer/FAQ";
import importantLinks from "./Footer/importantLinks"
import privacyPolicy from "./Footer/privacyPolicy";
import usagePolicy from "./Footer/usagePolicy";
// import ContactUsLanding from "./ContactUs/contatctUsLanding";
import CustomPage from "./CustomPage/customPage";
// import CompareStartPage from "./ProgramsAndActivities/programs/compareAndRate/compareStartPage";
import landingPage from "./landingPage/landingPage";
import SearchResult from '../components/GlobalComponent/searchResult/searchResult';
import Tests from "./ProgramsAndActivities/programs/quizes/tests/tests";
import { getProfileData } from "../actions/user-profile/userProfile"
import TechnicalProblem from "./GlobalComponent/TechnicalProblem/TechnicalProblem";
// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.
// import { Notifications } from 'react-push-notification';
class App extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            sideDrawerOpen: false,
            showAuthModal: false,
            local: null,
            lng: 'ar'
        };
    }

    //     showNotification() {
    // alert("show not fn")

    //         const notification =
    //             new Notification("new message from cpa", {
    //                 body: "how are you?"
    //             })
    //         notification.onclick = () => {
    //             window.location.href = "www.google.com"
    //         }

    //     }
    // showNotification() {
    //     if (!Notification) {
    //         alert("not support")
    //     }
    //     if (Notification.permission !== "granted")
    //         Notification.requestPermission();
    //     else {
    //         // $.ajax({
    //         // url : "notification.php",
    //         // type: "POST",
    //         // success: function(data, textStatus, jqXHR) {
    //         // var data = jQuery.parseJSON(data);
    //         // if(data.result == true) {
    //         //var data_notif = data.notif;
    //         //for (var i = data_notif.length - 1; i >= 0; i--) {
    //         // var theurl = data_notif[i]['url'];
    //         var notifikasi = new Notification("title", {
    //             // icon: data_notif[i]['icon'],
    //             body: "body",
    //         });
    //         notifikasi.onclick = function () {
    //             window.open("www.google.com");
    //             notifikasi.close();
    //             // };
    //             setTimeout(function () {
    //                 notifikasi.close();
    //             }, 5000);
    //         };
    //     }
    //     //else {
    //     // }
    //     // },
    //     //  error: function(jqXHR, textStatus, errorThrown) {}
    //     //  });
    //     // }
    // }


    getIsMobileOperatingSystem = () => {
        // let flag = 0;
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
            // flag=1;
            return 'iOS';
        }
        else if (userAgent.match(/Android/i)) {
            // flag=1;
            return 'Android';
        }
        else {
            // flag=0;

            return 'unknown';
        }
        // return flag;
    }

    //gets the type of browser
    detectBrowser() {
        if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
            return 'Opera';
        } else if (navigator.userAgent.indexOf("Chrome") != -1) {
            return 'Chrome';
        } else if (navigator.userAgent.indexOf("Safari") != -1) {
            return 'Safari';
        } else if (navigator.userAgent.indexOf("Firefox") != -1) {
            return 'Firefox';
        } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
            return 'IE';//crap
        } else {
            return 'Unknown';
        }
    }

    componentDidMount() {
        this.props.getProfileData()
        // console.log("fffffffffffffffffffff")
        // console.log("xxxxxxxxxxxxxxxxxxx", this.getIsMobileOperatingSystem(), this.detectBrowser())
        // if (this.getIsMobileOperatingSystem() == 'Android') {

        //     if (this.detectBrowser() != 'Chrome') {
        //         this.showNotification();

        //     }
        // } else if (this.getIsMobileOperatingSystem() == 'iOS') {
        //     if (this.detectBrowser() != 'Safari') {
        //         this.showNotification();

        //     }
        // }

        // else {
        //     if (this.detectBrowser() != 'IE' || this.detectBrowser() != 'Safari') {

        //         this.showNotification();
        //     }


        // }
        //         if(!Notification){
        // alert("!!!!!!!!!!!!!!!!!!!!!!!!!")
        //         }else{
        // alert("notific")
        //             if (Notification.permission === 'granted') {
        // alert("notific  grand")

        //                 // alert("we have permission");
        //                 this.showNotification()
        //             } else if (Notification.permission !== "denied") {
        // alert("notific ! grand")

        //                 Notification.requestPermission().then(permission => {
        //                     // console.log(permission)
        //                     if (permission === 'granted') {
        // alert("notific ;ast grand")

        //                         this.showNotification()
        //                     }

        //                 })
        //             }
        //             this.showNotification()
        //         }
        // console.log(Notification.permission)
        // console.log("m", Notification.permission)

    }
    // componentDidMount() {
    //     const pusher = new Pusher("FDEA8CDE7AA60455369BD888508151E13DC3B22B4B8D1635D05E0B62E44184FC", {
    //         cluster: "eu"
    //     });
    //     const channel = pusher.subscribe('CPA');
    //     channel.bind('CPA-event', function (data) {
    //         return data
    //     });
    //     const beamsClient = new PusherPushNotifications.Client({
    //         instanceId: '8e65931e-89eb-48f8-828e-0d72c5c4c153',
    //     })
    //     beamsClient.start()
    //         .then(() => beamsClient.addDeviceInterest('CPA'))
    //         .then(() => { });
    // }

    render() {
        let problem = false;
        let page;
        let backdrop;
        if (this.state.sideDrawerOpen) {
            backdrop = <Backdrop click={this.backdropClickHandler} />;
        }
        if (problem) {
            page = <TechnicalProblem />
        } else {
            page = <Router>
                <div className="cpa-content" dir={this.state.local}>
                    <header>
                        <CustomNavbar changeLanguage={this.props.changeLanguage} drawerClickHandler={this.drawerToggleClickHandler} toggleModal={this.toggleModal} logout={this.logout} />
                    </header>
                    <SideDrawer changeLanguage={this.props.changeLanguage} show={this.state.sideDrawerOpen} click={this.backdropClickHandler} toggleModal={this.toggleModal} logout={this.logout} />
                    {backdrop}
                    <section className="body">
                        <Switch>
                            <Route exact path="/" component={HomePage} />
                            <Route exact path="/ForgotPasswordAuth/:code" component={ForgotPasswordAuth} />
                            <Route path="/user-profile" component={UserProfile} />
                            {/* <Route exact path="/user-profile/service/:id" render={
                        props => <MotamakenSingle {...props} toggleModal={this.toggleModal} />
                    } /> */}
                            <Route path="/landing/:id" component={landingPage} />
                            <Route path="/search-result/:search" component={SearchResult} />
                            {/* <Route path="/association" component={Association} /> */}
                            <Route path="/about-us" component={AboutPage} />
                            <Route path="/function-of-the-association" component={Specialization} />
                            <Route path="/top-management" component={Members} />
                            <Route path="/mandate" component={Organize} />
                            <Route path="/committees" component={Board} />
                            {/* <Route path="/programs-and-activities" component={ProgramsAndActivities} /> */}
                            {/* <Route path="/programs" component={Programs} /> */}
                            <Route path="/now-you-know" component={TakeScience} />
                            <Route path="/Know/:cat?/:year?" component={Recognize} />
                            <Route path="/consumer-advisors" component={ConsumerConsultant} />
                            <Route path="/consumer-advisor/:id" component={MotamakenSingle} />
                            <Route exact path="/test-your-knowledge" component={Quizes} />
                            <Route exact path="/test-your-knowledge/tests/:id" component={Tests} />
                            <Route exact path="/test-your-knowledge/tests/:id/:testId" component={Tests} />
                            {/* <Route path="/single-articale" component={SingleArticale} /> ?? */}
                            <Route exact path="/skilled" component={Motamaken} />
                            <Route exact path="/skilled/category/:id" render={
                                props => <MotamakenSection {...props} toggleModal={this.toggleModal} />
                            } />
                            <Route exact path="/skilled/activity/:id" render={
                                props => <MotamakenSingle {...props} toggleModal={this.toggleModal} />
                            } />
                            {/* <Route path="/skilled/activity/:id" component={MotamakenSingle} /> */}
                            <Route path="/legal-advocacy/:tab/:id" render={
                                props => <LegalAdvocacy {...props} toggleModal={this.toggleModal} />
                            } />
                            <Route path="/legal-advocacy/:tab" render={
                                props => <LegalAdvocacy {...props} toggleModal={this.toggleModal} />
                            } />

                            <Route path="/legal-advocacy" render={
                                props => <LegalAdvocacy {...props} toggleModal={this.toggleModal} />
                            } />
                            {/* <Route exact path="/compare-and-rate/category/:id" component={ServiceProductPage} /> */}
                            <Route path="/compare-and-rate/category/general-tips/:branchId/:id?" component={GeneralTips} />
                            <Route path="/compare-and-rate/category/previous-ratings/:branchId/:id" component={GeneralTips} />
                            <Route path="/compare-and-rate/compare/:branchId" component={ComparePage} />
                            <Route path="/compare-and-rate/categories/:branchId/:id" component={CategoryPage} />
                            <Route path="/compare-and-rate/product/:id" component={MotamakenSingle} />
                            <Route path="/compare-and-rate/category" component={CompareAndRate} />
                            <Route path="/compare-and-rate/shareYourRating/:branchId/:id" component={ShareYourRating} />
                            {/* <Route path="/compare-and-rate/main/:id" component={CompareStartPage} /> */}
                            {/* <Route path="/initiatives" component={Initiatives} /> */}
                            <Route path="/consumer-protection-award" component={ConsumerProtectionAward} />
                            <Route path="/consumer-rights-comprehensive-guide" component={ComprehensiveGuide} />
                            <Route path="/guide-book/:bookId/:id" component={GuideBookPage} />
                            <Route path="/platforms-and-applications" component={PlatformsAndApplications} />
                            <Route path="/application/:id" component={Application} />
                            {/* <Route path="/SCAM" component={MainComponent} /> */}
                            <Route path="/international-days" component={WorldDays} />
                            <Route path="/international-day/:id" component={WorldDayPage} />
                            {/* <Route path="/informational-center" component={InformationalCenter} /> */}
                            <Route path="/recent-news/:cat?/:year?/:page?" component={news} />
                            <Route path="/statements" component={Data} />
                            <Route path="/single-news/:id" component={SingleCard} />
                            <Route path="/single-data/:id" component={SingleCard} />
                            {/* <Route path="/media-appearances/:page?" component={MediaInterview} /> */}

                            <Route path="/media-appearances/:cat?/:year?/:page?" component={MediaInterview} />
                            <Route path="/annualReports" component={AnnualReport} />
                            {/* <Route path="/Contact_us" component={ContactUsLanding} /> */}
                            <Route path="/send-message" component={ContactContainer} />
                            {/* <Route path="/volunteering" render={
                                props => <VolunteerContainer {...props} toggleModal={this.toggleModal} />
                            } />
                            <Route path="/employment" render={
                                props => <TawzeefContainer {...props} toggleModal={this.toggleModal} />
                            } />
                            <Route path="/job_apply/:id" render={
                                props => <JobApplyContainer {...props} toggleModal={this.toggleModal} />
                            } />
                            <Route path="/volunteer_apply/:id" render={
                                props => <VolunteerApplyContainer {...props} toggleModal={this.toggleModal} />
                            } /> */}
                            <Route path="/singlejob" component={SingleJob} />
                            <Route path="/site-map" component={siteMap} />
                            <Route path="/FAQS" component={FAQ} />
                            <Route path="/important-links" component={importantLinks} />
                            <Route path="/usage-policy" component={usagePolicy} />
                            <Route path="/privacy-policy" component={privacyPolicy} />
                            <Route path="/page/:id" render={
                                props => <CustomPage {...props} toggleModal={this.toggleModal} />
                            } />
                            <Route component={NotFoundPage} />
                            <Route path='*' exact={true} component={NotFoundPage} />
                        </Switch>
                    </section>
                    <PostForm />
                    <footer>
                        <Footer />
                    </footer>
                    <Authentication showModal={this.state.showAuthModal} toggleModal={this.toggleModal} logout={this.logout} />
                </div>
            </Router>


        }
        return (
            <>
                {page}
                {/* <Notifications /> */}

            </>
        );
    }
    drawerToggleClickHandler = () => {
        this.setState(prevState => {
            return { sideDrawerOpen: !prevState.sideDrawerOpen }
        })
    };

    backdropClickHandler = () => {
        this.setState({ sideDrawerOpen: false })
    };
    logout = () => {
        userLogout();
        store.dispatch({ type: USER_LOGOUT.SUCCESS, payload: {} });
    };
    toggleModal = () => {
        this.setState(prevState => {
            return { showAuthModal: !prevState.showAuthModal }
        })
    };
}

App.propTypes = {
    children: PropTypes.element,
    toggleModal: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    changeLanguage: PropTypes.func.isRequired

};

export default connect(null, { getProfileData })(App);
// export default hot(App);
// export default hot(module)(App);

