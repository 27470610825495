import React from 'react';
import PropTypes from 'prop-types';
import './ImgCard.scss';

const ImgCard = props => {
    // const height = document.getElementById('testtt').clientHeight;
    // console.log(height/2);
    return (
        <div className="img-card">
            {/* <div className="back-color shadow-sm" id="testtt"></div> */}
            <div className="img_test">
                <img src={props.imgSrc} />
            </div>

        </div>
    );
};
ImgCard.propTypes = {
    imgSrc: PropTypes.string
};
export default ImgCard;
