import React from 'react';
import IconCard from '../../GlobalComponent/IconCard/IconCard';
import BreadCrumb from '../../GlobalComponent/BreadCrumb/BreadCrumb';
import ImgHeader from '../../GlobalComponent/ImgHeader/ImgHeader';
import './Specialization.scss';
import * as actions from '../../../actions/El-Gam3ya/spectializations';
import * as navigationActions from '../../../actions/navigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

class Specialization extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            lng: localStorage.getItem('lng'),
            routes: []
        }
    }
    componentDidMount() {
        this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                this.props.actions.getSpectializationsData()
                    .then(() => {
                        this.setState({ ...this.state, loading: false });
                    }).catch(() => {
                    });
            })
    }
    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }
    render() {
        return (
            this.props.page &&
            <div className="specialization-container">
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                <div className="container specialization-content" dir="rtl">
                    <div className="row">
                        {
                            this.props.spectializations_array.map((value, index) => {
                                return (
                                    <div className="spec-card col-12 col-lg-6 col-xl-4" key={index}>
                                        <IconCard card={value} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )

    }
}
Specialization.propTypes = {
    actions: PropTypes.object.isRequired,
    spectializations_array: PropTypes.array.isRequired,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired
};
function mapStateToProps(state) {
    return {
        spectializations_array: state.spectializations.spectializations_array,
        page: state.navigation.page
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Specialization)
