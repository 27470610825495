import React from 'react'
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../GlobalComponent/BreadCrumb/BreadCrumb';
import ImgHeader from '../../GlobalComponent/ImgHeader/ImgHeader';
import { BASE_URL } from '../../../constants/config';
import * as actions from '../../../actions/auth/auth';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux';
import * as navigationAction from '../../../actions/navigation';

// let header_src = "https://gdurl.com/9DTn";
// let header_info = {
//   header: 'siteMap',
//   desc: ''
// }
// Since this component is simple and static, there's no parent container for it.
class siteMap extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true,
      img_url: BASE_URL + '/uploads/',
      page: {
        name: 'خريطة الموقع',
        name_en: 'Site Map'
      },
      routes: [
        {
          name: "خريطة الموقع",
          name_en: 'Site Map',
          link: 'site-map'
        },
      ],
    }

    this.handleLinkClick = this.handleLinkClick.bind(this);
  }

  componentDidMount() {
    this.props.navigationAction.getNavigationPages();
  }
  handleLinkClick() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <ImgHeader page={this.state.page} />
        <BreadCrumb routes={this.state.routes} filterbyYear={false} filterbySubject={false} />
        <Container className="mt-5">
          {this.props.pages &&
            this.props.pages.map((value, index) => {
              return (
                <ul key={index}>
                  <li className="gray-color bold font-md">
                    {value.target != '_blank' ?
                      <Link to={"/" + value.slug} onClick={this.handleLinkClick}>
                        {this.props.lng == "ar" ? value.menu_name : value.menu_name_en}
                      </Link> :
                      <a href={value.slug}>
                        {this.props.lng == "ar" ? value.menu_name : value.menu_name_en}
                      </a>
                    }

                  </li>
                  {value.child_menu.length != 0 &&
                    value.child_menu.map((value, index) => {
                      return (
                        <ul key={index} className="subMenu">
                          <li>
                            {value.target != '_blank' ?
                              <Link to={"/" + value.slug} onClick={this.handleLinkClick}>
                                {this.props.lng == "ar" ? value.menu_name : value.menu_name_en}
                              </Link> :
                              <a href={value.slug} target={value.target}>
                                {this.props.lng == "ar" ? value.menu_name : value.menu_name_en}
                              </a>
                            }
                          </li>
                          {value.child_menu.length != 0 &&
                            value.child_menu.map((value, index) => {
                              return (
                                <ul key={index} className="subMenu">
                                  <li>
                                    {value.target != '_blank' ?
                                      <Link to={"/" + value.slug} onClick={this.handleLinkClick}>
                                        {this.props.lng == "ar" ? value.menu_name : value.menu_name_en}
                                      </Link> :
                                      <a href={value.slug} target={value.target}>
                                        {this.props.lng == "ar" ? value.menu_name : value.menu_name_en}
                                      </a>
                                    }
                                  </li>
                                </ul>
                              )
                            })
                          }
                        </ul>
                      )
                    })
                  }
                </ul>
              )
            })
          }
        </Container>
      </div>
    )
  }
}

siteMap.propTypes = {
  lng: PropTypes.string.isRequired,
  pages: PropTypes.array.isRequired,
  navigationAction: PropTypes.object.isRequired
};
function mapStateToProps(state) {
  return {
    lng: state.user.lng,
    pages: state.navigation.pages
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    navigationAction: bindActionCreators(navigationAction, dispatch)
  };
}



export default connect(mapStateToProps, mapDispatchToProps)(siteMap)
