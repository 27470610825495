import React from 'react';
import ImgHeader from '../../../GlobalComponent/ImgHeader/ImgHeader';
import BreadCrumb from '../../../GlobalComponent/BreadCrumb/BreadCrumb';
// import VideoCard from '../../../GlobalComponent/videoCard/videoCard';
// import DataCard from '../../../GlobalComponent/dataCard/dataCard';
// import NewsCard from '../../../GlobalComponent/newsCard/newsCard';
import ContainerCard from "../../../GlobalComponent/ContainerCard/containerCard";
import './consumerProtectionAward.scss';
import { Container, Modal } from "react-bootstrap";
// import CpaSharing from '../../../GlobalComponent/cpaSharing/cpaSharing';
// import { Link } from 'react-router-dom';
// import {t} from 'i18next';
import * as actions from '../../../../actions/program-and-activities/initiatives/consumerAward';
import * as navigationActions from '../../../../actions/navigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { BASE_URL } from '../../../../constants/config';
import CpaSharing from '../../../GlobalComponent/cpaSharing/cpaSharing';
// import IdCard from "../../GlobalComponent/IdCard/IdCard";
import IdCard from "../../../GlobalComponent/IdCard/IdCard"
class ConsumerProtectionAward extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            btnClass: "yearBtns",
            show: false,
            url: BASE_URL + '/uploads/',
            lng: localStorage.getItem('lng'),
            loading: true,
            yearsToBeRendered: null,
            filtersToBeRenderedAr: null,
            filtersToBeRenderedEn: null,
            selectedCategory: null,
            currentYear: null,
            contentToBeRendered: null,
            modalName: null,
            modalImg: null,
            modalDesc: null,
            modalWinReason: null,
            modalTwitterLink: null,
            modalProjectBief: null,
            awards: [
                {
                    name: 'أفضل حساب شخصي توعوي',
                    winners: [
                        {
                            img: "https://gdurl.com/T7Qw",
                            name: "م.مازن محمد يوسف جان ",
                            role: 'المشرف على حساب “مخاطر المهدرجة”',
                            desc: 'حساب مختص في التوعية بالغذاء الصحي، والتحذير من مخاطر الزيوت المهدرجة في الوجبات الغذائية،',
                            twitter: 'https://twitter.com/Al_Mohadraj'
                        },
                        {
                            img: "https://gdurl.com/GG3e",
                            name: "د. أسعد العرفة",
                            role: '',
                            desc: 'مهتم في نشر الوعي الصحي',
                            twitter: 'https://twitter.com/drarafah'
                        },
                        {
                            img: "https://gdurl.com/7Qqa",
                            name: "أ.فهد البقمي",
                            role: '',
                            desc: 'مهتم بالتوعية في مجال التسويق الإلكتروني',
                            twitter: 'https://twitter.com/FahdAlbogami'
                        }
                    ]
                },
                {
                    name: 'المستهلك الوعي',
                    winners: [

                    ]
                },
                {
                    name: 'مشروع حكومي مشترك مع الجمعية',
                    winners: [

                    ]
                }
            ],
            routes: []

        }

        this.onYearClick = this.onYearClick.bind(this);
        this.filterClick = this.filterClick.bind(this);
        this.openModal = this.openModal.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    componentDidMount() {
        this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                this.props.actions.consumerAwardsData()
                    .then(() => {
                        let years = Object.keys(this.props.awards);
                        years = years.reverse();
                        let elements = [];
                        for (let x = 0; x < years.length; x++) {
                            let tempElement = <button className={x === 0 ? this.state.btnClass : null} onClick={this.onYearClick} value={years[x]}>{years[x]}</button>;
                            elements.push(tempElement);
                        }
                        this.setState({
                            loading: false,
                            yearsToBeRendered: elements,
                            currentYear: years[0]
                        });

                        ////////////////////////////////////////////////////////////////////////////////////////

                        let tempArray = this.props.awards[years[0]];
                        let categoriesToBeRenderedAr = [];
                        let categoriesToBeRenderedEn = [];
                        for (let x = 0; x < tempArray.length; x++) {
                            let element = tempArray[x];
                            let categoryName = element.name;
                            let categoryNameEn = element.name_en;
                            let catAr = <button className="categoryFilterBTNS" onClick={this.filterClick} value={this.props.lng === 'ar' ? categoryName : categoryNameEn}>{categoryName}</button>;
                            categoriesToBeRenderedAr.push(catAr);
                            let catEn = <button className="categoryFilterBTNS" onClick={this.filterClick} value={this.props.lng === 'ar' ? categoryName : categoryNameEn}>{categoryNameEn}</button>;
                            categoriesToBeRenderedEn.push(catEn);
                        }
                        this.setState({
                            currentYear: years[0],
                            filtersToBeRenderedAr: categoriesToBeRenderedAr,
                            filtersToBeRenderedEn: categoriesToBeRenderedEn,
                        })

                        /////////////////////////////////////////////////////////////////////////////////////////
                        if (this.props.lng === 'ar') {
                            this.setState({
                                selectedCategory: tempArray[0].name
                            });
                        } else {
                            this.setState({
                                selectedCategory: tempArray[0].name_en
                            });
                        }

                    }).catch(() => {
                    });
            })
    }
    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb



        //enhancement instead od (for ... index<4) if we not konw number of looping
        // while(temp.pread_crumb){
        //      routes.push({
        //                 name: temp.menu_name,
        //                 name_en: temp.menu_name_en,
        //                 link: temp.slug
        //             })
        //                 1temp = temp.pread_crumb

        // }
        //                 return routes.reverse();


        for (let index = 0; index < 4; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }

    // disableActive() {
    //     this.setState({ active: false })
    // }

    onYearClick = event => {
        this.setState({
            btnClass: "yearBtns"
        })
        let tempArray = this.props.awards[event.target.value];
        let categoriesToBeRenderedAr = [];
        let categoriesToBeRenderedEn = [];
        for (var x = 0; x < tempArray.length; x++) {
            let element = tempArray[x];
            let categoryName = element.name;
            let categoryNameEn = element.name_en;
            let catAr = <button className="categoryFilterBTNS" onClick={this.filterClick} value={this.props.lng === 'ar' ? categoryName : categoryNameEn}>{categoryName}</button>;
            categoriesToBeRenderedAr.push(catAr);
            let catEn = <button className="categoryFilterBTNS" onClick={this.filterClick} value={this.props.lng === 'ar' ? categoryName : categoryNameEn}>{categoryNameEn}</button>;
            categoriesToBeRenderedEn.push(catEn);
        }
        if (this.props.lng === 'ar') {
            this.setState({
                currentYear: event.target.value,
                filtersToBeRenderedAr: categoriesToBeRenderedAr,
                filtersToBeRenderedEn: categoriesToBeRenderedEn,
                selectedCategory: tempArray[0].name,
            })
        } else {
            this.setState({
                currentYear: event.target.value,
                filtersToBeRenderedAr: categoriesToBeRenderedAr,
                filtersToBeRenderedEn: categoriesToBeRenderedEn,
                selectedCategory: tempArray[0].name_en,
            })
        }
    }

    filterClick = event => {
        this.setState({
            selectedCategory: event.target.value
        });
    }

    openModal(value) {
        this.setState({
            modalName: this.props.lng == "ar" ? value.name : value.name_en,
            modalImg: value.image,
            modalDesc: this.props.lng == "ar" ? value.account_brief : value.account_brief_en,
            modalWinReason: this.props.lng == "ar" ? value.win_reason : value.win_reason_en,
            modalTwitterLink: value.twitter_link,
            modalProjectBief: this.props.lng == "ar" ? value.project_brief : value.project_brief_en,
            show: true,
        });
    }

    render() {
        // console.log("prop", this.props)
        // console.log("state", this.state)
        return (
            this.props.page &&
            <div className="consumerProtectionAward-container">
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                <Container className="consumerProtectionAward-content">
                    <div>
                        <div xs={12} className="categoryRow">
                            {this.props.lng === 'ar' ? this.state.filtersToBeRenderedAr : this.state.filtersToBeRenderedEn}
                            <CpaSharing active={'active'} />
                        </div>
                    </div>
                    <div>
                        <div itemRef={this.btnRef} xs={1} className="yearsContainer">
                            {/* {this.state.yearsToBeRendered} */}
                            {
                                this.props.awards &&
                                Object.keys(this.props.awards).reverse().map(year => {
                                    return (
                                        <button key={year} className={year === this.state.currentYear ? "yearBtns active" : "yearBtns"} onClick={this.onYearClick} value={year}>{year}</button>
                                    )
                                })
                            }
                        </div>
                        <div>
                            <div>
                                {(this.props.awards !== null && this.state.currentYear !== null && this.state.selectedCategory !== null) &&
                                    this.props.awards[this.state.currentYear].map((value) => {
                                        if (value.name === this.state.selectedCategory || value.name_en === this.state.selectedCategory) {
                                            return (

                                                // value.awards_normal_users.map((value1, index1) => {
                                                //     return (
                                                //         <Col xs={12} lg={4} key={index1} onClick={() => this.openModal(value1)}>

                                                //             <ContainerCard>
                                                //                 <div className="container-id-card">
                                                //                     <Row className="card-row">
                                                //                         <Col className="col-img" xs={6}>
                                                //                             <div className="card-img ">
                                                //                                 <img className="shadow-sm" src={this.state.url + value1.image} />
                                                //                             </div>
                                                //                         </Col>
                                                //                         <Col xs={6}>
                                                //                             <div>
                                                //                                 <h6 className="cardTitle">{this.props.lng == "ar" ? value1.name : value1.name_en}</h6>
                                                //                             </div>
                                                //                             <a className="pointer" onClick={() => this.openModal(value1)}><i className="icon-angle-double-left"></i></a>
                                                //                         </Col>
                                                //                     </div>
                                                //                 </div>
                                                //             </ContainerCard>
                                                //         </Col>
                                                //     )
                                                // })

                                                <div>

                                                    <div className="row justify-content-start">
                                                        {value.awards_normal_users.map((value1, index1) => {
                                                            return (
                                                                <div className="col-12 col-lg-6 col-xl-4 " key={index1}>

                                                                    <div className="card-container">
                                                                        <ContainerCard>
                                                                            <IdCard user={value1}>
                                                                                <a className="pointer" onClick={() => this.openModal(value1)}><i className="icon-angle-double-left"></i></a>
                                                                            </IdCard>
                                                                        </ContainerCard>

                                                                    </div>

                                                                </div>

                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                            </div>
                        </div>
                    </div>
                    <Modal dialogClassName="members-modal" size="xl" show={this.state.show} onHide={this.handleClose}>
                        <ContainerCard>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                                <div className="container-id-card" >
                                    <div className="card-row row">
                                        <div className="card-img col-12 col-md-6">
                                            <img className="shadow-lg" src={this.state.url + this.state.modalImg} />
                                        </div>
                                        <div className="card-content col-12 col-md-6 pr-0 pl-0">
                                            <h4>{this.state.modalName}</h4>
                                            <p>{ReactHtmlParser(this.state.modalDesc)}</p>

                                            <span>{ReactHtmlParser(this.state.modalWinReason)}</span>
                                            <div className="card-links">
                                                <a href={this.state.modalTwitterLink} target="blank"><i className="icon-twitter"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </ContainerCard>
                    </Modal>
                </Container>
            </div>
        )
    }
}
ConsumerProtectionAward.propTypes = {
    actions: PropTypes.object.isRequired,
    awards: PropTypes.array.isRequired,
    lng: PropTypes.string.isRequired,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired
};
function mapStateToProps(state) {
    return {
        awards: state.consumer_award.awards,
        lng: state.user.lng,
        page: state.navigation.page
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ConsumerProtectionAward)
