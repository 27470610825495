import {
    GET_ERSHEDNA_SECTIONS,
    GET_ERSHEDNA_PROBLEMS,
    GET_ERSHEDNA_ORGANIZATIONS,
    REMOVE_GUIDE_US_PROPS,
    GET_ERSHDNA_CATEGORIES,
    GET_ERSHDNA_CATEGORY,
    GET_ERSHDNA_SUBS,
    GET_HEAR_YOU_TITLE_PARAGRAPH,
    GET_HEAR_YOU_DESCRIPTION_PARAGRAPH,
    GET_HEAR_YOU_COMPLAINT_PARAGRAPH
} from '../../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './../../initialState';

/**
 *
 * @param state: object
 * @param action: object
 * @returns state: object
 */
export default function complainReducer(state = initialState.programs_and_activities.programs.ershedna, action) {
    switch (action.type) {
        case GET_ERSHEDNA_SECTIONS.SUCCESS:
            return objectAssign({}, state, { sections: action.payload });
        case GET_ERSHEDNA_PROBLEMS.SUCCESS:
            return objectAssign({}, state, { problems: action.payload });
        case GET_ERSHEDNA_ORGANIZATIONS.SUCCESS:
            return objectAssign({}, state, { organizations: action.payload });
        case REMOVE_GUIDE_US_PROPS.SUCCESS:
            return objectAssign({}, state, {
                problems: action.payload.problems,
                organizations: action.payload.organizations
            });
        case GET_ERSHDNA_CATEGORIES.SUCCESS:
            return objectAssign({}, state, { ershdnaCategories: action.payload });
        case GET_ERSHDNA_CATEGORY.SUCCESS:
            return objectAssign({}, state, { ershdnaCategory: action.payload });
        case GET_ERSHDNA_SUBS.SUCCESS:
            return objectAssign({}, state, { ershdnaSubs: action.payload });
        case GET_HEAR_YOU_TITLE_PARAGRAPH:
            return objectAssign({}, state, { head: action.payload });
        case GET_HEAR_YOU_DESCRIPTION_PARAGRAPH:
            return objectAssign({}, state, { desc: action.payload });
        case GET_HEAR_YOU_COMPLAINT_PARAGRAPH:
            return objectAssign({}, state, { guideUsDesc: action.payload });
        default:
            return state;
    }
}
