import { apiFetch } from '../../../utils/http.helpers';
import { GET_CONSUMER_DATA, GET_CONSUMER_DATA_BY_ID } from '../../../constants/actionTypes';
import { BASE_URL } from '../../../constants/config';

export const getConsumerData = () => {
  return function (dispatch) {
    return apiFetch(BASE_URL + '/api/consumerAdvisor/search', {
      method: "GET",
      // withCredentials: false,
      headers: {
        "Accept": "application/json",
        // "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
      }
    })
      .then(Response => Response.json())
      .then(res => {
        dispatch({ type: GET_CONSUMER_DATA, payload: res })
      })
      .catch(error => { throw error })
  }
}

export const getConsumerDataById = (id) => {
  return function (dispatch) {
    return apiFetch(BASE_URL + '/api/consumerAdvisor/getConsumerAdvisorById?consumerAdvisorId=' + id, {
      method: "GET",
      headers: {
        "Accept": "application/json",
      }
    })
      .then(Response => Response.json())
      .then(Response => {
        // console.log("res", Response)//[]
        if (!Array.isArray(Response)) {

          dispatch({ type: GET_CONSUMER_DATA_BY_ID, payload: Response })
        } else {
          // console.log("arr")
        }
      })
      .catch(error => { throw error })
  }

}