import { GET_IMP_LINKS_DATA } from '../../../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../../../initialState';

export default function impLinksReducer(state = initialState.programs_and_activities.programs.legal_advocay.imp_links, action) {
  switch (action.type) {
    case GET_IMP_LINKS_DATA.SUCCESS:
      return objectAssign({}, state, { links: action.payload });
    default:
      return state;
  }
}