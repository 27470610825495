import React from 'react';
import './takeScience.scss';
import ImgHeader from '../../../GlobalComponent/ImgHeader/ImgHeader';
import BreadCrumb from '../../../GlobalComponent/BreadCrumb/BreadCrumb';
// import CpaPagination from '../../../GlobalComponent/CpaPagination/CpaPagination';
import VideoCard from '../../../GlobalComponent/videoCard/videoCard';
import Translate from '../../../GlobalComponent/translate/translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../../actions/program-and-activities/programs/takeKnowledge';
import * as navigationActions from '../../../../actions/navigation';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import CpaSharing from '../../../GlobalComponent/cpaSharing/cpaSharing';

class TakeScience extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            indexOfLastCard: 9,
            indexOfFirstCard: 0,
            routes: [],
            vids: [
                {
                    link: "D_6gWPbQCtc",
                    header: 'خذ العلم الحلقة 1 "البضائع والمنتجات المقلدة"',
                    date: " الخميس 13 ديسمبر 2017",
                    viewers: "389"
                },
                {
                    link: "CpA_aSlmrNs",
                    header: 'خذ العلم الحلقة 2 "ضريبة القيمة المضافة"',
                    date: " الأثنين 25 ديسمبر 2017",
                    viewers: "987"
                },
                {
                    link: "BGs9uoD-70",
                    header: 'خذ العلم الحلقة 3 "الشراء من المتاجر الالكترونية"',
                    date: " الجمعة 29 ديسمبر 2017",
                    viewers: "129"
                },
                {
                    link: "6QmPrt1Coa0",
                    header: 'خذ العلم الحلقة 4 "دليل حقوق المستهلك في أنظمة وزارة التجارة والاستثمار"',
                    date: " الخميس 19 ابريل 2018",
                    viewers: "166"
                },
                {
                    link: "dUJEZxFIXnw",
                    header: 'خذ العلم الحلقة 5 "موثوقية المتاجر الالكترونية"',
                    date: " الخميس 17 مايو 2018",
                    viewers: "137"
                },
                {
                    link: "cKDQ0nfgcEg",
                    header: 'خذ العلم الحلقة 6 "نصائح وإرشادات عند إستخدام قدر الضغط"',
                    date: " الجمعة 1 مترس 2019",
                    viewers: "101"
                }
            ]
        };
    }

    addCards() {
        this.setState({ indexOfLastCard: this.state.indexOfLastCard + 9 })
    }

    componentDidMount() {
        // console.log('Do it for m');
        this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                this.props.actions.getKnowledgeData()
                    .then(() => {
                        this.setState({ ...this.state, loading: false });
                        // console.log("return data: ", this.state);

                    }).catch(() => {

                    });
            })
    }
    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }
    render() {

        let currentCards = [];
        if (this.props.knowledgeArray !== null) {
            currentCards = this.props.knowledgeArray.slice(this.state.indexOfFirstCard, this.state.indexOfLastCard);
        }
        let moreButton;
        if (this.props.knowledgeArray) {
            moreButton =
                currentCards.length !== this.props.knowledgeArray.length &&
                <button onClick={() => { this.addCards() }} className="green" type="button"><Translate text="theMore" /></button>
        }


        return (
            this.props.page &&
            <div className="takeScience-container">
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                <div className="container">
                    <h3><i className="icon-videos"></i><Translate text="takeKnowledge" /> </h3>
                    <CpaSharing active={'active'} />
                    <div className="row">
                        {this.state.loading && <div><p>Loading ...</p></div>}
                        {currentCards.length > 0
                            && currentCards.map((value, index) => {
                                return (
                                    <div className="col-12 col-lg-4" key={index}>
                                        {/* <Link to="/videoShow"> */}
                                        <VideoCard videoCard={value} />
                                        {/* </Link> */}
                                    </div>
                                )
                            })}
                        <div style={{ textAlign: "center" }} className="col-12">
                            {/* <CpaPagination page="1"/> */}
                            {moreButton}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
TakeScience.propTypes = {
    actions: PropTypes.object.isRequired,
    knowledgeArray: PropTypes.array.isRequired,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired
};
function mapStateToProps(state) {
    return {
        knowledgeArray: state.TakeKnowledge.knowledgeArray,
        page: state.navigation.page
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TakeScience);