import React from 'react';
import BreadCrumb from '../../../GlobalComponent/BreadCrumb/BreadCrumb';
import ImgHeader from '../../../GlobalComponent/ImgHeader/ImgHeader';
// import ImgCard from '../../../GlobalComponent/ImgCard/ImgCard';
// import IconCard from '../../../GlobalComponent/IconCard/IconCard';
// import HomeSectionFC from '../../../GlobalComponent/HomeSection/HomeSectionFC';
// import HomeSection from '../../../GlobalComponent/HomeSection/HomeSection';
import { Container, Col } from 'react-bootstrap';
// import NewsCard from '../../../GlobalComponent/newsCard/newsCard';
import SectionCard from '../../programs/motamken/SectionCard/SectionCard';
import './consumer-consultant.scss';
import * as actions from '../../../../actions/program-and-activities/programs/concumerConsultant';
import * as navigationActions from '../../../../actions/navigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { BASE_URL } from '../../../../../src/constants/config'
import Translate from '../../../GlobalComponent/translate/translate';
import { t } from 'i18next'
import filter from '../../../../actions/FilterBy';

class ConsumerConsultant extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      indexOfLastCard: 6,
      indexOfFirstCard: 0,
      show: false,
      showModal: false,
      ModalImg: '',
      main_card: null,
      consumer_items: null,
      image: null,
      url: BASE_URL,
      episodes: [],
      routes: [],
      consult_data: null,
      lastSelectedYear: null,
      lastSelectedCategory: null,
      loading: true

    };
  }
  showStatus = () => {
    this.setState({ show: true })
  }
  closeStatus = () => {
    this.setState({ show: false })
  }
  handleShow = (value) => {
    this.setState({ ModalImg: value })
    this.setState({ showModal: true })
  }
  handleClose = () => {
    this.setState({ showModal: false })
  }
  componentDidMount() {
    this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
      () => {
        this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
        this.props.actions.getConsumerData()
          .then(() => {
            this.setState({ ...this.state, loading: false, consult_data: this.props.consult_data });
            if (this.props.consult_data.length == 0) {
              this.setState({
                noData:
                  <div className="notFound-container">
                    <div className="notFound-content text-center">
                      <div className="inner">
                        <h3>
                          {t("noData")}
                        </h3>
                      </div>
                    </div>
                  </div>
              })
            }
          }).catch(() => {
          });
      })
  }
  filterByYear = (year) => {
    this.setState({
      consult_data: filter.filterByYear(this.props.consult_data, year, this.state.lastSelectedCategory),
      lastSelectedYear: year
    })
    setTimeout(() => {
      this.CheckSubjuctNoData()
    }, 1);
  }
  filterByCategory = (catgeory) => {
    this.setState({
      consult_data: filter.filterByCategory(this.props.consult_data, catgeory, this.state.lastSelectedYear),
      lastSelectedCategory: catgeory
    })
    setTimeout(() => {
      this.CheckSubjuctNoData()
    }, 1);
  }
  CheckSubjuctNoData = () => {
    if (this.state.consult_data.length == 0) {
      this.setState({
        noData:
          <div className="notFound-container">
            <div className="notFound-content text-center">
              <div className="inner">
                <h3>
                  {t("noData")}
                </h3>
              </div>
            </div>
          </div>
      })
    } else {
      this.setState({
        noData: '',
      })
    }
  }
  setRoutesFormApi(page) {

    let routes = []
    let temp = page.pread_crumb
    for (let index = 0; index < 3; index++) {
      routes.push({
        name: temp.menu_name,
        name_en: temp.menu_name_en,
        link: temp.slug
      })
      if (temp.pread_crumb) {
        temp = temp.pread_crumb
      } else {
        return routes.reverse();
      }
    }
  }



  addCards() {
    this.setState({ indexOfLastCard: this.state.indexOfLastCard + 6 })
  }

  render() {
    let currentCards = [];
    if (this.state.consult_data !== null) {
      currentCards = this.state.consult_data.slice(this.state.indexOfFirstCard, this.state.indexOfLastCard);
    }
    let moreButton;
    if (this.state.consult_data) {
      moreButton =
        currentCards.length !== this.state.consult_data.length &&
        <button onClick={() => { this.addCards(), this.showStatus() }} className="green" type="button"><Translate text="theMore" /></button>
    }

    return (

      this.props.page &&
      <div className="consumer-consultant-container">
        <ImgHeader page={this.props.page} />
        <BreadCrumb
          routes={this.state.routes}
          filterbyYear={true}
          filterbySubject={true}
          filterByYearfun={this.filterByYear}
          filterByCategoryfun={this.filterByCategory} />
        <div className="mt-1 mb-1">
          <Container className="episode-container">
            {this.state.loading && <div><p>Loading ...</p></div>}
            {this.state.consult_data && this.state.consult_data.length > 0 &&
              <div>
                <div className={"row videos-container-" + (this.state.show ? ' more' : 'less')}>
                  {currentCards.map((value, index) => {
                    return (
                      <Col xs={12} lg={4} key={index} onClick={() => this.handleShow(value.episodeImg)}>
                        <SectionCard consultantCard={value} url={"consultantUser"} />
                      </Col>
                    )
                  })}
                </div>
                <div className="row">
                  <div style={{ marginTop: "1rem" }} className="col-12 text-center">
                    {moreButton}
                  </div>
                </div>
              </div>
            }
            {this.state.noData}
          </Container>
        </div>
      </div>
    )
  }
}
ConsumerConsultant.propTypes = {
  actions: PropTypes.object.isRequired,
  consult_data: PropTypes.object.isRequired,
  match: PropTypes.object,
  "match.url": PropTypes.string,
  "match.url.includes": PropTypes.func,
  page: PropTypes.object.isRequired,
  navigationActions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    consult_data: state.consumerConsult.consult_data,
    page: state.navigation.page
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    navigationActions: bindActionCreators(navigationActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerConsultant)
