import React from 'react';
import ImgCard from '../../GlobalComponent/ImgCard/ImgCard';
import IconCard from '../../GlobalComponent/IconCard/IconCard';
import BreadCrumb from '../../GlobalComponent/BreadCrumb/BreadCrumb';
import ImgHeader from '../../GlobalComponent/ImgHeader/ImgHeader';
import './AboutPage.scss';
import * as actions from '../../../actions/El-Gam3ya/about';
// step to add
import * as navigationActions from '../../../actions/navigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { BASE_URL } from '../../../constants/config';
// Since this component is simple and static, there's no parent container for it.
class AboutPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true,
      img_url: BASE_URL,
      routes: []
    }
  }

  componentDidMount() {
    // step to add
    this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
      () => {
        this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
        this.props.actions.getAboutData()
          .then(() => {
            this.setState({ ...this.state, loading: false });
          }).catch();
      }
    )
  }
  // step to add
  setRoutesFormApi(page) {

    let routes = []
    let temp = page.pread_crumb
    for (let index = 0; index < 3; index++) {
      routes.push({
        name: temp.menu_name,
        name_en: temp.menu_name_en,
        link: temp.slug
      })
      if (temp.pread_crumb) {
        temp = temp.pread_crumb
      } else {
        return routes.reverse();
      }
    }
  }
  render() {
    return (
      /* // step to add */
      this.props.page &&
      <div className="about-content-page" id="testtt">
        {/* // step to add */}
        <ImgHeader page={this.props.page} />
        <BreadCrumb
          routes={this.state.routes}
          filterbyYear={false}
          filterbySubject={false} />
        {this.props.about_array.map((value, index) => {
          let card = {
            icon: value.image,
            title: value.title,
            title_en: value.title_en,
            description: value.description,
            description_en: value.description_en
          }
          return (
            <div className="about-row" key={index}>
              <div className="container">
                <div className="row m-0 justify-content-start">
                  <div className="col-12 col-lg-4">
                    <ImgCard imgSrc={this.state.img_url + '/uploads/' + value.image} />
                  </div>
                  <div className="col-12 col-lg-8">
                    <IconCard card={card} show_more={true} />
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}
AboutPage.propTypes = {
  actions: PropTypes.object.isRequired,
  about_array: PropTypes.array.isRequired,
  // step to add
  page: PropTypes.object.isRequired,
  navigationActions: PropTypes.object.isRequired
};
function mapStateToProps(state) {
  return {
    about_array: state.about.about_array,
    // step to add
    page: state.navigation.page
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    // step to add
    navigationActions: bindActionCreators(navigationActions, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AboutPage)
