
import { Row, Col } from 'react-bootstrap';
import React from 'react';
import './shareYourRating.scss';
import BreadCrumb from '../../../GlobalComponent/BreadCrumb/BreadCrumb';
import ImgHeader from '../../../GlobalComponent/ImgHeader/ImgHeader';
import * as navigationActions from '../../../../actions/navigation';
import * as actions from '../../../../actions/program-and-activities/programs/compareAndRate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BASE_URL } from '../../../../constants/config';
import PropTypes from 'prop-types';
import Translate from '../../../GlobalComponent/translate/translate';
import CpaSharing from '../../../GlobalComponent/cpaSharing/cpaSharing';

class ShareYourRating extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // loading: true,
            img_url: BASE_URL + '/uploads/',
            routes: [
                {
                    name: 'البرامج',
                    name_en: 'Programs',
                    link: 'landing/8',
                },
                {
                    name: 'قارن و قيمها',
                    name_en: 'Compare And Rate',
                    link: 'compare-and-rate/category',
                },
                {
                    name: 'شاركنا تقييمك',
                    name_en: 'share Your Rating',
                    link: '/compare-and-rate/shareYourRating',
                },
            ],
            page: {
                name: 'شاركنا تقييمك',
                name_en: 'Share Your Rating'
            }
        }
    }

    componentDidMount() {
        // this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
        // () => {
        // this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
        this.props.actions.getMultiCategoriesByIdWithEachSub(parseInt(this.props.match.params.branchId)).then(() => {
            let ratingsIndex = this.props.multiCategory[0].multi_categories_many.findIndex(cat => cat.id == this.props.match.params.id);
            this.props.actions.setFirstCollapseIndex(ratingsIndex);
            this.props.actions.setFirstCollapseId(this.props.match.params.id);
        })
        // }
        // )
    }

    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }

    render() {
        // console.log(" start share your rate")
        // console.log("proProps", this.props)
        // console.log("proState", this.state)
        // console.log(" end share your rate")

        // console.log("this.props.match.params.branchId ", this.props.match.params.branchId)
        // console.log("this.props.match.params.id", this.props.match.params.id)
        return (
            <div className="shareRating-container">
                <ImgHeader page={this.state.page.name} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                {/* <Row>
                    <Col sm={12} className="topMenu">
                        {this.props.match.params.id && <button onClick={() => this.props.history.push('/compare-and-rate/category/general-tips/' + parseInt(this.props.match.params.id) + '/' + this.props.firstCollapseId)} className="btn submit-cpa-btn"><Translate text="generalTips" /></button>}
                        {this.props.match.params.branchId && <button onClick={() => this.props.history.push('/compare-and-rate/category/general-tips/' + parseInt(this.props.match.params.branchId) + '/' + this.props.firstCollapseId)} className="btn submit-cpa-btn"><Translate text="generalTips" /></button>}
                        <button className="btn submit-cpa-btn active"><Translate text="shareYourRating" /></button>
                        <button onClick={() => this.props.history.push('/compare-and-rate/category/previous-ratings/' + parseInt(this.props.match.params.branchId) + '/' + this.props.firstCollapseId)} className="btn submit-cpa-btn"><Translate text="thePreviousRatings" /></button>
                        <button onClick={() => this.props.history.push('/compare-and-rate/categories/' + parseInt(this.props.match.params.branchId) + '/' + this.props.firstCollapseId)} className="btn submit-cpa-btn"><Translate text="compare" /></button>
                    </Col>
                </Row> */}

                <Row>
                    <Col sm={12} className="topMenu">
                        {/* <button onClick={() => this.props.history.push('/compare-and-rate/category/general-tips/' + parseInt(this.props.match.params.id) + '/' + this.props.firstCollapseId)} className="btn submit-cpa-btn"><Translate text="generalTips" /></button> */}
                        <button onClick={() => this.props.history.push('/compare-and-rate/category/general-tips/' + parseInt(this.props.match.params.branchId) + '/' + this.props.firstCollapseId)} className="btn submit-cpa-btn"><Translate text="generalTips" /></button>
                        <button className="btn submit-cpa-btn active"><Translate text="shareYourRating" /></button>
                        <button onClick={() => this.props.history.push('/compare-and-rate/category/previous-ratings/' + parseInt(this.props.match.params.branchId) + '/' + this.props.firstCollapseId)} className="btn submit-cpa-btn"><Translate text="thePreviousRatings" /></button>
                        <button onClick={() => this.props.history.push('/compare-and-rate/categories/' + parseInt(this.props.match.params.branchId) + '/' + this.props.firstCollapseId)} className="btn submit-cpa-btn"><Translate text="compare" /></button>
                    </Col>
                </Row>
                <CpaSharing active={'active'} />
                {/* {console.log(this.props.multiCategory &&
                    this.props.multiCategory.length > 0 &&
                    this.props.multiCategory[0].multi_categories_many.length > 0 &&
                    this.props.firstCollapseIndex !== -1 &&
                    this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex]
                    // &&
                    // this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex] !== null)
                    // &&
                    // this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].share_rating !== null
                )
                } */}

                {
                    this.props.multiCategory &&
                    this.props.multiCategory.length > 0 &&
                    this.props.multiCategory[0].multi_categories_many.length > 0 &&
                    this.props.firstCollapseIndex !== -1 &&
                    this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex] &&
                    this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex] !== null &&
                    this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].share_rating !== null
                    &&
                    <iframe src={this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].share_rating}></iframe>
                }
            </div>
        )
    }
}

ShareYourRating.propTypes = {
    location: PropTypes.object,
    "location.state": PropTypes.object,
    "location.state.surveyLink": PropTypes.string,
    multiCategory: PropTypes.array,
    "multiCategory[].multi_categories_many": PropTypes.array,
    firstCollapseIndex: PropTypes.number,
    navigationActions: PropTypes.object.isRequired,
    "navigationActions.getPageInfoApi": PropTypes.func.isRequired,
    page: PropTypes.object,
    match: PropTypes.object,
    "match.params": PropTypes.object,
    "match.params.id": PropTypes.string,
    actions: PropTypes.object,
    "actions.getMultiCategoriesByIdWithEachSub": PropTypes.func,
    history: PropTypes.object,
    "history.push": PropTypes.func,
    firstCollapseId: PropTypes.number
}


function mapStateToProps(state) {
    return {
        lng: state.user.lng,
        page: state.navigation.page,
        multiCategory: state.compare_and_rate.multiCategories,
        firstCollapseIndex: state.compare_and_rate.firstCollapseIndex,
        firstCollapseId: state.compare_and_rate.firstCollapseId
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ShareYourRating);