import { GET_NEWS_DATA } from '../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../initialState';

export default function newsReducer(state = initialState.information_center.news, action) {
    switch (action.type) {
        case GET_NEWS_DATA.SUCCESS:
            return objectAssign({}, state, {
                new: action.payload.new,
                more: action.payload.more


            });
        default:
            return state;
    }
}