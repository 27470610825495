import React from 'react';
// import NewsCard from '../../../GlobalComponent/newsCard/newsCard';
import ImgHeader from '../../../GlobalComponent/ImgHeader/ImgHeader';
import BreadCrumb from '../../../GlobalComponent/BreadCrumb/BreadCrumb';
import ArticaleCard from '../../../GlobalComponent/articaleCard/articaleCard';
import * as actions from '../../../../actions/information-center/singleNews';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as navigationActions from '../../../../actions/navigation';
import './singleCard.scss';

// import {Link} 
// import { Link } from 'react-router-dom';
// import DefaultPage from './../../../GlobalComponent/defaultPage/DefaultPage';
class SingleCard extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.myDivToFocus = React.createRef();
        this.state = {
            /// to simulate that we got the data from the backend
            consumerConsultant: {
                id: 1,
                title: 'حقوق و واجبات المستهلك عند هيئة الطيران',
                date: '12-8-2019',
                attachments: ['https://gdurl.com/98h5', 'https://gdurl.com/Nzya'],
                routes: [{
                    name: 'homePage',
                    link: ''
                },
                {
                    name: 'consumerConsultant',
                    link: 'consumer-advisor'
                }],
                description: `وريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور

                أنكايديديونتيوت لابوري ات دولار ماجنا أليكيوا . يوت انيم أد مينيم فينايم,كيواس نوستريد
                
                أكسير سيتاشن يللأمكو لابورأس نيسي يت أليكيوب أكس أيا كوممودو كونسيكيوات . ديواس
                
                أيوتي أريري دولار إن ريبريهينديرأيت فوليوبتاتي فيلايت أيسسي كايلليوم دولار أيو فيجايت
                
                نيولا باراياتيور. أيكسسيبتيور ساينت أوككايكات كيوبايداتات نون بروايدينت ,سيونت ان كيولبا
                
                كيو أوفيسيا ديسيريونتموليت انيم أيدي ايست لابوريوم."
                
                "سيت يتبيرسبايكياتيس يوندي أومنيس أستي ناتيس أيررور سيت فوليبتاتيم أكيسأنتييوم
                
                دولاريمكيو لايودانتيوم,توتام ريم أبيرأم,أيكيو أبسا كيواي أب أللو أنفينتوري فيرأتاتيس ايت
                
                كياسي أرشيتيكتو بيتاي فيتاي ديكاتا سيونت أكسبليكابو. نيمو أنيم أبسام فوليوباتاتيم كيواي
                
                فوليوبتاس سايت أسبيرناتشر أيوت أودايت أيوت فيوجايت, سيد كيواي كونسيكيونتشر ماجناي
                
                دولارس أيوس كيواي راتاشن فوليوبتاتيم سيكيواي نيسكايونت. نيكيو بوررو كيوايسكيوم
                
                ايست,كيواي دولوريم ايبسيوم كيوا دولار سايت أميت, كونسيكتيتيور,أديبايسكاي فيلايت, سيد
                
                كيواي نون نيومكيوام ايايوس موداي تيمبورا انكايديونت يوت لابوري أيت دولار ماجنام
                
                ألايكيوام كيوايرات فوليوبتاتيم. يوت اينايم أد مينيما فينيام, كيواس نوستريوم أكسيركايتاشيم
                
                يلامكوربوريس سيوسكايبيت لابورايوسام, نايساي يوت ألايكيوايد أكس أيا كوموداي
                
                كونسيكيواتشر؟ كيوايس أيوتيم فيل أيوم أيوري ريبريهينديرايت كيواي ان إيا فوليوبتاتي
                
                فيلايت ايسسي كيوم نايهايل موليستايا كونسيكيواتيو,فيلايليوم كيواي دولوريم أيوم فيوجايات كيو
                
                فوليوبتاس نيولا باراياتيور؟`

            },
            show: false,
            routes: [],
        };
    }

    getData = (props) => {
        const { match: { params } } = props;
        this.props.actions.getSingleNewsData(params.id)
            .then(() => {
                this.setState({ ...this.state, loading: false });
            }).catch(() => {

            });
    }

    handleScrollToDiv = () => {
        //.current is verification that your element has rendered
        if (this.myDivToFocus.current) {
            this.myDivToFocus.current.scrollIntoView({
                behavior: "smooth",
                block: "start"
            })
        }
    }
    componentDidMount() {
        this.handleScrollToDiv();
        var pageName = this.props.location.pathname;
        pageName = pageName.split('/')[1];
        if (pageName === "single-news") {
            this.props.navigationActions.getPageInfoApi('/recent-news').then(
                () => {
                    this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                    this.getData(this.props);
                }
            )
            // .catch(err => {
            //     // console.log("err", err)
            // })
        } else if (pageName === "single-data") {
            this.props.navigationActions.getPageInfoApi('/statements').then(
                () => {
                    this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                    this.getData(this.props);
                }
            )
            // .catch(err => {
            //     console.log("err", err)
            // })
        }
    }
    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                // console.log("if", index)
                temp = temp.pread_crumb
            } else {
                // console.log("else", index)

                return routes.reverse();
            }
        }
        // console.log("P", page)

    }
    render() {
        // console.log("prop", this.props)
        // if (
        //     this.props.page && !this.props.object.id && !this.object.message
        // ) {

        return (

            <div>
                {/* {console.log(this.props.object.mediaCenter)} */}
                {this.props.page &&
                    <div className="singleCard-container" ref={this.myDivToFocus}>
                        <ImgHeader page={this.props.page} />
                        <BreadCrumb
                            routes={this.state.routes}
                            filterbyYear={false}
                            filterbySubject={false} />
                        <div className="container">
                            <div className="main-card">
                                <ArticaleCard card={this.props.object.mediaCenter} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
        // }
        // else return <div></div>
        //  {
        //     return (<div>
        //         {this.props.object && (this.props.object.id || this.props.object.message) &&
        //             <DefaultPage />}
        //     </div>)
        // }




    }
}
SingleCard.propTypes = {
    match: PropTypes.object.isRequired,
    object: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired
};
function mapStateToProps(state) {
    return {
        object: state.single_news.object,
        page: state.navigation.page
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SingleCard)
