import React from 'react';
import NewsCard from '../../GlobalComponent/newsCard/newsCard';
import ImgHeader from '../../GlobalComponent/ImgHeader/ImgHeader';
import BreadCrumb from '../../GlobalComponent/BreadCrumb/BreadCrumb';
import '../../GlobalComponent/CpaPagination/CpaPagination.scss'
import { Link } from 'react-router-dom';
// import { Container, Row, Col } from "react-bootstrap";

import * as actions from '../../../actions/information-center/news';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Pagination from 'react-bootstrap/Pagination';
import * as navigationActions from '../../../actions/navigation';
import './news.scss';
import Translate from '../../GlobalComponent/translate/translate';
import { BASE_URL } from '../../../constants/config';
// import filter from '../../../actions/FilterBy';
import { t } from 'i18next';

class news extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            show: false,
            loading: true,
            lng: localStorage.getItem('lng'),
            pageNumber: 1,
            tableToBeRendered: null,
            selectedPage: 1,
            elementsPerPage: 3,
            selectedYear: undefined,
            selectedCat: undefined,
            routes: [],
            mainNews: [
                {
                    img: BASE_URL + '/uploads//Images/default_image.jpg',
                    header: 'بيان جمعية حماية المستهلك حول عقود التمويل العقاري “سايبر” لعملاء بنك ساب',
                    date: ' الاحد 24 فبراير 2019',
                    viewers: '5,624'
                }
            ],
            moreNews: [
                {
                    img: BASE_URL + '/uploads//Images/default_image.jpg',
                    header: 'جمعية حماية المستهلك توقع مذكرة تفاهم مع جمعية ذوي شهداء الواجب',
                    date: ' الخميس 6 ديسمبر 2018',
                    viewers: '1,063'
                }
            ],
            new: null,
            more: null,
            newNoData: null,
            moreNoData: null
        };
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.setPage = this.setPage.bind(this);
    }
    componentDidUpdate(prevProps) {
        // console.log("up", this.props.location, "fff", prevProps.location)
        if (prevProps) {

            const { page, year, cat } = this.getUrl(this.props.location.search);
            const { page: pPage, year: Pyear, cat: pCat } = this.getUrl(prevProps.location.search);


            if (page != pPage || year != Pyear || cat != pCat) {
                //     console.log("Changed")
                this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
                    () => {
                        this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                        this.props.actions.getNewsData(page + 1, year, cat)
                            .then(() => {
                                this.setState({
                                    ...this.state,
                                    loading: false,
                                    pageNumber: page,
                                    new: this.props.new,
                                    more: this.props.more.data,
                                    selectedYear: year,
                                    selectedCat: cat,
                                }, () => {
                                    if (this.props.history.action != "POP") {

                                        window.scrollTo(0, 0)
                                    }
                                })
                                setTimeout(() => {
                                    this.CheckSubjuctNoData()
                                }, 1);

                            })
                            .catch(() => {
                                // console.log("err", err)
                            });
                    })
            }
        }

    }
    getUrl(url) {
        let obj = {}
        if (url) {
            url.substring(1).split("?").map(u => {
                if (u.includes("page")) {
                    let pageX = u.split("=")[1]
                    obj.page = pageX ? Number(pageX) : 1
                }
                else if (u.includes("year")) {
                    obj.year = u.split("=")[1]
                }
                else if (u.includes("cat")) {
                    obj.cat = u.split("=")[1]

                }
            })

        }

        obj.page = obj.page ? obj.page : 1;


        obj.cat = obj.cat ? obj.cat : undefined;


        obj.year = obj.year ? obj.year : undefined

        return obj;
    }
    componentDidMount() {
        const { page, year, cat } = this.getUrl(window.location.search);

        this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                this.props.actions.getNewsData(page + 1, year, cat)
                    .then(() => {
                        this.setState({
                            ...this.state,
                            loading: false,
                            pageNumber: page,
                            new: this.props.new,
                            more: this.props.more.data,
                            selectedYear: year,
                            selectedCat: cat,
                        }, () => {
                            setTimeout(() => {
                                this.CheckSubjuctNoData()
                            }, 1);
                            // this.renderTable(1);
                        })
                    }).catch((err) => {
                        if (err) {
                            // console.log("err")
                            this.props.history.push("/")
                            window.location.reload(false)

                        }
                    });
            })
    }
    filterByYear = (newYear) => {


        //  console.log("yar changes", newYear)

        let url = `/recent-news?page=${1}${newYear ? `?year=${newYear}` : ""}${this.state.selectedCat ? `?cat=${this.state.selectedCat}` : ""}`
        this.props.history.push(url);


    }
    filterByCategory = (newCatgeory) => {
        // console.log("cat changes", newCatgeory)

        let url = `/recent-news?page=${1}${this.state.selectedYear ? `?year=${this.state.selectedYear}` : ""}${newCatgeory ? `?cat=${newCatgeory}` : ""}`
        this.props.history.push(url)


    }
    nextPage() {
        //  console.log("next")

        if (this.state.pageNumber < this.props.more.last_page) {
            let url = `/recent-news?page=${this.state.pageNumber + 1}${this.state.selectedYear ? `?year=${this.state.selectedYear}` : ""}${this.state.selectedCat ? `?cat=${this.state.selectedCat}` : ""}`
            this.props.history.push(url);

        }

    }
    setPage(number) {
        //  console.log("set")

        let url = `/recent-news?page=${number}${this.state.selectedYear ? `?year=${this.state.selectedYear}` : ""}${this.state.selectedCat ? `?cat=${this.state.selectedCat}` : ""}`
        this.props.history.push(url);

    }
    previousPage() {
        if (this.state.pageNumber > 1) {
            //  console.log("pre")

            let url = `/recent-news?page=${this.state.pageNumber - 1}${this.state.selectedYear ? `?year=${this.state.selectedYear}` : ""}${this.state.selectedCat ? `?cat=${this.state.selectedCat}` : ""}`
            this.props.history.push(url);
        }
    }

    renderTable(cpn) {
        if (this.state.more !== null) {
            let startFrom = (cpn * this.state.elementsPerPage) - this.state.elementsPerPage;
            let renderTo = startFrom + (this.state.elementsPerPage - 1);
            let elements = [];
            for (var x = startFrom; x <= renderTo; x++) {
                let element = this.state.more[x];
                if (element != null) {
                    let id = element.id;
                    let toBeRendered =
                        <Link to={"/single-news/" + id} key={x}>
                            <NewsCard newscard={element} />
                        </Link>
                    elements.push(toBeRendered);
                } else {
                    break;
                }
            }
            this.setState({
                tableToBeRendered: elements
            });
        }
    }

    // filterByYear = (year) => {
    //     this.props.actions.getNewsData(this.state.pageNumber + 1, year, this.state.lastSelectedCategory)
    //         .then(() => {
    //             this.setState({
    //                 ...this.state,
    //                 loading: false,
    //                 pageNumber: 2,
    //                 new: this.props.new,
    //                 more: this.props.more.data,
    //                 lastSelectedYear: year,
    //                 selectedPage: 1

    //             }, () => {
    //                 this.renderTable(1);
    //             })
    //             setTimeout(() => {
    //                 this.CheckSubjuctNoData()
    //             }, 1);
    //         }).catch(() => {
    //         });
    //     // this.setState({
    //     //     new: filter.filterByYear(this.props.new, year, this.state.lastSelectedCategory),
    //     //     more: filter.filterByYear(this.props.more.data, year, this.state.lastSelectedCategory),
    //     //     lastSelectedYear: year,
    //     //     selectedPage: 1
    //     // },
    //     //     () => {
    //     //         this.setState({
    //     //             pageNumber: Math.ceil(this.state.more.length / this.state.elementsPerPage),
    //     //         })
    //     //         this.renderTable(1);
    //     //     })
    //     // setTimeout(() => {
    //     //     this.CheckSubjuctNoData()
    //     // }, 1);
    // }

    // filterByCategory = (catgeory) => {
    //     this.props.actions.getNewsData(this.state.pageNumber + 1, this.state.lastSelectedYear, catgeory)
    //         .then(() => {
    //             this.setState({
    //                 ...this.state,
    //                 loading: false,
    //                 pageNumber: 2,
    //                 new: this.props.new,
    //                 more: this.props.more.data,
    //                 lastSelectedCategory: catgeory,
    //                 selectedPage: 1
    //             }, () => {
    //                 this.renderTable(1);
    //             })
    //             setTimeout(() => {
    //                 this.CheckSubjuctNoData()
    //             }, 1);
    //         }).catch(() => {
    //         });

    //     // this.setState({
    //     //     new: filter.filterByCategory(this.props.new, catgeory, this.state.lastSelectedYear),
    //     //     more: filter.filterByCategory(this.props.more.data, catgeory, this.state.lastSelectedYear),
    //     //     lastSelectedCategory: catgeory,
    //     //     selectedPage: 1
    //     // },
    //     //     () => {
    //     //         this.setState({
    //     //             pageNumber: Math.ceil(this.state.more.length / this.state.elementsPerPage),
    //     //         })
    //     //         this.renderTable(1);
    //     //     })
    //     // setTimeout(() => {
    //     //     this.CheckSubjuctNoData()
    //     // }, 1);
    // }

    CheckSubjuctNoData = () => {
        if (this.state.new.length == 0) {
            this.setState({
                newNoData:
                    <div className="notFound-container">
                        <div className="notFound-content text-center">
                            <div className="inner">
                                <h3>
                                    {t("noData")}
                                </h3>
                            </div>
                        </div>
                    </div>,
            })
        } else {
            this.setState({
                newNoData: '',
            })
        }
        if (this.state.more.length == 0) {
            this.setState({
                moreNoData:
                    <div className="notFound-container">
                        <div className="notFound-content text-center">
                            <div className="inner">
                                <h3>
                                    {t("noData")}
                                </h3>
                            </div>
                        </div>
                    </div>,
            })
        } else {
            this.setState({
                moreNoData: '',
            })
        }
    }


    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }
    render() {
        return (
            this.props.page &&
            <div className="news-conatiner">
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={true}
                    filterbySubject={true}
                    filterByYearfun={this.filterByYear}
                    filterByCategoryfun={this.filterByCategory}

                    selectedYear={this.state.selectedYear}
                    selectedCat={this.state.selectedCat}
                />
                <div className="container">
                    <h5 className="title"><Translate text="newNews" /> </h5>

                    <div className="row new-news justify-content-center">
                        {this.state.loading && <div><p>Loading ...</p></div>}
                        {this.state.new &&
                            this.state.new.map((value, index) => {
                                return (
                                    <div className="col-12 col-md-4" key={index}>
                                        <Link to={"/single-news/" + value.id}>
                                            <NewsCard newscard={value} />
                                        </Link>
                                    </div>
                                )
                            })}
                        {this.state.newNoData}

                    </div>

                    <div className="row more-container justify-content-center">
                        <div className="col-12">
                            <h5 className="sub-title"><Translate text="moreNews" /></h5>
                        </div>
                        <div className="col-12 col-lg-10 more-news">
                            {this.state.loading && <div><p>Loading ...</p></div>}
                            {this.state.more &&
                                this.state.more !== null ?
                                this.state.more.map((element, i) => {
                                    return (
                                        <Link to={"/single-news/" + element.id} key={i}>
                                            <NewsCard newscard={element} />
                                        </Link>
                                    )
                                })
                                :
                                <div></div>
                            }
                            {this.state.moreNoData}
                            {this.state.more && this.state.more.length > 0 &&

                                <div className="text-center">
                                    {this.props.lng === 'ar' ?
                                        <Pagination>
                                            {this.props.more.last_page && this.state.pageNumber <= this.props.more.last_page - 2 && <Pagination.Next onClick={this.nextPage} />}
                                            {this.props.more.last_page && this.state.pageNumber + 3 <= this.props.more.last_page &&

                                                <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.pageNumber + 2)}>{this.state.pageNumber + 2}</Pagination.Item>
                                            }
                                            {this.props.more.last_page && this.state.pageNumber + 2 <= this.props.more.last_page &&

                                                <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.pageNumber + 1)}>{this.state.pageNumber + 1}</Pagination.Item>
                                            }
                                            <Pagination.Item active>{this.state.pageNumber}</Pagination.Item>

                                            {this.state.pageNumber - 1 > 0 &&
                                                <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.pageNumber - 1)}>{this.state.pageNumber - 1}</Pagination.Item>
                                            }

                                            {
                                                this.state.pageNumber - 2 > 0 &&
                                                <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.pageNumber - 2)}>{this.state.pageNumber - 2}</Pagination.Item>
                                            }

                                            {this.state.pageNumber > 1 && <Pagination.Prev onClick={this.previousPage} />

                                            }
                                        </Pagination>



                                        :
                                        <Pagination>
                                            {this.state.pageNumber > 1 && <Pagination.Next onClick={this.previousPage} />}

                                            {this.state.pageNumber - 2 > 0 &&
                                                <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.pageNumber - 2)}>{this.state.pageNumber - 2}</Pagination.Item>}

                                            {this.state.pageNumber - 1 > 0 &&
                                                <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.pageNumber - 1)}>{this.state.pageNumber - 1}</Pagination.Item>}

                                            <Pagination.Item active>{this.state.pageNumber}</Pagination.Item>
                                            {this.props.more.last_page && this.state.pageNumber + 2 <= this.props.more.last_page &&

                                                <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.pageNumber + 1)}>{this.state.pageNumber + 1}</Pagination.Item>
                                            }
                                            {this.props.more.last_page && this.state.pageNumber + 3 <= this.props.more.last_page &&
                                                <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.pageNumber + 2)}>{this.state.pageNumber + 2}</Pagination.Item>
                                            }
                                            {this.props.more.last_page && this.state.pageNumber <= this.props.more.last_page - 2 && <Pagination.Prev onClick={this.nextPage} />}

                                        </Pagination>
                                    }
                                </div>
                            }
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}
news.propTypes = {
    actions: PropTypes.object.isRequired,
    new: PropTypes.array.isRequired,
    more: PropTypes.array.isRequired,
    lng: PropTypes.string.isRequired,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    location: PropTypes.object,
    history: PropTypes.object,
    'history.push': PropTypes.func
};
function mapStateToProps(state) {
    return {
        new: state.news.new,
        more: state.news.more,
        page: state.navigation.page
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(news)