import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import CpaCollapse from "../CpaCollpase/CpaCollapse";
import { Link, withRouter } from "react-router-dom";
import "./SideDrawer.scss";
import * as actions from "../../../actions/auth/auth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Translate from "../translate/translate";
import { t } from "i18next";
import { apiFetch } from "../../../utils/http.helpers";
import { BASE_URL } from "../../../constants/config";
import * as navigationAction from "../../../actions/navigation";

class SideDrawer extends Component {
    constructor(props) {
        super(props);
        // this.collapseHandling = this.collapseHandling.bind(this);
        this.state = {
            searchKey: null,
            lng: localStorage.getItem("lng"),
            user: {
                name: null,
            },
        };
    }
    changeLang = () => {
        this.props.changeLanguage();
        if (this.state.lng == "ar") {
            this.setState({ lng: "en" });
        } else if (this.state.lng == "en") {
            this.setState({ lng: "ar" });
        }
    };

    inputChangeHandler(event) {
        let searchKey = event.target.value;
        this.setState({ searchKey: searchKey });
    }

    inputChangeFocus() {
        apiFetch(BASE_URL + "/api/UserSearch/getUserSearch", {
            method: "GET",
            withCredentials: true,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("_cpaJwt"),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (localStorage.getItem("_cpaJwt")) {
                    this.setState({
                        searchHistory: response,
                        showHistoryList: true,
                    });
                } else {
                    this.setState({
                        searchHistory: response,
                        showHistoryList: false,
                    });
                }
            });
    }

    handleSearch = (event) => {
        event.preventDefault();
        let searchKey = this.state.searchKey;
        this.props.click();
        this.props.history.push(`/search-result/${searchKey}`);
    };

    componentDidMount() {
        this.props.navigationAction.getNavigationPages();
    }
    render() {
        let drawerClasses = "side-drawer";
        if (this.props.show) {
            drawerClasses = "side-drawer open";
        }

        return (
            <nav className={drawerClasses}>
                <div className="side-drawer-header">
                    <div className="col-10">
                        <i
                            className="icon-close"
                            onClick={this.props.click}
                        ></i>
                    </div>
                    <div className="col-2">
                        <div
                            className="icon-wrapper"
                            onClick={() => this.changeLang()}
                        >
                            <span className="translate-icon">
                                {t(this.state.lng == "ar" ? "en" : "ar")}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="side-drawer-buttons">
                    {/* //set a click function */}
                    {this.props.user.is_authenticated && (
                        <>
                            <span onClick={() => this.props.logout()}>
                                <Translate text="signOut" />
                            </span>
                            <Button
                                variant="success"
                                size="lg"
                                className="btn-drawer01"
                            >
                                <a className="text-light" href="/user-profile">
                                    {this.props.user.first_name +
                                        " " +
                                        this.props.user.last_name}
                                </a>
                            </Button>
                        </>
                    )}

                    {!this.props.user.is_authenticated && (
                        <Button
                            variant="success"
                            size="lg"
                            className="btn-drawer01"
                            onClick={() => {
                                this.props.toggleModal();
                                this.props.click();
                            }}
                        >
                            <Translate text="signIn" />
                        </Button>
                    )}
                    {/* <Link className="w-100" to="/legal-advocacy/SubmitAComplaint">
            <Button variant="info" size="lg" className="btn-drawer02" onClick={this.props.click}>
              <Translate text="sideDrawerComplain" />
            </Button>
          </Link> */}
                    <a
                        className="w-100"
                        href="https://cpa.sa/?lang=ar_SA"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button
                            variant="info"
                            size="lg"
                            className="btn-drawer02"
                            onClick={this.props.click}
                        >
                            <Translate text="sideDrawerComplain" />
                        </Button>
                    </a>

                    <form
                        className="search-form"
                        onSubmit={(event) => this.handleSearch(event)}
                        action=""
                    >
                        <input
                            className="search-input"
                            type="search"
                            onFocus={() => this.inputChangeFocus()}
                            onChange={(event) => this.inputChangeHandler(event)}
                        />
                        <i className="icon-search-1"></i>
                    </form>
                </div>
                <div className="side-drawer-links">
                    {this.props.pages &&
                        this.props.pages.map((value, index) => {
                            if (value.child_menu.length == 0) {
                                if (value.target == "_blank") {
                                    return (
                                        <div
                                            key={index}
                                            className="nav-item-cpa dropdown nav-item cpa-main-link"
                                        >
                                            <a
                                                href={value.slug}
                                                onClick={this.props.click}
                                                target={value.target}
                                            >
                                                <i className={value.icon}></i>
                                                <p className="d-inline">
                                                    {" "}
                                                    {this.props.lng == "ar"
                                                        ? value.menu_name
                                                        : value.menu_name_en}
                                                </p>
                                            </a>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            key={index}
                                            className="nav-item-cpa dropdown nav-item cpa-main-link"
                                        >
                                            <Link
                                                to={"/" + value.slug}
                                                onClick={this.props.click}
                                            >
                                                <i className={value.icon}></i>
                                                <p className="d-inline">
                                                    {" "}
                                                    {this.props.lng == "ar"
                                                        ? value.menu_name
                                                        : value.menu_name_en}
                                                </p>
                                            </Link>
                                        </div>
                                    );
                                }
                            } else {
                                return (
                                    <div
                                        key={index}
                                        className={"menu-icon " + value.icon}
                                    >
                                        <CpaCollapse
                                            title={
                                                this.props.lng == "ar"
                                                    ? value.menu_name
                                                    : value.menu_name_en
                                            }
                                        >
                                            <ul>
                                                {value.child_menu.map(
                                                    (value, index) => {
                                                        if (
                                                            value.target ==
                                                            "_blank"
                                                        ) {
                                                            return (
                                                                <li key={index}>
                                                                    <a
                                                                        href={
                                                                            value.slug
                                                                        }
                                                                        target={
                                                                            value.target
                                                                        }
                                                                    >
                                                                        {this
                                                                            .props
                                                                            .lng ==
                                                                        "ar"
                                                                            ? value.menu_name
                                                                            : value.menu_name_en}
                                                                    </a>
                                                                </li>
                                                            );
                                                        } else {
                                                            return (
                                                                <li key={index}>
                                                                    <a
                                                                        href={
                                                                            "/" +
                                                                            value.slug
                                                                        }
                                                                    >
                                                                        {this
                                                                            .props
                                                                            .lng ==
                                                                        "ar"
                                                                            ? value.menu_name
                                                                            : value.menu_name_en}
                                                                    </a>
                                                                </li>
                                                            );
                                                        }
                                                    }
                                                )}
                                            </ul>
                                        </CpaCollapse>
                                    </div>
                                );
                            }
                        })}
                </div>
            </nav>
        );
    }
}
SideDrawer.propTypes = {
    show: PropTypes.element,
    toggleModal: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    click: PropTypes.func.isRequired,
    changeLanguage: PropTypes.func.isRequired,
    pages: PropTypes.array.isRequired,
    lng: PropTypes.string.isRequired,
    navigationAction: PropTypes.object.isRequired,
    history: PropTypes.object,
    "history.push": PropTypes.func,
};
function mapStateToProps(state) {
    return {
        user: state.user,
        pages: state.navigation.pages,
        lng: state.user.lng,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationAction: bindActionCreators(navigationAction, dispatch),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SideDrawer)
);
