import { GET_CONTRACTS_DATA } from '../../../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../../../initialState';

export default function contractsReducer(state = initialState.programs_and_activities.programs.legal_advocay.contracts, action) {
  switch (action.type) {
    case GET_CONTRACTS_DATA.SUCCESS:
      return objectAssign({}, state, { contractsArray: action.payload });
    default:
      return state;
  }
}