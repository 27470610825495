import React from 'react';
import ImgHeader from '../../GlobalComponent/ImgHeader/ImgHeader';
import BreadCrumb from '../../GlobalComponent/BreadCrumb/BreadCrumb';
// import CpaPagination from '../../GlobalComponent/CpaPagination/CpaPagination';
import ContainerCard from "../../GlobalComponent/ContainerCard/containerCard";
import './annualReports.scss';
import filter from '../../../actions/FilterBy';
import { Modal } from "react-bootstrap";
import Translate from '../../GlobalComponent/translate/translate';
import * as actions from '../../../actions/information-center/annualReports';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { BASE_URL } from '../../../constants/config';
import * as navigationActions from '../../../actions/navigation';
// import TechnicalProblem from '../../GlobalComponent/TechnicalProblem/TechnicalProblem';
// import { Page } from 'react-pdf';
// import { Document } from 'react-pdf/dist/entry.webpack';

// import { Viewer, Worker } from '@react-pdf-viewer/core'; // install this library
// Plugins
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
// Import the styles
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// Worker
// import {  } from '@react-pdf-viewer/core'; // install this library
// import ReactGA from 'react-ga';
// import { Document, Page } from 'react-pdf';
class AnnualReports extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            show: false,
            modalFile: '',
            lng: localStorage.getItem('lng'),
            url: BASE_URL + '/uploads/',
            loading: true,
            annual_reports_array: [],
            routes: [],
            // pageNo: 1,
            // numPages: null
        };
    }

    // onDocumentLoadSuccess = () => {
    //     console.log("success")
    //     // setNumPages(numPages);
    // }

    // onDocumentLoad = ({ numPages }) => {
    //     this.setState({ numPages });
    // }
    // onLoadError = (err) => {
    //     alert('Error while loading document! ' + err.message)
    // }
    handleClose = () => {
        this.setState({ show: false });
    }

    handleShow = (value) => {

        // ReactGA.event({
        //     category: 'test1',
        //     action: 'test2',
        //     label: 'test3',
        //     value: 'test4'
        // })

        let fileSrc = this.state.url + value
        this.setState({
            modalFile: fileSrc,
            // show: true
        });
    }
    filterByYear = (year) => {
        this.setState({
            annual_reports_array: filter.filterByYear(this.props.annual_reports_array.new, year),
        })
        setTimeout(() => {
            this.CheckSubjuctNoData()
        }, 1);
    }
    filterByCategory = (catgeory) => {
        this.setState({
            annual_reports_array:

                filter.filterByCategory(this.props.annual_reports_array.new, catgeory)
        })
        setTimeout(() => {
            this.CheckSubjuctNoData()
        }, 1);
    }
    CheckSubjuctNoData = () => {
        if (this.state.annual_reports_array.length == 0) {
            this.setState({
                noData:
                    <div className="notFound-container">
                        <div className="notFound-content text-center">
                            <div className="inner">
                                <h3>
                                    {t("noData")}
                                </h3>
                            </div>
                        </div>
                    </div>,
                showMore: false
            })
        } else {
            this.setState({
                noData: '',
                showMore: true
            })
        }
    }
    componentDidMount() {
        // console.log('Do it for m');

        // ReactGA.initialize('UA-149005943-1', { debug: true });
        // ReactGA.pageview(window.location.pathname + window.location.search);

        this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                this.props.actions.getAnnualReportsData()
                    .then(() => {
                        this.setState({ ...this.state, loading: false, annual_reports_array: this.props.annual_reports_array.new });
                    }).catch(() => {
                    });
            })
    }
    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }
    render() {
        // console.log("sstate", this.state)
        // console.log(this.state.modalFile)

        // const { pageNumber, numPages } = this.state;
        // const defaultLayoutPluginInstance = defaultLayoutPlugin();

        return (
            this.props.page &&
            <div className="annualReports-container" >
                {/* <PDFViewer>
                    <Pdf />
                </PDFViewer> */}

                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={true}
                    filterbySubject={false}
                    filterByYearfun={this.filterByYear}
                    filterByCategoryfun={this.filterByCategory} />
                <div className="container reports-content">
                    {/* <TechnicalProblem /> */}
                    <div className="row">

                        {this.state.loading && <div><p>Loading ...</p></div>}

                        {this.state.annual_reports_array.length > 0 &&
                            this.state.annual_reports_array.map((value, index) => {
                                return (
                                    <div className="col-12 col-lg-4" key={index}>
                                        <div className="report-card" >
                                            <div className="report-img">
                                                <img src={this.state.url + value.image} />
                                            </div>
                                            <div className="report-title">
                                                <h4>{this.props.lng == 'ar' ? value.title : value.title_en}</h4>
                                                <div className="report-controllers">.


                                                    {/* <button className="green"
                                                        onClick={() => this.handleShow(JSON.parse(value.attachments)[0].download_link)}
                                                        type="button"><i className="icon-Observation">
                                                        </i><span><Translate text="view" /></span>
                                                    </button> */}

                                                    <a className="green" target="_blank" rel="noopener noreferrer"
                                                        onClick={() => this.handleShow(JSON.parse(value.attachments)[0].download_link)}
                                                        href={this.state.modalFile}
                                                        // onClick={() => this.handleShow(JSON.parse(value.attachments)[0].download_link)}
                                                        type="button"><i className="icon-Observation">
                                                        </i><span><Translate text="view" /></span>
                                                    </a>
                                                    {/* <button
                                                        className="default"
                                                        // onClick={() => this.handleShow(JSON.parse(value.attachments)[0].download_link)}
                                                        type="button"><i className="icon-download">
                                                        </i><span><Translate text="download" /></span>
                                                    </button> */}


                                                    <a className="default" target="_blank" rel="noopener noreferrer"
                                                        // style={{ padding: "1rem",borderRadius:"5px" }}
                                                        onClick={() => this.handleShow(JSON.parse(value.attachments)[0].download_link)}
                                                        href={this.state.modalFile}
                                                        // onClick={() => this.handleShow(JSON.parse(value.attachments)[0].download_link)}
                                                        type="button"><i className="icon-download">
                                                        </i><span><Translate text="download" /></span>
                                                    </a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {this.state.noData}
                    </div>

                    {/* <CpaPagination page="1"></CpaPagination> */}

                </div>
                <Modal dialogClassName="annualReports-container" size="xl" show={this.state.show} onHide={this.handleClose}>
                    <ContainerCard>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            {/* {console.log(this.state.modalFile)} */}
                            {/* <embed src={this.state.modalFile} width="100%" height="80%" /> */}
                            {/* <object data={this.state.modalFile} type="application/pdf" width="100%" height="80%"></object> */}
                            {/* <iframe src={"https://docs.google.com/viewer?url=" + this.state.modalFile} width="100%" height="80%" title="Iframe Example"></iframe> */}

                            {/* <Document
                                file={this.state.modalFile}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                            >
                                <Page pageNumber={this.state.pageNo} />
                            </Document> */}
                            {/* <div style={{ width: "200px", height: "200px" }}>

                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                                    <Viewer fileUrl={this.state.modalFile}
                                    // plugins={[defaultLayoutPluginInstance]}
                                    />
                                </Worker>
                            </div> */}

                            {/* <div>
                                <Document
                                    file="https://cpa.org.sa:99/uploads/media-center/December2020/4ZPfImGzCLW91xtE0b4G.pdf"

                                    onLoadSuccess={this.onDocumentLoad}
                                    onLoadError={this.onLoadError}
                                >
                                    <Page pageNumber={pageNumber} />
                                </Document>
                                <p>Page {pageNumber} of {numPages}</p>
                            </div> */}
                            {/* <object data={this.state.modalFile} width="100%" height="80%">
                                <p style={{ color: "white" }}>Oops! Your browser doesn't support PDFs!</p>
                                <p><a style={{ color: "#42C194" }} href={this.state.modalFile}>Download Instead</a></p>
                            </object> */}
                        </Modal.Body>
                    </ContainerCard>
                </Modal>
            </div>
        );
    }
}
AnnualReports.propTypes = {
    actions: PropTypes.object.isRequired,
    annual_reports_array: PropTypes.array.isRequired,
    lng: PropTypes.string.isRequired,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired
};
function mapStateToProps(state) {
    return {
        annual_reports_array: state.annualreports.annual_reports_array,
        lng: state.user.lng,
        page: state.navigation.page
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AnnualReports)
