import React from 'react';
import './contacts-info.scss';
import BreadCrumb from '../GlobalComponent/BreadCrumb/BreadCrumb';
import ImgHeader from '../GlobalComponent/ImgHeader/ImgHeader';
import { Container, Row, Col } from "react-bootstrap";
import ContactAddress from './contactAddress'
import Contacts from './contacts-info'
// import ContactForm from './ContactForm';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/ContactUs/contact-info';
import * as navigationActions from '../../actions/navigation';
import PropTypes from "prop-types";
import ReactHtmlParser from 'react-html-parser';
import CpaSharing from '../GlobalComponent/cpaSharing/cpaSharing';



class ContactContainer extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.myDivToFocus = React.createRef();

        this.state = {
            routes: [
                {
                    name: 'homePage',
                    link: ''
                },
                {
                    name: 'Contact_us',
                    link: 'landing/5'
                },
                {
                    name: 'connectWithUs',
                    link: 'contacts-info'
                }
            ],
            contactInfo: [],
        }
    }

    handleScrollToDiv = () => {
        //.current is verification that your element has rendered
        if (this.myDivToFocus.current) {
            this.myDivToFocus.current.scrollIntoView({
                behavior: "smooth",
                block: "start"
            })
        }
    }

    componentDidMount() {
        this.handleScrollToDiv();
        this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                this.props.actions.getData()
                    .then(() => {
                        this.setState({
                            contactInfo: this.props.contactInfo
                        })
                    })
                    .catch()
            })
    }
    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }
    render() {
        return (
            this.props.page &&
            <div className="contacts-container" ref={this.myDivToFocus} >
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                <div className="separator"> </div>
                <Container>
                    <Row className="contacts-content">
                        <CpaSharing active='active' />
                        <Col xs={12} sm={9} lg={12}>
                            {
                                ReactHtmlParser(this.props.lng === "ar" ? this.state.contactInfo.working_hours_description : this.state.contactInfo.working_hours_description_en)
                            }
                        </Col>
                        <Contacts fax={this.state.contactInfo.fax_number} mobile={this.state.contactInfo.phone} email={this.state.contactInfo.email} free_number={this.state.contactInfo.complain_number} mail_address={this.props.lng === "ar" ? this.state.contactInfo.mail_address : this.state.contactInfo.mail_address_en}></Contacts>
                        <ContactAddress location={this.state.contactInfo.gbs_link} main_center_address={this.props.lng === "ar" ? this.state.contactInfo.address : this.state.contactInfo.address_en}></ContactAddress>
                    </Row>
                    {/* <Row>
                        <Col style={{ textAlign: "center" }} xs={12} lg={12}>
                            <h3 className="complain-header"><Translate text="complainHeader" /></h3>
                            <p><i className="pen_2"></i><strong><a className="btn submit-cpa-btn" href="/legal-advocacy/SubmitAComplaint"><Translate text="complain1" /></a></strong></p>
                        </Col>

                    </Row> */}
                </Container>
            </div>
        );
    }
}

ContactContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    contactInfo: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    lng: PropTypes.string
}

function mapStateToProps(state) {
    return {
        contactInfo: state.contactInfo.contactInfo,
        page: state.navigation.page,
        lng: state.user.lng
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactContainer);
