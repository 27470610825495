import { GET_ANNUAL_REPORTS } from '../../constants/actionTypes';
import { apiFetch } from '../../utils/http.helpers';
import { BASE_URL } from '../../constants/config';

export function getAnnualReportsData() {
  return function (dispatch) {
    return apiFetch(BASE_URL + '/api/media_center/search?type=reports', {
      method: "GET",
      // mode: "cors",
      withCredentials: true,
      headers: {
        "Accept": "application/json",
        // "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
      }
    })
      .then(response => response.json())
      .then(res => {
        // console.log('Res in fetch', res);
        dispatch({ type: GET_ANNUAL_REPORTS.SUCCESS, payload: res });
      }).catch(() => {
        // console.log('Error is', error);
      });
  };
}
