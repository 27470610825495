import React from 'react'
import { Navbar,Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Translate from './GlobalComponent/translate/translate';

const Footer = () => {
  return (
    <div className="footer-wrapper  font-sm">
      <Container>
        <Row className="navbar-content-main">
          <Col lg={5} sm={12} xs={12} className="d-flex pt-2 pb-2">
            <div className="footer-side">
              <Navbar.Text>
                <Translate text="follow" />
                  <a href="https://www.facebook.com/cpaksa" target="blank">
                  <i className="icon-facebook"></i>
                </a>
                <a href="https://twitter.com/cpaksa" target="blank">
                  <i className="icon-twitter-1"></i>
                </a>
                <a href="https://www.instagram.com/cpaksa/" target="blank">
                  <i className="icon-instagram"></i>
                </a>
                <a href="https://www.youtube.com/user/cpaksa1" target="blank">
                  <i className="icon-youtube"></i>
                </a>
                <a href="https://telegram.me/cpaksa" target="blank">
                  <i className="icon-telegram"></i>
                </a>
                <a href="https://www.snapchat.com/add/cpaksa" target="blank">
                  <i className="icon-snapchat"></i>
                </a>
              </Navbar.Text>
            </div>
          </Col>
          <Col lg={7} sm={12} xs={12} className="pt-2 pb-2" >
            <Row expand="lg">
              <Col xs={3}>
                <Link to="/FAQS" onClick={() => { this.scrollToTop(); }}>
                     <Translate text="commonquestions" /></Link>
              </Col>
              {/* <Col xs={2}>
                <Link to="/important-links" onClick={() => { this.scrollToTop(); }}>
                     <Translate text="ImportantLinks" /></Link>
              </Col> */}
              <Col xs={3}>
                <Link to="/privacy-policy" onClick={() => { this.scrollToTop(); }}>
                     <Translate text="privacyPolicy" /></Link>
              </Col>
              <Col xs={3} className="p-0">
                <Link to="/usage-policy" onClick={() => { this.scrollToTop(); }}>
                     <Translate text="TermsConditions" /></Link>
              </Col>
              <Col xs={3} className="p-0">
              <Link to="/site-map" onClick={() => { this.scrollToTop(); }}>
              <Translate text="siteMap" />
                     </Link>
              </Col>
            </Row>
          </Col>
          {/* <Col xs={12} className="d-none d-lg-block">
            <Row>
              <Col xs={12} lg={4}>
              <div className="about-association">
                <img src="https://gdurl.com/L71O"/>
                <p><Translate text="cpadesc"/></p>
              </div>
                
              </Col>
              <Col xs={1}></Col>
              <Col xs={12} lg={7}>
                <Row>
                  <Col xs={12} lg={5} className="p-0">
                    <ul>
                      <li className="gray-color bold font-md">
                        <Link to="/landing/1"><Translate text="association" /></Link>
                      </li>
                      <li>
                        <Link to="/about" >
                       <Translate text="aboutUs" /></Link>
                      </li>
                      <li>
                        <Link to="/specialization" >
                       <Translate text="specialties" /></Link>
                      </li>
                      <li>
                        <Link to="/organize" >
                       <Translate text="associationOrganization" /></Link>
                      </li>
                      <li>
                        <Link to="/members" >
                       <Translate text="members" /></Link>
                      </li>
                      <li>
                        <Link to="committees" >
                       <Translate text="committees" /></Link>
                      </li>
                    </ul>
                  </Col>
                  <Col xs={12} lg={4} className="p-0">
                    <ul>
                      <li className="gray-color bold font-md">
                        <Link to="/landing/2">
                         <Translate text="programsAndActivities" /></Link>
                      </li>
                      <li>
                        <Link to="/landing/6" >
                               <Translate text="programs" /></Link>
                      </li>
                      <li>
                        <Link to="/landing/3" >
                               <Translate text="initiatives" /></Link>
                      </li>
                      <li>
                        <Link to="/platformsAndApplications" >
                               <Translate text="platforms" /></Link>
                      </li>
                      <li>
                        <Link to="/worldDays" >
                           <Translate text="worldDays" /></Link>
                      </li>
                    </ul>
                  </Col>
                  <Col xs={12} lg={3} className="p-0">
                    <ul>
                      <li className="gray-color bold font-md">                     
                        <Translate text="callUs" />
                      </li>
                      <li>
                        <Link to="/contacts-info">
                         <Translate text="connectWithUs" /></Link>
                      </li>
                      <li>
                        <a href="https://cpamember.org/members/">
                          <Translate text="membership" />
                        </a>
                      </li>
                    </ul>
                  </Col>

                </Row>
              </Col>
              
            </Row>
          </Col> */}
          <Col xs={12} className="mt-lg-0 mt-2 footer_bottom">
            <Row className="copyright">
              <Col xs={12} lg={4} className="text-center text-md-right" >
                <Translate text="copyright"/><a href="/"><Translate text="cpa"/></a>
              </Col>
              <Col xs={12} lg={4} className="d-none d-lg-block text-center">
                {/* <div className="cpa-divider"></div>
                <a href="/"><Translate text="cpa"/></a> */}
              </Col>
              <Col xs={12} lg={4} className="text-center text-md-left mt-3 mt-md-0" >
                <Translate text="developedDesigned"/><a href="https://www.seamlabs.com/" rel="noopener noreferrer" target="_blank"><Translate text="seamlabs"/></a>
              </Col>
            </Row>
          </Col>
        </Row>

      </Container>
    </div>
  )
}

export default Footer;
