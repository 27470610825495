import { apiFetch } from '../../utils/http.helpers';
import { GET_MEMBERS } from '../../constants/actionTypes';
import { BASE_URL } from '../../constants/config';

export const getMembersData = () => {
    return function (dispatch) {
        return apiFetch(BASE_URL+'/api/elgam3ya/higherManagement/search', {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
            }
        })
            .then(Response => Response.json())
            .then(res => {
                dispatch({ type: GET_MEMBERS, payload: res })
            })
            .catch(error => { throw error })
    }
}