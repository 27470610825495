import { GET_RECOGNIZE_SUBJECTS } from '../../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../../initialState';

export default function recognizeReducer(state = initialState.programs_and_activities.programs.recognize, action) {
  switch (action.type) {
    case GET_RECOGNIZE_SUBJECTS.SUCCESS:
      return objectAssign({}, state, { subjects: action.payload });
    default:
      return state;
  }
}