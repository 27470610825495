import React from "react";
import BreadCrumb from "../../GlobalComponent/BreadCrumb/BreadCrumb";
import ContainerCard from "../../GlobalComponent/ContainerCard/containerCard";
import IdCard from "../../GlobalComponent/IdCard/IdCard";
import ImgHeader from "../../GlobalComponent/ImgHeader/ImgHeader";
import { Modal } from "react-bootstrap";
import "./Members.scss";
// import Translate from '../../GlobalComponent/translate/translate'
import { t } from 'i18next';
import { connect } from 'react-redux';
import * as actions from '../../../actions/El-Gam3ya/members';
import * as navigationActions from '../../../actions/navigation';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { BASE_URL } from "../../../constants/config";
import ReactHtmlParser from 'react-html-parser';



class Members extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            show: false,
            baseUrl: BASE_URL + '/',
            lng: localStorage.getItem('lng'),
            isLoaded: false,
            routes: [],
            president: [],
            consulMembers: [],
            tempMembers: [],
            modalMmeber: [],
            genralManager: []
        };
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow(member) {
        this.setState({ modalMmeber: member })
        this.setState({ show: true });
    }

    componentDidMount() {
        this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });

                this.props.actions.getMembersData()
                    .then(() => {
                    });

                fetch(BASE_URL + "/api/elgam3ya/higherManagement/search")
                    .then(res => res.json())
                    .then(
                        (result) => {
                            this.setState({ president: result.filter(member => { return member.type === 'رئيس المجلس التنفيذي' }) });
                            this.setState({ consulMembers: result.filter(member => { return member.type === 'أعضاء المجلس التنفيذي' }) });
                            this.setState({ genralManager: result.filter(member => { return member.type === 'الأمانة العامة' }) });
                            this.setState({ tempMembers: result.filter(member => { return member.type === 'أعضاء المجلس التنفيذي الاحتياطيين' }) });
                            this.setState({ isLoaded: true });
                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    );
            })

    }
    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }
    render() {
        if (this.state.isLoaded) {
            return (
                this.props.page &&
                <div>
                    {this.state.isLoaded ? this.content() : null}
                </div>
            )
        }
        return <div>Loading...</div>;
    }

    content() {
        let userTypeAr;
        let userTypeEn;
        if (this.state.modalMmeber.type === "رئيس المجلس التنفيذي") {
            userTypeAr = 'رئيس المجلس التنفيذي';
            userTypeEn = 'Chief of Executive Board';
        }
        else if (this.state.modalMmeber.type === "أعضاء المجلس التنفيذي") {
            userTypeAr = 'عضو المجلس التنفيذي';
            userTypeEn = 'Executive Board Member';
        }
        else if (this.state.modalMmeber.type === "الأمانة العامة") {
            userTypeAr = 'الأمين العام';
            userTypeEn = 'General Secretary';
        }
        else {
            userTypeAr = 'عضوأً احتياطيا';
            userTypeEn = 'Reserve Member';
        }

        let modalContent =
            localStorage.getItem('lng') === "ar" ?
                <div className="card-content col-12 col-md-6 pr-0 pl-0">
                    <h4>{t(this.state.modalMmeber.name)}</h4>
                    {/* <p>{t(this.state.modalMmeber.type)}</p> */}
                    {userTypeAr}
                    <p className="col-12">{ReactHtmlParser(this.state.modalMmeber.description)}</p>
                    {/* <div className="card-links">
                                            <a><i className="icon-linkedin"></i></a>
                                            <a><i className="icon-twitter"></i></a>
                                        </div> */}
                </div>
                :
                <div className="card-content col-12 col-md-6 pr-0 pl-0">
                    <h4>{t(this.state.modalMmeber.name_en)}</h4>
                    {/* <p>{t(this.state.modalMmeber.type_en)}</p> */}
                    {userTypeEn}
                    <p className="col-12">{ReactHtmlParser(this.state.modalMmeber.description_en)}</p>
                    {/* <div className="card-links">
                                            <a><i className="icon-linkedin"></i></a>
                                            <a><i className="icon-twitter"></i></a>
                                        </div> */}
                </div>


        return (
            <div className="container-members">
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                <div className="container">
                    <section className="content">
                        {
                            this.state.president.length > 0 ? (
                                <div>
                                    <div className="row">
                                        <div className="col-12 title sub-title"><h3>{t('ChairmanBoard')}</h3></div>
                                    </div>
                                    <div className="row justify-content-start">
                                        <div className="col-12 col-lg-6" >
                                            <ContainerCard>
                                                <IdCard user={this.state.president[0]}>
                                                    <a className="pointer" onClick={() => this.handleShow(this.state.president[0])}><i className="icon-angle-double-left"></i></a>
                                                </IdCard>
                                            </ContainerCard>
                                        </div>
                                    </div>
                                </div>) : ''
                        }

                        {
                            this.state.consulMembers.length > 0 ? (
                                <div>
                                    <div className="row">
                                        <div className="col-12 title sub-title"><h3>{t('councilMembers')}</h3></div>
                                    </div>
                                    <div className="row justify-content-start">
                                        {this.state.consulMembers.map((value, index) => {
                                            return (
                                                <div className="col-12 col-lg-6 col-xl-4" key={index}>
                                                    <ContainerCard>
                                                        <IdCard user={value}>
                                                            <a className="pointer" onClick={() => this.handleShow(value)}><i className="icon-angle-double-left"></i></a>
                                                        </IdCard>
                                                    </ContainerCard>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>) : ''
                        }

                        {
                            this.state.genralManager.length > 0 ? (
                                <div>
                                    <div className="row">
                                        <div className="col-12 title"><h3>{t('generalSecretariat')}</h3></div>
                                    </div>
                                    <div className="row justify-content-start">
                                        <div className="col-12 col-lg-6">
                                            <ContainerCard>
                                                <IdCard user={this.state.genralManager[0]}>
                                                    <a className="pointer" onClick={() => this.handleShow(this.state.genralManager[0])}><i className="icon-angle-double-left"></i></a>
                                                </IdCard>
                                            </ContainerCard>
                                        </div>
                                    </div>
                                </div>) : ''
                        }
                        {
                            this.state.tempMembers.length > 0 ? (
                                <div>
                                    <div className="row">
                                        <div className="col-12 title sub-title"><h3>{t('reserveMembers')}</h3></div>
                                    </div>
                                    <div className="row justify-content-start">
                                        {this.state.tempMembers.map((value, index) => {
                                            return (
                                                <div className="col-12 col-lg-6 col-xl-4" key={index}>
                                                    <ContainerCard>
                                                        <IdCard user={value}>
                                                            <a className="pointer" onClick={() => this.handleShow(value)}><i className="icon-angle-double-left"></i></a>
                                                        </IdCard>
                                                    </ContainerCard>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>) : ''
                        }
                    </section>
                </div>
                <Modal id="members" dialogClassName="members-modal" size="xl" show={this.state.show} onHide={this.handleClose}>
                    <ContainerCard>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            <div className="container-id-card" >
                                <div className="card-row row">
                                    <div className="card-img col-12 col-md-6">
                                        <img className="shadow-lg"
                                            src={this.state.modalMmeber.image !== null ? BASE_URL + '/uploads/' + this.state.modalMmeber.image : BASE_URL + '/uploads/' + "Images/default_image.jpg"} />
                                    </div>
                                    {modalContent}
                                </div>
                            </div>
                        </Modal.Body>
                    </ContainerCard>
                </Modal>
            </div>
        );
    }
}


Members.propTypes = {
    actions: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        authority: state.members.membersArray,
        page: state.navigation.page,
        lng: state.user.lng
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Members);
