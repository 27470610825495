import React from 'react';
import CpaCollapse from '../../GlobalComponent/CpaCollpase/CpaCollapse';
import './organize.scss';
import BreadCrumb from '../../GlobalComponent/BreadCrumb/BreadCrumb';
import ImgHeader from '../../GlobalComponent/ImgHeader/ImgHeader';
import * as actions from '../../../actions/El-Gam3ya/organize';
import * as navigationActions from '../../../actions/navigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
// import { t } from 'i18next';

class Organize extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            routes: [],
            organizationintro: {
                line1: 'قرار مجلس الوزراء رقم (120) وتاريخ 1436/02/23هـ',
                line1_en: "Cabinet Decree No. (120) issued on 23/02/1436 A.H.",
                line2: "بعد الاطلاع على المعاملة الواردة من الديوان الملكي برقم ٤٧٢٠ وتاريخ ٦ / ٢ / ١٤٣٥ هـ، المشتملة على الأمر السامي الكريم رقم (٤٠٥٥١) وتاريخ ٤ / ١١ / ١٤٣٤ هـ، المتضمن الاستيضاح عن الفائدة من الدفع لجمعية حماية المستهلك وما إذا كانت أعمالها تستحق ذلك، والمشتملة أيضًا على برقية معالي وزير المالية رقم (٩٥٤٢) وتاريخ ٢٧ / ١١ / ١٤٣٤ هـ، وعلى برقية معالي وزير التجارة والصناعة رقم ٢٦٧ /١ /١ /٤٣ وتاريخ ١١ / ١ / ١٤٣٥ هـ، في شأن تعديل تنظيم جمعية حماية المستهلك، وحل المجلس التنفيذي الحالي للجمعية",
                line2_en: "After seeing transaction no. 4720 issued by the royal court on 6/2/1435 A.H. which includes the royal order no. (40551) issued on 4/11/1434 A.H. that includes inspecting the benefit of the dedicating payment to the Consumer Protection Association and whether its operations deserve it. The royal order also includes letter no. (9542) sent by the minister of finance on 27/11/1434 A.H. and letter no. 267/1/1/43 sent by the minister of commerce and industry on 11/1/1435 A.H. concerning the statute of the Consumer Protection Association and abolition of the current executive council.",
                line3: "وبعد الاطلاع على تنظيم جمعية حماية المستهلك، الصادر بقرار مجلس الوزراء رقم (٣) وتاريخ ١٢ / ١ / ١٤٢٩ هـ.",
                line3_en: "And, after seeing the statute of the Consumer Protection Association issued by the cabinet decree no. (3) on 12/1/1429 A.H.",
                line4: "وبعد الاطلاع على المحضرين رقم (٣٠٩) وتاريخ ١٨ / ٥ / ١٤٣٥ هـ، ورقم (٤٧) وتاريخ ٢٤ / ١ / ١٤٣٦ هـ، المعدين في هيئة الخبراء بمجلس الوزراء",
                line5: "وبعد الاطلاع على توصية اللجنة العامة لمجلس الوزراء رقم (١٦٧) وتاريخ ٩ / ٢ / ١٤٣٦ هـ",
                line5_en: "And, after seeing the recommendation no. (167) issued on 9/2/1436 by the General Committee of the Cabinet",
                line6: "تقرر ما يلي :",
                line6_en: "The following is decided:",
                line7: "أولًا: الموافقة على تنظيم جمعية حماية المستهلك، بالصيغة المرافقة",
                line7_en: "First: Approving the statute of the Consumer Protection Association in its attached wording",
                line8: "ثانيًا: استثناء من حكم المادة (الثالثة عشرة) من هذا التنظيم، يعين وزير التجارة والصناعة أول مجلس تنفيذي للجمعية بعد صدور هذا التنظيم، وتكون مدته ثلاث سنوات",
                line8_en: "Second: Excluding the rule mentioned in article (13) of this statute, the minister of commerce and industry appoints the first executive board of the Association after issuing the statute herein. The board's term is three years.",
                line9: "ثالثًا: تخصص نسبة – يحدد مقدارها وزير التجارة والصناعة – من رسوم اشتراكات العضوية التي تتقاضاها الغرف التجارية الصناعية في كل منطقة، لمصلحة جمعية حماية المستهلك، والجمعيات الأهلية المتخصصة – التي تنشأ مستقبلًا – في القطاعات المعنية بحماية المستهلك. وتورد في حساب خاص، وتوزع على تلك الجمعيات، وفقًا لنسب تحددها الوزارة.",
                line9_en: "Third: An amount - to be defined by the minister of commerce and industry - of the subscription fees payed to the commercial chambers of each region shall be dedicated for the sake of the Consumer Protection Association and the civil organizations - which will be established in the future - in the fields concerned with consumer protection. The collected money will be deposited to a private account and distributed among these associations, according to the percentages defined by the ministry."
            }
        }
    }
    componentDidMount() {
        // console.log('Do it for m');
        this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                this.props.actions.getOrganizationAssociationParagraph();
                this.props.actions.getOrgnizeData()
                    .then(() => {
                        this.setState({ ...this.state, loading: false });

                    }).catch(() => {
                    });
            })

    }
    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }
    render() {
        return (
            this.props.page &&
            <div className="Organize-container">
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                <div className="container" >
                    {
                        this.props.organizationsPara &&
                        <>
                            <h4>{this.props.lng === "ar" ? ReactHtmlParser(this.props.organizationsPara.name) : ReactHtmlParser(this.props.organizationsPara.name_en)}</h4>
                            <p>{this.props.lng === "ar" ? ReactHtmlParser(this.props.organizationsPara.text) : ReactHtmlParser(this.props.organizationsPara.text_en)}</p>
                        </>
                    }

                    <div className="cpa-divider"></div>

                    {this.props.organizationsArray.map((value, index) => {
                        return (
                            <div key={index}>
                                <CpaCollapse title={this.props.lng == "ar" ? value.title : value.title_en} >
                                    {ReactHtmlParser(this.props.lng == "ar" ? value.description : value.description_en)}
                                </CpaCollapse>
                            </div>
                        )
                    })}

                </div>
            </div>
        )
    }
}
Organize.propTypes = {
    actions: PropTypes.object.isRequired,
    organizationsArray: PropTypes.array.isRequired,
    lng: PropTypes.string.isRequired,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    organizationsPara: PropTypes.object,
    "organizationsPara.name": PropTypes.string,
    "organizationsPara.name_en": PropTypes.string,
    "organizationsPara.text": PropTypes.string,
    "organizationsPara.text_en": PropTypes.string
};

function mapStateToProps(state) {
    return {
        organizationsPara: state.organize.organizationsPara,
        organizationsArray: state.organize.organizationsArray,
        lng: state.user.lng,
        page: state.navigation.page
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Organize)
