import { GET_CONSUMER_DATA,GET_CONSUMER_DATA_BY_ID } from '../../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../../initialState';

export default function consumerConsultantReducer(state = initialState.programs_and_activities.programs.consumer_consult, action) {
    switch (action.type) {
        case GET_CONSUMER_DATA:
            return objectAssign({}, state, { consult_data: action.payload });
        case GET_CONSUMER_DATA_BY_ID:
            
            return objectAssign({}, state, { consult_data_by_id: action.payload })
        default:
            return state;
    }
}