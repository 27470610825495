import { GET_TAKEkNOWLEDGE_SUBJECTS } from '../../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../../initialState';

export default function takeKnowledgeReducer(state = initialState.programs_and_activities.programs.TakeKnowledge, action) {
  switch (action.type) {
    case GET_TAKEkNOWLEDGE_SUBJECTS.SUCCESS:
      return objectAssign({}, state, { knowledgeArray: action.payload });
    default:
      return state;
  }
}