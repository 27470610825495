import { apiFetch } from '../utils/http.helpers';
import { GET_NAVIGATION_PAGES, GET_LANDING_PAGES, GET_BUILDER_PAGE, GET_PAGE_INFO, SAVE_CURRENT_FORM, SAVE_CURRENT_FORM_STR } from '../constants/actionTypes';
import { BASE_URL } from '../constants/config';

export function saveCurrentForm(data) {
    return function (dispatch) {
        dispatch({ type: SAVE_CURRENT_FORM, payload: data });
    }
}

export function saveCurrentFormStr(data) {
    return function (dispatch) {

        return new Promise((resolve) => {
            dispatch({ type: SAVE_CURRENT_FORM_STR, payload: data })
            resolve();
        })
    }
}


export function getNavigationPages() {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/menuBuilder/getMenuBuilder', {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
            },
        })
            .then(response => response.json())
            .then(
                res => {
                    dispatch({ type: GET_NAVIGATION_PAGES.SUCCESS, payload: res });
                }
            ).catch(() => { });
    };
}

export function getLandingPages(id) {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/menuBuilder/getMenuBuilder', {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
            },
        })
            .then(response => response.json())
            .then(res => findPageId(res, id, dispatch))
    }
}

// export function getLandingPages(id) {
//     return function (dispatch) {
//         return apiFetch(BASE_URL + '/api/menuBuilder/getMenuBuilder', {
//             method: "GET",
//             withCredentials: true,
//             headers: {
//                 "Accept": "application/json",
//             },
//         })
//             .then(response => response.json())
//             .then(
//                 res => {
//                     res.forEach(page => {
//                         if (page.id == id) {
//                             dispatch({ type: GET_LANDING_PAGES.SUCCESS, payload: page });
//                             return;
//                         }
//                         else {
//                             if (page.child_menu.length != 0) {
//                                 page.child_menu.forEach(page => {
//                                     if (page.id == id) {
//                                         dispatch({ type: GET_LANDING_PAGES.SUCCESS, payload: page });
//                                         return;
//                                     }
//                                     else {
//                                         if (page.child_menu.length != 0) {
//                                             page.child_menu.forEach(page => {
//                                                 if (page.id == id) {
//                                                     dispatch({ type: GET_LANDING_PAGES.SUCCESS, payload: page });
//                                                     return;
//                                                 }
//                                                 else {
//                                                     if (page.child_menu.length != 0) {
//                                                         page.child_menu.forEach(page => {
//                                                             if (page.id == id) {
//                                                                 dispatch({ type: GET_LANDING_PAGES.SUCCESS, payload: page });
//                                                                 return;
//                                                             }
//                                                         })
//                                                     }
//                                                 }
//                                             })
//                                         }
//                                     }
//                                 })
//                             }
//                         }
//                     });
//                 }
//             ).catch(() => {
//             });

//     }
// }

export function getPageInfoApi(slug) {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/menuBuilder/getMenuBuilder', {
            method: "GET",
            headers: {
                "Accept": "application/json",
            },
        })
            .then(response => response.json())
            .then(
                res => {
                    getPageInfo(res, dispatch, slug)
                }
            ).catch(() => {
            });

    }
}
export function getBuilderPages(id) {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/menuBuilder/getPageDataById?id=' + id, {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
            },
        })
            .then(response => response.json())
            .then(
                res => {
                    dispatch({ type: GET_BUILDER_PAGE.SUCCESS, payload: res });
                }
            ).catch(() => { });
    };
}

export function findPageId(pagesArray, id, dispatch) {
    pagesArray.forEach(page => {
        if (page.id == id) {
            dispatch({ type: GET_LANDING_PAGES.SUCCESS, payload: page });
            return page;
        }
        else {
            findPageId(page.child_menu, id, dispatch);
        }
    })
}

function getPageInfo(res, dispatch, slug) {
    res.forEach(headPage => {
        if (("/" + headPage.slug) == slug) {
            dispatch({ type: GET_PAGE_INFO.SUCCESS, payload: headPage });
            return headPage;
        } else {

            if (headPage.child_menu.length != 0) {

                headPage.child_menu.forEach(page => {
                    if (("/" + page.slug) == slug) {
                        dispatch({ type: GET_PAGE_INFO.SUCCESS, payload: page });
                        return page;
                    } else {
                        if (page.child_menu.length != 0) {
                            page.child_menu.forEach(child => {
                                if (("/" + child.slug) == slug) {

                                    dispatch({ type: GET_PAGE_INFO.SUCCESS, payload: child });
                                    return child;
                                } else {
                                    if (child.child_menu.length != 0) {
                                        child.child_menu.forEach(child2 => {
                                            if (("/" + child2.slug == slug)) {
                                                dispatch({ type: GET_PAGE_INFO.SUCCESS, payload: child2 });
                                                return child2;
                                            }
                                        })
                                    }
                                }
                            })
                        }
                    }
                })

            }
        }
    });
}