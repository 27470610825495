import React, { Component } from 'react'
import { Carousel } from 'react-bootstrap'
import ".../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";
import './GalleryImage.scss';
import { BASE_URL } from '../../../constants/config';
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';

class GalleryImage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      imgs: props.imgs
    }
  }
  render() {
    let controlsAndIndicatorsControl =
      this.props.imgs.length > 2 ? true : false;

    let galleryContent =
      this.props.match.url.includes('consumer') || this.props.match.url.includes('compare')
        ?
        <Carousel interval={0} controls={controlsAndIndicatorsControl} indicators={controlsAndIndicatorsControl} >
          {
            this.props.imgs.filter(img => img !== "").length &&
            this.props.imgs.filter(img => img !== "").map((value, index) => {

              return (
                <Carousel.Item key={index}>
                  {value.download_link ?
                    <iframe className="attachments-carousel" scrolling="no" id="player" type="text/html"
                      src={BASE_URL + '/uploads/' + value.download_link}
                    ></iframe>
                    :
                    <iframe className="attachments-carousel" scrolling="no" id="player" type="text/html"
                      src={value.replace('//youtu.be/', '//youtube.com/embed/')}
                    // src={value}
                    ></iframe>
                  }
                  {value.download_link ?
                    value.download_link.includes('mp4') ? null :
                      <div onClick={() => this.props.show(value)} style={{ cursor: "pointer", backgroundColor: "transparent", top: "0", left: "0", width: "100%", height: "100%", position: "absolute" }} className="overlay"></div>
                    :
                    value.includes('mp4') ? null :
                      <div onClick={() => this.props.show(value)} style={{ cursor: "pointer", backgroundColor: "transparent", top: "0", left: "0", width: "100%", height: "100%", position: "absolute" }} className="overlay"></div>
                  }
                </Carousel.Item>
              )
            })
          }
        </Carousel>
        :
        <Carousel controls={controlsAndIndicatorsControl} indicators={controlsAndIndicatorsControl}>
          {this.props.imgs.map((value, index) => {
            return (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100"
                  src={BASE_URL + '/uploads/' + JSON.parse(value.src)[0].download_link}
                  alt="First slide"
                />
              </Carousel.Item>
            )
          })}
        </Carousel>
    return (

      galleryContent
    );
  }
}
GalleryImage.propTypes = {
  imgs: PropTypes.array,
  match: PropTypes.object,
  show: PropTypes.func,
  "match.url": PropTypes.string,
  "match.url.includes": PropTypes.func
};
export default withRouter(GalleryImage);
