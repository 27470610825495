import { GET_HOME_PAGE, GET_HOME_PAGE_ALERT } from '../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';

export default function homePageReducer(state = initialState.home, action) {
    switch (action.type) {
        case GET_HOME_PAGE:
            return objectAssign({}, state, { homePage: action.payload })
        case GET_HOME_PAGE_ALERT:
            return objectAssign({}, state, { homePageAlert: action.payload })
        default:
            return state;
    }
}
