import React from 'react';
import './profileCard.scss';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BASE_URL } from '../../../constants/config'

const ProfileCard = (props) => {
    let cardContent = props.card.activity ?
        <div className="profile-card-container">
            <div className="profile-card-container-image">
                {
                    props.card.activity.image_path ?
                        <img src={BASE_URL + '/uploads/' + props.card.activity.image_path} /> :
                        <img src={BASE_URL + '/uploads//Images/default_image.jpg'} />
                }
            </div>
            <div className="profile-card-container-details">
                <span className="profile-card-container-details-title">{props.user.lng === "ar" ? props.card.activity.title : props.card.activity.title_en}</span>
                <div className="profile-card-container-details-btns">
                    <span className="cer-date">{moment(props.card.activity.start_date).format('DD/MM/YYYY')}</span>
                    {
                        props.card.pdf_link &&
                        <Link className="show-btn" target="_blank" to={`/${JSON.parse(props.card.pdf_link)[0].download_link}`}>
                            {props.user.lng === 'ar' ? 'عرض الشهادة' : 'Show Certificate'}
                        </Link>
                    }
                </div>
            </div>
        </div>
        :
        <div className="profile-card-container">
            <div className="profile-card-container-image">
                {
                    props.card.attachments &&
                        props.card.attachments !== null &&
                        JSON.parse(props.card.attachments).length !== 0 ?
                        <img src={BASE_URL + '/uploads/' + JSON.parse(props.card.attachments)[0].download_link} /> :
                        <img src={BASE_URL + '/uploads//Images/default_image.jpg'} />
                }
            </div>
            <div className="profile-card-container-details">
                <span className="profile-card-container-details-title">{props.card.complaint}</span>
                <div className="profile-card-container-details-btns">
                    <span className="cer-date">{moment(props.card.created_at).format('DD/MM/YYYY')}</span>
                </div>
            </div>
        </div>
    return (cardContent)
}

function mapStateToProps(state) {
    return {
        user: state.user
    };
}

ProfileCard.propTypes = {
    motamaken: PropTypes.array,
    'motamaken.map': PropTypes.func,
    nasm3k: PropTypes.array,
    'nasm3k.map': PropTypes.func
};

export default withRouter(connect(mapStateToProps, null)(ProfileCard));