import React from 'react';
import './worldDaysCard.scss';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { BASE_URL } from '../../../constants/config';
import moment from 'moment';

const WorldDaysCard = props => {
    return (
        <div className="container-available-job-card">
            <div className="row">
                {
                    props.day.length > 0 ? (
                        props.day.map(function (item) {
                            return (
                                <div key={item.id} className="col-md-4 mb-4 col-sm-12 col-xs-12">
                                    <div className="card-content col-12 col-md-12 col-sm-12 col-xs-12 pr-0 pl-0">
                                        <a href={"/international-day/" + item.title}>
                                            <img href={"/international-day/" + item.title} className="card-img" src={BASE_URL + "/uploads/" + item.image} />
                                            <hr></hr>
                                            {localStorage.getItem("lng") === "en" ? 
                                            <p href={"/international-day/" + item.title_en}>{ReactHtmlParser(item.title_en)}</p> : 
                                            <p href={"/international-day/" + item.title}>{ReactHtmlParser(item.title)}</p>}
                                            <p>{moment(item.date).format('DD-MM')}</p>
                                        </a>
                                    </div>
                                </div>
                            )

                        })) : ""
                }

            </div>
        </div>
    );
}
WorldDaysCard.propTypes = {
    day: PropTypes.object,
    conditions: PropTypes.bool
};
export default WorldDaysCard;
