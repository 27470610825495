import React from 'react';
import './mediaInterview.scss';
import { Container, Row, Col } from "react-bootstrap";
import ImgHeader from '../../GlobalComponent/ImgHeader/ImgHeader';
import BreadCrumb from '../../GlobalComponent/BreadCrumb/BreadCrumb';
// import CustomPagination from "../../GlobalComponent/Pagination/pagination"
import NewsCard from '../../GlobalComponent/newsCard/newsCard';
// import CpaPagination from '../../GlobalComponent/CpaPagination/CpaPagination';
import Translate from '../../GlobalComponent/translate/translate';
import { t } from 'i18next';
import * as actions from '../../../actions/information-center/mediaInterview';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import '../../GlobalComponent/CpaPagination/CpaPagination.scss'
import Pagination from 'react-bootstrap/Pagination';
import { BASE_URL } from '../../../constants/config';
import * as navigationActions from '../../../actions/navigation';
// import filter from '../../../actions/FilterBy';
import CpaSharing from '../../GlobalComponent/cpaSharing/cpaSharing';
// import { number } from 'prop-types';

class MediaInterview extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            url: BASE_URL + '/uploads/',
            loading: true,
            pageNumber: 1,
            tableToBeRendered: null,
            selectedPage: 1,
            elementsPerPage: 3,
            selectedYear: undefined,
            selectedCat: undefined,
            // lastPage:1,
            mainNews: [
                {
                    img: 'https://gdurl.com/CnsA',
                    header: 'اليوم العالمي للمستهلك 2018',
                    date: 'الأحد 25 مارس 2018',
                    link: 'https://www.youtube.com/watch?v=dcRIdr0Nivo&authuser=0',
                    type: 'مداخلة هاتفية'
                },
                {
                    img: 'https://gdurl.com/CnsA',
                    header: 'للحديث حول متضرري قضية السايبر',
                    date: 'الثلاثاء 27 مارس 2018',
                    link: 'https://www.youtube.com/watch?v=tWBsr9abkmM&t=833s',
                    type: 'مداخلة هاتفية'
                },
                {
                    img: 'https://gdurl.com/CnsA',
                    header: 'حماية المستهلك للعربية: على "المراعي" تبرير رفع الأسعار',
                    date: 'الخميس 5 يليو 2018',
                    link: 'https://www.alarabiya.net/ar/aswaq/companies/2018/07/05/%D8%AC%D9%85%D8%B9%D9%8A%D8%A9',
                    type: 'مداخلة هاتفية'
                }
            ],
            moreNews: [
                {
                    img: 'https://gdurl.com/CnsA',
                    header: 'حماية المستهلك للراصد: بيان شركة المراعي مبهم وغير واضح',
                    date: 'الاثنين 9 يليو 2018',
                    link: 'https://twitter.com/alraasd/status/1016379095578566656',
                    type: 'مداخلة هاتفية'
                },
                {
                    img: 'https://gdurl.com/CnsA',
                    header: 'خفايا بقالات الأحياء.. خلف الخلف يقتحم عدداً من البقالات ويكشف المستور',
                    date: 'السبت 6 أكتوبر 2018',
                    link: 'https://www.youtube.com/watch?v=Zj-OWlTdMig&feature=youtu.be',
                    type: 'مشاركة في تقرير تلفزيوني'
                },
                {
                    img: 'https://gdurl.com/CnsA',
                    header: 'الحديث عن تطبيق فاتورتي',
                    date: 'السبت 11 نوفمبر 2018',
                    link: 'https://www.youtube.com/watch?v=Hv_uOPS7aNU',
                    type: 'حضور استديو'
                }
            ],
            routes: [],
            new: null,
            more: null,
            newNoData: null,
            moreNoData: null
        }
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.setPage = this.setPage.bind(this);
    }

    SetIcon = (type) => {
        switch (type) {
            case "telephone":
                return 'phone';
            case 'share_on_tv_reports':
                return 'videos';
            case 'attendance_studio':
                return 'sub';
            case 'share_on_tv_news':
                return 'videos';
        }
    }
    translateIcon = (type) => {
        switch (type) {
            case "telephone":
                return t("TelephoneIntervention");
            case 'share_on_tv_reports':
                return t('ShareTVreport');
            case 'attendance_studio':
                return t('attendance_studio');
            case 'share_on_tv_news':
                return t('ShareTVnews');
        }
    }
    componentDidUpdate(prevProps) {
        // console.log("up", this.props.location, "fff", prevProps.location)
        if (prevProps) {

            const { page, year, cat } = this.getUrl(this.props.location.search);
            const { page: pPage, year: Pyear, cat: pCat } = this.getUrl(prevProps.location.search);


            if (page != pPage || year != Pyear || cat != pCat) {
                //     console.log("Changed")
                this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
                    () => {
                        this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                        this.props.actions.getMediaInterviewData(page + 1, year, cat)
                            .then(() => {
                                this.setState({
                                    ...this.state,
                                    loading: false,
                                    pageNumber: page,
                                    new: this.props.new,
                                    more: this.props.more.data,
                                    selectedYear: year,
                                    selectedCat: cat,
                                }, () => {
                                    if (this.props.history.action != "POP") {

                                        window.scrollTo(0, 0)
                                    }
                                })
                                setTimeout(() => {
                                    this.CheckSubjuctNoData()
                                }, 1);

                            })
                            .catch(() => {
                                // console.log("err", err)
                            });
                    })
            }
        }

    }
    getUrl(url) {
        let obj = {}
        if (url) {
            url.substring(1).split("?").map(u => {
                if (u.includes("page")) {
                    let pageX = u.split("=")[1]
                    obj.page = pageX ? Number(pageX) : 1
                }
                else if (u.includes("year")) {
                    obj.year = u.split("=")[1]
                }
                else if (u.includes("cat")) {
                    obj.cat = u.split("=")[1]

                }
            })

        }

        obj.page = obj.page ? obj.page : 1;


        obj.cat = obj.cat ? obj.cat : undefined;


        obj.year = obj.year ? obj.year : undefined

        return obj;
    }
    componentDidMount() {
        //   console.log("did")

        const { page, year, cat } = this.getUrl(window.location.search);
        this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                this.props.actions.getMediaInterviewData(page + 1, year, cat)
                    .then(() => {
                        this.setState({
                            ...this.state,
                            loading: false,
                            pageNumber: page,
                            new: this.props.new,
                            more: this.props.more.data,
                            selectedYear: year,
                            selectedCat: cat,
                        }, () => {
                        })
                        setTimeout(() => {
                            this.CheckSubjuctNoData()
                        }, 1);

                    })
                    .catch(() => {
                        //  console.log("err", err)
                    });
            })
    }



    filterByYear = (newYear) => {


        //  console.log("yar changes", newYear)

        let url = `/media-appearances?page=${1}${newYear ? `?year=${newYear}` : ""}${this.state.selectedCat ? `?cat=${this.state.selectedCat}` : ""}`
        this.props.history.push(url);


    }
    filterByCategory = (newCatgeory) => {
        // console.log("cat changes", newCatgeory)

        let url = `/media-appearances?page=${1}${this.state.selectedYear ? `?year=${this.state.selectedYear}` : ""}${newCatgeory ? `?cat=${newCatgeory}` : ""}`
        this.props.history.push(url)


    }
    CheckSubjuctNoData = () => {
        if (this.state.new.length == 0) {
            this.setState({
                newNoData:
                    <div className="notFound-container">
                        <div className="notFound-content text-center">
                            <div className="inner">
                                <h3>
                                    {t("noData")}
                                </h3>
                            </div>
                        </div>
                    </div>,
            })
        } else {
            this.setState({
                newNoData: '',
            })
        }
        if (this.state.more.length == 0) {
            this.setState({
                moreNoData:
                    <div className="notFound-container">
                        <div className="notFound-content text-center">
                            <div className="inner">
                                <h3>
                                    {t("noData")}
                                </h3>
                            </div>
                        </div>
                    </div>,
            })
        } else {
            this.setState({
                moreNoData: '',
            })
        }
    }
    nextPage() {
        //  console.log("next")

        if (this.state.pageNumber < this.props.more.last_page) {
            let url = `/media-appearances?page=${this.state.pageNumber + 1}${this.state.selectedYear ? `?year=${this.state.selectedYear}` : ""}${this.state.selectedCat ? `?cat=${this.state.selectedCat}` : ""}`
            this.props.history.push(url);

        }

    }

    previousPage() {
        if (this.state.pageNumber > 1) {
            //  console.log("pre")

            let url = `/media-appearances?page=${this.state.pageNumber - 1}${this.state.selectedYear ? `?year=${this.state.selectedYear}` : ""}${this.state.selectedCat ? `?cat=${this.state.selectedCat}` : ""}`
            this.props.history.push(url);


        }

    }

    setPage(number) {
        //  console.log("set")

        let url = `/media-appearances?page=${number}${this.state.selectedYear ? `?year=${this.state.selectedYear}` : ""}${this.state.selectedCat ? `?cat=${this.state.selectedCat}` : ""}`
        this.props.history.push(url);

    }
    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }
    render() {
        return (
            this.props.page &&
            <div className="mediaInterview-container" >
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={true}
                    filterbySubject={true}
                    filterByYearfun={this.filterByYear}
                    filterByCategoryfun={this.filterByCategory}

                    selectedYear={this.state.selectedYear}
                    selectedCat={this.state.selectedCat}
                />
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h5 className="title"><Translate text="newMedia" /></h5>
                            <CpaSharing active={'active'} />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        {this.state.loading && <div><p>Loading ...</p></div>}
                        {this.state.new &&
                            this.state.new.map((value, index) => {
                                return (
                                    <Col xs={12} md={6} lg={4} className="media-card" key={index}>
                                        <a target="_blank" rel="noopener noreferrer" href={value.youtube_link !== null ? value.youtube_link : JSON.parse(value.attachments).length > 0 ? JSON.parse(value.attachments)[0].download_link : null}>
                                            <div className="media-icon">
                                                <div className="cpa-tooltip">
                                                    <h3>
                                                        <i className={"icon-" + this.SetIcon(value.meetings_type)}></i>
                                                    </h3>
                                                    <span className="cpa-tooltiptext">{this.translateIcon(value.meetings_type)}</span>
                                                </div>
                                            </div>
                                            <NewsCard newscard={value} />
                                        </a>
                                    </Col>
                                )

                            })}
                        {this.state.newNoData}
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <h5 className="title"><Translate text="more" /> <Translate text="MediaAppearances" /></h5>
                        </Col>
                    </Row>
                    <Row className="more-container justify-content-center">
                        <Col xs={10}>
                            {this.state.loading && <div><p>Loading ...</p></div>}
                            {this.state.new &&
                                this.state.more !== null ?
                                // this.state.tableToBeRendered
                                this.state.more.map((element, i) => {
                                    return (
                                        <div className="media-card" key={i} >
                                            <a rel="noopener noreferrer" target="_blank" href={element.youtube_link !== null ? element.youtube_link : JSON.parse(element.attachments).length > 0 ? JSON.parse(element.attachments)[0].download_link : null}>
                                                {/* no attachments... disabled for now  */}
                                                {/* <a href={element.attachments[0].src} className="pointer" target="blank"> */}
                                                <Row>
                                                    <Col xs={12} md={4}>
                                                        <div className="media-icon">
                                                            <div className="cpa-tooltip">
                                                                <h3>
                                                                    <i className={"icon-" + this.SetIcon(element.meetings_type)}></i>
                                                                </h3>
                                                                <span className="cpa-tooltiptext">{this.translateIcon(element.meetings_type)}</span>
                                                            </div>
                                                        </div>
                                                        <img className="w-100 mb-4" src={element.image ? this.state.url + element.image : +BASE_URL + "/uploads//Images/default_image.jpg"} />
                                                    </Col>
                                                    <Col xs={12} md={8}>
                                                        <div className="media-content ">
                                                            <h4>{this.props.lng == 'ar' ? element.title : element.title_en}</h4>

                                                            {element.date &&
                                                                <p>
                                                                    <i className="icon-calendar"></i><span>{element.date || <Translate text="notFound" />}</span>
                                                                </p>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </a>
                                            <div className="cpa-divider"></div>
                                        </div>
                                    )
                                })
                                :
                                <div></div>
                            }
                            {this.state.moreNoData}
                        </Col>

                        {this.state.more && this.state.more.length > 0 &&
                            // <col xs={12} className="text-center">
                            //     {/* <CustomPagination lng={this.props.lng} last_page={this.props.more.last_page} pageNumber={this.state.pageNumber} nextPage={this.nextPage} previousPage={this.previousPage} /> */}
                            // </col>
                            <Col xs={12} className="text-center">
                                {this.props.lng === 'ar' ?
                                    <Pagination>
                                        {this.props.more.last_page && this.state.pageNumber <= this.props.more.last_page - 2 && <Pagination.Next onClick={this.nextPage} />}
                                        {this.props.more.last_page && this.state.pageNumber + 3 <= this.props.more.last_page &&

                                            <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.pageNumber + 2)}>{this.state.pageNumber + 2}</Pagination.Item>
                                        }
                                        {this.props.more.last_page && this.state.pageNumber + 2 <= this.props.more.last_page &&

                                            <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.pageNumber + 1)}>{this.state.pageNumber + 1}</Pagination.Item>
                                        }
                                        <Pagination.Item active>{this.state.pageNumber}</Pagination.Item>

                                        {this.state.pageNumber - 1 > 0 &&
                                            <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.pageNumber - 1)}>{this.state.pageNumber - 1}</Pagination.Item>
                                        }

                                        {
                                            this.state.pageNumber - 2 > 0 &&
                                            <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.pageNumber - 2)}>{this.state.pageNumber - 2}</Pagination.Item>
                                        }

                                        {this.state.pageNumber > 1 && <Pagination.Prev onClick={this.previousPage} />

                                        }
                                    </Pagination>



                                    :
                                    <Pagination>
                                        {this.state.pageNumber > 1 && <Pagination.Next onClick={this.previousPage} />}

                                        {this.state.pageNumber - 2 > 0 &&
                                            <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.pageNumber - 2)}>{this.state.pageNumber - 2}</Pagination.Item>}

                                        {this.state.pageNumber - 1 > 0 &&
                                            <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.pageNumber - 1)}>{this.state.pageNumber - 1}</Pagination.Item>}

                                        <Pagination.Item active>{this.state.pageNumber}</Pagination.Item>
                                        {this.props.more.last_page && this.state.pageNumber + 2 <= this.props.more.last_page &&

                                            <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.pageNumber + 1)}>{this.state.pageNumber + 1}</Pagination.Item>
                                        }
                                        {this.props.more.last_page && this.state.pageNumber + 3 <= this.props.more.last_page &&
                                            <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.pageNumber + 2)}>{this.state.pageNumber + 2}</Pagination.Item>
                                        }
                                        {this.props.more.last_page && this.state.pageNumber <= this.props.more.last_page - 2 && <Pagination.Prev onClick={this.nextPage} />}

                                    </Pagination>
                                }
                            </Col>
                        }
                    </Row>
                </Container>
            </div>
        )
    }
}
MediaInterview.propTypes = {
    actions: PropTypes.object.isRequired,
    new: PropTypes.array.isRequired,
    more: PropTypes.array.isRequired,
    lng: PropTypes.string.isRequired,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    location: PropTypes.object,
    history: PropTypes.object,
    'history.push': PropTypes.func
};
function mapStateToProps(state) {
    return {
        new: state.mediainterview.new,
        more: state.mediainterview.more,
        lng: state.user.lng,
        page: state.navigation.page
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(MediaInterview)