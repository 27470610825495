import { t } from 'i18next';

/**
 *
 * User Login and Register validation rules that are used in the login and register form respectively
 */

export const loginConstraints = {
  email: {
    presence: {
      message: 'حقل البريد الالكتروني مطلوب'
    },
    email: {
      message: 'صيغة البريد الالكتروني غير صحيحة'
    },
  },
  password: {
    presence: {
      message: 'حقل كلمة المرور مطلوب'
    },
    length: {
      minimum: 4,
      message: 'كلمة المرور يجب ألا تقل عن ستة أحرف'
    }
  },
};

export const registerConstraints = {
  first_name: {
    presence: {
      message: 'حقل الاسم الأول مطلوب'
    },
  },
  last_name: {
    presence: {
      message: 'حقل اسم العائله مطلوب'
    },
  },
  email: {
    presence: {
      message: 'حقل البريد الالكتروني مطلوب'
    },
    email: {
      message: 'صيغة البريد الالكتروني غير صحيحة'
    },
  },
  password: {
    presence: {
      message: 'حقل كلمة المرور مطلوب'
    },
    length: {
      minimum: 4,
      message: 'كلمة المرور يجب ألا تقل عن أربعة أحرف'
    }
  },
  phone: {
    presence: {
      message: 'حقل الجوال مطلوب'
    },
    length: {
      minimum: 13,
      maximum: 13,
      message: 'رقم الجوال يجب ألا يقل عن 8 أرقام'
    }
  }
};


export const createComplainRules = {
  content: {
    presence: {
      message: 'حقل تفاصيل المشتكي مطلوب'
    }
  },
  requests: {
    presence: {
      message: 'حقل الطلبات مطلوب'
    }
  },
  city: {
    presence: {
      message: 'حقل المدينة مطلوب'
    }
  },
  identification_number: {
    presence: {
      message: 'حقل رقم الإقامة/الهوية مطلوب'
    },
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      notInteger: t("identifier_int_msg")
    }
  },
  name: {
    presence: {
      message: 'حقل اسم المنشأة/التاجر مطلوب'
    },
  },
  phone: {
    presence: {
      message: 'حقل رقم هاتف المنشأة مطلوب'
    },
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      notInteger: t("phone_valid_msg")
    }
  },
  address: {
    presence: {
      message: 'حقل العنوان مطلوب'
    },
  },
  activity_type: {
    presence: {
      message: 'حقل نوع النشاط مطلوب'
    },
  },
  identification: {
    presence: {
      message: 'حقل نوع النشاط مطلوب'
    },
  },
  case_id: {
    presence: {
      message: 'حقل نوع النشاط مطلوب'
    },
  },
  /*complaint_organization: {
      presence: {
          message: 'حقل نوع النشاط مطلوب'
      },
  },
  complaint_date :{
      presence: {
          message: 'حقل تاريخ الشكوي مطلوب'
      },
  },
  attachment: {
      presence: {
          message: 'حقل تاريخ الشكوي مطلوب'
      },
  },
  complained_before: {
      presence: {
          message: 'حقل تاريخ الشكوي مطلوب'
      },
  }*/
};
/**
 , '', '',
 '', '', '',
 '', '', 'user_id',
 '', '', '',
 '', '',''
 */


export const editProfileDataConstraints = {
  first_name: {
    presence: {
      message: 'حقل الاسم الأول مطلوب'
    },
  },
  last_name: {
    presence: {
      message: 'حقل اسم العائله مطلوب'
    },
  },
  // email: {
  //   presence: {
  //     message: 'حقل البريد الالكتروني مطلوب'
  //   },
  //   email: {
  //     message: 'صيغة البريد الالكتروني غير صحيحة'
  //   },
  // },
  // password: {
  //   presence: {
  //     message: 'حقل كلمة المرور مطلوب'
  //   },
  //   length: {
  //     minimum: 4,
  //     message: 'كلمة المرور يجب ألا تقل عن أربعة أحرف'
  //   }
  // },
  mobile: {
    presence: {
      message: 'حقل الجوال مطلوب'
    },
    length: {
      minimum: 13,
      maximum: 13,
      message: 'رقم الجوال يجب ألا يقل عن 8 أرقام'
    }
  }
};

