import React, { Component } from 'react'
import { Container, Navbar, Nav, NavDropdown, Image, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DrowerToggleButton from './GlobalComponent/DrowerNavigation/DrowerToggleButton';
import ScrollButton from './GlobalComponent/ScrollButton/ScrollButton';
import { Link, withRouter } from 'react-router-dom';
import * as actions from '../actions/auth/auth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { apiFetch } from '../utils/http.helpers';
// import Translate from './GlobalComponent/translate/translate';
import { t } from 'i18next';
import * as navigation from '../actions/navigation';
import { BASE_URL } from '../constants/config';

/**
 * This utility function allows function calls to be debounced.
 * @param {Function} func Function that requires debouncing
 * @param {Number} wait Wait time in milliseconds between successive invocations
 */

const debounce = (func, wait) => {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(this, args), wait)
  }
}
class CustomNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: null,
      searchHistory: [],
      showHistoryList: false,
      lng: "en",
      scrollPositionY: 0,
      user: {
        name: null
      },
    }
  }

  componentDidMount() {
    this.props.navigation.getNavigationPages();
    let lng = localStorage.getItem('lng');
    if (lng && lng == 'ar') {
      this.setState({ lng: 'en' });

    } else if (lng && lng == 'en') {
      this.setState({ lng: 'ar' });

    }

    if (lng === 'ar') {
      document.title = 'جمعية حماية المستهلك';
    } else {
      document.title = 'Consumer Protection Association';
    }
    return window.addEventListener('scroll', debounce(this.handleScroll, 32));
  }

  componentWillUnmount() {
    return window.removeEventListener('scroll', debounce(this.handleScroll, 32))
  }

  handleScroll = () => {
    // + is unary operator, same as Number(scrollPositionY)
    const scrollPositionY = +window.scrollY
    return this.setState({ scrollPositionY })
  }
  changeLang = () => {
    if (this.state.lng == 'ar') {
      this.setState({ lng: 'en' })
    } else if (this.state.lng == 'en') {
      this.setState({ lng: 'ar' })
    }
    this.props.changeLanguage();
  }

  handleSearch = (event) => {
    event.preventDefault();
    let searchKey = this.state.searchKey;
    this.props.history.push(`/search-result/${searchKey}`);
  }

  inputChangeHandler(event) {
    let searchKey = event.target.value;
    this.setState({ searchKey: searchKey })
  }

  // searchListHandler(searchKey) {
  //   this.setState({ searchKey: searchKey });
  //   this.props.history.push(`/search-result/${this.state.searchKey}`);
  // }

  inputChangeFocus() {
    apiFetch(BASE_URL + '/api/UserSearch/getUserSearch', {
      method: "GET",
      withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
      }
    })
      .then(response => response.json())
      .then(response => {

        if (localStorage.getItem('_cpaJwt')) {
          this.setState({ searchHistory: response, showHistoryList: true })
        }
        else {
          this.setState({ searchHistory: response, showHistoryList: false })
        }
      })
  }

  render() {
    // !! coerces value to be a Boolean
    // we want it to be true or false (true if scrollPositionY > 0)
    // it works because scrollPositionY === 0 is falsy
    const isScrolling = !!this.state.scrollPositionY
    return (

      <div className={(isScrolling) ? 'customHeader-container header-shrink isScrolling' : 'customHeader-container header-wrapper'}>
        <Container isScrolling={isScrolling}>
          <Row className="toolbar_cpa">
            <Col xs={2} className="navbar-content-main ">
              <Navbar.Brand>
                <Link to="/">
                  <Image src={BASE_URL + '/uploads/Images/logo-1.png'} className="shrink" />
                  <Image src={BASE_URL + '/uploads/Images/logo-white.svg'} className="cpaLogo unshrink" />
                  <Image src={BASE_URL + '/uploads/Images/visionlogo.svg'} className="vision_logo unshrink" />
                </Link>
              </Navbar.Brand>
              {/* <div className="beta_span">
                <span>{t("beta")}</span>
              </div> */}
            </Col>
            <Col xs={8} className="navbar-content-main visebal-view">
              <Navbar expand="lg">
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mr-auto">
                    {this.props.pages &&
                      this.props.pages.map((value, index) => {
                        if (value.child_menu.length == 0) {
                          return (
                            <div key={index} className="nav-item-cpa dropdown nav-item cpa-main-link">
                              <Nav.Link href={"/" + value.slug}>
                                {this.props.lng == 'ar' ? value.menu_name : value.menu_name_en}
                              </Nav.Link>
                            </div>
                          )
                        } else {
                          return (
                            <NavDropdown disabled title={this.props.lng == 'ar' ? value.menu_name : value.menu_name_en} className="nav-item-cpa">
                              {value.child_menu.map((value, index) => {
                                if (value.target == "_blank") {
                                  return (
                                    <NavDropdown.Item key={index} href={value.slug} target={value.target}>
                                      {this.props.lng == 'ar' ? value.menu_name : value.menu_name_en}
                                    </NavDropdown.Item>
                                  )
                                } else {
                                  return (
                                    <NavDropdown.Item key={index} href={"/" + value.slug} target={value.target}>
                                      {this.props.lng == 'ar' ? value.menu_name : value.menu_name_en}
                                    </NavDropdown.Item>
                                  )
                                }
                              })}

                            </NavDropdown>
                          )
                        }
                      })}
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Col>
            <Col xs={2} className="header-icons visebal-view">
              <div className="icon-wrapper" onClick={() => this.changeLang()}>
                <span className="translate-icon">{t(this.state.lng)}</span>
              </div>

              <form className="search-form" onSubmit={(event) => this.handleSearch(event)} action="">
                <input className="search-input" type="search" onFocus={() => this.inputChangeFocus()} onChange={(event) => this.inputChangeHandler(event)} />
                <i className="icon-search-1"></i>
              </form>
              <div onMouseEnter={() => { this.setState({ showHistoryList: true }) }} onMouseLeave={() => { this.setState({ showHistoryList: false }) }} className={this.state.showHistoryList === true ? 'search-history-show' : 'search-history-hide'}>
                {
                  this.state.searchHistory.length > 0 &&
                  this.state.searchHistory.map(history => {
                    return (
                      <Link key={history.id} to={`/search-result/${history.search_text}`}>{history.search_text}</Link>
                    )
                  })
                }
              </div>
              {
                this.props.user.is_authenticated &&
                <>
                  <div className="user-wrapper" >
                    <a href="/user-profile" className="pointer">
                      {/* {this.props.user.first_name + ' ' + this.props.user.last_name} */}
                      {this.props.user.first_name}
                    </a>
                  </div>
                  <div className="icon-wrapper" onClick={() => this.props.logout()}>
                    <i className="icon-logout" />
                    {/* <Translate text="signOut" /> */}
                  </div>
                </>
              }

              {
                !this.props.user.is_authenticated &&
                <div className="icon-wrapper" onClick={() => this.props.toggleModal()}>
                  <i className="icon-user" />
                </div>
              }

              {/* <span className="phone-number">
>>>>>>> 2e65acaa357cb7c38c5f980c7f8186c3242c7981
                <i className="icon-phone"></i>
                8001160087
                </span> */}
            </Col>
            {/* <div className="spacer visebal-view" /> */}

            {/* <div className="spacer" /> */}

            {/* <div className="spacer invesible-view" /> */}
            <div className="invesible-view">
              <DrowerToggleButton click={this.props.drawerClickHandler} />
            </div>
          </Row>
        </Container>
        <ScrollButton scrollStepInPx="50" delayInMs="16.66" />
      </div>
    )
  }
}
CustomNavbar.propTypes = {
  drawerClickHandler: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  navigation: PropTypes.object.isRequired,
  pages: PropTypes.array.isRequired,
  lng: PropTypes.string.isRequired,
  history: PropTypes.object,
  "history.push": PropTypes.func
};

function mapStateToProps(state) {
  return {
    user: state.user,
    pages: state.navigation.pages,
    lng: state.user.lng
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    navigation: bindActionCreators(navigation, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomNavbar))