import React from 'react';
import ImgHeader from '../../GlobalComponent/ImgHeader/ImgHeader';
import BreadCrumb from '../../GlobalComponent/BreadCrumb/BreadCrumb';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
// import Translate from '../../GlobalComponent/translate/translate';
import * as navigationActions from '../../../actions/navigation';
import * as platformsActions from '../../../actions/program-and-activities/platformsandapps';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
// import { apiFetch } from '../../../utils/http.helpers';
import { BASE_URL } from '../../../constants/config';


class PlatformsAndApplications extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            show: false,
            programs: [],
            routes: []
        };
    }
    componentDidMount() {
        // step to add
        this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                this.props.platformsActions.getAllApplications();
            }
        )
    }
    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }
    render() {
        return (
            this.props.page &&
            <div className="landing-container" >
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                <Container>
                    <Row className="landing-row justify-content-center">
                        {
                            this.props.platforms.map(program => {
                                if (program.website_link === null) {
                                    return (
                                        <Col key={program.id} xs={12} sm={6} lg={4} className="mb-4">
                                            <Link to={`/application/${program.id}`}>
                                                <div className="landing-card">
                                                    <ul>
                                                        <li><img style={{ maxWidth: "100px" }} src={program.image !== null ? BASE_URL + '/uploads/' + program.image : ''} /></li>
                                                        <li><h5>{localStorage.getItem('lng') === "ar" ? program.title : program.title_en}</h5></li>
                                                        <li><p></p></li>
                                                    </ul>
                                                </div>
                                            </Link>
                                        </Col>
                                    )
                                }
                                else {
                                    return (
                                        <Col key={program.id} xs={12} sm={6} lg={4} className="mb-4">
                                            <a href={program.website_link} target="_blank" rel="noopener noreferrer">
                                                <div className="landing-card">
                                                    <ul>
                                                        <li><img src={program.image !== null ? BASE_URL + '/uploads/' + program.image : ''} /></li>
                                                        <li><h5>{localStorage.getItem('lng') === "ar" ? program.title : program.title_en}</h5></li>
                                                        <li><p></p></li>
                                                    </ul>
                                                </div>
                                            </a>
                                        </Col>
                                    )
                                }
                            })
                        }
                    </Row>
                </Container>
            </div>
        )
    }
}

PlatformsAndApplications.propTypes = {
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    platformsActions: PropTypes.object.isRequired,
    "platformsActions.getAllApplications": PropTypes.func.isRequired,
    platforms: PropTypes.array,
    "platforms.map": PropTypes.func
};

function mapStateToProps(state) {
    return {
        page: state.navigation.page,
        platforms: state.platforms.applications
    };
}

function mapDispatchToProps(dispatch) {
    return {
        navigationActions: bindActionCreators(navigationActions, dispatch),
        platformsActions: bindActionCreators(platformsActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PlatformsAndApplications)
