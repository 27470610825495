import { apiFetch } from '../utils/http.helpers';
import { GET_HOME_PAGE, GET_HOME_PAGE_ALERT } from '../constants/actionTypes';
import { BASE_URL } from '../constants/config';

export function getHomePage() {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/homePage/getHomePage', {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
            },
        })
            .then(response => response.json())
            .then(response => {
                dispatch({ type: GET_HOME_PAGE, payload: response })
            })
    }
}

export function getHomePageAlert() {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/paragraph/getHomepageAlert', {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
            },
        })
            .then(response => response.json())
            .then(response => {
                dispatch({ type: GET_HOME_PAGE_ALERT, payload: response })
            })
    }
}
