import React from 'react';
import './CookieMessage.scss';
import Translate from '../GlobalComponent/translate/translate';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from "prop-types";


class CookieMessage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            display: true
        }
    }

    hideMessage() {
        this.setState({
            display: false
        })
        localStorage.setItem('displayCookieMessage', false)
    }

    render() {
        return (
            this.props.homeAlert &&
            <div style={{ display: localStorage.getItem('displayCookieMessage') === null ? 'block' : 'none' }} className="CookieClass" >
                {ReactHtmlParser(this.props.lng === 'en' ? this.props.homeAlert.text_en : this.props.homeAlert.text)}
                <button onClick={() => { this.hideMessage() }} className="btn"><Translate text="agree" /></button>
            </div>
        )
    }
}

CookieMessage.propTypes = {
    homeAlert:PropTypes.object,
    lng:PropTypes.string,
    'homeAlert.text_en':PropTypes.string,
    'homeAlert.text':PropTypes.string

}
export default CookieMessage;