import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Col, Table, Row } from 'react-bootstrap';
import { t } from 'i18next';
import PropTypes from "prop-types";
import Translate from '../GlobalComponent/translate/translate';
import Pagination from 'react-bootstrap/Pagination';
import { connect } from 'react-redux';
import '../GlobalComponent/CpaPagination/CpaPagination.scss'


class TabServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            complaints: null,
            pageNumber: 0,
            tableToBeRendered: null,
            selectedPage: 1,
            elementsPerPage: 5,
        }

        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.setPage = this.setPage.bind(this);
    }
    componentDidMount() {
        if (this.props.complaints != null) {
            let tempPageNumber = Math.ceil(this.props.complaints.length / this.state.elementsPerPage);
            //console.log(tempPageNumber);
            this.setState({
                complaints: this.props.complaints,
                pageNumber: tempPageNumber
            }, () => {
                this.renderTable(1, this.props.lng);
            });
        }
    }

    renderTable(cpn, language) {
        let startFrom = (cpn * this.state.elementsPerPage) - this.state.elementsPerPage;
        let renderTo = startFrom + (this.state.elementsPerPage - 1);
        let elements = [];
        for (var x = startFrom; x <= renderTo; x++) {
            let element = this.state.complaints[x];
            if (element != null) {
                let id = 'cpa' + element.complaint_number;
                let name = element.cases.name;
                let nameen = element.cases.name_en;
                let date = element.updated_at;
                let status = element.status;
                let status_en = element.status_en;
                let toBeRendered =
                    <tr onClick={() => this.props.history.push(`legal-advocacy/trackyourcomplaint/cpa${element.complaint_number}`)} style={{ cursor: "pointer" }}>
                        <td>{id}</td>
                        <td>{language === 'ar' ? name : nameen}</td>
                        <td>{date}</td>
                        <td>{language === 'ar' ? status : status_en}</td>
                    </tr>;
                elements.push(toBeRendered);
            } else {
                break;
            }
        }
        this.setState({
            tableToBeRendered: elements
        });
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        //console.log(newProps);
        this.renderTable(this.state.selectedPage, newProps.lng);
    }

    nextPage() {
        if (this.state.selectedPage < this.state.pageNumber) {
            //console.log("Next");
            let nextPageValue = this.state.selectedPage + 1;
            this.setState({
                selectedPage: nextPageValue,
            }, () => {
                this.renderTable(this.state.selectedPage, this.props.lng);
            });
        }
    }

    previousPage() {
        if (this.state.selectedPage > 1) {
            //console.log("Previous");
            let previousPageValue = this.state.selectedPage - 1;
            this.setState({
                selectedPage: previousPageValue,
            }, () => {
                this.renderTable(this.state.selectedPage, this.props.lng);
            })
        }
    }

    setPage(number) {
        this.setState({
            selectedPage: number
        }, () => {
            this.renderTable(this.state.selectedPage, this.props.lng);
        })
    }

    render() {
        // console.log("com", this.props.complaints)
        return (
            <div className="tabServices-container">
                {this.props.complaints.length ?
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <h4>{t('complains')}</h4>

                            </Col>
                            <Col xs={12}>
                                <div className="table-container">
                                    <Table responsive>

                                        <thead>
                                            <tr className="tableTitle">
                                                <th><h6><Translate text="complaintNumber" /></h6></th>
                                                <th><h6><Translate text="aboutComplaint" /></h6></th>
                                                <th><h6><Translate text="complaintDate" /></h6></th>
                                                <th><h6><Translate text="complaintStatus" /></h6></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.state.complaints !== null ?
                                                this.state.tableToBeRendered
                                                :
                                                <div></div>
                                            }
                                        </tbody>
                                    </Table>
                                    <div>
                                        {this.props.lng === 'ar' ?
                                            <Pagination>

                                                <Pagination.Next onClick={this.nextPage} />

                                                {this.state.selectedPage + 1 < this.state.pageNumber &&
                                                    <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.selectedPage + 2)}>{this.state.selectedPage + 2}</Pagination.Item>}

                                                {this.state.selectedPage !== this.state.pageNumber &&
                                                    <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.selectedPage + 1)}>{this.state.selectedPage + 1}</Pagination.Item>}

                                                <Pagination.Item active>{this.state.selectedPage}</Pagination.Item>

                                                {this.state.selectedPage !== 1 &&
                                                    <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.selectedPage - 1)}>{this.state.selectedPage - 1}</Pagination.Item>}

                                                {this.state.selectedPage - 1 > 1 &&
                                                    <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.selectedPage - 2)}>{this.state.selectedPage - 2}</Pagination.Item>}

                                                <Pagination.Prev onClick={this.previousPage} />

                                            </Pagination>

                                            :

                                            <Pagination>
                                                <Pagination.Next onClick={this.previousPage} />

                                                {this.state.selectedPage - 1 > 1 &&
                                                    <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.selectedPage - 2)}>{this.state.selectedPage - 2}</Pagination.Item>}

                                                {this.state.selectedPage !== 1 &&
                                                    <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.selectedPage - 1)}>{this.state.selectedPage - 1}</Pagination.Item>}

                                                <Pagination.Item active>{this.state.selectedPage}</Pagination.Item>


                                                {this.state.selectedPage !== this.state.pageNumber &&
                                                    <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.selectedPage + 1)}>{this.state.selectedPage + 1}</Pagination.Item>}


                                                {this.state.selectedPage + 1 < this.state.pageNumber &&
                                                    <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.selectedPage + 2)}>{this.state.selectedPage + 2}</Pagination.Item>}

                                                <Pagination.Prev onClick={this.nextPage} />

                                            </Pagination>

                                        }
                                    </div>
                                </div>
                            </Col>
                            {/* <Col xs={12}>
                            <h4>{t('suggestions')}</h4>
                        </Col>
                        <Col xs={12}>
                            <div className="table-container">
                           
                            </div>
                        </Col> */}
                        </Row>
                    </Container>
                    :
                    <h4>{t('noComplaint')}</h4>
                }
            </div>
        )
    }
}
TabServices.propTypes = {
    complaints: PropTypes.array.isRequired,
    lng: PropTypes.string.isRequired,
    history: PropTypes.object,
    'history.push': PropTypes.func,
}

function mapStateToProps(state) {
    return {
        lng: state.user.lng,
    };
}
export default withRouter(connect(mapStateToProps)(TabServices));