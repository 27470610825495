import validate from 'validate.js';
// Option to avoid pre-pending field name to error messages
validate.options = {
  fullMessages: false,
};
export const validateFormData = (fields, values, validationRules) => {
  const attributes = {};
  for (let i = 0; i < fields.length; i++) {
    if (validationRules.hasOwnProperty(fields[i]) && values[i].length !== 0) {
      attributes[fields[i]] = values[i];
    }
  }
  const result = validate(attributes, validationRules);

  const state = {};
  if (result !== undefined) {
    // there is validation errors
    const keys = Object.keys(result); // get the names of the fields that has errors
    fields.forEach((fieldName) => {
      state[`${fieldName}_error`] = keys.indexOf(fieldName) !== -1;
      if (keys.indexOf(fieldName) !== -1) {
        state[`${fieldName}_error_value`] = result[fieldName][0];
      } else {
        state[`${fieldName}_success`] = true;
      }
    });

    return { result: false, state };

  } else {
    fields.map((val) => {
      state[`${val}_success`] = true;
      state[`${val}_error`] = false;
      state[`${val}_error_value`] = null;
    });

    return { result: true, state };
  }
};
