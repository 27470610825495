import {
  USER_LOGIN, USER_REGISTER, LOAD_USER_DATA, USER_LOGOUT, CHANGE_USER_LNG, REGISTER_ERROR, RESITER_VERIFICATION
} from '../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './../initialState';

export default function authReducer(state = initialState.user, action) {
  // console.log(action.payload)
  // let newState;
  switch (action.type) {
    case USER_LOGIN.SUCCESS:
      return objectAssign({}, state, {
        id: action.payload.id,
        email: action.payload.email,
        auth_token: action.payload.token,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        is_authenticated: true,
        error: null,
        verified: action.payload.verified
      });
    case USER_REGISTER.SUCCESS:
      return objectAssign({}, state, {
        id: action.payload.id,
        email: action.payload.email,
        // auth_token: action.payload.token,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        // is_authenticated: true,
        error: null
      });
    case REGISTER_ERROR.SUCCESS:
      return objectAssign({}, state, {
        error: action.payload
      });
    case LOAD_USER_DATA.SUCCESS:
      return objectAssign({}, state, {
        id: action.payload.id,
        email: action.payload.email,
        auth_token: action.payload.auth_token,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        is_authenticated: action.payload.is_authenticated,
        lng: action.payload.lng,
        error: null
      });
    case USER_LOGOUT.SUCCESS:
      return objectAssign({}, state, {
        email: null,
        auth_token: null,
        first_name: null,
        last_name: null,
        is_authenticated: null
      });
    case CHANGE_USER_LNG.SUCCESS:
      return objectAssign({}, state, {
        lng: action.payload.lng
      })
    case RESITER_VERIFICATION.SUCCESS:
      return objectAssign({}, state, {
        error: null,
        auth_token: action.payload.auth_token,
        is_authenticated: true,

      })
    default:
      return state;
  }
}
