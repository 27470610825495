import {
    CREATE_NEW_COMPLAIN,
    GET_CASES_OF_COMPLAINS,
    GET_ACTIVITY_TYPE,
    GET_DESCRIPTION_COMPLAINTS
} from '../../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './../../initialState';

/**
 *
 * @param state: object
 * @param action: object
 * @returns state: object
 */
export default function complainReducer(state = initialState.programs_and_activities.programs.complain, action) {
    switch (action.type) {
        case GET_CASES_OF_COMPLAINS.SUCCESS:
            return objectAssign({}, state, { cases: action.payload });
        case CREATE_NEW_COMPLAIN.SUCCESS:
            return objectAssign({}, state, { complain: action.payload });
        case GET_ACTIVITY_TYPE:
            return objectAssign({}, state, { activityTypes: action.payload })
        case GET_DESCRIPTION_COMPLAINTS:
            return objectAssign({}, state, { complainParagraph: action.payload })
        default:
            return state;
    }
}
