import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../../../actions/program-and-activities/programs/motamken';
import * as ConsumerActions from '../../../../../actions/program-and-activities/programs/concumerConsultant';
import ReactHtmlParser from 'react-html-parser';
import BreadCrumb from '../../../../GlobalComponent/BreadCrumb/BreadCrumb';
import ImgHeader from '../../../../GlobalComponent/ImgHeader/ImgHeader';
import HomeSectionFC from '../../../../GlobalComponent/HomeSection/HomeSectionFC';
import { Container, Row, Col, Button, Table, Modal, Carousel } from 'react-bootstrap';
import GalleryImage from '../../../../GlobalComponent/GalleryImage/GalleryImage';
import ContainerCard from '../../../../GlobalComponent/ContainerCard/containerCard';
import CpaSharing from '../../../../GlobalComponent/cpaSharing/cpaSharing';
import { apiFetch } from '../../../../../utils/http.helpers';
import * as navigationActions from '../../../../../actions/navigation';
import { toast } from 'react-toastify';


import './MotamakenSingle.scss';
import { t } from 'i18next'

import moment from 'moment';
import Translate from '../../../../GlobalComponent/translate/translate';
import { BASE_URL } from "../../../../../constants/config";
import DefaultPage from '../../../../GlobalComponent/defaultPage/DefaultPage';


class MotamakenSingle extends React.Component {
  constructor(props) {
    super(props);
    this.myDivToFocus = React.createRef();
    this.state = {
      showSharingIcons: false,
      show: false,
      modalImg: '',
      loading: true,
      url: BASE_URL,
      isPassed: null,
      product_prop: [],
      routes: []
    }
  }

  handleScrollToDiv = () => {
    //.current is verification that your element has rendered
    if (this.myDivToFocus.current) {
      this.myDivToFocus.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      })
    }
  }

  componentDidMount() {
    this.handleScrollToDiv();
    const { match: { params } } = this.props;
    if (this.props.match.url.includes('/skilled')) {
      this.props.navigationActions.getPageInfoApi('/skilled').then(
        () => {
          this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
          this.props.actions.getMotamakenSingleActivityData(parseInt(params.id))
            .then(() => {
              this.setState({ ...this.state, loading: false });
              let currentDate = new Date();
              let eventDate = new Date(this.props.activity.start_date);

              this.setState({
                isPassed: eventDate.getTime() > currentDate.getTime()
              });
            }).catch(() => {

            });
        })
    }
    else if (this.props.match.url.includes('/consumer')) {
      this.props.navigationActions.getPageInfoApi('/consumer-advisors').then(
        () => {
          this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page), loading: false });
          this.props.ConsumerActions.getConsumerDataById(parseInt(this.props.match.params.id));
        })
    }
    else {
      this.props.navigationActions.getPageInfoApi('/compare-and-rate/category').then(
        () => {
          this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page), loading: false });
          let productId = parseInt(this.props.match.params.id);
          apiFetch(BASE_URL + `/api/propertiesValues/getPropertiesValuesByMultiCategoriesId?multi_categories_id=${productId}`, {
            method: "GET",
            withCredintials: true,
            headers: {
              "Accept": "application/json"
            }
          })
            .then(response => response.json())
            .then(res => {
              this.setState({ product_prop: res })
            })
        })
    }
  }

  setRoutesFormApi(page) {
    let routes = []
    let temp = page.pread_crumb
    for (let index = 0; index < 3; index++) {
      routes.push({
        name: temp.menu_name,
        name_en: temp.menu_name_en,
        link: temp.slug
      })
      if (temp.pread_crumb) {
        temp = temp.pread_crumb
      } else {
        return routes.reverse();
      }
    }
  }
  handleShow = (value) => {
    this.setState({
      show: true,
      modalImg: value
    })
  }
  handleClose = () => {
    this.setState({ show: false });
  }

  calRemainigDays() {
    let noOfDays;
    if (new Date() < Date.parse(this.props.activity.start_date)) {
      noOfDays = Math.round((Date.parse(this.props.activity.start_date) - new Date()) / (1000 * 60 * 60 * 24));
    }
    else {
      noOfDays = 0;
    }
    return noOfDays;
  }

  regUserInEvent() {
    apiFetch(BASE_URL + '/api/motamaken/activity/addActivityRegister', {
      method: "POST",
      headers: {
        "Accept": "application/json",
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
      },
      body: JSON.stringify({ activity_id: parseInt(this.props.match.params.id) })
    })
      .then(response => {
        if (response.status === 200) {
          toast.success(t('sendAttendanceCode'), {
            position: toast.POSITION.BOTTOM_RIGHT
          })
        }
        else {
          if (response.status === 401) {
            toast.error(t('login_for_submit'), {
              position: toast.POSITION.BOTTOM_RIGHT
            })
            this.props.toggleModal()
          }
          else {
            toast.error('error', {
              position: toast.POSITION.BOTTOM_RIGHT
            })
          }
        }
      })
  }

  render() {
    // console.log("prop", this.props)
    // console.log("s", this.state)

    moment.locale(this.props.lng);
    let cardContent;
    /// card in case of compare and rate product
    if (this.props.match.url.includes('compare-and-rate')) {
      cardContent =
        <div className="MotamakenSingle-container" ref={this.myDivToFocus}>
          <ImgHeader page={this.props.page} />
          <BreadCrumb
            routes={this.state.routes}
            filterbyYear={false}
            filterbySubject={false} />
          <HomeSectionFC>
            <div className="single-info">
              <Container>
                {this.state.loading && <span><Translate text="Loading" /> ...</span>}
                {!this.state.loading
                  &&
                  <Row>
                    <Col md={8} sm={12} xs={12}>
                      <p style={{ fontSize: "1.5rem", fontWeight: "bold", margin: 0, color: "#6BE2B7" }}>{this.props.lng == "ar" ? this.props.location.state.product_name : this.props.location.state.product_name_en}</p>
                      {/* <a className="share-link" onClick={() => {
                        this.setState({ showSharingIcons: !this.state.showSharingIcons })
                      }}>
                        <div className="resp-sharing-button resp-sharing-button--download resp-sharing-button--small">
                          <div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" id="share" width="32.616" height="34.316" fontSize="2rem" viewBox="0 0 32.289 34.252">
                              <path id="Path_104" data-name="Path 104" d="M39.958,22a6.129,6.129,0,0,0-4.85,2.39L25.7,19.139a6.144,6.144,0,0,0,.341-2.02,6.009,6.009,0,0,0-.348-2.027l9.4-5.249a6.117,6.117,0,1,0-1.266-3.72,5.979,5.979,0,0,0,.348,2.027L24.781,13.4a6.118,6.118,0,1,0,.014,7.432l9.4,5.256A6.12,6.12,0,1,0,39.958,22Zm0-20.077a4.2,4.2,0,1,1-4.2,4.2A4.207,4.207,0,0,1,39.958,1.927ZM19.931,21.322a4.2,4.2,0,1,1,4.2-4.2A4.207,4.207,0,0,1,19.931,21.322Zm20.027,11a4.2,4.2,0,1,1,4.2-4.2A4.207,4.207,0,0,1,39.958,32.324Z" transform="translate(-13.8)" fill="#6be2b7" />
                            </svg>
                          </div>
                        </div>
                      </a> */}
                      {
                        <CpaSharing active={this.state.showSharingIcons ? 'active' : ''} />
                      }
                      {/* {console.log("ggg", this.props.location.state.product_desc)} */}
                      <p style={{ fontSize: "1rem", margin: 0 }}>{ReactHtmlParser(this.props.lng == "ar" ? this.props.location.state.product_desc : this.props.location.state.product_desc_en)} </p>
                      <Table style={{ marginTop: "3rem" }} striped bordered hover>
                        <tbody>
                          {this.state.product_prop.map((prop) => {
                            return (
                              <tr key={prop.id}>
                                <td>{this.props.lng === "ar" ? prop.properties.name : prop.properties.name_en}</td>
                                <td>{this.props.lng === "ar" ? ReactHtmlParser(prop.value) : ReactHtmlParser(prop.value_en)}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </Col>
                    {
                      this.props.location.state.product_image !== null &&
                      <Col md={4} sm={12} xs={12}>
                        <img style={{ width: "60%", maxWidth: "60%", maxHeight: "80%", boxShadow: "0px 0px 10px -2px #707070" }}
                          src={JSON.parse(this.props.location.state.product_image).length === 0 ? '' : BASE_URL + '/uploads/' + JSON.parse(this.props.location.state.product_image)[0].download_link} />
                      </Col>
                    }
                  </Row>}
              </Container>
            </div>
          </HomeSectionFC>
        </div>
    }
    else {
      let attachmentsArray;
      let consumer_advisor_videos_array;

      if (this.props.consult_data_by_id !== null && this.props.match.url.includes('consumer') && this.props.consult_data_by_id.attachments !== null) {
        attachmentsArray = JSON.parse(this.props.consult_data_by_id.attachments);
        consumer_advisor_videos_array = this.props.consult_data_by_id.consumer_advisor_videos.map(item => {
          return (
            item.link
          )
        })
        // attachmentsArray = attachmentsArray.concat(consumer_advisor_videos_array);
      }

      ///card in case of consumer
      cardContent = this.props.match.url.includes('consumer') ?

        <div>

          {this.props.consult_data_by_id != null ?
            <div className="MotamakenSingle-container" ref={this.myDivToFocus}>
              <ImgHeader page={this.props.page} />
              <BreadCrumb
                routes={this.state.routes}
                filterbyYear={false}
                filterbySubject={false} />
              <HomeSectionFC>
                <div className="single-info">
                  <Container>
                    {this.state.loading && <span><Translate text="Loading" /> ...</span>}
                    {!this.state.loading && this.props.consult_data_by_id &&
                      <Row>
                        <Col md={8} sm={12} xs={12}>
                          <p className="title" style={{ direction: this.props.lng === "ar" ? "rtl" : "ltr" }}>{this.props.lng == "ar" ? this.props.consult_data_by_id.title : this.props.consult_data_by_id.title_en}</p>
                          {
                            <CpaSharing active={this.state.showSharingIcons ? 'active' : ''} />
                          }
                          <div className="author-wrapper" style={{ paddingRight: this.props.lng === "en" ? "19rem" : 0 }}>
                            <img src={BASE_URL + '/uploads/' + this.props.consult_data_by_id.instructor_image} />
                            <p>{this.props.lng == "ar" ? this.props.consult_data_by_id.instructor_name : this.props.consult_data_by_id.instructor_name_en}</p>
                          </div>
                          <div className="counter-wrapper">
                            <div className="row">
                              <span className="col-12"><i className="icon-location"></i>
                                {this.props.lng == "ar" ? this.props.consult_data_by_id.address : this.props.consult_data_by_id.address_en}</span>
                              <span className="col-12">
                                <i className="icon-calendar"></i>
                                {moment(this.props.consult_data_by_id.date).format('dddd Do MMMM YYYY')}
                              </span>

                              <span className="col-12"><i className="icon-clock"><Translate text="from" /> :
                              </i>{moment(this.props.consult_data_by_id.date).format('h:mm a')}</span>
                              <span className="col-12"><i className="icon-clock"><Translate text="to" /> :
                              </i>{moment(this.props.consult_data_by_id.to_date).format('h:mm a')}</span>
                            </div>
                          </div>
                          <p>{ReactHtmlParser(this.props.lng == "ar" ? this.props.consult_data_by_id.description : this.props.consult_data_by_id.description_en)} </p>
                        </Col>
                        <Col md={4} sm={12} xs={12}>
                          <img src={BASE_URL + '/uploads/' + this.props.consult_data_by_id.image} />
                        </Col>
                      </Row>}
                  </Container>
                </div>
                <div className="single-content">
                  <Container>
                    {this.props.consult_data_by_id &&
                      this.props.consult_data_by_id.consumer_advisor_videos.length > 0 &&
                      <h4 style={{ paddingRight: this.props.lng === "en" ? "65rem" : "0" }}><Translate text="content" /></h4>
                    }

                    {/* {console.log("fgfg", attachmentsArray !== null &&
                  attachmentsArray.length > 0 &&
                  JSON.parse(attachmentsArray).length > 0)} */}


                    {!this.state.loading &&
                      <Row className="justify-content-center">
                        <Col xs={10} >

                          {
                            attachmentsArray !== undefined &&
                            attachmentsArray.length > 0 &&
                            <Carousel interval={0} controls={attachmentsArray.length > 1} indicators={attachmentsArray.length > 1} >
                              {
                                attachmentsArray.map((value, index) => {
                                  return (
                                    <Carousel.Item key={index}>
                                      <img
                                        className="d-block w-100"
                                        src={BASE_URL + '/uploads/' + value}
                                        alt="First slide"
                                      />
                                    </Carousel.Item>
                                  )
                                })
                              }
                            </Carousel>
                          }
                          {
                            consumer_advisor_videos_array !== undefined &&
                            consumer_advisor_videos_array.length > 0 &&
                            < GalleryImage imgs={consumer_advisor_videos_array} show={this.handleShow} />
                          }


                        </Col>
                      </Row>}
                  </Container>
                  <Modal dialogClassName="annualReports-container" size="xl" show={this.state.show} onHide={() => this.handleClose()}>
                    <ContainerCard>
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <div className="shareOn">
                          <CpaSharing url={this.state.url + '/' + this.state.modalImg} />
                        </div>
                        <div className="img-body">
                          <img src={this.state.url + '/' + this.state.modalImg} />
                        </div>
                      </Modal.Body>
                    </ContainerCard>
                  </Modal>
                </div>
              </HomeSectionFC>
            </div>
            : <DefaultPage />}

        </div>
        :
        /// card in case of motmaken
        <div>
          {this.props.activity != null ?
            <div className="MotamakenSingle-container" ref={this.myDivToFocus}>
              <ImgHeader page={this.props.page} />
              <BreadCrumb
                routes={this.state.routes}
                filterbyYear={false}
                filterbySubject={false} />
              <HomeSectionFC>
                <div className="single-info">
                  <Container>
                    {this.state.loading && <span><Translate text="Loading" /> ...</span>}
                    {!this.state.loading
                      &&
                      <Row>
                        <Col md={8} sm={12} xs={12}>
                          <h4>{this.props.lng == "ar" ? this.props.activity.title : this.props.activity.title_en}</h4>
                          {/* <a className="share-link" onClick={() => {
                        this.setState({ showSharingIcons: !this.state.showSharingIcons })
                      }}>
                        <div className="resp-sharing-button resp-sharing-button--download resp-sharing-button--small">
                          <div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" id="share" width="32.616" height="34.316" fontSize="2rem" viewBox="0 0 32.289 34.252">
                              <path id="Path_104" data-name="Path 104" d="M39.958,22a6.129,6.129,0,0,0-4.85,2.39L25.7,19.139a6.144,6.144,0,0,0,.341-2.02,6.009,6.009,0,0,0-.348-2.027l9.4-5.249a6.117,6.117,0,1,0-1.266-3.72,5.979,5.979,0,0,0,.348,2.027L24.781,13.4a6.118,6.118,0,1,0,.014,7.432l9.4,5.256A6.12,6.12,0,1,0,39.958,22Zm0-20.077a4.2,4.2,0,1,1-4.2,4.2A4.207,4.207,0,0,1,39.958,1.927ZM19.931,21.322a4.2,4.2,0,1,1,4.2-4.2A4.207,4.207,0,0,1,19.931,21.322Zm20.027,11a4.2,4.2,0,1,1,4.2-4.2A4.207,4.207,0,0,1,39.958,32.324Z" transform="translate(-13.8)" fill="#6be2b7" />
                            </svg>
                          </div>
                        </div>
                      </a> */}
                          {
                            <CpaSharing active={this.state.showSharingIcons ? 'active' : ''} />
                          }
                          {this.props.activity.instructors.length > 0 &&
                            <div className="author-wrapper">
                              <img src={BASE_URL + '/uploads/' + this.props.activity.instructors[0].image_path} />
                              <p>{this.props.activity.instructors[0].name}</p>
                            </div>
                          }
                          <div className="counter-wrapper">
                            <div className="row">
                              <span className="col-12"><i className="icon-location"></i>
                                {this.props.lng == "ar" ? this.props.activity.address : this.props.activity.address_en}
                                &nbsp;
                                {this.props.lng == "ar" ? this.props.activity.address_details : this.props.activity.address_details_en}
                              </span>
                              <div className="date-content">
                                <span className="col-6">
                                  <i className="icon-calendar"></i><Translate text="from" /> : &nbsp;
                                  {moment(this.props.activity.start_date).format('dddd Do MMMM YYYY')}
                                </span>
                                <span className="col-6">
                                  <Translate text="to" /> : &nbsp;
                                  {moment(this.props.activity.end_date).format('dddd Do MMMM YYYY')}
                                </span>
                              </div>

                              {
                                this.calRemainigDays() !== 0 ?
                                  <div className="days-counter">
                                    <span className="date-counter">{this.calRemainigDays()}</span>&nbsp;<span>{t("day")}</span>
                                  </div>
                                  :
                                  null
                              }
                              <div className="date-content">
                                <span className="col-6">
                                  <i className="icon-clock"></i><Translate text="from" /> : &nbsp;
                                  {moment(this.props.activity.start_date).format('h:mm a')}
                                </span>
                                <span className="col-6">
                                  <i className="icon-clock"></i><Translate text="to" /> : &nbsp;
                                  {moment(this.props.activity.end_date).format('h:mm a')}
                                </span>
                              </div>
                              <span className="col-12"><i className="icon-user"></i>{this.props.activity.number_of_user}</span>

                              {
                                this.props.activity.degree !== null &&
                                <span className="col-12"><i className="icon-graduation-cap"></i>{this.props.activity.degree === "is_not_degree" ? <Translate text="noCertificate" /> : <Translate text="certficateExist" />}</span>
                              }
                              {
                                this.props.activity.attendance_gender === null ?
                                  <>
                                    <span className="col-12"><i className="icon-users"></i> &nbsp; <Translate text="bothMalesAndFemales" /></span>
                                  </>
                                  :
                                  this.props.activity.attendance_gender === "male" ?
                                    <span className="col-12"> <i className="icon-male"></i> <Translate text="males" /></span>
                                    :
                                    <span> <i className="icon-female"></i> <Translate text="females" /></span>
                              }
                            </div>
                          </div>
                        </Col>

                        <Col md={4} sm={12} xs={12}>
                          {
                            this.props.user.is_authenticated === null ?
                              <Button variant="success" size="lg" className="single-reg" onClick={this.props.toggleModal}><Translate text="signIn" /></Button>
                              :
                              <Button disabled={!this.state.isPassed} onClick={() => this.regUserInEvent()} variant="success" size="lg" className="single-reg"><Translate text="register" /></Button>
                          }
                          <img src={BASE_URL + '/uploads/' + this.props.activity.image_path} />
                        </Col>
                        <p>{ReactHtmlParser(this.props.lng == "ar" ? this.props.activity.description : this.props.activity.description_en)} </p>
                      </Row>
                    }
                  </Container>
                </div>
                <div className="single-content">
                  <Container>

                    {
                      !this.state.loading &&
                      this.props.activity.media.length > 0 &&
                      <h4><Translate text="content" /></h4>
                    }

                    {!this.state.loading
                      && <Row className="justify-content-center">
                        <Col xs={10} >
                          {this.props.activity.media.length > 0 &&
                            <GalleryImage imgs={this.props.activity.media.filter(item => item.type === "image")} />
                          }

                          {this.props.activity.media.length > 0 &&
                            this.props.activity.media.map((item, index) => {
                              return item.type === "link" ?
                                <div className="links-attach">
                                  <a key={index} href={BASE_URL + '/uploads/' + JSON.parse(item.src)[0].download_link} target={"blank"}>
                                    <i className="icon-link" />
                                    <img src={BASE_URL + '/uploads/' + JSON.parse(item.src)[0].download_link} />
                                  </a>
                                </div> : null
                            })
                          }

                          {this.props.activity.media.length > 0 &&
                            this.props.activity.media.map((item, index) => {
                              return item.type === "attachment" ? <div className="links-attach"> <a key={index} href={BASE_URL + '/uploads/' + JSON.parse(item.src)[0].download_link} target={"blank"}><i className="icon-download" />{JSON.parse(item.src)[0].original_name}</a> </div> : null
                            })
                          }

                          {
                            this.props.activity.media.length > 0 &&
                            this.props.activity.media.map((item) => {
                              return item.type === "video" ?
                                <div className="links-attach">
                                  <iframe className="embed-responsive-item" width="100%" height="500px"
                                    src={BASE_URL + '/uploads/' + JSON.parse(item.src)[0].download_link}
                                    frameBorder="0"
                                    allow="accelerometer;encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                                  </iframe>
                                </div> : null
                            })
                          }
                        </Col>
                      </Row>
                    }
                  </Container>
                </div>
              </HomeSectionFC>
            </div>
            : <DefaultPage header={true} />}
        </div>

    }
    return (
      this.props.page &&
      cardContent
    );
  }
}

MotamakenSingle.propTypes = {
  actions: PropTypes.object.isRequired,
  fuelSavings: PropTypes.object.isRequired,
  activity: PropTypes.object.isRequired,
  match: PropTypes.object,
  lng: PropTypes.string.isRequired,
  "location": PropTypes.object,
  "location.state": PropTypes.object,
  title: PropTypes.string,
  title_en: PropTypes.string,
  instructor_image: PropTypes.string,
  address: PropTypes.string,
  address_en: PropTypes.string,
  date: PropTypes.string,
  tp_date: PropTypes.string,
  desc: PropTypes.string,
  desc_en: PropTypes.string,
  descripton: PropTypes.string,
  description_en: PropTypes.string,
  e_image: PropTypes.string,
  page: PropTypes.object.isRequired,
  navigationActions: PropTypes.object.isRequired,
  ConsumerActions: PropTypes.object,
  "ConsumerActions.getConsumerDataById": PropTypes.func,
  consult_data_by_id: PropTypes.object,
  "consult_data_by_id.attachments": PropTypes.array,
  "consult_data_by_id.consumer_advisor_videos": PropTypes.array,
  "consult_data_by_id.consumer_advisor_videos.map": PropTypes.func,
  "consult_data_by_id.title": PropTypes.string,
  "consult_data_by_id.title_en": PropTypes.string,
  "consult_data_by_id.instructor_image": PropTypes.string,
  "consult_data_by_id.instructor_name": PropTypes.string,
  "consult_data_by_id.instructor_name_en": PropTypes.string,
  "consult_data_by_id.address": PropTypes.string,
  "consult_data_by_id.address_en": PropTypes.string,
  "consult_data_by_id.date": PropTypes.string,
  "consult_data_by_id.to_date": PropTypes.string,
  "consult_data_by_id.description": PropTypes.string,
  "consult_data_by_id.description_en": PropTypes.string,
  "consult_data_by_id.image": PropTypes.string,
  "consult_data_by_id.consumer_advisor_videos.length": PropTypes.number,
  toggleModal: PropTypes.func,
  user: PropTypes.object,
  "user.is_authenticated": PropTypes.bool
};

function mapStateToProps(state) {
  return {
    consult_data_by_id: state.consumerConsult.consult_data_by_id,
    activity: state.motamaken.activity,
    lng: state.user.lng,
    page: state.navigation.page,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    ConsumerActions: bindActionCreators(ConsumerActions, dispatch),
    navigationActions: bindActionCreators(navigationActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MotamakenSingle)
