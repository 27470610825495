import React from 'react';
import { Row, Col } from "react-bootstrap";
import PdfFiles from '../../../../GlobalComponent/pdfFiles/pdfFiles';
import CpaSharing from '../../../../GlobalComponent/cpaSharing/cpaSharing';
import PropTypes from "prop-types";
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class AutoContracts extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let attachmentsArray = JSON.parse(this.props.contract.attachments);
        let attachmentsArrayEn = JSON.parse(this.props.contract.attachments_en);
        return (
            <div className="AutoContracts-conatiner">
                <Row>
                    <Col xs={12}>
                        <h4>{this.props.lng == 'ar' ? this.props.contract.title : this.props.contract.title_en}</h4>
                    </Col>
                    <Col xs={12}>
                        <CpaSharing />
                    </Col>
                    <Col xs={12}>
                        {ReactHtmlParser(this.props.lng === 'ar' ? this.props.contract.description : this.props.contract.description_en)}
                    </Col>
                    <Col xs={12}>
                        {
                            this.props.contract.attachments !== null &&
                            this.props.contract.attachments.length > 0 &&
                            <PdfFiles files={this.props.lng === "ar" ? attachmentsArray : attachmentsArrayEn} />
                        }
                    </Col>
                </Row>
            </div>
        )
    }
}
AutoContracts.propTypes = {
    contract: PropTypes.object,
    lng: PropTypes.string.lng
}
function mapStateToProps(state) {
    return {
        lng: state.user.lng,
    };
}

export default withRouter(connect(mapStateToProps, null)(AutoContracts))
