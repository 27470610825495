
const filter = {
    filterByYear(array, year,category) {
        let result
        if(category!=null && category !='' ){
            result = array.filter(function (item) {
                return item.year_id == year && item.category_id==category;
            });
        }else{
            if(year==''){
                result=array
            }else{
                result = array.filter(function (item) {
                    return item.year_id == year ;
                });
            }
            
        }
        return result
    },
    filterByCategory(array, category,year) {
        let result
        if (year!=null && year!=''){
            result = array.filter(function (item) {
                return item.category_id == category && item.year_id == year;
            });
        }else{
            if(category==''){
                result=array
            }else{
                result = array.filter(function (item) {
                    return item.category_id == category ;
                });
            }
            
        }
        
        return result
    }
};

export default filter;