import React from 'react';
import ImgHeader from '../../../GlobalComponent/ImgHeader/ImgHeader';
import BreadCrumb from '../../../GlobalComponent/BreadCrumb/BreadCrumb';
import ContainerCard from "../../../GlobalComponent/ContainerCard/containerCard";
import './recognize.scss';
import { Container, Row, Col, Modal } from "react-bootstrap";
import * as actions from '../../../../actions/program-and-activities/programs/recognize';
import * as navigationActions from '../../../../actions/navigation';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import CpaSharing from '../../../GlobalComponent/cpaSharing/cpaSharing';
import { BASE_URL } from '../../../../constants/config';
// import filter
// import filter from '../../../../actions/FilterBy';

class Recognize extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            show: false,
            modalImg: null,
            subjects: [],
            filteredSubjects: [],
            loading: true,
            showMore: true,
            subjectLength: '',
            lng: localStorage.getItem('lng'),
            img_url: BASE_URL + '/uploads/',
            contentNo: 9,
            routes: [],
            lastSelectedYear: null,
            lastSelectedCategory: null,
            selectedYear: undefined,
            selectedCat: undefined,
        };
    }

    getUrl(url) {
        let obj = {}
        if (url) {
            url.substring(1).split("?").map(u => {
                if (u.includes("year")) {
                    obj.year = u.split("=")[1]
                }
                else if (u.includes("cat")) {
                    obj.cat = u.split("=")[1]

                }
            })

        }



        obj.cat = obj.cat ? obj.cat : undefined;


        obj.year = obj.year ? obj.year : undefined

        return obj;
    }

    componentDidMount() {
        const { cat, year } = this.getUrl(window.location.search)
        // console.log(cat, year)
        this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                this.props.actions.getRecogizeSubjects()
                    .then(() => {
                        this.setState({ ...this.state, contentNo: 9, loading: false, subjects: this.props.subjects, filteredSubjects: this.props.subjects });
                        this.filterByCategory(cat);
                        this.filterByYear(year);
                        setTimeout(() => {

                            if (this.state.filteredSubjects.length == 0) {
                                this.setState({
                                    noData:
                                        <div className="notFound-container">
                                            <div className="notFound-content text-center">
                                                <div className="inner">
                                                    <h3>
                                                        {t("noData")}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>,
                                    showMore: false
                                })
                            }

                        }, 1)
                    }).catch(() => {
                    });
            })
    }
    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }
    handleClose = () => {
        this.setState({ show: false });
    }

    handleShow = (id) => {
        // console.log("i", id)
        let item = this.props.subjects.filter(el => el.id == id);
        // console.log(item)
        if (this.props.lng === 'ar') {
            // this.setState({ modalImg: this.props.subjects[id].src });
            this.setState({ modalImg: item[0].src });
        } else {
            // this.setState({ modalImg: this.props.subjects[id].src_en });
            this.setState({ modalImg: item[0].src_en });
        }
        this.setState({ show: true });
    }

    handleShowDay = (index) => {
        if (this.props.lng === 'ar') {
            this.setState({ modalImg: this.props.attachments[index] });
        } else {
            this.setState({ modalImg: this.props.attachments[index] });
        }
        this.setState({ show: true });
    }
    //To filter 
    filterByYear = (commingYear) => {
        let url = `/Know${commingYear ? `?year=${commingYear}` : ""}${this.state.selectedCat ? `?cat=${this.state.selectedCat}` : ""}`
        this.props.history.push(url);
        // console.log("year", commingYear);
        this.setState({ ...this.state, selectedYear: commingYear })
        setTimeout(() => {

            this.filter();
        }, 1)

    }


    filter = () => {
        let catId = this.getCatID();
        let yearId = this.getYearID();
        // this.setState({ ...this.state, filteredSubjects: this.state.subjects.filter(sub => sub.category_id == catId && sub.year_id == yearId) })

        if (this.state.selectedCat && this.state.selectedYear) {
            this.setState({ ...this.state, filteredSubjects: this.state.subjects.filter(sub => sub.category_id == catId && sub.year_id == yearId) })
        } else if (this.state.selectedCat) {
            this.setState({ ...this.state, filteredSubjects: this.state.subjects.filter(sub => sub.category_id == catId) })

        } else if (this.state.selectedYear) {
            this.setState({ ...this.state, filteredSubjects: this.state.subjects.filter(sub => sub.year_id == yearId) })

        } else {
            this.setState({ ...this.state, filteredSubjects: this.state.subjects })

        }

        setTimeout(() => {
            this.CheckSubjuctNoData()
        }, 1);
    }
    filterByCategory = (catgeory) => {
        let url = `/Know${catgeory ? `?cat=${catgeory}` : ""}${this.state.selectedYear ? `?year=${this.state.selectedYear}` : ""}`
        this.props.history.push(url);
        // console.log("Cat", catgeory);
        this.setState({ ...this.state, selectedCat: catgeory })
        setTimeout(() => {

            this.filter();
        }, 1)
        // console.log("filtered", this.props.filters.categories.filter(cat => cat.name_en == catgeory))
        // if(this.props.subjects.length==this.props.filteredSubjects.length){


        // if (this.props.filters.categories.filter(cat => cat.name_en == catgeory).length == 1) {
        //     this.setState({ ...this.state, filteredSubjects: this.state.subjects.filter(sub => sub.category_id == this.props.filters.categories.filter(cat => cat.name_en == catgeory)[0].id) })
        //     console.log("fil", this.state.filteredSubjects)


        // }}
        // else{
        //     if (this.props.filters.categories.filter(cat => cat.name_en == catgeory).length == 1) {
        //         this.setState({ ...this.state, filteredSubjects: this.state.filteredSubjects.filter(sub => sub.category_id == this.props.filters.categories.filter(cat => cat.name_en == catgeory)[0].id) })
        //         console.log("fil", this.state.filteredSubjects)


        //     }
        // }
        // this.setState({
        //     subjects: filter.filterByCategory(this.props.subjects, catgeory, this.state.lastSelectedYear),
        //     lastSelectedCategory: catgeory
        // })
        // setTimeout(() => {
        //     this.CheckSubjuctNoData()
        // }, 1);
    }


    // filterByYear = (newYear) => {


    //     //  console.log("yar changes", newYear)

    //     let url = `/Know${newYear ? `?year=${newYear}` : ""}${this.state.selectedCat ? `?cat=${this.state.selectedCat}` : ""}`
    //     this.props.history.push(url);

    //     console.log(this.props.filters)



    // }
    getCatID = () => {
        if (this.state.selectedCat) {
            if (this.props.filters.categories.filter(cat => cat.name == this.state.selectedCat).length >= 1) {
                return this.props.filters.categories.filter(cat => cat.name == this.state.selectedCat)[0].id;
            } else {
                this.setState({ ...this.state, selectedCat: undefined })
                return 0;
            }

        }
    }
    getYearID = () => {
        if (this.state.selectedYear) {
            if (this.props.filters.years.filter(year => year.year == this.state.selectedYear).length >= 1) {
                return this.props.filters.years.filter(year => year.year == this.state.selectedYear)[0].id;
            } else {
                this.setState({ ...this.state, selectedCat: undefined })
                return 0;
            }

        }
    }
    // filterByCategory = (newCatgeory) => {
    //     // console.log("cat changes", newCatgeory)
    //     getCatID(newCatgeory)
    //     let url = `/know${this.state.selectedYear ? `?year=${this.state.selectedYear}` : ""}${newCatgeory ? `?cat=${newCatgeory}` : ""}`
    //     this.props.history.push(url)


    // }
    CheckSubjuctNoData = () => {
        if (this.state.filteredSubjects.length == 0) {
            this.setState({
                noData:
                    <div className="notFound-container">
                        <div className="notFound-content text-center">
                            <div className="inner">
                                <h3>
                                    {t("noData")}
                                </h3>
                            </div>
                        </div>
                    </div>,
                showMore: false
            })
        } else {
            this.setState({
                noData: '',
                showMore: true
            })
        }
    }
    viewMore = () => {
        let moreNextValue = this.state.contentNo
        if (this.state.contentNo + 6 < this.state.filteredSubjects.length) {
            this.setState({
                contentNo: moreNextValue += 6
            })
        } else {
            this.setState({
                contentNo: moreNextValue += (this.state.filteredSubjects.length - moreNextValue)
                ,
                showMore: false

            })
        }
    }
    view(index) {
        return index < this.state.contentNo
    }
    render() {

        // console.log("prop", this.state)
        if (this.props.url) {
            return (
                <div className="annualReports-container">
                    <Container>
                        <Row>
                            {this.props.attachments.length > 0
                                && this.props.attachments.map((value, index) => {
                                    return (
                                        <Col xs={12} md={6} lg={4} key={index} className={this.view(index) ? '' : 'd-none'}>
                                            <div className="flip-card">
                                                <div className="flip-card-inner" onClick={() => this.handleShowDay(index)}>
                                                    <div className="flip-card-front">
                                                        <img src={this.state.img_url + value} />
                                                    </div>
                                                    <div className="flip-card-back">
                                                        <img src={this.state.img_url + value} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })}
                            {this.state.noData}
                        </Row>
                    </Container>
                    <Modal dialogClassName="annualReports-container" size="xl" show={this.state.show} onHide={() => this.handleClose()}>
                        <ContainerCard>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                                <div className="shareOn">
                                    <CpaSharing url={this.state.img_url + this.state.modalImg} />
                                </div>
                                <div className="img-body">
                                    <img src={this.state.img_url + this.state.modalImg} />
                                </div>
                            </Modal.Body>
                        </ContainerCard>
                    </Modal>
                </div>

            )
        }
        else {
            return (
                this.props.page &&
                <div className="annualReports-container">
                    <ImgHeader page={this.props.page} />
                    <BreadCrumb
                        routes={this.state.routes}
                        filterbyYear={true}
                        filterbySubject={true}
                        filterByYearfun={this.filterByYear}
                        filterByCategoryfun={this.filterByCategory}
                        selectedYear={this.state.selectedYear}
                        selectedCat={this.state.selectedCat}
                    />

                    <Container>
                        <Row>
                            {this.state.loading && <div><p>Loading ...</p></div>}
                            {this.state.filteredSubjects.length > 0
                                && this.state.filteredSubjects.map((value, index) => {
                                    {/* console.log(JSON.parse(value.src)[0]); */

                                        // console.log("b",value)
                                    }
                                    return (
                                        <Col xs={12} md={6} lg={4} key={index} className={this.view(index) ? '' : 'd-none'}>
                                            <div className="flip-card">
                                                <div className="flip-card-inner" onClick={() => this.handleShow(value.id)}>
                                                    <div className="flip-card-front">
                                                        <img src={this.state.img_url + (this.props.lng === 'ar' ? JSON.parse(value.src)[0].download_link : (JSON.parse(value.src_en) !== null ? JSON.parse(value.src_en)[0].download_link : ''))} />
                                                    </div>
                                                    <div className="flip-card-back">
                                                        <img src={this.state.img_url + (this.props.lng === 'ar' ? JSON.parse(value.src)[0].download_link : (JSON.parse(value.src_en) !== null ? JSON.parse(value.src_en)[0].download_link : ''))} />
                                                        <div className="title-back">
                                                            <h4 >{this.props.lng == 'ar' ? value.title : value.title_en}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })}
                            {this.state.noData}
                            {this.state.contentNo != this.state.filteredSubjects.length &&
                                <Col xs={12} className="text-center">
                                    <button type="button" className="btn submit-cpa-btn" onClick={this.viewMore}>{t("theMore")}</button>
                                </Col>
                            }

                        </Row>
                    </Container>
                    <Modal dialogClassName="annualReports-container" size="xl" show={this.state.show} onHide={() => this.handleClose()}>
                        <ContainerCard>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                                <div className="shareOn">
                                    <CpaSharing url={this.state.img_url + this.state.modalImg} />
                                </div>
                                <div className="img-body">
                                    {
                                        this.state.modalImg &&
                                        <img src={this.state.img_url + JSON.parse(this.state.modalImg)[0].download_link} />
                                    }
                                </div>
                            </Modal.Body>
                        </ContainerCard>
                    </Modal>
                </div>
            );
        }
    }
}
Recognize.propTypes = {
    actions: PropTypes.object.isRequired,
    subjects: PropTypes.array.isRequired,
    "attachments": PropTypes.array,
    "attachments.length": PropTypes.number,
    "attachments.map": PropTypes.func,
    "url": PropTypes.string,
    lng: PropTypes.string.isRequired,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    location: PropTypes.object,
    history: PropTypes.object,
    'history.push': PropTypes.func,
    'filters': PropTypes.any,
    'filters.categories': PropTypes.any,
    'filters.categories.filter': PropTypes.any,
    'filters.years': PropTypes.any,
    'filters.years.filter': PropTypes.any

};
function mapStateToProps(state) {
    return {
        subjects: state.recognize.subjects,
        lng: state.user.lng,
        page: state.navigation.page,
        filters: state.filters.filterObject
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Recognize)