import React from 'react';
import ShakawaSection from './ShakawaSection/ShakawaSection';
import DetailsSection from './DetailsSection/DetailsSection';
import ServicesSection from './servicesSection/servicesSection';
// import Statistics from './statistics/statistics';
import AdPopUp from '../GlobalComponent/adPopUp/adPopUp';
import './HomePage.scss';
import { Link } from 'react-router-dom';
import { ButtonToolbar, Button, Carousel } from 'react-bootstrap';
import Translate from '../GlobalComponent/translate/translate';
import { BASE_URL } from '../../constants/config';
// import { apiFetch } from '../../utils/http.helpers';
import * as actions from '../../actions/home';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import CookieMessage from '../cookiesMessage/CookieMessage';
// import Countdown from 'react-countdown';


class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testYourKnowledge: "",
      testYourKnowledge_en: "",
      knowledgeQuestionsHome: [],
      video: "",
      homeCards: [],
      news: [],
      header_title: null,
      header_title_en: null
    }
  }

  componentDidMount() {
    this.props.actions.getHomePage()
    this.props.actions.getHomePageAlert()
  }


  chunkArray = (array, chunkSize) => {
    const numberOfChunks = Math.ceil(array.length / chunkSize)
    return [...Array(numberOfChunks)]
      .map((value, index) => {
        return array.slice(index * chunkSize, (index + 1) * chunkSize)
      })
  }

  render() {
    return (
      this.props.home &&
      <div className="homePage-container">
        <div className="top-head">
          <div className="overlay"></div>
          <Carousel controls={false} indicators={false}>
            <Carousel.Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video className="embed-responsive-item" autoPlay loop muted control="false">
                  <source
                    src={BASE_URL + '/uploads/' + JSON.parse(this.props.home.video)[0].download_link}
                    type="video/mp4" />
                </video>
              </div>
              <Carousel.Caption>
                <p>{localStorage.getItem('lng') === "ar" ? this.props.home.header_title : this.props.home.header_title_en}</p>
                <ButtonToolbar>
                  <Link to="/landing/3">
                    <Button variant="outline-success" size="lg"><Translate text="ourPrograms" /></Button>
                  </Link>
                  <Link to="/about-us">
                    <Button variant="outline-success" size="lg"> <Translate text="moreAbout" /> </Button>
                  </Link>
                </ButtonToolbar>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          {/* <Countdown date={Date.now() + 10000} /> */}

          <AdPopUp />
        </div>
        {/* {console.log("this.props.home.home_cards", this.props.home.home_cards)} */}
        <ShakawaSection knowledgeQuestions={this.props.home.knowledge_question_home} testYourKnowlegde={this.props.home.test_your_knowledge} testYourKnowlegde_en={this.props.home.test_your_knowledge_en} />
        <Carousel controls={this.props.home.home_cards.length > 4} indicators={this.props.home.home_cards.length > 4} interval={null}>
          {
            this.chunkArray(this.props.home.home_cards, 4).map((cardsArray, index) => {
              return (
                <Carousel.Item key={index}>
                  <ServicesSection homeCards={cardsArray} />
                </Carousel.Item>
              )
            })
          }
        </Carousel>
        <DetailsSection news={this.props.home.news} />
        <CookieMessage homeAlert={this.props.homeAlert} lng={this.props.lng} />
        {/* <Statistics complainsCount={this.props.home.completeComplainsCount} weHearYouCount={this.props.home.nasma3akCount} subscribersCount={this.props.home.subscribeUsersCount} /> */}
      </div>
    )
  }
}

HomePage.propTypes = {
  actions: PropTypes.object.isRequired,
  "actions.getHomePage": PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
  homeAlert: PropTypes.object.isRequired,
  "home.video": PropTypes.string,
  "home.header_title": PropTypes.string,
  "home.header_title_en": PropTypes.string,
  "home.knowledge_question_home": PropTypes.array,
  "home.test_your_knowledge": PropTypes.string,
  "home.test_your_knowledge_en": PropTypes.string,
  "home.home_cards": PropTypes.array,
  "home.news": PropTypes.array,
  lng: PropTypes.string
}


function mapStateToProps(state) {
  return {
    lng: state.user.lng,
    home: state.home.homePage,
    homeAlert: state.home.homePageAlert,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);