
// import Translate from '../translate/translate';
import Translate from './../translate/translate';
import React from "react"
import bug from "./../../../img/bug.png"
import './TechnicalProblem.scss';
class TechnicalProblem extends React.Component {
  render() {
    return <div className="technical">
      <img src={bug} alt="" />
      <h2><Translate text="technicalProblem" /></h2>
      {/* <h3>   <Translate text="willgoback" /></h3> */}

    </div>
  }
}

export default TechnicalProblem;