import React from 'react';
import PropTypes from 'prop-types';
import './SectionCard.scss';
import { Button } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
// import Authentication from '../../../../Authentication/Authentication';
import Authentication from '../../../../Authentication/Authentication';
import Translate from '../../../../GlobalComponent/translate/translate';
import { connect } from 'react-redux';
import { BASE_URL } from '../../../../../constants/config';

class SectionCard extends React.Component {
  constructor(props) {
    super(props)
    this.cardHover = this.cardHover.bind(this);
    this.cardLeave = this.cardLeave.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
      img: '0',
      btn: '0',
      shadow: '0 1px 4px #999',
      url: BASE_URL + '/uploads/',
      isPassed: null
    }
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  cardHover() {
    this.setState({
      img: '1',
      btn: 'calc(100% - 145px)',
      shadow: '0 8px 9px #999'
    });
  }
  cardLeave() {
    this.setState({
      img: '0',
      btn: '0',
      shadow: '0 1px 4px #999'
    });
  }

  componentDidMount() {
    let currentDate = new Date();
    let eventDate;

    ///motamaken special variables
    if (this.props.url.includes('skilled')) {
      eventDate = new Date(this.props.newscard.date);

      this.setState({
        isPassed: eventDate.getTime() <= currentDate.getTime()
      });
    }
  }

  isLateDate() {
    if (this.props.url.includes('skilled')) {
      let todayDate = new Date();
      let cardDate = new Date(this.props.newscard.end_date) /// motmaken
      return todayDate.getTime() > cardDate.getTime();
    }
    else return null;
  }

  render() {
    moment.locale(this.props.lng);
    const date = this.props.url.includes('skilled') ?
      moment(this.props.newscard.start_date) : moment(this.props.consultantCard.date);
    let cardContent = this.props.url.includes('skilled') ?
      <div
        className="newsCard-container sectionCard-container"
        style={{ boxShadow: this.state.shadow }} >
        <Link to={`/skilled/activity/${this.props.newscard.id}`}>
          <div className="card-img">
            <img src={this.state.url + this.props.newscard.image_path} />
            <div className="img-overlay" style={{ opacity: this.state.img }}></div>
          </div>
          <div className="card-content bg-white">
            <h4>{this.props.lng == 'ar' ? this.props.newscard.title : this.props.newscard.title_en}</h4>
            {this.props.newscard.instructors.length > 0 &&
              <div className="author-wrapper">
                <img src={this.state.url + this.props.newscard.instructors[0].image_path} />
                <p>{this.props.lng == 'ar' ? this.props.newscard.instructors[0].name : this.props.newscard.instructors[0].name_en}</p>
              </div>
            }
            <div className="counter-wrapper">
              <div className="row">
                <span className="col-12"><i className="icon-location"></i>{this.props.lng == 'ar' ? this.props.newscard.address : this.props.newscard.address_en}</span>
                <span className="col-12"><i className="icon-calendar"></i>{date.format('dddd Do MMMM YYYY')}</span>
                <span className="col-12" style={{ display: 'none' }}><i className="icon-user"></i>{this.props.newscard.number_of_user} <Translate text="subscribed" /> </span>
                {!(this.isLateDate() && !this.props.newscard.is_registered) &&
                  <Button variant="success" className="col-6" onClick={this.handleShow} > <Translate text="register" /> </Button>
                }
                {(this.isLateDate() && this.props.newscard.is_registered) &&
                  <Button variant="success" className="col-6" onClick={() => this.props.showIsRegisteredModal(this.props.newscard.id)}  ><Translate text="registered" /></Button>
                }
              </div>
            </div>
          </div>
        </Link>
        <div className="action-hover">
          <h5>{this.props.lng == 'ar' ? this.props.newscard.title : this.props.newscard.title_en}</h5>
          <div className="button-wrapper">
            {!(this.isLateDate() && !this.props.newscard.is_registered) &&
              (!this.state.isPassed &&
                <Button variant="success" className="col-6" onClick={this.handleShow} > <Translate text="register" /> </Button>)
            }
            {(this.isLateDate() && this.props.newscard.is_registered) &&
              <Button variant="success" className="col-6" onClick={() => this.props.showIsRegisteredModal(this.props.newscard.id)}  ><Translate text="registered" /> </Button>
            }
            <Button variant="outline-success" >
              <Link to={`/skilled/activity/${this.props.newscard.id}`}>
                <Translate text="details" />
              </Link>
            </Button>
          </div>
        </div>
        <Authentication />
      </div>
      :
      <div style={{ height: "400px", paddingBottom: "1rem" }}>
        <div
          className="newsCard-container sectionCard-container"
          style={{ boxShadow: this.state.shadow }}>
          <div>
            <div className="card-img">
              <img src={this.state.url + this.props.consultantCard.image} />
              <div className="img-overlay"></div>
            </div>
            <div className="card-content bg-white">
              <h4>{this.props.lng == 'ar' ? this.props.consultantCard.title : this.props.consultantCard.title_en}</h4>
              <div className="author-wrapper">
                <img src={this.state.url + this.props.consultantCard.instructor_image} />
                <p>{this.props.lng == 'ar' ? this.props.consultantCard.instructor_name : this.props.consultantCard.instructor_name_en}</p>
              </div>
              <div className="counter-wrapper">
                <div className="row">
                  <span className="col-12 "><i className="icon-location"></i>{this.props.lng == 'ar' ? this.props.consultantCard.address : this.props.consultantCard.address_en}</span>
                  <span className="col-12"><i className="icon-calendar"></i>{date.format('dddd Do MMMM YYYY')}</span>
                  <span className="col-12"><i className="icon-clock"></i>{moment(date).format('h:mm a')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="consumer-action-hover">
            <h5>{this.props.lng == 'ar' ? this.props.consultantCard.title : this.props.consultantCard.title_en}</h5>
            <h5>{this.props.lng == 'ar' ? this.props.consultantCard.instructor_name : this.props.consultantCard.instructor_name_en}</h5>
            <span className="col-12"><i className="icon-location"></i>
              {this.props.lng == 'ar' ? this.props.consultantCard.address : this.props.consultantCard.address_en}</span>
            <div className="col-12">
              <i className="icon-calendar"></i>
              <span>{moment(this.props.consultantCard.date).format('dddd Do MMMM YYYY')}</span>
            </div>
            <div className="col-12">
              <i className="icon-clock"></i>
              <span>{moment(this.props.consultantCard.date).format('h:mm a')}</span>
            </div>
            <div className="button-wrapper">
              <Button onClick={() => this.props.history.push(`/consumer-advisor/${this.props.consultantCard.id}`)} variant="outline-success" >
                <Translate text="details" />
              </Button>
            </div>
          </div>
          <Authentication />
          {/* <Modal show={this.state.show} onHide={this.handleClose}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <div className="quiz-header">
                  <h4>سجل الآن</h4>
                  <p>إملئ القائمة لكي تتمكن من الاشتراك في هذه الدورة</p>
                </div>
              </Modal.Body>
            </Modal> */}
        </div>
      </div>
    return (cardContent);
  }
}

SectionCard.propTypes = {
  newscard: PropTypes.object,
  handleActivityRegister: PropTypes.func,
  showIsRegisteredModal: PropTypes.func,
  lng: PropTypes.string.isRequired,
  url: PropTypes.string,
  includes: PropTypes.func,
  consultantCard: PropTypes.object,
  history: PropTypes.object
};

function mapStateToProps(state) {
  return {
    lng: state.user.lng,
  };
}

export default withRouter(connect(mapStateToProps)(SectionCard));