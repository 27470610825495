import {GET_WORLD_DAYS_DATA, GET_WORLD_DAY_PAGE} from '../../constants/actionTypes';
import {apiFetch} from '../../utils/http.helpers';
import { BASE_URL } from '../../constants/config';

export function getworlddaysArray () {
    return function (dispatch) {
        return apiFetch(BASE_URL+'/api/world_days/days', {
          method: "GET",
          // mode: "cors",
          withCredentials: true,
          headers: {
            "Accept": "application/json",
            // "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
          }})
          .then(response => response.json())
          .then(res => {
            // console.log('Res in fetch', res);
            dispatch({ type: GET_WORLD_DAYS_DATA.SUCCESS, payload:res });
          }).catch(() => {
            // console.log('Error is', error);
          });
      };
}
export function getworlddayPage (id) {
  return function (dispatch) {
      return apiFetch(BASE_URL+'/api/world_days/day/?id='+id, {
        method: "GET",
        // mode: "cors",
        withCredentials: true,
        headers: {
          "Accept": "application/json",
          // "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
        }})
        .then(response => response.json())
        .then(res => {
          // console.log('Res in fetch', res);
          dispatch({ type: GET_WORLD_DAY_PAGE.SUCCESS, payload:res });
        }).catch(() => {
          // console.log('Error is', error);
        });
    };
}