import React from 'react';
import './serviceCard.scss';
import PropTypes from 'prop-types';
// import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Translate from '../../../GlobalComponent/translate/translate';
import { BASE_URL } from '../../../../constants/config';

const ServiceCard = props => {    
    return (
        <div className="serciceCard-container main shadow-sm rounded">
            <div className="card-back-img">
                <img className="img-fluid" src={BASE_URL + '/uploads/' + props.card.image} />
            </div>
            <div className="card-icon">
                {/* <img src={BASE_URL + '/' + props.card.image} /> */}
            </div>
            <div className="card-content">
                <h4>{localStorage.getItem('lng') === "ar" ? props.card.title : props.card.title_en}</h4>
                <p>{localStorage.getItem('lng') === "ar" ? props.card.description : props.card.description_en}</p>
                <div className="moreBtnCard">
                    <Link to={props.card.link} onClick={() => { this.scrollToTop(); }}>
                        <button className="rounded" ><Translate text="LearnMore" /></button>
                    </Link>
                </div>
            </div>
        </div>
    );
};
ServiceCard.propTypes = {
    card: PropTypes.object
};
export default ServiceCard;
