import { CREATE_NEW_ERSHEDNA_SHAKWA } from '../../../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../../../initialState';

export default function guideUsReducer(state = initialState.programs_and_activities.programs.legal_advocay.guideUs, action) {
  switch (action.type) {
    case CREATE_NEW_ERSHEDNA_SHAKWA.SUCCESS:
      return objectAssign({}, state, { apiResponse: action.payload });
    default:
      return state;
  }
}