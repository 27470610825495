import { GET_SINGLE_NEWS_DATA } from '../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../initialState';

export default function singleNewsReducer(state = initialState.information_center.single_news, action) {    
    switch (action.type) {
        case GET_SINGLE_NEWS_DATA.SUCCESS:
            return objectAssign({}, state, {
                object: action.payload,

            });
        default:
            return state;
    }
}