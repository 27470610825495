import React from 'react';
import './comparePage.scss';
import BreadCrumb from '../../../GlobalComponent/BreadCrumb/BreadCrumb';
import ImgHeader from '../../../GlobalComponent/ImgHeader/ImgHeader';
// import {Container, Row, Col, Carousel} from 'react-bootstrap';
// import { Container, Row, Col, Button, Collapse, Table, Modal } from 'react-bootstrap';
import { Row, Col, Table, Modal } from 'react-bootstrap';
import PropTypes from "prop-types";
import * as actions from "../../../../actions/program-and-activities/programs/compareAndRate";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Translate from '../../../GlobalComponent/translate/translate';
import * as navigationActions from '../../../../actions/navigation';
import ReactHtmlParser from 'react-html-parser';
import { BASE_URL } from '../../../../constants/config';

// import { Link } from "react-router-dom";


class ComparePage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            // pageID: null,
            menuOpen: false,
            menuOpen1: false,
            arrowDown: 'icon-down-dir',
            arrowUp: 'icon-up-dir',
            arrow: null,
            arrowDown1: 'icon-down-dir',
            arrowUp1: 'icon-up-dir',
            arrow1: null,
            filtersArray: [],
            filtersToBeRendered: null,
            tableToBeRendered: null,
            modalVisibility: false,
            cards: [
                {
                    icon: 'icon-board',
                    title: 'Title1',
                    hover: [
                        {
                            name: 'btn1',
                            link: '/compare-and-rate/categories/1'
                        },
                        {
                            name: 'btn2',
                            link: '/compare-and-rate/categories/2',
                        },
                        {
                            name: 'btn3',
                            link: '/compare-and-rate/categories/3',
                        },
                    ]
                },
                {
                    icon: 'icon-board',
                    title: 'Title2',
                    hover: [
                        {
                            name: 'btn1',
                            link: '/compare-and-rate/categories/4'
                        },
                        {
                            name: 'btn2',
                            link: '/compare-and-rate/categories/5',
                        },
                        {
                            name: 'btn3',
                            link: '/compare-and-rate/categories/6',
                        },
                    ]
                },
                {
                    icon: 'icon-board',
                    title: 'Title1',
                    hover: [
                        {
                            name: 'btn1',
                            link: '/compare-and-rate/categories/1'
                        },
                        {
                            name: 'btn2',
                            link: '/compare-and-rate/categories/2',
                        },
                        {
                            name: 'btn3',
                            link: '/compare-and-rate/categories/3',
                        },
                    ]
                },
                {
                    icon: 'icon-board',
                    title: 'Title1',
                    hover: [
                        {
                            name: 'btn1',
                            link: '/compare-and-rate/categories/1'
                        },
                        {
                            name: 'btn2',
                            link: '/compare-and-rate/categories/2',
                        },
                        {
                            name: 'btn3',
                            link: '/compare-and-rate/categories/3',
                        },
                    ]
                },
            ],
            routes: [],
            comparisonProducts: [],

            availableProducts: [
                {
                    name: 'New Product 1',
                    img: 'https://www.snb.com/stuff/contentmgr/files/0/39deaa2de12c64deca866c0cce11045c/misc/personal_credit_card.png',
                    description: [
                        {
                            filterName: 'Filter1',
                            value: 'asd'
                        },
                        {
                            filterName: 'Filter2',
                            value: 'qwe'
                        }
                    ]
                },
                {
                    name: 'New Product 2',
                    img: 'https://www.snb.com/stuff/contentmgr/files/0/39deaa2de12c64deca866c0cce11045c/misc/personal_credit_card.png',
                    description: [
                        {
                            filterName: 'Filter3',
                            value: 'asd'
                        },
                        {
                            filterName: 'Filter2',
                            value: 'qwe'
                        }
                    ]
                },
                {
                    name: 'New Product 3',
                    img: 'https://www.snb.com/stuff/contentmgr/files/0/39deaa2de12c64deca866c0cce11045c/misc/personal_credit_card.png',
                    description: [
                        {
                            filterName: 'Filter1',
                            value: 'asd'
                        },
                        {
                            filterName: 'Filter2',
                            value: 'qwe'
                        }
                    ]
                },
            ]
        }

        this.removeobjectFromCompareList = this.removeobjectFromCompareList.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.toggleCollapse1 = this.toggleCollapse1.bind(this);
        this.handleFilters = this.handleFilters.bind(this);
        this.renderTable = this.renderTable.bind(this);
        this.renderFilters = this.renderFilters.bind(this);
        this.deleteProductfromComparison = this.deleteProductfromComparison.bind(this);
        this.addProductToComparison = this.addProductToComparison.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.showModal = this.showModal.bind(this);
    }

    componentDidMount() {
        this.props.navigationActions.getPageInfoApi('/compare-and-rate/category').then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page), loading: true });
                let objectsIds = this.props.location.state.IdsArray;
                this.props.actions.compareObjects(objectsIds)
                    .then(res => {
                        this.setState({ comparisonProducts: res }, () => {
                            this.renderFilters();
                        })
                    })
            }
        )

    }

    toggleCollapse() {
        if (this.state.arrow === this.state.arrowDown) {
            this.setState({
                menuOpen: true,
                arrow: this.state.arrowUp
            });
        } else {
            this.setState({
                menuOpen: false,
                arrow: this.state.arrowDown
            });
        }

    }

    toggleCollapse1() {
        if (this.state.arrow1 === this.state.arrowDown1) {
            this.setState({
                menuOpen1: true,
                arrow1: this.state.arrowUp1
            });
        } else {
            this.setState({
                menuOpen1: false,
                arrow1: this.state.arrowDown1
            });
        }

    }

    handleFilters = event => {
        let tempArray = this.state.filtersArray;
        if (event.target.checked) {
            tempArray.push(event.target.value);
        } else {
            for (var x = 0; x < tempArray.length; x++) {
                if (tempArray[x] === event.target.value) {
                    tempArray.splice(x, 1);
                    break;
                }
            }
        }
        this.setState({
            filtersArray: tempArray
        }, () => {
            this.renderTable();
        })
    }

    renderFilters() {
        let tempArray1 = [];
        let tempArray = [];
        var i = 0;
        this.state.comparisonProducts.properties.map(value => {
            let filterName = value.name;
            if (!tempArray1.includes(filterName)) {
                tempArray1.push(filterName);
                let element = <span>
                    <input type="checkbox" id={'c' + i} className='chk-btn' value={filterName} onClick={this.handleFilters} />
                    <label htmlFor={'c' + i} >{filterName}</label>
                </span>;
                i++;
                tempArray.push(element);
            }
            return (tempArray);
        })
        this.setState({ filtersToBeRendered: tempArray })
    }



    renderTable() {
        let elementsArray = [];
        let allFilters = [];
        // get all filters
        this.state.comparisonProducts.properties.map((value) => {
            let tempFilterName = value.name;
            if (!allFilters.includes(tempFilterName)) {
                allFilters.push(tempFilterName);
            }
            return (allFilters);
        })

        for (var x = 0; x < this.state.filtersArray.length; x++) {
            let elementRow = [];
            let filter = this.state.filtersArray[x];
            if (allFilters.includes(filter)) {
                elementRow.push(filter);
                for (var y = 0; y < this.state.comparisonProducts.compare.length; y++) {
                    let productDesc = this.state.comparisonProducts.compare[y].properties_values;
                    let productFilters = [];
                    for (var z = 0; z < productDesc.length; z++) {
                        let tempFilter = productDesc[z];
                        productFilters.push(tempFilter.properties.name);
                    }
                    if (productFilters.includes(filter)) {
                        for (var w = 0; w < productDesc.length; w++) {
                            let temp = productDesc[w];

                            if (temp.properties.name === filter) {
                                elementRow.push(temp.value);
                                break;
                            }
                        }
                    } else {
                        elementRow.push(<Translate text="noData" />);
                    }
                }
            }

            let rowToBeRendered = <tr>
                {elementRow.map((value, index) => {
                    return (
                        <td key={index}>{ReactHtmlParser(value)}</td>
                    )
                })}
            </tr>;
            elementsArray.push(rowToBeRendered);
        }

        this.setState({
            tableToBeRendered: elementsArray,
        })
    }

    removeobjectFromCompareList(value) {
        let objectsArrayCopy = [...this.props.comparisonProducts.compare];
        let propertiesArrayCopy = [...this.props.comparisonProducts.properties];
        let objectToBeDeletedIndex = objectsArrayCopy.findIndex(od => od.id === value.id)
        objectsArrayCopy.splice(objectToBeDeletedIndex, 1);
        let productObject = { compare: objectsArrayCopy, properties: propertiesArrayCopy }
        this.props.actions.removeSpecificObjectFromComparisonList(productObject);

        let toBeComparedObjectsArrayCopy = [...this.props.toBeComparedObjects];
        let ToBeComparedobjectToBeDeletedIndex = toBeComparedObjectsArrayCopy.findIndex(od => od.id === value.id)
        toBeComparedObjectsArrayCopy.splice(ToBeComparedobjectToBeDeletedIndex, 1);
        this.props.actions.removeSpecificObjectFromToBeComparedObjects(toBeComparedObjectsArrayCopy);


        let buttonsOfAddedObjects = [...this.props.diableObjectCompareBtn];
        let index = buttonsOfAddedObjects.findIndex(bi => bi === value.id);
        buttonsOfAddedObjects.splice(index, 1);
        this.props.actions.removeButtonIdOfAnAddedObject(buttonsOfAddedObjects);
    }

    deleteProductfromComparison = value => {
        let tempArray = this.state.comparisonProducts;
        let productToBeDeletedIndex = tempArray.compare.findIndex(pr => pr.id === value.id);
        tempArray.compare.splice(productToBeDeletedIndex, 1);
        this.setState({
            comparisonProducts: tempArray,
        }, () => {
            this.renderFilters();
            this.renderTable();
        })

    }

    addProductToComparison() {
        let newObject = {
            name: 'Product 3',
            img: 'https://www.snb.com/stuff/contentmgr/files/0/39deaa2de12c64deca866c0cce11045c/misc/personal_credit_card.png',
            description: [
                {
                    filterName: 'Filter1',
                    value: 'asd'
                },
                {
                    filterName: 'Filter2',
                    value: 'qwe'
                }
            ]
        };

        let tempArray = this.state.comparisonProducts.compare;
        tempArray.push(newObject);

        this.setState({
            comparisonProducts: tempArray,
        }, () => {
            this.closeModal();
            this.renderFilters();
            this.renderTable();
        })
    }

    closeModal() {
        this.setState({
            modalVisibility: false,
        })
    }

    showModal() {
        this.setState({
            modalVisibility: true,
        })
    }
    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }
    render() {
        return (
            this.props.page && this.state.comparisonProducts &&
            <div className="compare-page-container comapreAndRate-container landing-container">
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                <div className="compareContent mt-5">
                    <Row className="comparingObjects">
                        <Col xs={12}>
                            <h2 className="pageTitle mb-4">المقارنة</h2>
                        </Col>
                        <Col xs={12} className="filtersContainer mt-3">
                            {this.state.filtersToBeRendered}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="mt-5">
                            <Table responsive>
                                <thead>
                                    <tr className="tableTitle">
                                        <th className="addToComparision">
                                            {this.state.comparisonProducts.compare &&
                                                /* this.state.comparisonProducts.compare.length > 0 && */
                                                this.state.comparisonProducts.compare.length < 3 ?
                                                <button className="btn submit-cpa-btn" onClick={() => this.props.history.push(`/compare-and-rate/categories/${parseInt(this.props.match.params.branchId)}/${this.props.firstCollapseId}`)}>+</button>
                                                :
                                                <button className="btn submit-cpa-btn disabled" disabled>+</button>
                                            }
                                        </th>
                                        {this.state.comparisonProducts.compare &&
                                            this.state.comparisonProducts.compare.length > 0 &&
                                            this.props.comparisonProducts.compare.map((value, index) => {
                                                return (
                                                    <th key={index}>
                                                        <div className="removeFromComparison">
                                                            <button className="btn submit-cpa-btn" value={index}
                                                                onClick={() => {
                                                                    this.removeobjectFromCompareList(value)
                                                                    this.deleteProductfromComparison(value)
                                                                }
                                                                }>X</button>
                                                        </div>
                                                        {
                                                            value.image &&
                                                            <img width="100%" src={JSON.parse(value.image).length === 0 ? '' : BASE_URL + '/uploads/' + JSON.parse(value.image)[0].download_link} />
                                                        }
                                                        <h3>{value.name}</h3>
                                                    </th>
                                                )
                                            })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.tableToBeRendered}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Modal show={this.state.modalVisibility} size="lg" aria-labelledby="example-modal-sizes-title-sm">
                        <Modal.Header closeButton onClick={this.closeModal} />
                        <Modal.Body >
                            <div className="comparisonModal">
                                <Row>
                                    <Col xs={10}>
                                        <h4>اختر الخدمات/المنتجات المراد مقارنتها</h4>
                                    </Col>
                                    <Col xs={2}>
                                        <button className="btn submit-cpa-btn" onClick={this.addProductToComparison}>مقارنة</button>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    {this.state.availableProducts.map((value, index) => {
                                        return (
                                            <Col xs={4} key={index} className="comparisonCard">
                                                <div className="comparisonCardContent">
                                                    <img src={value.img} width="100%"></img>
                                                    <br></br>
                                                    <h5>{value.name}</h5>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        )
    }

}
ComparePage.propTypes = {
    actions: PropTypes.object,
    location: PropTypes.object,
    "location.pathname": PropTypes.string,
    "location.state": PropTypes.object,
    "location.state.IdsArray": PropTypes.array,
    "actions.compareObjects": PropTypes.func,
    "location.state.productCategoryId": PropTypes.number,
    history: PropTypes.object,
    "history.push": PropTypes.func,
    comparisonProducts: PropTypes.object,
    "comparisonProducts.compare": PropTypes.array,
    "comparisonProducts.compare.map": PropTypes.func,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    toBeComparedObjects: PropTypes.array,
    diableObjectCompareBtn: PropTypes.array,
    match: PropTypes.object,
    "match.params": PropTypes.object,
    "match.params.branchId": PropTypes.number,
    firstCollapseId: PropTypes.number
}

function mapStateToProps(state) {
    return {
        lng: state.user.lng,
        comparisonProducts: state.compare_and_rate.comparisonProducts,
        page: state.navigation.page,
        toBeComparedObjects: state.compare_and_rate.toBeComparedObjects,
        diableObjectCompareBtn: state.compare_and_rate.diableObjectCompareBtn,
        firstCollapseId: state.compare_and_rate.firstCollapseId
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}
ComparePage.propTypes = {
    location: PropTypes.object,
    actions: PropTypes.object,
    comparisonProducts: PropTypes.object,
    history: PropTypes.object
};
export default connect(mapStateToProps, mapDispatchToProps)(ComparePage);