import React from 'react';
import ImgHeader from '../../../GlobalComponent/ImgHeader/ImgHeader';
import BreadCrumb from '../../../GlobalComponent/BreadCrumb/BreadCrumb';
// import CpaPagination from '../../../GlobalComponent/CpaPagination/CpaPagination';
// import ContainerCard from "../../../GlobalComponent/ContainerCard/containerCard";
import './comprehensiveGuide.scss';
import { Dropdown, Container, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import * as actions from '../../../../actions/program-and-activities/initiatives/comprehensiveGuide';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ShakawaSection from '../../../HomePage/ShakawaSection/ShakawaSection';
import { BASE_URL } from '../../../../constants/config';
import * as navigationActions from '../../../../actions/navigation';
import * as homeActions from '../../../../actions/home';
import CpaSharing from '../../../GlobalComponent/cpaSharing/cpaSharing';
import CpaDownloading from '../../../GlobalComponent/cpaDownloadingFiles/cpaDownload';
import Translate from '../../../GlobalComponent/translate/translate';



export class ComprehensiveGuide extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {

            loading: true,
            img_url: BASE_URL + '/uploads/',
            show: false,
            showSharingIcons: false,
            guideBooks: [
                'وزارة الاستثمار'
            ],
            modalContent: {
                name: '',
                category: '',
                content: []
            },
            routes: []
        };
    }
    componentDidMount() {
        // console.log("this.props",this.props,window.location.pathname)

        this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) }, () => {
                    this.setState({ ...this.state, loading: false })
                    this.props.homeActions.getHomePage();
                    this.props.actions.comprehensiveGuideArray();
                    this.props.actions.getAttachments();
                })
            })
        // window.scrollTo(0, 0)
    }

    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }
    handleClose = () => {
        this.setState({ show: false });
    }

    handleShow = (value) => {
        this.setState({ modalContent: value });
        this.setState({ show: true });
    }
    render() {
        // console.log("gg", this.props.attachments)   //need to attachments in DB to check 
        return (
            this.props.page &&
            <div className="landing-container ComprehensiveGuide-container">
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                <Container>
                    <Row className="shakwa-section">
                        {
                            this.props.home &&
                            <ShakawaSection knowledgeQuestions={this.props.home.knowledge_question_home} testYourKnowlegde={this.props.home.test_your_knowledge} testYourKnowlegde_en={this.props.home.test_your_knowledge_en} />
                        }
                    </Row>
                    {/* {console.log("this.props.attachments.attachments", this.props.attachments)} */}
                    <Row>
                        {this.props.lng == 'ar' && this.props.attachments.attachments &&
                            this.props.attachments.attachments.length > 0 &&
                            this.props.attachments.attachments.some(attachment => attachment !== "[]") &&
                            <Col lg={4} md={6} xs={12}>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <Translate text="downloadGuidear" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ width: '100%' }}>

                                        {

                                            this.props.attachments.attachments &&
                                            this.props.attachments.attachments.length > 0 &&
                                            this.props.attachments.attachments.every(attachment => attachment === "[]") &&
                                            <Dropdown.Item><Translate text="noData" /></Dropdown.Item>

                                        }



                                        {

                                            this.props.attachments.attachments &&
                                            this.props.attachments.attachments.length > 0 &&
                                            this.props.attachments.attachments.map(attachment => {
                                                if (attachment != "[]") {
                                                    return (
                                                        <Dropdown.Item key={attachment} href={BASE_URL + '/uploads/' + JSON.parse(attachment)[0].download_link} target="_blank" >{JSON.parse(attachment)[0].original_name}</Dropdown.Item>
                                                    )
                                                }
                                            })


                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        }

                        {this.props.lng != 'ar' && this.props.attachments.attachments_en &&
                            this.props.attachments.attachments_en.length > 0 &&
                            this.props.attachments.attachments_en.some(attachment => attachment !== "[]") &&
                            <Col lg={4} md={6} xs={12}>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <Translate text="downloadGuideen" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ width: '100%' }}>

                                        {

                                            this.props.attachments.attachments_en &&
                                            this.props.attachments.attachments_en.length > 0 &&
                                            this.props.attachments.attachments_en.every(attachment => attachment === "[]") &&
                                            <Dropdown.Item><Translate text="noData" /></Dropdown.Item>
                                        }



                                        {

                                            this.props.attachments.attachments_en &&
                                            this.props.attachments.attachments_en.length > 0 &&
                                            this.props.attachments.attachments_en.map(attachment => {
                                                if (attachment != "[]") {
                                                    return (
                                                        <Dropdown.Item key={attachment} href={BASE_URL + '/uploads/' + JSON.parse(attachment)[0].download_link} target="_blank" >{JSON.parse(attachment)[0].original_name}</Dropdown.Item>
                                                    )
                                                }
                                            })
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        }

                        <Col className="sharing-downloading--container" lg={4} md={6} xs={12}>
                            <a className="share-link" onClick={() => {
                                this.setState({ showSharingIcons: !this.state.showSharingIcons })
                            }}>
                                <div className="resp-sharing-button resp-sharing-button--download resp-sharing-button--small">
                                    <div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
                                        <svg xmlns="http://www.w3.org/2000/svg" id="share" width="32.616" height="34.316" fontSize="2rem" viewBox="0 0 32.289 34.252">
                                            <path id="Path_104" data-name="Path 104" d="M39.958,22a6.129,6.129,0,0,0-4.85,2.39L25.7,19.139a6.144,6.144,0,0,0,.341-2.02,6.009,6.009,0,0,0-.348-2.027l9.4-5.249a6.117,6.117,0,1,0-1.266-3.72,5.979,5.979,0,0,0,.348,2.027L24.781,13.4a6.118,6.118,0,1,0,.014,7.432l9.4,5.256A6.12,6.12,0,1,0,39.958,22Zm0-20.077a4.2,4.2,0,1,1-4.2,4.2A4.207,4.207,0,0,1,39.958,1.927ZM19.931,21.322a4.2,4.2,0,1,1,4.2-4.2A4.207,4.207,0,0,1,19.931,21.322Zm20.027,11a4.2,4.2,0,1,1,4.2-4.2A4.207,4.207,0,0,1,39.958,32.324Z" transform="translate(-13.8)" fill="#6be2b7" />
                                        </svg>
                                    </div>
                                </div>
                            </a>

                            {/* print */}
                            <a style={{ cursor: "pointer" }} onClick={() => { window.print() }} className="resp-sharing-button__link"
                                target="_blank" rel="noopener noreferrer" aria-label="Share on Twitter">
                                <div className="resp-sharing-button resp-sharing-button--print resp-sharing-button--small"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
                                    <svg xmlns="http://www.w3.org/2000/svg" id="printer" width="32.616" height="34.316" fontSize="2rem" viewBox="0 0 32.616 34.316">
                                        <g id="Group_55" data-name="Group 55" transform="translate(0)">
                                            <path id="Path_100" data-name="Path 100" d="M39.495,7.034h-.882V5.071A5.078,5.078,0,0,0,33.542,0H22.9a5.078,5.078,0,0,0-5.071,5.071V7.034h-.8A5.078,5.078,0,0,0,11.95,12.1v8.179a5.078,5.078,0,0,0,5.071,5.071h.8v6.429a2.537,2.537,0,0,0,2.532,2.532H36.074a2.537,2.537,0,0,0,2.532-2.532V25.354h.889a5.078,5.078,0,0,0,5.071-5.071V12.1A5.078,5.078,0,0,0,39.495,7.034ZM19.738,5.071A3.157,3.157,0,0,1,22.888,1.92h10.64a3.157,3.157,0,0,1,3.151,3.151V7.034H19.738ZM36.686,31.8a.616.616,0,0,1-.612.612H20.349a.616.616,0,0,1-.612-.612v-10.6H36.686Zm5.96-11.507a3.157,3.157,0,0,1-3.151,3.151h-.882V21.194h1.266a.96.96,0,0,0,0-1.92H16.409a.96.96,0,0,0,0,1.92h1.415v2.247h-.8a3.157,3.157,0,0,1-3.151-3.151V12.112a3.157,3.157,0,0,1,3.151-3.151H39.495a3.157,3.157,0,0,1,3.151,3.151Z" transform="translate(-11.95)" fill="#6be2b7" />
                                            <path id="Path_101" data-name="Path 101" d="M141.61,339.32h12.226a.96.96,0,0,0,0-1.92H141.61a.96.96,0,1,0,0,1.92Z" transform="translate(-131.497 -313.404)" fill="#6be2b7" />
                                            <path id="Path_102" data-name="Path 102" d="M154.843,392.6H142.61a.96.96,0,0,0,0,1.92h12.226a.96.96,0,0,0,.007-1.92Z" transform="translate(-132.426 -364.678)" fill="#6be2b7" />
                                            <path id="Path_103" data-name="Path 103" d="M360.959,151.9H359.01a.96.96,0,1,0,0,1.92h1.949a.96.96,0,1,0,0-1.92Z" transform="translate(-333.435 -141.097)" fill="#6be2b7" />
                                        </g>
                                    </svg>
                                </div>
                                </div>
                            </a>
                            {
                                <CpaSharing active={this.state.showSharingIcons ? 'active' : ''} />
                            }
                            {/* {console.log("b", this.props)} */}
                            {
                                this.props.lng == 'ar' && this.props.comprehensiveGuideArray.length > 0 &&
                                <CpaDownloading fileToDownload={this.props.comprehensiveGuideArray[0].attachments_all} />
                            }

                            {
                                this.props.lng != 'ar' && this.props.comprehensiveGuideArray.length > 0 &&
                                <CpaDownloading fileToDownload={this.props.comprehensiveGuideArray[0].attachments_all_en} />
                            }

                            {/* {console.log(this.props.lng == 'ar' && this.props.attachments.attachments &&
                                this.props.attachments.attachments.length > 0 &&
                                this.props.attachments.attachments.some(attachment => attachment !== "[]"))} */}
                            {/* {console.log("v", this.props.attachments.attachments.find(a => a != "[]"))}*/}
                            {/* {
                                this.props.lng == 'ar' && this.props.attachments.attachments &&
                                this.props.attachments.attachments.length > 0 &&
                                this.props.attachments.attachments.some(attachment => attachment !== "[]") &&

                                <CpaDownloading fileToDownload={this.props.attachments.attachments.find(a => a != "[]")} />
                            }
                            {console.log(this.props)}
                            {
                                this.props.lng != 'ar' && this.props.attachments.attachments_en
                                &&
                                this.props.attachments.attachments_en.length > 0 &&
                                this.props.attachments.attachments_en.some(attachment => attachment !== "[]") &&
                                <CpaDownloading fileToDownload={this.props.attachments.attachments_en.find(a => a != "[]")} />
                            } */}
                        </Col>
                    </Row>
                    <Row className="landing-row">
                        {this.state.loading && <div><p>Loading ...</p></div>}

                        {this.props.comprehensiveGuideArray.length > 0
                            &&
                            this.props.comprehensiveGuideArray.map((value, index) => {
                                return (
                                    value.sub_categories.length > 0 &&
                                    <Col xs={12} md={6} lg={4} key={index} className="mb-4">
                                        <Link to={"/guide-book/" + value.id + '/' + value.sub_categories[0].id}>
                                            <div className="landing-card">
                                                <div className="inner">
                                                    <ul>
                                                        <li>
                                                            <h3>{this.props.lng === 'ar' ? value.title : value.title_en}</h3>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                )
                            })}
                    </Row>
                    {/* <CpaPagination page="1"></CpaPagination> */}
                </Container>
                {/* <Modal dialogClassName="compare-container" size="xl" show={this.state.show} onHide={this.handleClose}>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            <ContainerCard>
                                <Row  className="ComprehensiveGuide-container modal-container">
                                    <Col xs={12}>
                                        <h4>{this.state.modalContent.name}</h4>
                                    </Col>
                                    <Col xs={12}>
                                        <span>{this.state.modalContent.category}</span>
                                    </Col>
                                    <Col xs={12}>
                                        <ul>
                                            {this.state.modalContent.content.map((value,index)=>{
                                                return (
                                                    <li key={index}>
                                                        <h5>{value.point}</h5>
                                                        <ul className="inner-list">
                                                            {value.relatedPointed.map((value,index)=>{
                                                                return(
                                                                    <li key={index}>
                                                                        {value}
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </Col>
                                </Row>
                            </ContainerCard>
                        </Modal.Body>
                </Modal> */}
            </div>
        );
    }
}

ComprehensiveGuide.propTypes = {
    actions: PropTypes.object.isRequired,
    comprehensiveGuideArray: PropTypes.array.isRequired,
    lng: PropTypes.string.isRequired,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    homeActions: PropTypes.object.isRequired,
    "homeActions.getHomePage": PropTypes.func.isRequired,
    "home": PropTypes.object.isRequired,
    "home.knowledge_question_home": PropTypes.array.isRequired,
    "home.test_your_knowledge": PropTypes.string,
    "home.test_your_knowledge_en": PropTypes.string,
    attachments: PropTypes.object,
    "attachments.length": PropTypes.number,
    "attachments.map": PropTypes.func
};

function mapStateToProps(state) {
    return {
        page: state.navigation.page,
        comprehensiveGuideArray: state.comprehensive_guide.comprehensiveGuideArray,
        attachments: state.comprehensive_guide.attachments,
        lng: state.user.lng,
        home: state.home.homePage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch),
        homeActions: bindActionCreators(homeActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ComprehensiveGuide)