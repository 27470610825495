import React from 'react';
import { Link } from 'react-router-dom';
import './worldDayPage.scss';
import ImgHeader from '../../../GlobalComponent/ImgHeader/ImgHeader';
import BreadCrumb from '../../../GlobalComponent/BreadCrumb/BreadCrumb';
import { Container, Card, Modal, Row, Col } from 'react-bootstrap';
import * as actions from '../../../../actions/program-and-activities/worlddays';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Slider from "react-slick";
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import * as navigationActions from '../../../../actions/navigation';
import { BASE_URL } from '../../../../constants/config';
import SectionCard from '../../programs/motamken/SectionCard/SectionCard';
import NewsCard from '../../../GlobalComponent/newsCard/newsCard';
import CpaSharing from '../../../GlobalComponent/cpaSharing/cpaSharing';
import ContainerCard from '../../../GlobalComponent/ContainerCard/containerCard'
import DefaultPage from '../../../GlobalComponent/defaultPage/DefaultPage';


var id = null;

class WorldDayPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.myDivToFocus = React.createRef();

        id = props.match.params.id;

        this.state = {
            currentDay: 0,
            dayDetails: [],
            data: [],
            reports: [],
            meetings: [],
            news: [],
            loaded: false,
            routes: [],
            show: false,
            modalImg: ''
        }
    }

    handleScrollToDiv = () => {
        //.current is verification that your element has rendered
        if (this.myDivToFocus.current) {
            this.myDivToFocus.current.scrollIntoView({
                behavior: "smooth",
                block: "start"
            })
        }
    }
    componentDidMount() {
        this.props.navigationActions.getPageInfoApi('/international-days').then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                fetch(BASE_URL + "/api/world_days/getWorldDaysDetails?title=" + id)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            this.setState({ dayDetails: result.reverse() });
                            var This = this;
                            this.state.dayDetails[this.state.currentDay].media_center.length > 0 ? (
                                this.state.dayDetails[this.state.currentDay].media_center.map(function (item) {
                                    if (item.type === "news") {
                                        This.setState({ news: [...This.state.news, item] });
                                    } else if (item.type === "meetings") {
                                        This.setState({ meetings: [...This.state.meetings, item] });
                                    } else if (item.type === "reports") {
                                        This.setState({ reports: [...This.state.reports, item] });
                                    } else if (item.type === "data") {
                                        This.setState({ data: [...This.state.data, item] });
                                    }
                                }
                                )) : "";
                            this.setState({ loaded: true });
                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    );
            })
    }
    setRoutesFormApi(page) {
        // console.log("p", page)
        if (!page) {
            this.props.history.push("/")
            window.location.reload(false)
        }
        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }

    handleYearClicked = ev => {
        this.setState({
            currentDay: ev.currentTarget.dataset.div_id
        });
        this.handleScrollToDiv();
    };

    handleShow = (value) => {
        this.setState({
            show: true,
            modalImg: value
        })
    }

    handleClose = () => {
        this.setState({ show: false });
    }

    render() {
        // console.log('gf', this.state.loaded)
        let skilledArrayForSlider = [];
        this.state.dayDetails[this.state.currentDay] &&
            this.state.dayDetails[this.state.currentDay].motamaken_categories.length > 0 &&
            this.state.dayDetails[this.state.currentDay].motamaken_categories.map(category => {
                category.activities.map(activity => {
                    skilledArrayForSlider.push(activity)
                })
            })

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: window.innerWidth <= 767 ? 1 : 2,
            slidesToScroll: 1
        };
        let dataArray = [];
        let newsArray = [];
        if (this.state.loaded) {
            var years = [];

            this.state.dayDetails.map(day => {
                years.push(Moment(day.date).format('YYYY'));
            });

            var attachments = JSON.parse(this.state.dayDetails[this.state.currentDay].attachments);


            return (
                this.props.page &&
                <div>
                    <div className="worldDayPage-conatiner" ref={this.myDivToFocus} >
                        <ImgHeader page={this.props.page} />
                        <BreadCrumb
                            routes={this.state.routes}
                            filterbyYear={false}
                            filterbySubject={false} />
                        <Container>
                            <Row className="align-dates">
                                <Col className="col-md-2">
                                    <div className="vertical-menu">
                                        {
                                            years.map((year, index) => {
                                                return (
                                                    <span className={this.state.currentDay == index ? "active" : ""} key={index} data-div_id={index} onClick={this.handleYearClicked}>{year}</span>
                                                )
                                            })
                                        }
                                    </div>
                                </Col>
                                <Col className="col-md-10">
                                    <img className="header-img" src={BASE_URL + "/uploads/" + this.state.dayDetails[this.state.currentDay].image} />
                                </Col>
                            </Row>
                            <CpaSharing active='active' />
                            <h3 className="header-title">
                            </h3>
                            {localStorage.getItem("lng") === "en" ?
                                <p className="day-description">{
                                    ReactHtmlParser(this.state.dayDetails[this.state.currentDay].description_en)
                                }
                                </p> :
                                <p className="day-description">{
                                    ReactHtmlParser(this.state.dayDetails[this.state.currentDay].description)
                                }
                                </p>
                            }
                            {
                                this.state.dayDetails[this.state.currentDay].video_link ?
                                    <iframe width="100%" height="513"
                                        src={this.state.dayDetails[this.state.currentDay].video_link}>
                                    </iframe> : null
                            }

                            {
                                this.state.dayDetails[this.state.currentDay].motamaken_categories.length > 0 &&
                                <h4>{t('versed')}</h4>
                            }
                            {
                                skilledArrayForSlider.length > 0 &&
                                <div className="motamaken-container">
                                    {
                                        skilledArrayForSlider.length >= (window.innerWidth <= 767 ? 1 : 2) ?
                                            <div className="demo-4 medium">
                                                <Slider {...settings}>
                                                    {
                                                        skilledArrayForSlider.map((item) => {
                                                            return (
                                                                <SectionCard
                                                                    key={item.id}
                                                                    newscard={item}
                                                                    handleActivityRegister={null}
                                                                    showIsRegisteredModal={null}
                                                                    url={"/skilled/"}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </Slider>
                                            </div>
                                            :
                                            <>
                                                {
                                                    skilledArrayForSlider.map((item) => {
                                                        return (
                                                            <div key={item.id} style={{ marginInlineEnd: "1rem" }}>
                                                                <SectionCard
                                                                    newscard={item}
                                                                    handleActivityRegister={null}
                                                                    showIsRegisteredModal={null}
                                                                    url={"skilled"}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </>
                                    }
                                </div>
                            }
                            {
                                this.state.dayDetails[this.state.currentDay].consumer_advisor.length > 0 &&
                                <h4>{t('consumerConsultant')}</h4>
                            }

                            {
                                this.state.dayDetails[this.state.currentDay].consumer_advisor.length > 0 &&
                                <div className="consumer-advisor-container">
                                    {
                                        this.state.dayDetails[this.state.currentDay].consumer_advisor.length >= (window.innerWidth <= 767 ? 1 : 2) ?
                                            <div className="demo-4 medium">
                                                <Slider {...settings}>
                                                    {
                                                        this.state.dayDetails[this.state.currentDay].consumer_advisor.map((item) => {
                                                            return (
                                                                <SectionCard
                                                                    key={item.id}
                                                                    consultantCard={item}
                                                                    url={"consultantUser"}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </Slider>
                                            </div>
                                            :
                                            <>
                                                {
                                                    this.state.dayDetails[this.state.currentDay].consumer_advisor.map(function (item) {
                                                        return (
                                                            <div style={{ marginLeft: "1rem" }} key={item.id}>
                                                                <SectionCard
                                                                    consultantCard={item}
                                                                    url={"consultantUser"}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </>
                                    }
                                </div>
                            }
                            {
                                this.state.dayDetails[this.state.currentDay].media_center.length > 0 &&
                                this.state.dayDetails[this.state.currentDay].media_center.map(item => {
                                    if (item.type === "data") {
                                        dataArray.push(item);
                                    }
                                    else if (item.type === "news") {
                                        newsArray.push(item)
                                    }
                                })
                            }

                            {
                                dataArray.length > 0 &&
                                <h4>{t('data')}</h4>
                            }

                            {
                                dataArray.length > 0 &&
                                <div className="data-container">
                                    {
                                        dataArray.length >= (window.innerWidth <= 767 ? 1 : 2) ?
                                            <div className="demo-4 medium">
                                                <Slider {...settings}>
                                                    {
                                                        dataArray.map((item) => {
                                                            return (
                                                                <Link key={item.id} to={'/single-data/' + item.id}>
                                                                    <NewsCard newscard={item} />
                                                                </Link>
                                                            )
                                                        })
                                                    }
                                                </Slider>
                                            </div>
                                            :
                                            <>
                                                {
                                                    dataArray.map(item => {
                                                        return (
                                                            <div style={{ marginLeft: "1rem" }} key={item.id}>
                                                                <Link to={'/single-data/' + item.id}>
                                                                    <NewsCard newscard={item} />
                                                                </Link>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </>
                                    }
                                </div>
                            }

                            {
                                newsArray.length > 0 &&
                                <h4>{t('news')}</h4>
                            }

                            {
                                newsArray.length > 0 &&
                                <div className="news-container">
                                    {
                                        newsArray.length >= (window.innerWidth <= 767 ? 1 : 2) ?
                                            <div className="demo-4 medium">
                                                <Slider {...settings}>
                                                    {
                                                        newsArray.map((item) => {
                                                            return (
                                                                <Link key={item.id} to={"/single-news/" + item.id}>
                                                                    <NewsCard newscard={item} />
                                                                </Link>)
                                                        })
                                                    }
                                                </Slider>
                                            </div>
                                            :
                                            <>
                                                {
                                                    newsArray.map(item => {
                                                        return (
                                                            <div style={{ marginLeft: "1rem" }} key={item.id}>
                                                                <Link to={"/single-news/" + item.id}>
                                                                    <NewsCard newscard={item} />
                                                                </Link>

                                                            </div>
                                                        )
                                                    })
                                                }
                                            </>
                                    }
                                </div>
                            }

                            {
                                attachments.length > 0 &&
                                <h4 className="header-title info-graphic">{t("info_graphic_view")}</h4>
                            }
                            {
                                attachments.length > 0
                                    &&
                                    attachments.length >= (window.innerWidth <= 767 ? 1 : 2)
                                    ?
                                    <div className="info_graphic_body">
                                        {
                                            <Slider {...settings}>
                                                {
                                                    attachments.map((attachment, index) => {
                                                        return (
                                                            <img key={index} onClick={() => this.handleShow(attachment)} className="worldDaysWorkShopsImg" variant="top" src={BASE_URL + "/uploads/" + attachment} style={{ display: "inline-block", height: "280px", objectFit: "fill" }} />
                                                        )
                                                    })
                                                }
                                            </Slider>
                                        }
                                    </div>
                                    :
                                    <>


                                        {
                                            attachments.map((attachment, index) => {
                                                return (
                                                    <div onClick={() => { this.handleShow(attachment) }} key={index} className="col col-md-4">
                                                        <Card style={{ width: "18rem", height: "20rem", cursor: "pointer" }}>
                                                            <Card.Img className="worldDaysWorkShopsImg" variant="top" src={BASE_URL + "/uploads/" + attachment} />
                                                        </Card>
                                                    </div>
                                                )
                                            })
                                        }


                                    </>
                            }
                            <Modal dialogClassName="annualReports-container" size="xl" show={this.state.show} onHide={() => this.handleClose()}>
                                <ContainerCard>
                                    <Modal.Header closeButton></Modal.Header>
                                    <Modal.Body>
                                        <div className="shareOn">
                                            <CpaSharing url={BASE_URL + '/uploads/' + this.state.modalImg} />
                                        </div>
                                        <div className="img-body">
                                            <img src={BASE_URL + '/uploads/' + this.state.modalImg} />
                                        </div>
                                    </Modal.Body>
                                </ContainerCard>
                            </Modal>
                        </Container>
                    </div>
                </div>
            )
        } else {
            return (<div>

                <DefaultPage header={true} />
                {/* there is no go to <Link to="/">Home</Link> */}
            </div>)
        }
    }
}
WorldDayPage.propTypes = {
    actions: PropTypes.object.isRequired,
    worldDayPage: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    lng: PropTypes.string.isRequired,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired
};
function mapStateToProps(state) {
    return {
        worldDayPage: state.world_days.worldDayPage,
        lng: state.user.lng,
        page: state.navigation.page
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(WorldDayPage)
