import React from 'react';
import './userProfile.scss';
import { Container, Row, Col, Modal, Nav, Tab } from 'react-bootstrap';
import ImgHeader from '../GlobalComponent/ImgHeader/ImgHeader';
import BreadCrumb from '../GlobalComponent/BreadCrumb/BreadCrumb';
import Translate from '../GlobalComponent/translate/translate';
import { t } from 'i18next';
import ContainerCard from '../GlobalComponent/ContainerCard/containerCard';
import PropTypes from "prop-types";
import { bindActionCreators } from 'redux';
import * as actions from "../../actions/user-profile/userProfile";

import { connect } from "react-redux";
import { BASE_URL } from "../../constants/config";
import { Redirect } from 'react-router-dom'

import TabUSerForm from './TabUserForm';
import TabServices from './TabServices';
import TabCoursesAndLectures from './TabCoursesAndLectures';

class UserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sucess: false,
            key: '',
            userImageStyle: null,
            profile_image: null,
            loading: true,
            phone: {
                phonenumber: '',
            },
            complaints: null,
            show: false,
            show_mail: false,
            edit: false,
            step: 1,
            showinput: true,
            routes: [
                {
                    name: 'ملف تعريفي للمستخدم',
                    name_en: 'user Profile',
                    link: 'user-profile'
                }
            ],
            page: {
                name: 'ملف تعريفي للمستخدم',
                name_en: 'user Profile'
            }
        }
    }

    handleEdit = () => {
        this.setState({
            edit: !this.state.edit
        })
    }
    renderRedirect = () => {
        if (!localStorage.getItem('_cpaJwt')) {
            return <Redirect to='' />
        }
    }
    componentDidMount() {
        let id = this.props.user.id
        this.props.actions.getProfileData(id)
            .then(() => {
                this.setState({
                    ...this.state,
                    complaints: this.props.profile.complaints,
                    userImageStyle: {
                        backgroundImage: `url('${BASE_URL}/uploads/${(this.props.profile.image == 'null' ? "/Images/default_image.jpg" : this.props.profile.image)}')`
                    },
                    sucess: true
                })
            })
            .catch();
    }
    handleSetUserData = (data) => {
        data = {
            ...data,
            profile_image: this.state.profile_image
        }
        this.props.actions.setProfileData(data)
            .then(() => {
                // console.log("res", res)

                this.setState({
                    ...this.state,
                    userImageStyle: {
                        backgroundImage: 'url(' + BASE_URL + "/uploads//" + this.props.profile.image + ')'
                    }
                });
            })
        // .catch(err => console.log("err2", err));
    }

    handleOptionChange = (changeEvent) => {
        changeEvent.preventDefault();
        const { name, value } = changeEvent.target;
        if (name == "profile_image") {
            let fileURl = URL.createObjectURL(changeEvent.target.files[0]);
            this.setState({
                [name]: changeEvent.target.files[0],
                userImageStyle: {
                    backgroundImage: `url('  ${fileURl}  ')`
                }
            })
        } else {
            this.setState({
                ...this.state,
                user: { ...this.state.user, [name]: value },
            });
        }

    };
    showModal = () => {
        this.setState({ show: true })
    };
    handleClose = () => {
        this.setState({ show: false })
    };

    showModalMail = () => {
        this.setState({ show_mail: true })
    };
    handleCloseMail = () => {
        this.setState({ show_mail: false })
    };
    handleSubmitUser = (event) => {
        event.preventDefault();
        if (this.state.edit) {
            this.setState({ edit: !this.state.edit })
        } else {
            this.setState({ edit: !this.state.edit })
        }
    }
    verificationCode = () => {
        this.setState({ showinput: false });
    }
    handleSubmitChangePassword = (event) => {
        event.preventDefault();
    }
    render() {
        return (
            <div className="userProfile_container">
                {this.renderRedirect()}
                <ImgHeader page={this.state.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />

                {this.state.sucess ?
                    <Container className="userProfile-inner">
                        <Row>
                            <Col xs={12}>
                                <div className="user-img-container">
                                    <div className="user-img" style={this.state.userImageStyle} >
                                        <div className={"changeImg" + (!this.state.edit ? " disabled" : '')} >
                                            {/* <div className="input-btn-layout">
                                                <i className="icon-plus">+</i>
                                            </div> */}
                                            {/* <input disabled={!this.state.edit} type="file" name="profile_image" accept="image/*" onChange={this.handleOptionChange} /> */}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} className="userName">
                                <h3>{this.props.profile.first_name + " " + this.props.profile.last_name}</h3>
                            </Col>
                        </Row>
                        <Row>
                            <div className="profile-content">
                                <Tab.Container id="left-tabs-example" defaultActiveKey={"userData"}>
                                    <Row>
                                        <Col xs={12}>
                                            <Nav variant="pills" className="flex-row">
                                                <Nav.Item className="col-6 col-md-6 col-lg">
                                                    <Nav.Link eventKey="userData">
                                                        <div className="tab-container">
                                                            {t('userData')}
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="col-6 col-md-6 col-lg">
                                                    <Nav.Link eventKey="servicese">
                                                        <div className="tab-container">
                                                            {t('servicese')}
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                {/* <Nav.Item className="col-6 col-md-6 col-lg">
                                                    <Nav.Link eventKey="employmentVolunteer">
                                                        <div className="tab-container">
                                                            {t('employmentVolunteer')}
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item> */}
                                                <Nav.Item className="col-6 col-md-6 col-lg">
                                                    <Nav.Link eventKey="coursesAndLectures">
                                                        <div className="tab-container">
                                                            {t('coursesAndLectures')}
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col xs={12}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="userData">
                                                    <TabUSerForm user={this.props.profile} edit={this.state.edit} handleEdit={this.handleEdit} handleSetUserData={this.handleSetUserData} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="servicese">
                                                    <TabServices complaints={this.state.complaints} />
                                                </Tab.Pane>
                                                {/* <Tab.Pane eventKey="employmentVolunteer">
                                                </Tab.Pane> */}
                                                <Tab.Pane eventKey="coursesAndLectures">
                                                    <TabCoursesAndLectures motamaken={this.props.profile.motamaken} nasm3k={this.props.profile.nasm3k} />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </Row>
                    </Container> :
                    <div className="notFound-container">
                        <div className="notFound-content text-center">
                            <div className="inner">
                                <h3>
                                    {this.props.user.lng == "ar" ? "تحميل" : "loading"}
                                </h3>
                                {/* <Link to="/"><Translate text="goback"/> <Translate text="homePage"/> </Link> */}
                            </div>
                        </div>
                    </div>
                }

                <Modal dialogClassName="members-modal" size="md" show={this.state.show} onHide={() => this.handleClose()}>
                    <ContainerCard>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            <Row className="Authentication-container changePass-container justify-content-center">
                                <Col xs={12}>
                                    <i className="lock-old icon-lock" />
                                </Col>
                                <Col xs={12}>
                                    <h4><Translate text="changePass" /></h4>
                                </Col>
                                <Col xs={12} sm={8} >
                                    <div className="form-input">
                                        <i className="icon-lock" />
                                        <input
                                            name="old_password"
                                            placeholder={t('oldPass')}
                                            onChange={this.handleChange}
                                            type="text" required />
                                    </div>
                                </Col>
                                <Col xs={12} sm={8} >
                                    <div className="form-input">
                                        <i className="icon-lock" />
                                        <input
                                            name="old_password"
                                            placeholder={t('NewPass')}
                                            onChange={this.handleChange}
                                            type="text" required />
                                    </div>
                                </Col>
                                <Col xs={12} sm={8}>
                                    <button type="submit" className="btn btn-success" onClick={this.handleSubmitChangePassword}>
                                        <Translate text="submit" />
                                    </button>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </ContainerCard>
                </Modal>
                <Modal dialogClassName="members-modal" size="md" show={this.state.show_mail} onHide={() => this.handleCloseMail()}>
                    <ContainerCard>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>

                            <Row className="Authentication-container changePass-container justify-content-center">
                                {this.state.step === 1 &&
                                    <div>
                                        <Col xs={12}>
                                            <h4>تغيير البريد الالكتروني</h4>
                                        </Col>
                                        <Col xs={12}>
                                            <div className="form-input">
                                                <input
                                                    name="new_mail"
                                                    placeholder="البريد الالكتروني الجديد"
                                                    type="text" required />
                                            </div>
                                            <button className="verify-btn" onClick={this.HandelStep} >ارسال كود التحقيق</button>
                                        </Col>
                                    </div>
                                }
                                {this.state.step === 2 &&
                                    <div>
                                        <div className="form-input">
                                            <input
                                                name="code"
                                                placeholder="كود التحقيق"
                                                type="number" required />
                                        </div>
                                        <button className="verify-btn" onClick={this.HandelStep} >تاكيد كود التحقيق</button>
                                    </div>
                                }
                                {this.state.step === 3 &&
                                    <div>
                                        تم تعديل البريد الالكتروني بنجاح
                                    </div>
                                }
                            </Row>
                        </Modal.Body>
                    </ContainerCard>
                </Modal>
            </div>
        )
    }
}
UserProfile.propTypes = {
    actions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    user: PropTypes.object
};

function mapStateToProps(state) {
    return {
        profile: state.profile,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
