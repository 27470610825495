import React from 'react';
import { Row, Col, Modal } from "react-bootstrap";
import Translate from '../../../../GlobalComponent/translate/translate';
import { t } from 'i18next';
import * as actions from "../../../../../actions/program-and-activities/programs/legalAdvocacy";
import { userLogout } from "../../../../../utils/auth.helpers";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import { toast } from 'react-toastify';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import './guideIs.scss'
import { propTypes } from 'react-recaptcha';
import { store } from "../../../../../index";
import { USER_LOGOUT } from "../../../../../constants/actionTypes";


var FormData = require('form-data');
let attachmentsArray = [];

class GuideUs extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.myRef = React.createRef();
        this.suggestion = React.createRef();
        this.statistics = React.createRef();
        this.catRef = React.createRef();
        this.state = {
            values: {
                section_id: "",
                type_id: "",
                organization_id: "",
                complaint: "",
                attachments: null,
            },
            attachments_file: "",
            showModal: false,
            count: null,
            category: null,
            selectedCategory: null,
            selectedsection: 'home',
            presentage: 50,
            ershdnaSubs: null
            //selectedsection => home / setSuggestion / viewCategory
        };

        this.handleCreateErshedna = this.handleCreateErshedna.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.removeFile = this.removeFile.bind(this);
    }
    componentDidMount() {
        this.props.actions.getSections()
            .then()
            .catch(error => {
                if (error.message === "unauthorized") {
                    this.props.toggleModal();
                }
            });
        this.props.actions.getErshdnaCategories()
            .then()
        this.props.actions.getErshdnaSectionsWithEachSub()
            .then(() => {
                this.setState({
                    ershdnaSubs: this.props.ershdnaSubs
                })
            });
        this.props.actions.getHearYouTitleParagraph();
        this.props.actions.getHearYouDescriptionParagraph();
        this.props.actions.getHearYouComplaintParagraph();
        // console.log(this.props.match.params)
        if (this.props.match.params.id && this.props.match.params.tab == 'LetUsHearYou') {
            this.getCategory(this.props.match.params.id)
        } else {
            this.setState({
                // selectedCategory: id,
                selectedsection: 'viewCategory'
            })
        }

    }

    setSection = (section) => {
        this.setState({
            selectedsection: section,
            selectedCategory: null
        })
        if (section == 'setSuggestion') {
            setTimeout(() => {

                window.scrollTo({
                    top: this.getCoords(this.suggestion.current).top - 100,
                    behavior: 'smooth'
                }
                )
            }, 0)

        } else {

            setTimeout(() => {
                window.scrollTo({
                    top: this.getCoords(this.statistics.current).top - 100, behavior: 'smooth'
                })
            }, 0)

        }


    }
    getCoords(elem) {
        let box = elem.getBoundingClientRect();

        return {
            top: box.top + window.pageYOffset,
            right: box.right + window.pageXOffset,
            bottom: box.bottom + window.pageYOffset,
            left: box.left + window.pageXOffset
        };
    }

    getCategory = (id) => {
        // console.log("id", id)
        this.props.actions.getErshdnaCategory(id)
            .then(() => {
                this.setState({
                    selectedCategory: id,
                    selectedsection: 'viewCategory'
                })
                // console.log("ref", this.getCoords(this.catRef.current))
                setTimeout(() => {
                    this.props.history.push(`/legal-advocacy/LetUsHearYou/${id}`)
                    window.scrollTo({
                        top: this.getCoords(this.catRef.current).top - 100, behavior: 'smooth'
                    })
                }, 0)
            })
    }
    removeFile(id) {
        for (var i = 0; i < attachmentsArray.length; i++) {
            if (attachmentsArray[i].id == id) {
                attachmentsArray.splice(i, 1);
                break;
            }
        }
        this.setState({
            value: { ...this.state.values, [name]: attachmentsArray },
        })
    }
    handleChange = event => {
        event.preventDefault();
        const { name, value } = event.target;
        let attachedObject, files;
        if (name === "attachments") {
            files = event.target.files;
            for (let index = 0; index < files.length; index++) {
                if (files[index].type != "application/x-msdownload") {
                    attachedObject = {
                        file: files[index],
                        id: attachmentsArray.length
                    }
                    attachmentsArray.push(attachedObject);
                }

            }

            this.setState({
                value: { ...this.state.values, [name]: attachmentsArray },
            });
        } else {
            this.setState({
                values: { ...this.state.values, [name]: value },
            })
        }
    };
    handleSectionsChange = event => {
        event.preventDefault();
        this.props.actions.removeGuideUsProps();
        const { name, value } = event.target;
        this.setState({
            values: { ...this.state.values, [name]: value, type_id: '', organization_id: '' },
        });
        this.props.actions.getProblems(value)
            .then()
            .catch(error => {
                if (error.message === "unauthorized") {
                    this.props.toggleModal();
                }
            });
    };
    handleProblemsChange = event => {
        event.preventDefault();
        const { name, value } = event.target;
        this.setState({
            values: { ...this.state.values, [name]: value },
        });
        this.props.actions.getOrganizations(value)
            .then()
            .catch(error => {
                if (error.message === "unauthorized") {
                    this.props.toggleModal();
                }
            });
    };
    handleOrganizationsChange = event => {
        const { name, value } = event.target;
        this.setState({
            values: { ...this.state.values, [name]: value },
        });
    };

    showLoadingNotification(msg, doneBoolean, type) {
        if (!doneBoolean) {
            toast.update(this.state.loadingToast, {
                render: msg,
                type: toast.TYPE.INFO,
                autoClose: 1000
            });
        } else {
            if (type == 'error') {
                toast.update(this.state.loadingToast, {
                    render: msg,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            } else if (type == 'sucsess') {
                toast.update(this.state.loadingToast, {
                    render: msg,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000
                });
            }

        }

    }

    logout = () => {
        store.dispatch({ type: USER_LOGOUT.SUCCESS, payload: {} });
        userLogout();
    };

    handleCreateErshedna(event) {
        event.preventDefault();
        let loadingmsg = 'loading';
        this.setState({
            ...this.state,
            loadingToast: toast(t(loadingmsg), { autoClose: false, type: toast.TYPE.INFO, position: toast.POSITION.BOTTOM_RIGHT }),
        });
        const formData = new FormData();
        Object.keys(this.state.values).forEach(key => {
            if (key == "attachments") {
                attachmentsArray.forEach(elment => {
                    formData.append('attachments[]', elment.file)
                })
            } else {
                formData.append(key, this.state.values[key])
            }
        });
        this.props.actions.createErshednaShakwa(formData)
            .then(() => {
                if (this.props.apiResponse.count) {
                    this.setState({
                        showModal: true,
                        count: this.props.apiResponse.count
                    });
                    loadingmsg = "succsess"
                    this.showLoadingNotification(t(loadingmsg), true, 'sucsess');
                }
                else if (this.props.apiResponse.status === 'Token is Invalid') {
                    loadingmsg = this.props.apiResponse.status;
                    this.showLoadingNotification(t(loadingmsg), true, 'error');
                    setTimeout(() => {
                        this.logout();
                    }, 5000);
                }
                else {
                    loadingmsg = "fillAllFields"
                    this.showLoadingNotification(t(loadingmsg), true, 'error');
                }
            })
        //.catch();

        event.preventDefault();
    }
    handleBlur = event => {
        if (event.target.value === "") {
            event.target.style.border = "1px solid #c14242";
        } else {
            event.target.style.border = "1px solid #42C194";
        }
    }
    closeComplainModal = () => {
        this.setState({ showModal: false });
        window.location.reload();
    }
    onSelectComplainChnage = (event) => {
        let id = event.target.value
        if (id == '') {
            this.setState({
                ershdnaSubs: this.props.ershdnaSubs
            })
        } else {
            this.setState({
                ershdnaSubs: this.props.ershdnaSubs.filter(function (item) {
                    return item.id == id
                })
            })
        }
    }
    render() {
        // console.log("state", this.state)
        // console.log("prop", this.props)
        moment.locale(this.props.lng);
        //Data of each select will be found in folder of data in cpa-project
        return (
            <div className="guideUs-container">
                <div className="choose-case height-auto">
                    <div className="row head-weHearYou">
                        <div className="col-6 title">
                            <h2>{t('guidedUs')}</h2>
                        </div>
                        <div className="col-6 review-btn">
                            {(this.state.selectedsection == 'home' || this.state.selectedsection == 'viewCategory') &&
                                <button
                                    className="btn submit-cpa-btn"
                                    type="button"
                                    onClick={() => this.setSection('setSuggestion')}>
                                    {t('set_suggestion')}
                                </button>
                            }
                            {this.state.selectedsection == 'setSuggestion' &&
                                <button
                                    className="btn submit-cpa-btn"
                                    type="button"
                                    onClick={() => this.setSection('home')}>
                                    {t('statistics')}
                                </button>
                            }

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="guideUs-intro">{this.props.lng === 'ar' ? ReactHtmlParser(this.props.guideUsDesc.text) : ReactHtmlParser(this.props.guideUsDesc.text_en)}</p>
                        </div>
                    </div>
                    {/* {console.log(this.state.selectedsection, "cat", this.state.selectedCategory)} */}
                    <div className="row">
                        {this.props.ershdnaCategories && this.props.ershdnaCategories.filter(c => c.draft == 0).length > 0 &&
                            this.props.ershdnaCategories.filter(c => c.draft == 0)
                                .map((cat) => {
                                    return (
                                        <div className="col-12 col-lg-4" key={cat.id}>
                                            <div className={"card-weHearYou" + (this.state.selectedCategory == cat.id ? ' active-card' : '')}
                                                onClick={() => { this.getCategory(cat.id) }}>
                                                <div className="cpa-tooltip">
                                                    <h4>{this.props.lng == "ar" ? cat.name : cat.name_en}</h4>
                                                    <span style={{ 'font-size': '14px' }} className="cpa-tooltiptext w-100">{this.props.lng == "ar" ? cat.name : cat.name_en}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </div>


                    <div ref={this.catRef}>

                        {
                            this.props.ershdnaCategories &&
                                this.props.ershdnaCategories.find(ershdnaCategory => ershdnaCategory.id == this.state.selectedCategory) &&
                                this.props.ershdnaCategories.find(ershdnaCategory => ershdnaCategory.id == this.state.selectedCategory).draft == 0 ?
                                <div className="row">
                                    {
                                        this.props.lng === 'ar' ?
                                            ReactHtmlParser(this.props.ershdnaCategories.find(ershdnaCategory => ershdnaCategory.id == this.state.selectedCategory).description) :
                                            ReactHtmlParser(this.props.ershdnaCategories.find(ershdnaCategory => ershdnaCategory.id == this.state.selectedCategory).description_en)
                                    }
                                </div> : <div></div>
                        }

                        <div>
                            {
                                this.props.ershdnaCategories &&
                                    this.props.ershdnaCategories.find(ershdnaCategory => ershdnaCategory.id == this.state.selectedCategory) &&
                                    this.props.ershdnaCategories.find(ershdnaCategory => ershdnaCategory.id == this.state.selectedCategory).draft == 0 ?
                                    <div className={"calendar-view row " + (this.state.selectedsection == "viewCategory" && this.state.selectedCategory ? '' : 'd-none')}>
                                        <div className="col-12">

                                            <div style={{}} className="grid-view row" ref={this.myRef}>
                                                {Object.keys(this.props.ershdnaCategory).map((key, index) => {
                                                    return (
                                                        <div className="month-card col-12" key={index}>
                                                            <div className="row">
                                                                <button className="btn secendary-cpa-btn m-auto" disabled>
                                                                    {moment(key).format('MMMM YYYY')}
                                                                </button>
                                                            </div>
                                                            {this.props.ershdnaCategory[key].map((day, index) => {
                                                                return (
                                                                    <div className="row day-card" key={index}>
                                                                        <div className="col-6">
                                                                            <div className={day.link ? "text-card link" : "text-card"} >
                                                                                <a href={day.link} target="_blank" rel="noopener noreferrer">
                                                                                    <p>{this.props.lng == "ar" ? day.name : day.name_en}</p>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="date-card">
                                                                                <span>{moment(day.date).format('Do MMMM YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                            <div className="row">
                                                <button className="btn secendary-cpa-btn working-on-btn m-auto" disabled>
                                                    {t('already_working_on')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div></div>
                            }
                        </div>

                    </div>

                    <div className={"set-suggestion row " + (this.state.selectedsection == "setSuggestion" ? '' : 'd-none')}>
                        <form ref={this.suggestion}>
                            <Row>
                                <Col xs={12} lg={3}>
                                    <h5 className="pr-4" ><Translate text="pleaseSelect" /><span Style="color:red">*</span></h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} lg={4}>
                                    {
                                        this.props.sections.length > 0 &&
                                        <div className="form-input">
                                            <i className="icon-menu-media"></i>
                                            <select
                                                name="section_id"
                                                onChange={this.handleSectionsChange}
                                                onBlur={this.handleBlur}
                                                value={this.state.section_id}>
                                                <option value="" disabled selected><Translate text="selectSector" /></option>
                                                {
                                                    this.props.sections.map((section, index) => {
                                                        return <option key={index} value={section.id}>{this.props.user.lng === 'ar' ? section.name : section.name_en}</option>
                                                    })
                                                }

                                            </select>
                                        </div>
                                    }
                                </Col>
                                <Col xs={12} lg={4}>
                                    {this.props.problems.length > 0 &&

                                        <div className="form-input">
                                            <i className="icon-menu-media"></i>
                                            <select
                                                name="type_id"
                                                onChange={this.handleProblemsChange}
                                                onBlur={this.handleBlur}
                                                value={this.state.type_id}>
                                                <option value="" disabled selected><Translate text="ChooseProblem" /></option>
                                                {
                                                    this.props.problems.map((problem, index) => {
                                                        return <option key={index} value={problem.id}>{this.props.user.lng === 'ar' ? problem.name : problem.name_en}</option>
                                                    })
                                                }

                                            </select>
                                        </div>
                                    }
                                </Col>
                                <Col xs={12} lg={4}>
                                    {this.props.organizations.length > 0 &&
                                        <div className="form-input">
                                            <i className="icon-menu-media"></i>
                                            <select
                                                name="organization_id"
                                                onChange={this.handleOrganizationsChange}
                                                onBlur={this.handleBlur}
                                                value={this.state.organization_id}>
                                                <option value="" disabled selected><Translate text="SelectDestination" /></option>
                                                {
                                                    this.props.organizations.map((organization, index) => {
                                                        return <option key={index} value={organization.id}>{this.props.user.lng === 'ar' ? organization.name : organization.name_en}</option>
                                                    })
                                                }

                                            </select>
                                        </div>
                                    }
                                </Col>
                                <Col xs={12}>
                                    <h5 className="pr-4"><Translate text="DetailsComplaint" /><span Style="color:red">*</span></h5>
                                </Col>
                                <Col xs={12}>
                                    <div className="form-input">
                                        <i className="icon-menu-media"></i>
                                        <textarea
                                            name="complaint"
                                            placeholder={t("DetailsComplaint")}
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            type="text" required />
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <h5 className="pr-4"><Translate text="AddAttachments" /></h5>
                                </Col>
                                <Col xs={12}>
                                    <div className="form-input file-input">
                                        <i className="icon-picture"></i>
                                        <div className="custom-input-file">{this.state.attachments_file ? <span>{this.state.attachments_file}</span> : <Translate text="AddAttachments" />}</div>
                                        <input
                                            name="attachments"
                                            onChange={this.handleChange}
                                            multiple="multiple"
                                            type="file" required
                                            accept="image/* , application/pdf, application/vnd.ms-excel, application/msword" />
                                        <span>doc/docx/pdf/images/xls/xlsx, <Translate text="fileSize" /></span>
                                    </div>
                                    <div className="attachedFilesPreview">
                                        <Row>
                                            {attachmentsArray.map((value, index) => {
                                                return (
                                                    <Col xs={12} md={6} lg={3} key={index}>
                                                        <div className="attachedFile" onClick={() => this.removeFile(value.id)}>
                                                            <span className="deleteFileBtn">x</span>
                                                            <span className="btnFileName">{value.file.name}</span>
                                                        </div>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </div>
                                </Col>
                                <Col xs={12} className="submit-row">

                                    {this.props.user.is_authenticated ?
                                        <button
                                            className="btn submit-cpa-btn"
                                            type="submit"
                                            onClick={this.handleCreateErshedna}>
                                            <Translate text="guideUsLoggedIn" />
                                        </button>
                                        :
                                        <button
                                            className="btn submit-cpa-btn"
                                            type="button"
                                            onClick={this.props.toggleModal}>
                                            <Translate text="guideUsLoggedOut" />
                                        </button>
                                    }

                                </Col>
                            </Row>
                        </form>
                    </div>
                    <div className={"home-section row " + (this.state.selectedsection == "home" ? '' : 'd-none')}>
                        <div className="col-12" ref={this.statistics}>
                            <div className="row">
                                <div className="col-12">
                                    <h3 className="home-section-head">
                                        {this.props.lng === "ar" ? ReactHtmlParser(this.props.head.text) : ReactHtmlParser(this.props.head.text_en)}
                                    </h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    {/* <p className="get-recived-row">{t('gotResived')}</p> */}
                                    {this.props.lng === "ar" ? ReactHtmlParser(this.props.desc.text) : ReactHtmlParser(this.props.desc.text_en)}
                                </div>
                                <div className="col-12 col-lg-6 get-recived-row">
                                    <select className="w-75 rounded p-2 select-a-complain" onChange={this.onSelectComplainChnage}>
                                        <option value="">{t('recivedComplains')}</option>
                                        {this.props.ershdnaSubs &&
                                            this.props.ershdnaSubs.map((sub, index) => {
                                                return (
                                                    <option value={sub.id} key={index}>{this.props.lng == "ar" ? sub.name : sub.name_en}</option>
                                                )
                                            })}
                                    </select>
                                </div>
                            </div>
                            <div className="row" >
                                {this.state.ershdnaSubs &&
                                    this.state.ershdnaSubs.map((sub, index) => {
                                        return (
                                            <div className="col-12" key={index}>
                                                <div className="complains-card row">
                                                    <div className="col-12 col-md-4 col-lg-3">
                                                        <div className="complain-title">
                                                            <h4>{this.props.lng == "ar" ? sub.name : sub.name_en}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-8 col-lg-9">
                                                        {sub.problems.map((problem, index) => {
                                                            return (
                                                                <div className="d-flex complain-row" key={index}>
                                                                    <div className="mr-2 ml-2">
                                                                        <h5>{this.props.lng == "ar" ? problem.name : problem.name_en}</h5>
                                                                    </div>
                                                                    <div className="mr-1 ml-1">
                                                                        <div className="complains-progress-bar" style={{ width: problem.compainsCount > 39 ? '39' : problem.compainsCount + 'vw' }}></div>
                                                                    </div>
                                                                    <div className="mr-2 ml-2">
                                                                        <span>{problem.compainsCount}</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                            <div className="row complains-no">
                                <div className="col">
                                    <span>{t('ComplainantsNo')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal dialogClassName="guideUs-modal" show={this.state.showModal} size="lg" aria-labelledby="example-modal-sizes-title-sm">
                    <Modal.Header closeButton onClick={this.closeComplainModal} />
                    <Modal.Body >
                        <div className="success_guideUs Authentication-container">
                            <h3>{t('successfullyRegistered')}</h3>
                            <h2 >{t('suggestionCount', { count: this.state.count })} </h2>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }

}

GuideUs.propTypes = {
    actions: PropTypes.object.isRequired,
    sections: PropTypes.array.isRequired,
    problems: PropTypes.array.isRequired,
    organizations: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    toggleModal: PropTypes.func.isRequired,
    apiResponse: PropTypes.object.isRequired,
    showError: PropTypes.func.isRequired,
    showSuccess: PropTypes.func.isRequired,
    lng: PropTypes.string.isRequired,
    ershdnaCategories: PropTypes.array.isRequired,
    ershdnaCategory: PropTypes.array,
    ershdnaSubs: PropTypes.array.isRequired,
    head: PropTypes.object,
    desc: PropTypes.object,
    guideUsDesc: PropTypes.object,
    'guideUsDesc.text': PropTypes.object,
    'guideUsDesc.text_en': PropTypes.object,
    "head.text": PropTypes.string,
    "head.text_en": PropTypes.string,
    "desc.text": PropTypes.string,
    "desc.text_en": PropTypes.string,
    history: PropTypes.object,
    "history.push": PropTypes.func,
    match: propTypes.object,
};

function mapStateToProps(state) {
    return {
        user: state.user,
        sections: state.ershedna.sections,
        problems: state.ershedna.problems,
        organizations: state.ershedna.organizations,
        apiResponse: state.guideUs.apiResponse,
        lng: state.user.lng,
        ershdnaCategories: state.ershedna.ershdnaCategories,
        ershdnaCategory: state.ershedna.ershdnaCategory,
        ershdnaSubs: state.ershedna.ershdnaSubs,
        head: state.ershedna.head,
        desc: state.ershedna.desc,
        guideUsDesc: state.ershedna.guideUsDesc
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GuideUs))
