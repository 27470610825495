import {
  GET_MOTAMKN_LANDING_DATA,
  GET_MOTAMKN_ACTIVITIES_DATA,
  GET_MOTAMKN_SINGLE_ACTIVITY_DATA,
  USER_REGISTER_IN_ACTIVITY
} from '../../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './../../initialState';

export default function motamkenReducer(state = initialState.programs_and_activities.programs.motamaken, action) {
  switch (action.type) {
    case GET_MOTAMKN_LANDING_DATA.SUCCESS:
      return objectAssign({}, state, {categories: action.payload});
    case GET_MOTAMKN_ACTIVITIES_DATA.SUCCESS:
      return objectAssign({}, state, {activities: action.payload});
    case GET_MOTAMKN_SINGLE_ACTIVITY_DATA.SUCCESS:
      return objectAssign({}, state, {activity: action.payload});
      case USER_REGISTER_IN_ACTIVITY.SUCCESS:
          return objectAssign({}, state, {
              activities: [
                  ...state.activities.map((activity) => {
                      if(activity.id === action.payload.id) {
                          activity.is_registered = true
                      }
                      return activity;
                  }),
                  // Object.assign({}, {...action.payload, is_registered: true})
                  ]
          });
    default:
      return state;
  }
}
