import React from 'react';
import { t } from 'i18next';
import { Form, Col, Row } from 'react-bootstrap';
import { BASE_URL } from '../../constants/config';
import PropTypes from "prop-types";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
class TabUSerForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: BASE_URL + '/uploads/',
            attachments_file: null,
            profile: {},
            values: null,
            countryCodes: [
                {
                    name: 'sa_code',
                    value: '+966'
                }
                , {
                    name: 'egy_code',
                    value: '+20'
                }
            ],
        }
    }
    handleChange = event => {
        event.preventDefault();
        const { name, value } = event.target;
        //console.log(event.target.files[0].name);
        if (name === "identification_path") {
            let fileURl = URL.createObjectURL(event.target.files[0]);
            this.setState({
                values: { ...this.state.values, [name]: event.target.files[0] },
                attachments_file: fileURl
            })
        } else {
            this.setState({
                values: { ...this.state.values, [name]: value },
            })
        }
    };

    setEditModeAndSave = (event) => {
        event.preventDefault();
        // console.log(this.props.user);
        if (!this.props.edit) {
            this.props.handleEdit()
        } else {
            this.props.handleEdit();
            this.props.handleSetUserData(this.state.values);
        }

    }
    render() {
        let phoneDir = this.props.user.lng === "en" ?
            { direction: "ltr", width: "100%", border: "0" } :
            { direction: "rtl", width: "100%", border: "0" }

        return (
            <div className="tabUserForm-container">
                <Form>
                    <Form.Group as={Row} controlId="user_firstName">
                        <Form.Label column sm="2">{t('firstName')}</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                name="first_name"
                                plaintext={!this.props.edit}
                                readOnly={!this.props.edit}
                                defaultValue={this.props.user.first_name}
                                onChange={this.handleChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="user_lastName">
                        <Form.Label column sm="2">{t('lastName')}</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                name="last_name"
                                plaintext={!this.props.edit}
                                readOnly={!this.props.edit}
                                defaultValue={this.props.user.last_name}
                                onChange={this.handleChange} />
                        </Col>
                    </Form.Group>
                    {/* <Form.Group as={Row} controlId="user_phone">
                        <Form.Label column sm="2">{t('phoneNumber')}</Form.Label>
                        <Col sm="10">
                            <Row>
                                <Col xs={2}>
                                    <Form.Control as="select" name="country_code"
                                        plaintext={!this.props.edit}
                                        readOnly={!this.props.edit}
                                        disabled={!this.props.edit}
                                        onChange={this.handleChange}>
                                        {this.state.countryCodes.map((value, index) => {
                                            return (
                                                <option key={index} value={value.value}>{t(value.name)}</option>
                                            )
                                        })}

                                    </Form.Control>
                                </Col>
                                <Col xs={10}>
                                    <Form.Control
                                        name="mobile"
                                        plaintext={!this.props.edit}
                                        readOnly={!this.props.edit}
                                        defaultValue={this.props.user.mobile}
                                        onChange={this.handleChange} />
                                </Col>
                            </Row>
                        </Col>
                    </Form.Group> */}

                    <Form.Group as={Row} controlId="user_phone">
                        <Form.Label column sm="2">{t('phoneNumber')}</Form.Label>
                        <Col sm="10">
                            <Row>

                                <Col xs={10}>


                                    <div className="form-input">
                                        <i className="icon-smartphone" style={{ border: "0" }} />
                                        <PhoneInput
                                            id="phone"

                                            flags={flags}
                                            // country='SA'
                                            // readOnly={!this.props.edit}
                                            disabled={!this.props.edit}
                                            style={phoneDir}
                                            name="mobile"
                                            ref={this.mobileRef}

                                            value={this.props.user.mobile}
                                            // onChange={this.handleChange} />
                                            onChange={(mobile) => {
                                                this.setState({
                                                    values: { ...this.state.values, mobile }
                                                })
                                            }}
                                        />
                                    </div>

                                </Col>
                            </Row>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="user_city">
                        <Form.Label column sm="2">{t('city')}</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                name="city"
                                plaintext={!this.props.edit}
                                readOnly={!this.props.edit}
                                defaultValue={this.props.user.city}
                                onChange={this.handleChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="user_identityNumber"  style={{display:'none'}}>
                        <Form.Label column sm="2">{t('identityNumber')}</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                name="identification_number"
                                plaintext={!this.props.edit}
                                readOnly={!this.props.edit}
                                defaultValue={this.props.user.identification_number || ""}
                                onChange={this.handleChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="user_identityNumberImage" style={{display:'none'}}>
                        <Form.Label column sm="2">{t('AttachResidence')}</Form.Label>
                        <Col sm="10">
                            {this.props.edit &&
                                <div className="input-file">
                                    <Form.Control
                                        name="identification_path"
                                        disabled={!this.props.edit}
                                        type="file"
                                        accept="image/*"
                                        onChange={this.handleChange} />
                                    <Form.Control />
                                    <i className="icon-link"></i>
                                    <p>{t('AttachResidence')}</p>
                                </div>
                            }
                            {((this.props.user.identification_path != '' && this.props.user.identification_path) && !this.state.attachments_file) ?
                                <div className="image-preview-container">
                                    <img className="" src={BASE_URL + "/uploads/" + this.props.user.identification_path} />
                                </div>
                                :
                                this.state.attachments_file &&
                                <div className="image-preview-container">
                                    <img className="" src={this.state.attachments_file} />
                                </div>
                            }

                        </Col>
                    </Form.Group>
                    <div className="form-btn">
                        <button className="btn submit-cpa-btn" type="submit" onClick={this.setEditModeAndSave} >
                            {!this.props.edit ? t('edit') : t('save')}
                        </button>
                    </div>
                </Form>
            </div>
        )
    }
}
TabUSerForm.propTypes = {
    edit: PropTypes.bool.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleSetUserData: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
}
export default TabUSerForm;
