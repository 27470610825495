import { GET_ANNUAL_REPORTS } from '../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../initialState';

export default function annualReportsReducer(state = initialState.information_center.annual_reports, action) {
    switch (action.type) {
        case GET_ANNUAL_REPORTS.SUCCESS:
            return objectAssign({}, state, {
                annual_reports_array: action.payload,


            });
        default:
            return state;
    }
}