import React from 'react';
import './videoCard.scss';
import { withRouter } from 'react-router-dom';
// import EmbededVideo from '../../GlobalComponent/embededVideo/embededVideo';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BASE_URL } from '../../../constants/config';
class VideoCard extends React.Component {
    constructor(context, props) {
        super(context, props);
        this.state = {
            url: BASE_URL + '/'
        }
    }
    render() {
        let videoCardContent =
            this.props.match.url.includes('general-tips') || this.props.match.url.includes('previous-ratings') ?
                this.props.tipsVideoCard.length > 0 &&
                <div className="videoCard-container bg-white shadow-sm">
                    {
                        this.props.tipsVideoCard.map(item => {
                            return (
                                <div key={item.name} className="card-video">
                                    <div className="embed-responsive embed-responsive-16by9">
                                        {/* <iframe width="100%" height="300" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/625440000&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe> */}
                                        {item.download_link ?
                                            /* <iframe className="embed-responsive-item" width="100%" height="500px" control="false"
                                                src={BASE_URL + '/' + item.download_link}
                                                frameBorder="0"
                                                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */
                                            <video width="100%" height="300" controls autoPlay={false}>
                                                <source src={BASE_URL + '/uploads/' + item.download_link}></source>
                                            </video>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className="card-content bg-white">
                        <h4>{this.props.lng == 'ar' ? this.props.tipsVideoCard[0].original_name : this.props.tipsVideoCard[0].original_name}</h4>
                    </div>
                </div>
                :
                <div className="videoCard-container bg-white shadow-sm" >
                    <div className="card-video">
                        <div className="embed-responsive embed-responsive-16by9">
                            {/* <iframe width="100%" height="300" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/625440000&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe> */}

                            {
                                this.props.videoCard &&
                                this.props.videoCard.type === "video" &&
                                <iframe autoPlay={false} className="embed-responsive-item" width="100%" height="500px"
                                    src={this.props.videoCard.youtube_link}
                                    frameBorder="0"
                                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                                </iframe>
                            }

                            {
                                this.props.videoCard &&
                                this.props.videoCard.type === "mp3" &&
                                <iframe autoPlay={false} className="embed-responsive-item" width="100%" height="500px"
                                    src={BASE_URL + '/uploads/' + JSON.parse(this.props.videoCard.src)[0].download_link}
                                    frameBorder="0"
                                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                                </iframe>
                            }

                            {
                                this.props.videoCard &&
                                this.props.videoCard.type === "upload_video" &&
                                <iframe autoPlay={false} className="embed-responsive-item" width="100%" height="500px"
                                    src={BASE_URL + '/uploads/' + JSON.parse(this.props.videoCard.src)[0].download_link}
                                    frameBorder="0"

                                    allow="accelerometer;encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                                </iframe>
                            }
                        </div>
                    </div>
                    <div className="card-content bg-white">
                        <h4>{this.props.lng == 'ar' ? this.props.videoCard.title : this.props.videoCard.title_en}</h4>
                        <div className="card-viewData row">
                            <p className="col-6">
                                {this.props.videoCard.date &&
                                    <div>
                                        <i className="icon-calendar"></i><span>{this.props.videoCard.date}</span>
                                    </div>
                                }
                            </p>
                            {/* <p className="col-6 p-0">
                        <i className="icon-Observation"></i> <span>{props.videoCard.viewers} :عدد المشاهدات</span>
                        </p> */}
                        </div>
                    </div>
                </div>
        return (videoCardContent)
    }
}

VideoCard.propTypes = {
    videoCard: PropTypes.object,
    lng: PropTypes.string.isRequired,
    match: PropTypes.object,
    "match.url": PropTypes.string,
    "match.url.includes": PropTypes.func,
    tipsVideoCard: PropTypes.array,
    "tipsVideoCard.length": PropTypes.number,
    "tipsVideoCard.map": PropTypes.func,
    "tipsVideoCard.original_name": PropTypes.string,
};
function mapStateToProps(state) {
    return {
        lng: state.user.lng,
    };
}

export default withRouter(connect(mapStateToProps)(VideoCard))