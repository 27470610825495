import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as navigationActions from '../../actions/navigation';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import BreadCrumb from '../GlobalComponent/BreadCrumb/BreadCrumb';
import ImgHeader from '../GlobalComponent/ImgHeader/ImgHeader';
import { t } from 'i18next'
import { apiFetch } from '../../utils/http.helpers';
import { BASE_URL } from '../../constants/config';
import CustomPageContent from './CustomPageContent/customPageContent';
import { toast } from 'react-toastify';
import Recaptcha from 'react-recaptcha';
import { finished } from 'form-data';
import NotFoundPage from './../NotFoundPage';
import objectAssign from 'object-assign';

class CustomPage extends React.Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.state = {

            buiderPageData: [],
            buiderPageForms: [],
            showPrevBtnFlag: false,
            form_id: null,
            routes: [],
            currentForm: null,
            prev_form_data: null,
            next_form_data: null,
            next_form: null,
            isVerified: false,
            showPrev: false
            , finished: false
        }
    }
    checkExsitNext = () => {
  
        let next = this.child.current.checkExsitContentNext();

        this.setState({ ...this.state, next_form: next })

    }
    onChangeConditionalQ = () => {
        let next = this.child.current.nextFormForm();
        if (next) {
            this.setState({ ...this.state, next_form: next })
        }
        if (!this.state.currentForm.form_fields.some(el => el.type == 'conditional_question')) {
            this.setState({ ...this.state, next_form: null })

        }

    }
    componentDidMount() {
        let result = this.props.navigationActions.getBuilderPages(this.props.match.params.id)
        result.then(() => {
            this.setState({
                ...this.state,
                routes: this.setRoutesFormApi(this.props.buiderPage),
                currentForm: this.props.buiderPage.forms !== undefined ? this.props.buiderPage.forms : this.props.buiderPage,
                form_id: this.props.buiderPage.forms !== undefined ? this.props.buiderPage.forms.id : null,
                showPrevBtnFlag: false,
            }, () => {
           
                this.checkExsitNext()

            })
        })
    }

    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 10; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }

    saveData = () => {

    }
    nextButtonHandler = () => {

        let res = this.child.current.validateForm();
        if (!res) {
            let next_form = { ...this.child.current.state.next_form }
            let next_form_check = this.child.current.state.next_form
            if (next_form_check === null && !this.state.finished) {
                let warningMessage = this.props.lng === 'ar' ? `سيتم حفظ البيانات ... لاحظ انك لن تكون قادر ع العودة وتعديل بياناتك ... استمرار ؟ ` :
                    `Your data will be saved .. please notice that you will not be able to go back and edit .. continue ? `
                let result = confirm(warningMessage)
                if (!result) {
                    return false;
                } else {
                    this.setState({ ...this.state, finished: true })
                    this.setState({ ...this.state, showPrevBtnFlag: false })
                }
            }

            let next_form_find = this.state.buiderPageForms.find(singleForm => singleForm.id === next_form.id);
            let next_form_data = null;
            if (next_form_find !== undefined) {
                next_form_data = this.state.buiderPageData.find(singleForm => singleForm.form_id === next_form.id)
            }
            let formData = Object.assign(this.child.current.state.form_data, { form_id: this.state.currentForm.id });
       
            let state = {}

            if (this.state.buiderPageData.findIndex(singleData => singleData.form_id === formData.form_id) === -1) {
                objectAssign(state, this.state, { buiderPageData: [...this.state.buiderPageData, formData] });
          
            }
            else {
                let temp = [...this.state.buiderPageData]
                temp = temp.filter(singleData => singleData.form_id !== formData.form_id)
                objectAssign(state, this.state, { buiderPageData: [...temp, formData] });
                
            }

        
            if (this.state.buiderPageForms.findIndex(singleData => singleData.form_id === this.state.currentForm.form_id) === -1) {
                objectAssign(state, { buiderPageForms: [...this.state.buiderPageForms, this.state.currentForm] });
            
                if (this.child.current.state.next_form !== null) {
                    let prev_form = state.currentForm

                    next_form = Object.assign(next_form, { prev_form: prev_form })

                    objectAssign(state, { currentForm: next_form, next_form_data: next_form_data, showPrevBtnFlag: true });
                 
                }
                else {
                    objectAssign(state, { next_form: null });

                }
            }
            else {
                let temp = [...this.state.buiderPageForms]
                temp = temp.filter(singleData => singleData.form_id !== this.state.currentForm.form_id)
                objectAssign(state, { buiderPageForms: [...temp, this.state.currentForm] });
                if (this.child.current.state.next_form !== null) {
                    let prev_form = state.currentForm

                    next_form = Object.assign(next_form, { prev_form: prev_form })

                    objectAssign(state, { currentForm: next_form, next_form_data: next_form_data, showPrevBtnFlag: true });
                  
                }
                else {
                    objectAssign(state, { next_form: null });

                }
              
            }
            
            this.setState(state, () => {
                this.child.current.nextButtonHandler();

            })
         
        
        }
        return true;
    }

    buildFormData = (formData, data, parentKey) => {
        if (
            data &&
            typeof data === 'object' &&
            !(data instanceof Date) &&
            !(data instanceof File)
        ) {
            Object.keys(data).forEach((key) => {
                this.buildFormData(
                    formData,
                    data[key],
                    parentKey ? `${parentKey}[${key}]` : key
                );
            });
        } else {
            const value = data == null ? '' : data;

            formData.append(parentKey, value);
        }
    }


    jsonToFormData = (data) => {
        const formData = new FormData();

        this.buildFormData(formData, data, null);

        return formData;
    }
    saveCurrentForm = (data, form) => {
        
        let state = {}
        let next_form;
        let next_form_data;
        if (this.state.buiderPageData.findIndex(singleData => singleData.form_id === data.form_id) === -1) {
            objectAssign(state, this.state, { buiderPageData: [...this.state.buiderPageData, data] });
          
        }
        else {
            let temp = [...this.state.buiderPageData]
            temp = temp.filter(singleData => singleData.form_id !== data.form_id)
            objectAssign(state, this.state, { buiderPageData: [...temp, data] });
        
        }

        

        if (this.state.buiderPageForms.findIndex(singleData => singleData.form_id === form.form_id) === -1) {
            objectAssign(state, { buiderPageForms: [...this.state.buiderPageForms, form] });
      
            if (this.child.current.state.next_form !== null) {
                let prev_form = state.currentForm

                next_form = Object.assign(next_form, { prev_form: prev_form })

                objectAssign(state, { currentForm: next_form, next_form_data: next_form_data, showPrevBtnFlag: true });
             
            }
            else {
                objectAssign(state, { next_form: null });

            }
        }
        else {
            let temp = [...this.state.buiderPageForms]
            temp = temp.filter(singleData => singleData.form_id !== form.form_id)
            objectAssign(state, { buiderPageForms: [...temp, form] });
            if (this.child.current.state.next_form !== null) {
                let prev_form = state.currentForm

                next_form = Object.assign(next_form, { prev_form: prev_form })

                objectAssign(state, { currentForm: next_form, next_form_data: next_form_data, showPrevBtnFlag: true });
          
            }
            else {
                objectAssign(state, { next_form: null });

               
            }
          
        }
        
        this.setState(state, () => {
            this.child.current.nextButtonHandler();

        })
    }
 
    previousButtonHandler = () => {
        let prev_form = this.state.buiderPageForms.find(singleForm => singleForm.id === this.state.currentForm.prev_form.id);
        let prev_form_data = this.state.buiderPageData.find(singleForm => singleForm.form_id === this.state.currentForm.prev_form.id);
        let next_form = this.state.currentForm;
        if (this.child.current !== null && prev_form !== undefined) {
            this.setState({ ...this.state, currentForm: prev_form, prev_form_data: prev_form_data, next_form: next_form, showPrevBtnFlag: prev_form.prev_form ? true : false }, () => {
                this.child.current.prevButtonHandler();
            })
        }
    }

    onSubmitHandlerWithNoReg() { }

    onSubmitHandler(e) {



        if (this.nextButtonHandler()) {
            let res = this.child.current.validateForm();
            if (!res) {
                e.preventDefault();
                let formData = Object.assign(this.child.current.state.form_data, { form_id: this.state.currentForm.id });
                this.saveCurrentForm(formData, this.state.currentForm);
              
                let objectToBeSaved = {};
                setTimeout(() => {
                    this.state.buiderPageData.map(singleData => {
                        Object.assign(objectToBeSaved, { ...singleData })
                    })

                    Object.assign(objectToBeSaved, { form_id: this.state.form_id })



                    if (!this.state.isVerified) {
                        toast.error(this.props.lng === 'ar' ? 'يرجي التحقق أنك لست روبوتا' : `Please verify you're not a robot`, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        })
                    } else {
                        if (this.state.currentForm.needs_registration === 1) {
                            apiFetch(BASE_URL + '/api/FormBuilder/addFormData', {
                                method: 'POST',
                                headers: {
                                  
                                    "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
                                },
                                body: this.jsonToFormData(objectToBeSaved)
                            

                            })
                        }
                        else {
                            apiFetch(BASE_URL + '/api/FormBuilder/addNonRegisteredFormData', {
                                method: 'POST',
                               
                                body: this.jsonToFormData(objectToBeSaved)


                            })
                        }


                        toast.success(this.props.lng === 'ar' ? 'تم الحفظ' : 'Saved', {
                            position: toast.POSITION.BOTTOM_RIGHT
                        })
                        setTimeout(() => {
                            window.location.reload();
                        }, 5000);
                    }

                }, 100);
             
              

            }
            else {
                this.child.current.validateForm();
            }
        }
    }

    loadCallback() {
    }
    expiredCallback = () => {
        this.setState({ ...this.state, isVerified: false });
    }

    verifyCallback = () => {
        this.setState({ ...this.state, isVerified: true });
    }

    render() {
        let page = this.props && this.props.buiderPage && !this.props.buiderPage.message ?
            <div className="customPage-container">

                <ImgHeader page={this.props.buiderPage} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                <Container>
                    {ReactHtmlParser(this.props.lng == "ar" ? this.props.buiderPage.content : this.props.buiderPage.content_en)}
                    {
                        this.state.currentForm &&
                        <CustomPageContent
                            images={this.props.buiderPage.image_slider}
                            lng={this.props.lng}
                            ref={this.child}
                            nextForm={this.state.next_form}
                            form={this.state.currentForm}
                            formData={this.state.prev_form_data}
                            nextFormData={this.state.next_form_data}
                            onChangeConditionalQ={() => this.onChangeConditionalQ()}
                            checkExsitNext={this.checkExsitNext}
                            finished={finished}
                        >
                        </CustomPageContent>
                    }
                    {
                        this.props.buiderPage &&
                        this.props.buiderPage.forms &&
                        this.state.next_form === null &&
                        this.state.currentForm !== null &&
                        // this.state.finished &&
                        // this.state.currentForm.needs_registration === 1 &&
                        < div className="submit-row">
                            <Recaptcha
                                sitekey="6LdgyaQUAAAAAP773vGEpAbayR1-QSsN7ZZTV4-V"
                                render="explicit"
                                verifyCallback={this.verifyCallback}
                                onloadCallback={this.loadCallback}
                                expiredCallback={this.expiredCallback}
                                hl={this.props.lng} />
                        </div>
                    }
                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1.5rem" }} className="button-container">
                        {
                            this.props.buiderPage &&
                            this.props.buiderPage.forms &&
                            this.props.buiderPage.forms.form_fields &&
                            !this.state.finished &&
                            // this.state.next_form !== null &&
                            <button
                                style={{ visibility: this.state.showPrevBtnFlag ? 'unset' : "hidden" }}
                                onClick={() => this.previousButtonHandler()} className="btn submit-cpa-btn" >{t('previous')}</button>
                        }
                        {
                            this.props.buiderPage &&
                                this.props.buiderPage.forms &&
                                this.state.currentForm !== null &&
                                this.state.currentForm !== undefined &&
                                this.state.currentForm.needs_registration === 1 &&
                                this.props.buiderPage.forms.form_fields &&
                                localStorage.getItem('_cpaJwt') === null ?
                                <button className="btn submit-cpa-btn" onClick={this.props.toggleModal} type="submit">{t('login_for_submit')}</button> :
                                this.props.buiderPage &&
                                this.props.buiderPage.forms &&
                                (this.state.next_form === null) &&
                                this.props.buiderPage.forms.form_fields &&
                                <button onClick={(e) => this.onSubmitHandler(e)} className="btn submit-cpa-btn" >{t('submit')}</button>
                        }
                        {
                            this.props.buiderPage &&
                            this.props.buiderPage.forms &&
                            (this.state.next_form !== null) &&
                            this.props.buiderPage.forms.form_fields &&
                            <button onClick={() => this.nextButtonHandler()} className="btn submit-cpa-btn" >{t('next')}</button>
                        }
                    </div>
                </Container >
            </div > : <NotFoundPage />
        return (
            page
        )
    }
}

CustomPage.propTypes = {
    lng: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    buiderPage: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    toggleModal: PropTypes.func,
    buiderPageData: PropTypes.object,
    'buiderPageData.find': PropTypes.func,
    'buiderPageData.map': PropTypes.func,
    buiderPageForms: PropTypes.object,
    'buiderPageForms.find': PropTypes.func,
};

function mapStateToProps(state) {
    return {
        lng: state.user.lng,
        buiderPage: state.navigation.buiderPage,
        buiderPageData: state.navigation.buiderPageData,
        buiderPageForms: state.navigation.buiderPageForms
    };
}

function mapDispatchToProps(dispatch) {
    return {
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomPage)