import {
    GET_COMPARE_AND_RATE_ITEM_DATA,
    GET_COMPARE_AND_RATE_LANDING_DATA,
    GET_COMPARE_AND_RATE_ITEM_SIBLING,
    GET_COMPARE_AND_RATE_COMPARIOSN,
    GET_ALL_MULTI_CATEGORIES_WITH_EACH_SUB,
    GET_MULTI_CATEGORIES_BY_ID_WITH_EACH_SUB,
    COMPARE_OBJECTS,
    ADD_OBJETCS_COMPARE_LIST, REMOVE_TO_BE_COMPARED_OBJECTS,
    REMOVE_SPECIFIC_OBJECT_FROM_TO_BE_COMPARED_OBJECTS,
    ADD_BUTTON_ID_OF_ADDED_OBJECT_TO_COMPARISION,
    REMOVE_BUTTON_ID_OF_ADDED_OBJECT_TO_COMPARISION,
    REMOVE_DIABLE_OBJECT_COMPARE_BTN,
    REMOVE_SPECIFIC_OBJECT_FROM_COMPARISON_LIST,
    SET_FIRST_COLLAPSE_INDEX,
    SET_FIRST_COLLAPSE_ID,
    SET_SECOND_COLLAPSE_INDEX,
    SET_SECOND_COLLAPSE_ID,
    GET_GENERAL_TIPS,
    GET_PREVIOUS_EVALUATIONS
} from '../../../constants/actionTypes';

import objectAssign from 'object-assign';
import initialState from './../../initialState';


export default function compareAndRateReducer(state = initialState.programs_and_activities.programs.compare_and_rate, action) {
    // let newState;

    switch (action.type) {
        case GET_ALL_MULTI_CATEGORIES_WITH_EACH_SUB:
            return objectAssign({}, state, { categories: action.payload })
        case GET_MULTI_CATEGORIES_BY_ID_WITH_EACH_SUB:
            return objectAssign({}, state, { multiCategories: action.payload })
        case COMPARE_OBJECTS:
            return objectAssign({}, state, { comparisonProducts: action.payload })
        case ADD_OBJETCS_COMPARE_LIST:
            return objectAssign({}, state, { toBeComparedObjects: action.payload })
        case REMOVE_TO_BE_COMPARED_OBJECTS:
            return objectAssign({}, state, { toBeComparedObjects: action.payload })
        case REMOVE_SPECIFIC_OBJECT_FROM_TO_BE_COMPARED_OBJECTS:
            return objectAssign({}, state, { toBeComparedObjects: action.payload })
        case ADD_BUTTON_ID_OF_ADDED_OBJECT_TO_COMPARISION:
            return objectAssign({}, state, { diableObjectCompareBtn: action.payload })
        case REMOVE_BUTTON_ID_OF_ADDED_OBJECT_TO_COMPARISION:
            return objectAssign({}, state, { diableObjectCompareBtn: action.payload })
        case REMOVE_DIABLE_OBJECT_COMPARE_BTN:
            return objectAssign({}, state, { diableObjectCompareBtn: action.payload })
        case REMOVE_SPECIFIC_OBJECT_FROM_COMPARISON_LIST:
            return objectAssign({}, state, { comparisonProducts: action.payload })
        case SET_FIRST_COLLAPSE_INDEX:
            return objectAssign({}, state, { firstCollapseIndex: action.payload })

        case SET_FIRST_COLLAPSE_ID:
            return objectAssign({}, state, { firstCollapseId: action.payload })

        case SET_SECOND_COLLAPSE_INDEX:
            return objectAssign({}, state, { secondCollapseIndex: action.payload })
        case SET_SECOND_COLLAPSE_ID:
            return objectAssign({}, state, { secondCollapseId: action.payload })
        case GET_GENERAL_TIPS:
            return objectAssign({}, state, { generalTips: action.payload })
        case GET_PREVIOUS_EVALUATIONS:
            return objectAssign({}, state, { prevEvaluations: action.payload })




        case GET_COMPARE_AND_RATE_LANDING_DATA.SUCCESS:
            return objectAssign({}, state, { categories: action.payload });
        case GET_COMPARE_AND_RATE_ITEM_DATA.SUCCESS:
            return objectAssign({}, state, { item: action.payload });
        case GET_COMPARE_AND_RATE_ITEM_SIBLING.SUCCESS:
            return objectAssign({}, state, { siblings: action.payload });
        case GET_COMPARE_AND_RATE_COMPARIOSN.SUCCESS:
            return objectAssign({}, state, { comparison: action.payload });
        default:
            return state;
    }
}
