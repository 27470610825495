import React from 'react';
// import { Link } from 'react-router-dom';
import ImgHeader from '../../GlobalComponent/ImgHeader/ImgHeader';
import { Container } from "react-bootstrap";
import Translate from '../../GlobalComponent/translate/translate';
import PropTypes from 'prop-types';

import { Link } from "react-router-dom";
let page = {
  name: "هذه الصفحة ليست موجوده حاليا",

  name_en: "This Page Is Not Exist Now",
}
class DefaultPage extends React.Component {
  render() {
    return (

      <div className="notFound-container w-80">
        {this.props.header && <ImgHeader page={page} />}
        <Container>
          <div className="notFound-content text-center">
            <div className="inner">
              <h4> <Translate text="notexistpage" /></h4>
              <p> <Translate text="goback" /></p>
              <h3 style={{ color: "#42C194 !important", textDecoration: "underline" }}>
                <Link to="/">    <Translate text="home" /></Link>
              </h3>
              {/* <Link to="/"><Translate text="goback"/> <Translate text="homePage"/> </Link> */}
            </div>
          </div>
        </Container>
      </div>

    )
  }
}

DefaultPage.propTypes = {
  header: PropTypes.any,

};
export default DefaultPage;
