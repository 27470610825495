import { GET_APPLICATIONS, GET_APPLICATION_BY_ID } from '../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../initialState';

export default function platformsReducers(state = initialState.programs_and_activities.platforms, action) {
    switch (action.type) {
        case GET_APPLICATIONS:
            return objectAssign({}, state, { applications: action.payload })
        case GET_APPLICATION_BY_ID:
            return objectAssign({}, state, { application: action.payload })
        default:
            return state;
    }
}