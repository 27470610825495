import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './landing-card.scss';
import { connect } from 'react-redux';

class LandingCard extends React.Component {
  constructor(props) {
    super(props);
    this.cardHover = this.cardHover.bind(this);
    this.cardLeave = this.cardLeave.bind(this);

    this.state = {
      inner: '0 1px 4px #999',
      icon: '2rem',
      title: 'normal',
      lng:localStorage.getItem('lng')
    }
  }

  cardHover() {
    this.setState({
        inner: '0 8px 9px #999',
        icon: '2rem',
        title: 'bold',

    });
  }
  cardLeave(){
    this.setState({
        inner: '0 1px 4px #999',
        icon: '2rem',
        title: 'normal',
    });
  }
  render(){
    return (
      <div className="flex-row"
        onMouseEnter={this.cardHover}
        onMouseLeave={this.cardLeave}
        >
        <div className="flex-card" style={{boxShadow: this.state.inner}}>
          <Link to={`/skilled/category/${this.props.category.id}`}>
              <ul>
                <li><i className={this.props.category.icon} style={{fontSize: this.state.icon}} /></li>
                <li><h3 style={{ fontWeight: this.state.title }}>
                  {this.props.lng=="ar"?this.props.category.name:this.props.category.name_en}
                </h3></li>
              </ul>
          </Link>
        </div>
      </div>
    );
  }
}
LandingCard.propTypes = {
    category: PropTypes.object,
    lng:PropTypes.string.isRequired
};
function mapStateToProps(state) {
  return {
      lng: state.user.lng,
  };
}

export default connect(mapStateToProps)(LandingCard)
