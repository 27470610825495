import {GET_IMP_LINKS_DATA} from '../../../../constants/actionTypes';
import { apiFetch } from '../../../../utils/http.helpers';
import { BASE_URL } from '../../../../constants/config';
export function getLinksData() {
    return function (dispatch) {
        return apiFetch(BASE_URL+'/api/elmonasra/links', {
            method: 'GET',
            // mode: "cors",
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(res => {
                // console.log("links",res);
                
                dispatch({ type: GET_IMP_LINKS_DATA.SUCCESS, payload:res });

            }).catch((error) => {
                throw error;
                // console.log('Error in create complain', error);
            });
    }
}
