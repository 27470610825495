import React from 'react';
import './generalTips.scss';
import BreadCrumb from '../../../GlobalComponent/BreadCrumb/BreadCrumb';
import ImgHeader from '../../../GlobalComponent/ImgHeader/ImgHeader';
import GalleryImage from '../../../GlobalComponent/GalleryImage/GalleryImage';
import CpaSharing from '../../../GlobalComponent/cpaSharing/cpaSharing';
import VideoCard from '../../../GlobalComponent/videoCard/videoCard';
import ContainerCard from '../../../GlobalComponent/ContainerCard/containerCard';
import ReactHtmlParser from 'react-html-parser';
import Translate from '../../../GlobalComponent/translate/translate';
import { BASE_URL } from '../../../../constants/config';
import * as actions from "../../../../actions/program-and-activities/programs/compareAndRate";
import * as userActions from '../../../../actions/auth/auth';
import * as navigationActions from '../../../../actions/navigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button, Collapse, Modal, Carousel } from 'react-bootstrap';
import { apiFetch } from '../../../../utils/http.helpers';
import PdfFiles from '../../../GlobalComponent/pdfFiles/pdfFiles';

class GeneralTips extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            showSharingIcons: false,
            intervalId: 0,
            pages: [
                {
                    link: '',
                    name: 'تقييم الربع الاول'
                },
                {
                    link: "",
                    name: 'تقييم الربع الثاني'
                },
                {
                    link: '',
                    name: 'تقييم الربع الثالث'
                },
                {
                    link: '',
                    name: 'تقييم الربع الرابع'
                },
                {
                    link: '',
                    name: 'تقييم الربع الرابع'
                },
                {
                    link: '',
                    name: 'تقييم الربع الرابع'
                },
            ],
            menuOpen: false,
            menuOpen1: false,
            arrowDown: 'icon-down-dir',
            arrowUp: 'icon-up-dir',
            arrow: 'icon-down-dir',
            arrowDown1: 'icon-down-dir',
            arrowUp1: 'icon-up-dir',
            arrow1: 'icon-down-dir',
            tips: "",
            prevRatings: [],
            prevRating: "",
            imagesArr: [],
            mp3Arr: [],
            documentsArr: [],
            videosArr: [],
            youtubeArr: [],
            ratingsImagesArr: [],
            ratingsMp3Arr: [],
            ratingsDocumentsArr: [],
            ratingsVideosArr: [],
            ratingsYoutubeArr: [],
            showModalImg: false,
            // modalImg: '',
            dataArr: [],
            loading: true,
            url: BASE_URL,
            routes: [],
            annualFees: [
                {
                    name: "الرسوم السنوية",
                    "value": "لا يوجد"
                },
                {
                    name: "الرسوم السنوية",
                    "value": "لا يوجد"
                },
                {
                    name: "الرسوم السنوية",
                    "value": "لا يوجد"
                },
                {
                    name: "الرسوم السنوية",
                    "value": "لا يوجد"
                },
            ]
            ,
            modalImg: null,
            show: false,
            img_url: BASE_URL + '/uploads/',

        }
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.toggleCollapse1 = this.toggleCollapse1.bind(this);
        this.firstCollapseHandler = this.firstCollapseHandler.bind(this);
        this.secondCollapseHandler = this.secondCollapseHandler.bind(this);
    }
    //  handleSelect (selectedIndex, e) {

    //      console.log("handle")
    //     this.setState({index:selectedIndex});
    //   }
    prevRatingsHandler(rating) {
        this.scrollToTop();
        this.setState({
            loading: false,
            prevRating: rating,
            ratingsImagesArr: JSON.parse(rating.images),
            ratingsMp3Arr: JSON.parse(rating.mp3),
            ratingsDocumentsArr: JSON.parse(rating.documents),
            ratingsVideosArr: JSON.parse(rating.videos),
            ratingsYoutubeArr: rating.youtube,
        })
    }

    firstCollapseHandler(value) {
        // console.log("value from firstCollapseHandler", value)
        let index = this.props.multiCategory[0].multi_categories_many.findIndex(category => category.id === value);
        this.props.actions.setFirstCollapseIndex(index);

        this.props.actions.setFirstCollapseId(value);
        this.props.match.url.includes('general-tips') ?
            this.props.history.push('/compare-and-rate/category/general-tips/' + parseInt(this.props.match.params.branchId) + '/' + value)
            :
            this.props.history.push('/compare-and-rate/category/previous-ratings/' + parseInt(this.props.match.params.branchId) + '/' + value)
    }

    secondCollapseHandler(value) {
        // console.log("value from secondCollapseHandler", value)

        let index = this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many.findIndex(category => category.id === value.id);
        this.props.actions.setSecondCollapseIndex(index);
        this.props.actions.setSecondCollapseId(value.id);
        this.props.match.url.includes('general-tips') ?
            this.props.history.push('/compare-and-rate/category/general-tips/' + parseInt(this.props.match.params.branchId) + '/' + value.id)
            :
            this.props.history.push('/compare-and-rate/category/previous-ratings/' + parseInt(this.props.match.params.branchId) + '/' + value.id)
    }

    handleShow = (value) => {
        // console.log("val", value)
        this.setState({
            showModalImg: true,
            modalImg: value
        })
    }

    handleClose = () => {
        this.setState({ showModalImg: false });
    }

    toggleCollapse() {
        if (this.state.arrow === this.state.arrowDown) {
            this.setState({
                menuOpen: true,
                arrow: this.state.arrowUp
            });
        } else {
            this.setState({
                menuOpen: false,
                arrow: this.state.arrowDown
            });
        }
    }

    toggleCollapse1() {
        if (this.state.arrow1 === this.state.arrowDown1) {
            this.setState({
                menuOpen1: true,
                arrow1: this.state.arrowUp1
            });
        } else {
            this.setState({
                menuOpen1: false,
                arrow1: this.state.arrowDown1
            });
        }
    }

    initSecondCollapse() {
        this.props.actions.setSecondCollapseIndex(0);
        this.props.actions.setSecondCollapseId(0);
    }

    componentDidUpdate(prevProps) {
        // console.log(" prevProps.match.url", prevProps.match.url);
        // console.log(" this.props.match.url", this.props.match.url);

        if (this.props.match.url.includes('general-tips')) {
            // if (parseInt(prevProps.match.params.id) === parseInt(this.props.match.params.id) && prevProps.match.url !== this.props.match.url) {
            //     console.log("if")
            //     this.getGeneralTips(parseInt(this.props.match.params.id));
            // }
            // else if (parseInt(prevProps.match.params.id) !== parseInt(this.props.match.params.id)) {

            //     console.log("else")
            //     console.log(parseInt(prevProps.match.params.id), parseInt(this.props.match.params.id))

            //     this.getGeneralTips(parseInt(this.props.match.params.id))
            // }
            if (prevProps.match.url !== this.props.match.url) {
                // console.log("if")
                this.getGeneralTips(parseInt(this.props.match.params.id ? this.props.match.params.id : this.props.match.params.branchId));
            }
        }
        if (this.props.match.url.includes('previous-ratings')) {
            if (parseInt(prevProps.match.params.id) === parseInt(this.props.match.params.id) && prevProps.match.url !== this.props.match.url) {
                this.getPrevRatings(parseInt(this.props.match.params.id));
            }
            else if (parseInt(prevProps.match.params.id) !== parseInt(this.props.match.params.id)) {
                this.getPrevRatings(parseInt(this.props.match.params.id));
            }
        }
    }

    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scrollToTop() {
        // console.log("scroll to")
        let intervalId = setInterval(this.scrollStep.bind(this), 16.66);
        this.setState({ intervalId: intervalId });
    }

    componentDidMount() {
        // console.log("didi")
        this.scrollToTop();
        this.props.navigationActions.getPageInfoApi('/compare-and-rate/category').then(
            () => {
                // console.log("getPageInfoApi")

                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                this.props.actions.getMultiCategoriesByIdWithEachSub(parseInt(this.props.match.params.branchId)).then(() => {
                    // console.log("getMultiCategoriesByIdWithEachSub")

                    this.props.actions.setFirstCollapseId(parseInt(this.props.match.params.id));
                    let index = 0;
                    let parentCat;
                    for (let i = 0; i < this.props.multiCategory.length; i++) {
                        index = this.props.multiCategory[i].multi_categories_many.findIndex(category => category.id === parseInt(this.props.match.params.id));
                    }
                    if (index === -1) {
                        for (let i = 0; i < this.props.multiCategory.length; i++) {
                            this.props.multiCategory[i].multi_categories_many.map(oneCat => {
                                if (oneCat.multi_categories_many.length > 0) {
                                    index = oneCat.multi_categories_many.findIndex(category => category.id === parseInt(this.props.match.params.id));
                                    if (index !== -1) {
                                        parentCat = oneCat;
                                    }
                                }
                            })
                        }
                    }
                    // console.log(parentCat)
                    if (parentCat !== undefined) {
                        for (let i = 0; i < this.props.multiCategory.length; i++) {
                            index = this.props.multiCategory[i].multi_categories_many.findIndex(category => category.id === parentCat.id);
                        }
                        // console.log("if",parentCat)

                        this.props.actions.setFirstCollapseIndex(index);
                    }
                    else {
                        // console.log("else",parentCat)

                        // console.log(index)

                        this.props.actions.setFirstCollapseIndex(index);
                    }
                })
                this.props.match.url.includes('general-tips') ?
                    //in the case of general tips page
                    apiFetch(BASE_URL + '/api/multiCategories/getGeneralTips?multi_categories_id=' + parseInt(this.props.match.params.id), {
                        method: "GET",
                        withCredentials: true,
                        headers: {
                            "Accept": "application/json"
                        }
                    })
                        .then(response => response.json())
                        .then(response => {
                            // console.log("res", response)
                            // console.log("include ", this.props.match.url.includes('general-tips'))
                            // console.log("routes", this.state.routes)
                            let routesCopy = [...this.state.routes]
                            let newRoutes = routesCopy.concat({ name: "نصائح عامة", name_en: "General Tips", link: this.props.match.url });
                            if (response.length === 0) {
                                // console.log("response.length === 0")
                                this.setState({
                                    loading: false,
                                    tips: "",
                                    imagesArr: [],
                                    mp3Arr: [],
                                    documentsArr: [],
                                    videosArr: [],
                                    youtubeArr: []
                                })
                            }

                            this.setState({

                                loading: false,
                                tips: response,
                                imagesArr: JSON.parse(response.images),
                                mp3Arr: JSON.parse(response.mp3),
                                documentsArr: JSON.parse(response.documents),
                                videosArr: JSON.parse(response.videos),
                                youtubeArr: response.youtube,
                                routes: newRoutes
                            })

                        })
                    :
                    /// in the case of previous evaluations
                    apiFetch(BASE_URL + '/api/multiCategories/getPreviousEvaluations?multi_categories_id=' + parseInt(this.props.match.params.id), {
                        method: "GET",
                        withCredentials: true,
                        headers: {
                            "Accept": "application/json"
                        }
                    })
                        .then(response => response.json())
                        .then(response => {
                            if (response.length === 0) {
                                this.setState({
                                    loading: false,
                                    prevRatings: [],
                                    prevRating: "",
                                    ratingsImagesArr: [],
                                    ratingsMp3Arr: [],
                                    ratingsDocumentsArr: [],
                                    ratingsVideosArr: [],
                                    ratingsYoutubeArr: []
                                })
                            }

                            this.setState({
                                loading: false,
                                prevRatings: response,
                                prevRating: response[0],
                                ratingsImagesArr: JSON.parse(response[0].images),
                                ratingsMp3Arr: JSON.parse(response[0].mp3),
                                ratingsDocumentsArr: JSON.parse(response[0].documents),
                                ratingsVideosArr: JSON.parse(response[0].videos),
                                ratingsYoutubeArr: response[0].youtube
                            })
                        })
            }
        )
    }

    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }


    getGeneralTips(value) {
        // console.log("v", value)
        apiFetch(BASE_URL + '/api/multiCategories/getGeneralTips?multi_categories_id=' + value, {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json"
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.length === 0) {
                    this.setState({
                        loading: false,
                        tips: "",
                        imagesArr: [],
                        mp3Arr: [],
                        documentsArr: [],
                        videosArr: [],
                        youtubeArr: []
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        tips: response,
                        imagesArr: JSON.parse(response.images),
                        mp3Arr: JSON.parse(response.mp3),
                        documentsArr: JSON.parse(response.documents),
                        videosArr: JSON.parse(response.videos),
                        youtubeArr: response.youtube
                    })
                }
            })
    }

    getPrevRatings(value) {
        /// in the case of previous evaluations
        apiFetch(BASE_URL + '/api/multiCategories/getPreviousEvaluations?multi_categories_id=' + value, {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json"
            }
        })
            .then(response => response.json())
            .then(response => {
                let routesCopy = [...this.state.routes];
                routesCopy.splice(3);
                let newRoutes = routesCopy.concat({ name: "التقييمات السابقة", name_en: "Previous Ratings", link: this.props.match.url });
                if (response.length === 0) {
                    this.setState({
                        loading: false,
                        prevRatings: [],
                        prevRating: "",
                        ratingsImagesArr: [],
                        ratingsMp3Arr: [],
                        ratingsDocumentsArr: [],
                        ratingsVideosArr: [],
                        ratingsYoutubeArr: []
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        prevRatings: response,
                        prevRating: response[0],
                        ratingsImagesArr: JSON.parse(response[0].images),
                        ratingsMp3Arr: JSON.parse(response[0].mp3),
                        ratingsDocumentsArr: JSON.parse(response[0].documents),
                        ratingsVideosArr: JSON.parse(response[0].videos),
                        ratingsYoutubeArr: response[0].youtube,
                        routes: newRoutes
                    })
                }
            })
    }

    render() {

        let dataArr = this.props.match.url.includes('general-tips') ?
            this.state.videosArr.concat(this.state.youtubeArr)
            :
            this.state.ratingsVideosArr.concat(this.state.ratingsYoutubeArr);

        dataArr = dataArr.length === 1 && dataArr[0] === "" ? [] : dataArr;
        let generatTipsContent = this.props.match.url.includes('general-tips')
            ?

            <>{

                this.props.page &&
                <div className="generalTips-container" >
                    <ImgHeader page={this.props.page} />
                    <BreadCrumb
                        routes={this.state.routes}
                        filterbyYear={false}
                        filterbySubject={false} />
                    {this.state.loading && <span><Translate text="Loading" /> ...</span>}

                    {
                        !this.state.loading &&
                        this.props.multiCategory.length > 0 &&
                        <Container>
                            <Row>
                                <Col md={12} sm={12} className="TopMenu">
                                    <button className="btn submit-cpa-btn active"><Translate text="generalTips" /></button>

                                    <button onClick={() => this.props.history.push('/compare-and-rate/shareYourRating/' + parseInt(this.props.match.params.branchId) + '/' + this.props.firstCollapseId)} className="btn submit-cpa-btn"><Translate text="shareYourRating" /></button>
                                    <button onClick={() => this.props.history.push('/compare-and-rate/category/previous-ratings/' + parseInt(this.props.match.params.branchId) + '/' + this.props.firstCollapseId)} className="btn submit-cpa-btn"><Translate text="thePreviousRatings" /></button>
                                    <button onClick={() => this.props.history.push('/compare-and-rate/categories/' + parseInt(this.props.match.params.branchId) + '/' + this.props.firstCollapseId)} className="btn submit-cpa-btn"><Translate text="compare" /></button>
                                </Col>
                                {
                                    <CpaSharing active={this.state.showSharingIcons ? 'active' : ''} />
                                }
                            </Row>

                            <Row className="generalTips">
                                {
                                    this.props.multiCategory &&
                                    this.props.multiCategory[0] &&
                                    this.props.multiCategory[0].multi_categories_many &&
                                    this.props.multiCategory[0].multi_categories_many.length > 0 &&
                                    this.props.multiCategory[0].multi_categories_many[0] !== null
                                    &&
                                    // this.props.multiCategory[0].multi_categories_many[0].multi_categories_many.length > 0 &&

                                    <Col xs={12} md={3}>
                                        <h1>{this.props.lng === "ar" ? this.props.multiCategory[0].name : this.props.multiCategory[0].name_en}</h1>
                                        <div className="sideMenu">
                                            <label><Translate text="types" /></label>
                                            <Button
                                                onClick={this.toggleCollapse}
                                                variant="menu">
                                                <div className="btnContent">
                                                    {
                                                        this.props.firstCollapseIndex === -1 ?
                                                            <label className="sideMenu-label"><Translate text="pleaseChoose" /></label> :
                                                            this.props.multiCategory[0].multi_categories_many.length > 0 &&
                                                                this.props.lng === "ar" ?
                                                                this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].name :
                                                                this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].name_en
                                                        // <div>no</div>
                                                    }
                                                    <i className={this.state.arrow}></i>
                                                </div>
                                            </Button>
                                            <Collapse in={this.state.menuOpen}>
                                                {
                                                    this.props.multiCategory &&
                                                    this.props.multiCategory.length > 0 &&
                                                    this.props.multiCategory[0].multi_categories_many &&
                                                    this.props.multiCategory[0].multi_categories_many.length > 0 &&
                                                    <div id="menuCollapseArea">
                                                        {
                                                            this.props.multiCategory &&
                                                            this.props.multiCategory.length > 0 &&
                                                            this.props.multiCategory[0].multi_categories_many.map(value => {
                                                                return (
                                                                    <Button key={value.id}
                                                                        onClick={() => {
                                                                            this.firstCollapseHandler(value.id),
                                                                                this.toggleCollapse()
                                                                        }
                                                                        }
                                                                        variant="subMenu">
                                                                        {this.props.lng === 'ar' ? <p>{value.name}</p> : <p>{value.name_en}</p>}
                                                                    </Button>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                            </Collapse>


                                            {

                                                this.props.multiCategory &&
                                                    this.props.multiCategory.length > 0 &&

                                                    this.props.multiCategory[0].multi_categories_many &&
                                                    this.props.multiCategory[0].multi_categories_many.length > 0 &&

                                                    this.props.firstCollapseIndex !== -1 &&
                                                    this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many &&
                                                    this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many.length > 0 &&

                                                    this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[0].multi_categories_many &&
                                                    this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[0].multi_categories_many.length > 0
                                                    ?
                                                    <>
                                                        <label><Translate text="departements" /></label>



                                                        <Button
                                                            onClick={this.toggleCollapse1}
                                                            variant="menu">
                                                            <div className="btnContent">
                                                                {
                                                                    this.props.multiCategory[0].multi_categories_many.length > 0 &&
                                                                        this.props.firstCollapseIndex !== -1 &&
                                                                        this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[0].multi_categories_many.length > 0 &&
                                                                        this.props.actions.setSecondCollapseId(this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[this.props.secondCollapseIndex].id) &&

                                                                        this.props.lng === "ar" ?
                                                                        this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[this.props.secondCollapseIndex].name :
                                                                        this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[this.props.secondCollapseIndex].name_en
                                                                }
                                                                <i className={this.state.arrow1}></i>
                                                            </div>
                                                        </Button>
                                                    </>
                                                    :
                                                    this.initSecondCollapse()
                                            }


                                            <Collapse in={this.state.menuOpen1}>
                                                <div id="menuCollapseArea">
                                                    {
                                                        this.props.multiCategory[0].multi_categories_many.length > 0 &&
                                                        this.props.firstCollapseIndex !== -1 &&
                                                        this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex] !== null &&
                                                        this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many.length > 0 &&
                                                        this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[0].multi_categories_many.length > 0 &&
                                                        (this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many.sort()).map(value => {
                                                            return (
                                                                <Button key={value.id}
                                                                    onClick={() => {
                                                                        this.secondCollapseHandler(value)
                                                                        this.toggleCollapse1()
                                                                    }
                                                                    }
                                                                    variant="subMenu">
                                                                    {this.props.lng === 'ar' ? <p>{value.name}</p> : <p>{value.name_en}</p>}
                                                                </Button>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Collapse>
                                        </div>
                                    </Col>
                                }

                                <Col xs={12} md={9}>
                                    <h3 className="generalInst">{localStorage.getItem('lng') === "ar" ? this.state.tips.title : this.state.tips.title_en}</h3>
                                    <p>{localStorage.getItem('lng') === "ar" ? ReactHtmlParser(this.state.tips.description) : ReactHtmlParser(this.state.tips.description_en)}</p>
                                </Col>
                            </Row>

                            <Row className="generalTips-videos">
                                <Col lg={6}>
                                    {
                                        this.state.imagesArr !== undefined &&
                                        this.state.imagesArr !== null &&
                                        this.state.imagesArr.length > 0 &&

                                        <Carousel
                                            //     interval={0} 
                                            //   //  controls={false}
                                            //   //  indicators={false}
                                            controls={this.state.imagesArr.length > 1}
                                            indicators={this.state.imagesArr.length > 1} >

                                            {/* {console.log(this.state.imagesArr)} */}
                                            {
                                                this.state.imagesArr.map((value, index) => {
                                                    return (
                                                        <Carousel.Item key={index}>
                                                            <img
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => this.handleShow(value)}
                                                                className="d-block w-100"
                                                                src={BASE_URL + '/uploads/' + value}
                                                                alt="First slide"
                                                            />
                                                        </Carousel.Item>
                                                    )
                                                })
                                            }
                                        </Carousel>


                                        //     <Carousel
                                        //     // className="d-block w-100 h-100"
                                        //     style={{width:"100% !important",height:"250px !important"}}

                                        //     >
                                        //              {
                                        //                  this.state.imagesArr.map((value, index) => {
                                        //                      return (
                                        //                          <Carousel.Item key={index}
                                        //     style={{width:"100% !important",height:"250px !important"}}

                                        //     // className="d-block w-100 h-100"

                                        //                          >
                                        //                              <img
                                        //                                  onClick={() => this.handleShow(value)}
                                        //                                 // className="d-block w-100 h-100"
                                        //     style={{display:"block !important",width:"200px !important",height:"250px !important"}}

                                        //                                  src={BASE_URL + '/uploads/' + value}
                                        //                                 alt="First slide"
                                        //                             />
                                        //                         </Carousel.Item>
                                        //                     )
                                        //                  })
                                        //              }
                                        //   </Carousel>

                                    }
                                </Col>
                            </Row>
                            {/* <div 
style={{margin:"auto"}}
>



{this.state.imagesArr !== undefined &&
                                        this.state.imagesArr !== null &&
                                        this.state.imagesArr.length > 0 &&
<Carousel 
style={{width:"80%",height:"500px",margin:"auto"}}
>

    {
         this.state.imagesArr.map((v,i)=>(

      <Carousel.Item key={i}>
        <img
         onClick={() => this.handleShow(v)}
         style={{objectFit:"fill",height:"500px"}}
         className="d-block w-100 "
          src={BASE_URL + '/uploads/' + v}
          alt={v}
        />
       
      </Carousel.Item>
         ))
    }
      
    </Carousel>
    }

</div> */}





                            {
                                dataArr !== undefined &&
                                dataArr !== null &&
                                dataArr.length > 0 &&

                                <GalleryImage show={this.handleShow} imgs={dataArr} />
                            }
                            {
                                this.state.mp3Arr.length > 0 &&
                                // this.state.videosArr.length > 0 ||
                                // this.state.youtubeArr.length > 0 &&
                                <>
                                    <h3 className="videoTitle"><Translate text="mp3" /></h3>
                                    <VideoCard tipsVideoCard={this.state.mp3Arr} />
                                </>
                            }

                            {
                                this.state.documentsArr.length > 0 &&
                                <PdfFiles files={this.state.documentsArr} />
                            }

                        </Container>
                    }
                    <Modal dialogClassName="annualReports-container" size="xl" show={this.state.showModalImg} onHide={() => this.handleClose()}>
                        <ContainerCard>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                                <div className="shareOn">
                                    <CpaSharing url={this.state.url + '/' + this.state.modalImg} />
                                </div>
                                <div className="img-body">
                                    <img src={this.state.url + '/' + this.state.modalImg} />
                                </div>
                            </Modal.Body>
                        </ContainerCard>
                    </Modal>
                </div>

            }
            </>
            :
            this.props.page &&
            <>
                <div className="generalTips-container" >
                    <ImgHeader page={this.props.page} />
                    <BreadCrumb
                        routes={this.state.routes}
                        filterbyYear={false}
                        filterbySubject={false} />
                    {this.state.loading && <span><Translate text="Loading" /> ...</span>}
                    {
                        !this.state.loading && this.props.multiCategory.length > 0 &&
                        <Container>
                            <Row>
                                <Col md={12} sm={12} className="TopMenu">
                                    {/* <div>xxxxxxxxxxxxxxxxxx</div> */}
                                    <button onClick={() => this.props.history.push('/compare-and-rate/category/general-tips/' + parseInt(this.props.match.params.branchId) + '/' + this.props.firstCollapseId)} className="btn submit-cpa-btn"><Translate text="generalTips" /></button>
                                    <button onClick={() => this.props.history.push('/compare-and-rate/shareYourRating/' + parseInt(this.props.match.params.branchId) + '/' + this.props.firstCollapseId)} className="btn submit-cpa-btn"><Translate text="shareYourRating" /></button>
                                    <button className="btn submit-cpa-btn active"><Translate text="thePreviousRatings" /></button>
                                    <button onClick={() => this.props.history.push('/compare-and-rate/categories/' + parseInt(this.props.match.params.branchId) + '/' + this.props.firstCollapseId)} className="btn submit-cpa-btn"><Translate text="compare" /></button>
                                </Col>
                                {
                                    <CpaSharing active={this.state.showSharingIcons ? 'active' : ''} />
                                }
                            </Row>

                            <Row className="generalTips">
                                {
                                    // this.props.multiCategory &&
                                    //  this.props.multiCategory[0].multi_categories_many[0] &&
                                    //  this.props.multiCategory[0].multi_categories_many[0].multi_categories_many.length > 0 &&

                                    this.props.multiCategory &&
                                    this.props.multiCategory[0] &&
                                    this.props.multiCategory[0].multi_categories_many &&
                                    this.props.multiCategory[0].multi_categories_many.length > 0 &&
                                    this.props.multiCategory[0].multi_categories_many[0] !== null &&

                                    <Col xs={12} md={3}>
                                        <h1>{this.props.lng === "ar" ? this.props.multiCategory[0].name : this.props.multiCategory[0].name_en}</h1>
                                        <div className="sideMenu">
                                            <label><Translate text="types" /></label>
                                            <Button
                                                onClick={this.toggleCollapse}
                                                variant="menu">
                                                {/* the first collapse */}
                                                <div className="btnContent">
                                                    {
                                                        this.props.firstCollapseIndex === -1 ?
                                                            <label className="sideMenu-label"><Translate text="pleaseChoose" /></label> :
                                                            this.props.multiCategory[0].multi_categories_many.length > 0 &&
                                                                this.props.lng === "ar" ?
                                                                this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].name :
                                                                this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].name_en
                                                    }
                                                    <i className={this.state.arrow}></i>
                                                </div>
                                            </Button>
                                            <Collapse in={this.state.menuOpen}>
                                                {
                                                    this.props.multiCategory &&
                                                    this.props.multiCategory.length > 0 &&
                                                    this.props.multiCategory[0].multi_categories_many &&
                                                    this.props.multiCategory[0].multi_categories_many.length > 0 &&

                                                    <div id="menuCollapseArea">
                                                        {
                                                            (this.props.multiCategory[0].multi_categories_many.sort()).map(value => {
                                                                return (
                                                                    <Button key={value.id}
                                                                        onClick={() => {
                                                                            this.firstCollapseHandler(value.id),
                                                                                this.toggleCollapse()
                                                                        }
                                                                        }
                                                                        variant="subMenu">
                                                                        {this.props.lng === 'ar' ? <p>{value.name}</p> : <p>{value.name_en}</p>}
                                                                    </Button>
                                                                )
                                                            })}
                                                    </div>
                                                }
                                            </Collapse>

                                            {
                                                this.props.multiCategory &&
                                                    this.props.multiCategory.length > 0 &&

                                                    this.props.multiCategory[0].multi_categories_many &&
                                                    this.props.multiCategory[0].multi_categories_many.length > 0 &&

                                                    this.props.firstCollapseIndex !== -1 &&
                                                    this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many &&
                                                    this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many.length > 0 &&

                                                    this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[0].multi_categories_many &&
                                                    this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[0].multi_categories_many.length > 0

                                                    ?
                                                    <>
                                                        <label><Translate text="departements" /></label>
                                                        <Button
                                                            onClick={this.toggleCollapse1}
                                                            variant="menu">
                                                            <div className="btnContent">
                                                                {
                                                                    this.props.multiCategory[0].multi_categories_many.length > 0 &&
                                                                        this.props.firstCollapseIndex !== -1 &&
                                                                        this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[0].multi_categories_many.length > 0 &&
                                                                        this.props.actions.setSecondCollapseId(this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[this.props.secondCollapseIndex].id)

                                                                        && this.props.lng === "ar" ?
                                                                        this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[this.props.secondCollapseIndex].name :
                                                                        this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[this.props.secondCollapseIndex].name_en
                                                                }
                                                                <i className={this.state.arrow1}></i>
                                                            </div>
                                                        </Button>
                                                    </>
                                                    :
                                                    this.initSecondCollapse()
                                            }
                                            <Collapse in={this.state.menuOpen1}>
                                                <div id="menuCollapseArea">
                                                    {
                                                        this.props.multiCategory[0].multi_categories_many.length > 0 &&
                                                        this.props.firstCollapseIndex !== -1 &&
                                                        this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex] !== null &&
                                                        this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many.length > 0 &&
                                                        this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[0].multi_categories_many.length > 0 &&
                                                        (this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many.sort()).map(value => {
                                                            return (
                                                                <Button key={value.id}
                                                                    onClick={() => {
                                                                        this.secondCollapseHandler(value)
                                                                        this.toggleCollapse1()
                                                                    }
                                                                    }
                                                                    variant="subMenu">
                                                                    {this.props.lng === 'ar' ? <p>{value.name}</p> : <p>{value.name_en}</p>}
                                                                </Button>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Collapse>
                                        </div>
                                    </Col>
                                }
                                <Col xs={12} md={9}>
                                    <h3 className="generalInst">{localStorage.getItem('lng') === "ar" ? this.state.prevRating.title : this.state.prevRating.title_en}</h3>
                                    <p>{localStorage.getItem('lng') === "ar" ? ReactHtmlParser(this.state.prevRating.description) : ReactHtmlParser(this.state.prevRating.description_en)}</p>
                                </Col>
                            </Row>
                            <Row className="generalTips-videos">
                                <Col lg={6}>
                                    {
                                        this.state.ratingsImagesArr !== undefined &&
                                        this.state.ratingsImagesArr !== null &&
                                        this.state.ratingsImagesArr.length > 0 &&
                                        <Carousel interval={0} controls={this.state.ratingsImagesArr.length > 1} indicators={this.state.ratingsImagesArr.length > 1} >
                                            {
                                                this.state.ratingsImagesArr.map((value, index) => {
                                                    return (
                                                        <Carousel.Item key={index}>
                                                            <img
                                                                style={{ cursor: "pointer" }}

                                                                onClick={() => this.handleShow(value)}

                                                                className="d-block"
                                                                src={BASE_URL + '/uploads/' + value}
                                                                alt="First slide"
                                                            />
                                                        </Carousel.Item>
                                                    )
                                                })
                                            }
                                        </Carousel>
                                    }
                                </Col>
                            </Row>
                            {
                                dataArr !== undefined &&
                                dataArr.length > 0 &&

                                <GalleryImage show={this.handleShow} imgs={dataArr} />
                            }

                            {
                                this.state.ratingsMp3Arr.length > 0 &&
                                // this.state.ratingsVideosArr.length > 0 ||
                                // this.state.ratingsYoutubeArr.length > 0 &&
                                <h3 className="videoTitle"><Translate text="mp3" /></h3>
                            }

                            {
                                this.state.ratingsMp3Arr.length > 0 &&
                                // this.state.ratingsVideosArr.length > 0 ||
                                // this.state.ratingsYoutubeArr.length > 0 &&
                                <VideoCard tipsVideoCard={this.state.ratingsMp3Arr} />
                            }

                            {
                                this.state.ratingsDocumentsArr.length > 0 &&
                                <PdfFiles files={this.state.ratingsDocumentsArr} />
                            }

                        </Container>
                    }

                    <Modal dialogClassName="annualReports-container" size="xl" show={this.state.showModalImg} onHide={() => this.handleClose()}>
                        <ContainerCard>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                                <div className="shareOn">
                                    <CpaSharing url={this.state.url + '/' + this.state.modalImg} />
                                </div>
                                <div className="img-body">
                                    <img src={this.state.url + '/' + this.state.modalImg} />
                                </div>
                            </Modal.Body>
                        </ContainerCard>
                    </Modal>
                </div>
                {
                    this.state.prevRatings.length > 0 &&
                    <div className=" compare-landing-container active">
                        <div className="landing-row">
                            {this.state.prevRatings.map((value) => {
                                return (
                                    <span className={this.state.prevRating.id === value.id ? 'active' : null}
                                        key={value.id}
                                        onClick={() => {
                                            this.prevRatingsHandler(value)
                                        }}>
                                        {this.props.lng === "ar" ? value.title : value.title_en}
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                }
            </>
        return (generatTipsContent
        )
    }
}

GeneralTips.propTypes = {
    match: PropTypes.object,
    "match.params": PropTypes.object,
    "match.params.id": PropTypes.number,
    "match.url": PropTypes.string,
    "match.url.includes": PropTypes.func,
    "history": PropTypes.object,
    "history.push": PropTypes.func,
    multiCategory: PropTypes.array,
    "multiCategory[].multi_categories_many": PropTypes.array,
    "multiCategory[].multi_categories_many.findIndex": PropTypes.func,
    actions: PropTypes.object,
    "actions.setFirstCollapseIndex": PropTypes.func,
    "actions.setFirstCollapseId": PropTypes.func,
    firstCollapseIndex: PropTypes.number,
    "actions.setSecondCollapseId": PropTypes.func,
    "actions.setSecondCollapseIndex": PropTypes.func,
    "actions.getMultiCategoriesByIdWithEachSub": PropTypes.func,
    "multiCategory.length": PropTypes.number,
    secondCollapseId: PropTypes.number,
    firstCollapseId: PropTypes.number,
    "multiCategory[].multi_categories_many[].multi_categories_many": PropTypes.array,
    "multiCategory[].multi_categories_many[].multi_categories_many.length": PropTypes.number,
    lng: PropTypes.string,
    "multiCategory[].name": PropTypes.string,
    "multiCategory[].multi_categories_many.length": PropTypes.number,
    "multiCategory[].multi_categories_many.sort": PropTypes.func,
    secondCollapseIndex: PropTypes.number,
}
GeneralTips.propTypes = {
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired
};
function mapStateToProps(state) {
    return {
        user: state.user,
        lng: state.user.lng,
        multiCategory: state.compare_and_rate.multiCategories,
        firstCollapseIndex: state.compare_and_rate.firstCollapseIndex,
        firstCollapseId: state.compare_and_rate.firstCollapseId,
        secondCollapseId: state.compare_and_rate.secondCollapseId,
        secondCollapseIndex: state.compare_and_rate.secondCollapseIndex,
        page: state.navigation.page
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(GeneralTips)