import { apiFetch } from '../utils/http.helpers';
import {GET_FILTER_ARRAY} from '../constants/actionTypes';
import { BASE_URL } from '../constants/config';
export function getFiltersData() {
  return function (dispatch) {
    return apiFetch(BASE_URL+'/api/filters/index', {
      method: "GET",
      // withCredentials: false,
      headers: {
        "Accept": "application/json",
      },
    })
      .then(response => response.json())
      .then(res => {
        dispatch({ type: GET_FILTER_ARRAY.SUCCESS, payload: res });
      }).catch(() => {
      });
  };
}
