import {GET_WORLD_DAYS_DATA, GET_WORLD_DAY_PAGE} from '../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../initialState';

export default function worlddaysReducer(state = initialState.programs_and_activities.world_days, action) {
    switch (action.type) {
        case GET_WORLD_DAYS_DATA.SUCCESS:
          return objectAssign({}, state, {worldDaysArray: action.payload});
        case GET_WORLD_DAY_PAGE.SUCCESS:
          return objectAssign({}, state, {worldDayPage: action.payload});
        default:
          return state;
      }
}