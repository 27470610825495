import {
    CREATE_NEW_COMPLAIN,
    GET_CASES_OF_COMPLAINS,
    CREATE_NEW_ERSHEDNA_SHAKWA,
    GET_ERSHDNA_CATEGORY,
    GET_ERSHEDNA_SECTIONS,
    GET_ERSHEDNA_ORGANIZATIONS,
    GET_ERSHEDNA_PROBLEMS,
    REMOVE_GUIDE_US_PROPS,
    GET_ERSHDNA_SUBS,
    GET_ERSHDNA_CATEGORIES,
    GET_HEAR_YOU_TITLE_PARAGRAPH,
    GET_HEAR_YOU_DESCRIPTION_PARAGRAPH,
    GET_ACTIVITY_TYPE,
    GET_HEAR_YOU_COMPLAINT_PARAGRAPH,
    GET_DESCRIPTION_COMPLAINTS


} from '../../../constants/actionTypes';
import { apiFetch } from "../../../utils/http.helpers";
import { BASE_URL } from '../../../constants/config';

/**
 * Create a new Complain in Eshtaky section
 * @param data
 * @returns {function(*): Promise<any | never>}
 */
export const createComplain = (data) => {
    return function (dispatch) {
        return fetch(BASE_URL + '/api/eshtaky/complaint/create', {
            method: 'POST',
            // mode: "cors",
            headers: {
                // "Accept": "application/json",
                // "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
            },
            body: data
        })
            .then(res => res.json())
            .then(response => {
                dispatch({ type: CREATE_NEW_COMPLAIN.SUCCESS, payload: response });
            })
            .catch((error) => {
                throw error;
            });
    }
};

export const getSections = () => {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/ershdna/sections', {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
            }
        })
            .then(response => {
                if (response.status === 401)
                    throw new Error('unauthorized');
                return response.json();
            })
            .then(res => {
                dispatch({ type: GET_ERSHEDNA_SECTIONS.SUCCESS, payload: res });
            }).catch((error) => {
                throw error;
            });
    };
};

export const getProblems = (id) => {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/ershdna/problems/?id=' + id, {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
            }
        })
            .then(response => {
                if (response.status === 401)
                    throw new Error('unauthorized');
                return response.json();
            })
            .then(res => {
                dispatch({ type: GET_ERSHEDNA_PROBLEMS.SUCCESS, payload: res.problems });
            }).catch((error) => {
                throw error;
            });
    };
};

export const getOrganizations = (id) => {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/ershdna/organizations/?id=' + id, {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
            }
        })
            .then(response => {
                if (response.status === 401)
                    throw new Error('unauthorized');
                return response.json();
            })
            .then(res => {
                dispatch({ type: GET_ERSHEDNA_ORGANIZATIONS.SUCCESS, payload: res.organizations });
            }).catch((error) => {
                throw error;
            });
    };
};

export const createErshednaShakwa = (data) => {
    return function (dispatch) {
        return fetch(BASE_URL + '/api/ershdna/create', {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
            },
            body: data
        })
            .then(response => response.json())
            .then(res => {
                dispatch({ type: CREATE_NEW_ERSHEDNA_SHAKWA.SUCCESS, payload: res });
            }).catch((error) => {
                throw error;
            });
    }
};

export const removeGuideUsProps = () => {
    return function (dispatch) {
        let res = {
            problems: [],
            organizations: []
        }
        dispatch({ type: REMOVE_GUIDE_US_PROPS.SUCCESS, payload: res });
    }
}
/**
 * Get the data of the cases in Eshtay form
 * @returns {function(*): (Promise|Promise<T | never>|*|undefined)}
 */
export const getCasesOfComplains = () => {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/eshtaky/case/search', {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
            }
        })
            .then(response => {
                if (response.status === 401)
                    throw new Error('unauthorized');
                return response.json();
            })
            .then(res => {
                dispatch({ type: GET_CASES_OF_COMPLAINS.SUCCESS, payload: res });
            }).catch((error) => {
                throw error;
            });
    };
};
export const getErshdnaCategories = () => {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/ershdna/getErshdnaCategories', {
            method: "GET",
            // withCredentials: true,
            headers: {
                "Accept": "application/json",
                // "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
            }
        })
            .then(response => {
                if (response.status === 401)
                    throw new Error('unauthorized');
                return response.json();
            })
            .then(res => {
                dispatch({ type: GET_ERSHDNA_CATEGORIES.SUCCESS, payload: res });
                return res;
            }).catch((error) => {
                throw error;
            });
    };
};
export const getErshdnaCategory = (id) => {
    return function (dispatch) {
        return fetch(BASE_URL + '/api/ershdna/getErshdnaCategoriesData?ershdna_categories_id=' + id, {
            method: "GET",
            // withCredentials: true,
            headers: {
                "Accept": "application/json",
                // "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
            }
        })
            .then(response => {
                if (response.status === 401)
                    throw new Error('unauthorized');
                return response.json();
            })
            .then(res => {
                dispatch({ type: GET_ERSHDNA_CATEGORY.SUCCESS, payload: res });

                return res
            }).catch((error) => {
                throw error;
            });
    };
};
export const getErshdnaSectionsWithEachSub = () => {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/ershdna/getErshdnaSectionsWithEachSub', {
            method: "GET",
            // withCredentials: true,
            headers: {
                "Accept": "application/json",
                // "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
            }
        })
            .then(response => {
                if (response.status === 401)
                    throw new Error('unauthorized');
                return response.json();
            })
            .then(res => {
                dispatch({ type: GET_ERSHDNA_SUBS.SUCCESS, payload: res });
                return res;
            }).catch((error) => {
                throw error;
            });
    };
};

export const getHearYouTitleParagraph = () => {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/paragraph/getHearYouTitleParagraph', {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        })
            .then(response => response.json())
            .then(response => {
                dispatch({ type: GET_HEAR_YOU_TITLE_PARAGRAPH, payload: response })
            })
    }
}

export const getDescriptionComplaints = () => {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/paragraph/getDescriptionComplaints', {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        })
            .then(response => response.json())
            .then(response => {
                dispatch({ type: GET_DESCRIPTION_COMPLAINTS, payload: response })
            })
    }
}
export const getHearYouDescriptionParagraph = () => {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/paragraph/getHearYouDescriptionParagraph', {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        })
            .then(response => response.json())
            .then(response => {
                dispatch({ type: GET_HEAR_YOU_DESCRIPTION_PARAGRAPH, payload: response })
            })
    }
}

export const getHearYouComplaintParagraph = () => {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/paragraph/getHearYouComplaintParagraph', {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        })
            .then(response => response.json())
            .then(response => {
                dispatch({ type: GET_HEAR_YOU_COMPLAINT_PARAGRAPH, payload: response })
            })
    }
}

export const getActivityType = () => {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/eshtaky/activity/getEshtakyActivityType', {
            method: 'GET',
            headers: {
                "Accept": "application/json"
            }
        })
            .then(response => response.json())
            .then(response => {
                dispatch({ type: GET_ACTIVITY_TYPE, payload: response })
            })
    }
}