import { GET_RECOGNIZE_ARRAY, GET_ORGANIZE_PARAGRAPH } from '../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../initialState';

export default function organizeReducer(state = initialState.el_gam3ya.organize, action) {
    switch (action.type) {
        case GET_RECOGNIZE_ARRAY.SUCCESS:
            return objectAssign({}, state, {
                organizationsArray: action.payload,
            });
        case GET_ORGANIZE_PARAGRAPH.SUCCESS:            
            return objectAssign({}, state, {
                organizationsPara: action.payload
            })
        default:
            return state;
    }
}