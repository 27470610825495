import {GET_FILTER_ARRAY} from '../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';

export default function GerFiltersDataReduser (state = initialState.filters, action) {
    switch (action.type) {
        case GET_FILTER_ARRAY.SUCCESS:
          return objectAssign({}, state, {filterObject: action.payload});
        default:
          return state;
      }
}