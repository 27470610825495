import { POST_SENDAMESSAGE } from '../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../initialState';

export default function sendamessageReducer(state = initialState.contactUs.sendamessage.msg, action){
    switch (action.type){
        case POST_SENDAMESSAGE.SUCCESS:
            return objectAssign({}, state, {apiResponse: action.payload});
        default:
            return state;
    }
}