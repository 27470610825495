import { GET_MEMBERS } from '../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './../initialState';

export default function membersReducer(state = initialState.el_gam3ya.members, action) {
    // console.log(action.payload);
    switch (action.type) {
        case GET_MEMBERS:
            return objectAssign({}, state, { membersArray: action.payload })
        default:
            return state;
    }
}
