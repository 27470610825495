import React from 'react';
import './articaleCard.scss';
import { Card, Modal } from 'react-bootstrap';

import PropTypes from 'prop-types';
import CpaSharing from '../cpaSharing/cpaSharing';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { t } from 'i18next';
import { BASE_URL } from '../../../constants/config';
import DefaultPage from '../defaultPage/DefaultPage';
import ContainerCard from "../ContainerCard/containerCard";
import Slider from "react-slick";

class ArticaleCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lng: localStorage.getItem('lng'),
            url: BASE_URL + '/uploads/',
            modalImg: null,
            show: false,
            showModalImg: false,
            img_url: BASE_URL + '/uploads/',
        }
    }

    handleShow = (value) => {
        this.setState({
            showModalImg: true,
            modalImg: value
        })
    }

    handleClose = () => {
        this.setState({ showModalImg: false });
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: window.innerWidth <= 767 ? 1 : 2,
            slidesToScroll: 1
        };
        if (this.props && this.props.card && this.props.card.title && this.props.card.draft == 0) {
            let contentDescription =
                <div className="main-conten">
                    <p>{ReactHtmlParser(this.props.lng == "ar" ? this.props.card.description : this.props.card.description_en)}</p>
                </div>



            let carouselImages =
                this.props.card && this.props.card.attachments && JSON.parse(this.props.card.attachments).length > 0 &&
                    // <Carousel controls={this.props.card && this.props.card.attachments && JSON.parse(this.props.card.attachments).length > 0}>
                    //     {
                    //         this.props.card.attachments && JSON.parse(this.props.card.attachments).length > 0 &&
                    //         JSON.parse(this.props.card.attachments).map((value, index) => {
                    //             return (
                    //                 <Carousel.Item key={index}>
                    //                     <img src={this.state.url + value.download_link}
                    //                         style={{ cursor: "pointer" }}

                    //                         onClick={() => this.handleShow(value.download_link)}
                    //                     />
                    //                 </Carousel.Item>
                    //             )
                    //         })
                    //     }
                    // </Carousel>
                    // this.props.card && this.props.card.attachments && JSON.parse(this.props.card.attachments).length > 0 &&
                    JSON.parse(this.props.card.attachments).length >= (window.innerWidth <= 767 ? 1 : 2)
                    ?
                    <div className="info_graphic_body">
                        {
                            <Slider {...settings}>
                                {
                                    this.props.card.attachments && JSON.parse(this.props.card.attachments).length > 0 &&
                                    JSON.parse(this.props.card.attachments).map((attachment, index) => {
                                        return (
                                            <img key={index}
                                                src={this.state.url + attachment.download_link}
                                                // style={{ cursor: "pointer" }}
                                                onClick={() => this.handleShow(attachment.download_link)}
                                                // onClick={() => this.handleShow(attachment)}
                                                className="worldDaysWorkShopsImg"
                                                variant="top"
                                                // src={BASE_URL + "/uploads/" + attachment}
                                                style={{ display: "inline-block", height: "280px", objectFit: "fill" }} />
                                        )
                                    })
                                }
                            </Slider>
                        }
                    </div>
                    :
                    <>


                        {
                            this.props.card.attachments && JSON.parse(this.props.card.attachments).length > 0 &&
                            JSON.parse(this.props.card.attachments).map((attachment, index) => {
                                return (
                                    <div onClick={() => { this.handleShow(attachment.download_link) }} key={index} className="col col-md-4">
                                        <Card style={{ width: "18rem", height: "20rem", cursor: "pointer" }}>
                                            <Card.Img

                                                className="worldDaysWorkShopsImg" variant="top"
                                                src={this.state.url + attachment.download_link}
                                                // style={{ cursor: "pointer" }}
                                                onClick={() => this.handleShow(attachment.download_link)}
                                            // src={BASE_URL + "/uploads/" + attachment}
                                            />
                                        </Card>
                                    </div>
                                )
                            })
                        }


                    </>

            return (

                <div className="articaleCard-container">
                    <div className="main-header">
                        <div className="date_by">
                            <div className="row">
                                <div className="col-1">
                                    <img src={this.props.card.image != null ? this.state.url + this.props.card.image : this.state.url + 'Images/default_image.jpg'} />
                                </div>
                                <div className="col-12">
                                    <h5>{this.props.lng == "ar" ? this.props.card.title : this.props.card.title_en}</h5>
                                    <p className="date">
                                        <i className="icon-calendar"></i><span>{this.props.card.date}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <CpaSharing />
                    </div>
                    <div className="main-img">
                        <div className="row justify-content-center">
                            <div className="col-8">
                                {carouselImages}
                            </div>
                        </div>
                    </div>
                    {contentDescription}
                    <Modal dialogClassName="annualReports-container" size="xl" show={this.state.showModalImg} onHide={() => this.handleClose()}>
                        <ContainerCard>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                                <div className="shareOn">
                                    <CpaSharing url={this.state.url + '/' + this.state.modalImg} />
                                </div>
                                <div className="img-body">
                                    {/* {console.log("modal", this.state.modalImg)} */}
                                    <img src={this.state.url + '/' + this.state.modalImg} />
                                </div>
                            </Modal.Body>
                        </ContainerCard>
                    </Modal>
                </div>
            )
        }
        else return <DefaultPage header={false} />
    }
}
ArticaleCard.propTypes = {
    card: PropTypes.object,
    lng: PropTypes.object,
    match: PropTypes.string,
    url: PropTypes.string
};
function mapStateToProps(state) {
    return {
        lng: state.user.lng,
    };
}

export default withRouter(connect(mapStateToProps)(ArticaleCard));