import React from 'react';
import './newsCard.scss';
import PropTypes from 'prop-types';
// import Translate from '../translate/translate';
import { connect } from 'react-redux';
import moment from 'moment';
import { BASE_URL } from '../../../constants/config';
// let  img_url=BASE_URL+'/';
class NewsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        // console.log("prop", this.props)
        moment.locale(this.props.lng);
        return (
            <div className="newsCard-container bg-white shadow-sm" >
                <div className="card-img">
                    {/* (img_url+props.newscard.attachments[0].src )|| */}
                    <img src={this.props.newscard.image != null ? (BASE_URL + '/uploads/' + this.props.newscard.image) : (BASE_URL + '/Images/default_image.jpg')} />
                </div>
                <div className="card-content bg-white">
                    <div className="cpa-tooltip"><h4>{this.props.lng == "ar" ? this.props.newscard.title : this.props.newscard.title_en}</h4>
                        <span className="cpa-tooltiptext">{this.props.lng == "ar" ? this.props.newscard.title : this.props.newscard.title_en}</span>
                    </div>
                    <div className="card-viewData row">
                        <p className="col-8">
                            {this.props.newscard.date &&
                                <div>
                                    <i className="icon-calendar"></i><span>{moment(this.props.newscard.date).format('dddd Do MMMM YYYY')}</span>
                                </div>
                            }
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
NewsCard.propTypes = {
    newscard: PropTypes.object,
    lng: PropTypes.string.isRequired
};
function mapStateToProps(state) {
    return {
        lng: state.user.lng,
    };
}

export default connect(mapStateToProps)(NewsCard)
