import { apiFetch } from "../../utils/http.helpers";
import { GET_PROFILE_DATA, USER_LOGOUT } from "../../constants/actionTypes";
import { BASE_URL } from "../../constants/config";

export const getProfileData = () => {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/profile/show', {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
            }
        })
            .then(response => {
                if (response.status === 401) {
                    // console.log("un")
                    throw new Error('unauthorized');

                }
                return response.json();
            })
            .then(res => {
                // console.log("res", res)
                // console.log('user prof', res);
                // localStorage.setItem("profileData", res)
                // console.log(localStorage.getItem("profileData"))
                dispatch({ type: GET_PROFILE_DATA.SUCCESS, payload: res });
            }).catch((error) => {
                {
                    localStorage.removeItem('_cpaJwt');
                    localStorage.removeItem('id');
                    localStorage.removeItem('first_name');
                    localStorage.removeItem('last_name');
                    localStorage.removeItem('email');
                    localStorage.removeItem('city');
                    localStorage.removeItem('identification_number');
                    localStorage.removeItem('identification_path');
                    localStorage.removeItem('verified');
                    // localStorage.clear()
                    dispatch({ type: USER_LOGOUT.SUCCESS });

                    // console.log("err")
                    throw error;
                }
            });
    };
};


export const setProfileData = (data) => {
    return function () {
        // let id = localStorage.getItem('id')
        const formData = new FormData();
        Object.keys(data).forEach(d => formData.append(d, data[d]));
        formData.append("_method", 'put')
        return apiFetch(BASE_URL + '/api/profile/update', {
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')

            },
            body: formData
        })
            .then(response => {
                if (response.status === 401)
                    throw new Error('unauthorized');
                return response.json();
            })
            .then(res => {
                // console.log('user prof', res);

                if (res.code == 200) {

                    localStorage.setItem('first_name', res.first_name);
                    localStorage.setItem('last_name', res.last_name);
                } else {
                    throw new Error(res.message)
                }
                // dispatch({ type: GET_PROFILE_DATA.SUCCESS, payload: res });
            }).catch((error) => {
                // console.log("errr", error)
                throw error;
            });
    };
};