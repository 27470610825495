import { apiFetch } from '../utils/http.helpers';
import {POST_POST_FORM} from '../constants/actionTypes';
import { BASE_URL } from '../constants/config';
export function postPostForm(data) {
  return function (dispatch) {
    return apiFetch(BASE_URL+'/api/lka2ma_lbaredea/create', {
      method: "POST",
      // mode: "cors",
    //   withCredentials: true,
      headers: {
        "Accept": "application/json",
        'Content-Type': 'application/json',
        // "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(res => {
        dispatch({ type: POST_POST_FORM.SUCCESS, payload: res });
      }).catch((err) => {
        throw err
      });
  };
}