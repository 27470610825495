const fetchDefaults = require("fetch-defaults");

let headers = {};
if(localStorage.getItem('_cpaJwt') !== null && localStorage.getItem('_cpaJwt').length > 0) {
  headers = {"Authorization": "Bearer " + localStorage.getItem('_cpaJwt')};
}

export const apiFetch = fetchDefaults(fetch, {
  headers
});
