import React from 'react';
import { Form, Row, Col, Carousel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import validate from '../../../utils/validation';
import { BASE_URL } from '../../../constants/config';
import { Modal } from 'react-bootstrap';

import ContainerCard from "../../GlobalComponent/ContainerCard/containerCard"
import CpaSharing from '../../GlobalComponent/cpaSharing/cpaSharing';

import "./customPageContent.scss"
class CustomPageContent extends React.Component {
    constructor(props) {
        super(props);

        this.formRef = React.createRef();
        this.uploadRef = React.createRef();
        this.state = {
            form_data_errors: {},
            form_data: {},
            next_form: null,
            lng: localStorage.getItem('lng'),
            url: BASE_URL + '/uploads/',
            modalImg: null,
            show: false,
            showModalImg: false,
            img_url: BASE_URL + '/uploads/',
        }
    }

    componentDidMount() {
        this.initForm();


    }

    initForm() {
        let fieldsTypes = [];
        if (this.props.form !== null && this.props.form.form_fields !== undefined) {
            this.props.form.form_fields.map(item => {
                fieldsTypes.push(this.props.form.name + '_' + item.key);
            });
        }
        let typesObj = {};
        let typesObj_error = {};
        let temp = {};
        let temp_error = {};
        if (fieldsTypes.length !== 0) {
            fieldsTypes.map(field => {
                temp = { [field]: { value: '', id: '' } }
                Object.assign(typesObj, temp)
                temp_error = { [field]: null }
                Object.assign(typesObj_error, temp_error)
            })
            let nextForm = this.props.form.form !== undefined ? this.props.form.form : null;
            this.setState({ form_data: typesObj, form_data_errors: typesObj_error, next_form: nextForm }, () => {
                this.props.checkExsitNext()
            })
        }

    }

    nextButtonHandler() {
        let fieldsTypes = [];
        let answers = [];
        let itemKey = null;
        if (this.props.form !== null) {
            this.props.form.form_fields.map(item => {
                fieldsTypes.push(this.props.form.name + '_' + item.key);
                if (item.type === 'conditional_question') {
                    answers = item.answers;
                    itemKey = this.props.form.name + '_' + item.key
                }
            });
        }
        let typesObj = {};
        let typesObj_error = {};
        let temp = {};
        let temp_error = {};
        if (fieldsTypes.length !== 0) {
            fieldsTypes.map(field => {
                temp = {
                    [field]: {
                        value: this.props.nextFormData !== null && this.props.nextFormData !== undefined ? this.props.nextFormData[field].value : '',
                        id: this.props.nextFormData !== null && this.props.nextFormData !== undefined ? this.props.nextFormData[field].id : ''
                    }
                }
                Object.assign(typesObj, temp)
                temp_error = { [field]: null }
                Object.assign(typesObj_error, temp_error)
            })
            let nextForm = this.props.form.form !== undefined ? this.props.form.form :
                this.props.nextFormData !== null ? answers.find(answer => answer.answer === temp[itemKey].value).form : null;
            this.setState({ form_data: typesObj, form_data_errors: typesObj_error, next_form: nextForm }, () => {
                this.props.checkExsitNext()
                window.scrollTo({
                    top: this.formRef.current.offsetTop - 66,
                    left: 100,
                    behavior: 'smooth'
                })
            })
        }
    }

    prevButtonHandler() {
        let fieldsTypes = [];
        if (this.props.form !== null) {
            this.props.form.form_fields.map(item => {
                fieldsTypes.push(this.props.form.name + '_' + item.key);
            });
        }
        let typesObj = {};
        let typesObj_error = {};
        let temp = {};
        let temp_error = {};
        if (fieldsTypes.length !== 0) {
            fieldsTypes.map(field => {
                temp = { [field]: { value: this.props.formData[field].value, id: this.props.formData[field].id } }
                Object.assign(typesObj, temp)
                temp_error = { [field]: null }
                Object.assign(typesObj_error, temp_error)
            })
            let nextForm = this.props.nextForm !== undefined ? this.props.nextForm : null;
            this.setState({ form_data: typesObj, form_data_errors: typesObj_error, next_form: nextForm }, () => {
                window.scrollTo({
                    top: this.formRef.current.offsetTop - 66,
                    left: 100,
                    behavior: 'smooth'
                })
            })
        }
    }
    checkExsitContentNext = () => {
        let next = this.state.next_form;

        if (this.props.form.form) {
            next = this.props.form.form
        }
        // if (this.props && this.props.form && this.props.form.form_fields && this.props.form.form_fields.length && this.props.form.form_fields.some(el => el.type == "conditional_question")) {

        // }
        this.setState({ next_form: next })
        return next;
    }
    uploadFiles = (item, value) => {


        // let fileURl = URL.createObjectURL(value.target.files[0]);
        let fileURl = value.target.files[0]
        const obj = {};
        const obj_errors = {};
        for (let key in this.state.form_data) {
            obj[key] = { ...this.state.form_data[key] }
        }
        for (let key in this.state.form_data_errors) {
            obj_errors[key] = this.state.form_data_errors[key]
        }
        obj[this.props.form.name + '_' + item.key].value = fileURl
        obj[this.props.form.name + '_' + item.key].id = item.id
        obj_errors[this.props.form.name + '_' + item.key] = false
        if (
            // value.target.files[0].type.split('/')[0] === item.attachment_type &&
            value.target.files[0].size <= (item.attachment_size * 1024 * 1024)) {
            this.setState({ form_data: { ...obj }, form_data_errors: obj_errors })
        }
        else {
            alert(`file required ${item.attachment_type} with size ${item.attachment_size} Mega`)
            this.uploadRef.current.value = '';
        }
    }

    onChangeHandler = (item, value) => {
        //copying the state
        const obj = {};
        for (let key in this.state.form_data) {
            obj[key] = { ...this.state.form_data[key] }
        }
        const obj_errors = {};
        for (let key in this.state.form_data_errors) {
            obj_errors[key] = this.state.form_data_errors[key]
        }
        if (item.type === "checkbox") {
            let arrayValues = obj[this.props.form.name + '_' + item.key].value.split(',');
            let result = arrayValues.find(cbValue => cbValue === value);
            if (result) {
                arrayValues = arrayValues.filter(cbValue => cbValue !== result)
                obj[this.props.form.name + '_' + item.key].value = arrayValues.join();
            }
            else {
                let values = [obj[this.props.form.name + '_' + item.key].value];
                values.push(value)
                obj[this.props.form.name + '_' + item.key].value = values.join();
                obj[this.props.form.name + '_' + item.key].id = item.id;
                obj_errors[this.props.form.name + '_' + item.key] = false
            }
        }
        else {
            obj[this.props.form.name + '_' + item.key].value = value;
            obj[this.props.form.name + '_' + item.key].id = item.id;
            obj_errors[this.props.form.name + "_" + item.key] = false;
        }
        this.setState({ form_data: { ...obj }, form_data_errors: obj_errors })
    }

    conditionalQuestionHandler(item, value) {
        let next = this.state.next_form
        let selectedAnswer = item.answers.find(itemAnswer => itemAnswer.answer === value);
        if (selectedAnswer) {
            next = selectedAnswer.form
        }
        this.setState({ next_form: next }, () => {
            this.onChangeHandler(item, value);
            this.props.onChangeConditionalQ();

        })

    }

    handleBlur = (item, value) => {
        if (item.required === 'required' && validate(item.type, value.value) === false) {
            let obj = {};
            obj = { ...this.state.form_data_errors };
            obj[this.props.form.name + "_" + item.key] = true;
            this.setState({
                form_data_errors: obj
            })
        }
    }

    validateForm() {
        let obj = {};
        obj = { ...this.state.form_data_errors }
        this.props.form.form_fields.map(field => {
            if (field.required === 'required'
                && obj[this.props.form.name + '_' + field.key] === null
                && this.state.form_data[this.props.form.name + '_' + field.key].value == '') {
                obj[this.props.form.name + '_' + field.key] = true;
            }
        })
        this.setState({
            form_data_errors: obj
        })
        let trueKeys = Object.keys(obj).filter(key => obj[key] === true).length
        return trueKeys > 0
    }
    nextFormForm = () => {

        return this.state.next_form;
    }
    handleShow = (value) => {
        this.setState({
            showModalImg: true,
            modalImg: value
        })
    }

    handleClose = () => {
        this.setState({ showModalImg: false });
    }
    render() {
        return (
            <>
                <Form style={{ textAlign: this.props.lng === 'ar' ? 'right' : 'left' }} ref={this.formRef}>
                    {
                        this.state.form_data !== null &&
                        this.props.form.form_fields !== undefined &&
                        this.props.form.form_fields.map(item => {
                            if (item.type === "drop_down") {
                                return (
                                    <div key={item.id} style={{ marginBottom: "1rem" }}>
                                        <Form.Label style={{ color: "#42c194" }}>{this.props.lng === 'ar' ? item.label : item.label_en}</Form.Label>
                                        <Form.Control
                                            isInvalid={this.state.form_data_errors[this.props.form.name + "_" + item.key] === true ? true : false}
                                            value={this.state.form_data[this.props.form.name + '_' + item.key] !== undefined && this.state.form_data[this.props.form.name + '_' + item.key].value}
                                            as="select"
                                            style={{ padding: '0 0.7rem !important' }}
                                            onChange={(e) => this.onChangeHandler(item, e.target.value)}>

                                            {/* {
                                                <style type="text/css">
                                                    {`.form-select-input{
                                                        
                                                         padding:0 !important; 

                                                     }`}
                                                </style>
                                            } */}
                                            <option>{this.props.lng === 'ar' ? 'برجاء الاختيار' : 'Please choose'}</option>
                                            {
                                                this.props.lng === 'ar' ?
                                                    JSON.parse(item.drop_downs_values).map(dropDown => {
                                                        return (
                                                            <option key={dropDown}>{dropDown}</option>
                                                        )
                                                    }) :
                                                    JSON.parse(item.drop_downs_values_en).map(dropDown => {
                                                        return (
                                                            <option key={dropDown}>{dropDown}</option>
                                                        )
                                                    })
                                            }
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">{this.props.lng === 'ar' ? 'برجاء الاختيار' : 'Please choose'}</Form.Control.Feedback>
                                    </div>
                                )
                            }
                            if (item.type === "conditional_question") {
                                let items = item.answers
                                return (
                                    <div key={item.id} className="test" style={{ marginBottom: "1rem" }}>
                                        <Form.Label style={{ color: "#42c194" }}>{this.props.lng === 'ar' ? item.label : item.label_en}</Form.Label>
                                        {
                                            items.map(answer => {
                                                return (
                                                    <Form.Group as={Row} key={answer}>
                                                        {
                                                            <style type="text/css">
                                                                {`.form-check-input{
                                                             bottom:${this.props.lng === 'ar' ? 2 : 20}%;
                                                            margin-left:${2}px !important;

                                                     }`}
                                                            </style>
                                                        }
                                                        {

                                                            <style type="text/css">
                                                                {`.form-check-label{
                                                            position:relative;
                                                            left:${this.props.lng === 'ar' ? 2 : 6}%;
                                                            margin-left:${5}px !important;
                                                            margin-right:${10}% !important

                                                        }`}
                                                            </style>
                                                        }
                                                        <Col sm={10}>
                                                            <Form.Check
                                                                isInvalid={this.state.form_data_errors[this.props.form.name + "_" + item.key] === true ? true : false}
                                                                inval
                                                                style={{ display: 'flex' }}
                                                                onChange={
                                                                    (e) =>
                                                                        this.conditionalQuestionHandler(item, e.target.value)

                                                                }
                                                                type='radio'
                                                                label={answer.answer}
                                                                name={item.label_en}
                                                                id={item.id}
                                                                checked={
                                                                    this.state.form_data[this.props.form.name + '_' + item.key] !== undefined &&
                                                                        this.state.form_data[this.props.form.name + '_' + item.key].value === answer.answer ? true : null
                                                                }
                                                                value={answer.answer}
                                                            />
                                                            {/* <Form.Control.Feedback type="invalid">{this.props.lng === 'ar' ? 'برجاء الاختيار' : 'Please choose'}</Form.Control.Feedback> */}
                                                        </Col>
                                                    </Form.Group>
                                                )
                                            })}
                                    </div>
                                )
                            }
                            if (item.type === "radio" || item.type === "checkbox") {
                                let items = this.props.lng === 'ar' ? item.drop_downs_values : item.drop_downs_values_en;
                                return (
                                    <div key={item.id} className="test" style={{ marginBottom: "1rem" }}>
                                        <Form.Label style={{ color: "#42c194" }}>{this.props.lng === 'ar' ? item.label : item.label_en}</Form.Label>
                                        {
                                            JSON.parse(items).map(dropDown => {
                                                return (
                                                    <Form.Group as={Row} key={dropDown}>
                                                        {
                                                            <style type="text/css">
                                                                {`.form-check-input{
                                                         bottom:${this.props.lng === 'ar' ? 2 : 20}%;
                                                         margin-left:${2}px !important; 

                                                     }`}
                                                            </style>
                                                        }
                                                        {

                                                            <style type="text/css">
                                                                {`.form-check-label{
                                                            position:relative;
                                                            left:${this.props.lng === 'ar' ? 2 : 6}%;
                                                            margin-left:${5}px !important;
                                                            margin-right:10% !important;
import CustomPageContent from './customPageContent';

                                                            
                                                        }`}
                                                            </style>
                                                        }
                                                        <Col sm={10}>
                                                            <Form.Check
                                                                isInvalid={this.state.form_data_errors[this.props.form.name + "_" + item.key] === true ? true : false}
                                                                style={{ display: 'flex' }}
                                                                onChange={(e) => this.onChangeHandler(item, e.target.value)}
                                                                type={item.type}
                                                                label={dropDown}
                                                                name={item.label_en}
                                                                id={dropDown}
                                                                checked={
                                                                    item.type === 'checkbox' &&
                                                                        this.state.form_data[this.props.form.name + '_' + item.key] !== undefined &&
                                                                        this.state.form_data[this.props.form.name + '_' + item.key].value.split(',').findIndex(singleValue => singleValue === dropDown) !== -1 ? true
                                                                        :
                                                                        this.state.form_data[this.props.form.name + '_' + item.key] !== undefined &&
                                                                            this.state.form_data[this.props.form.name + '_' + item.key].value === dropDown ? true : null
                                                                }
                                                                value={dropDown}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                )
                                            })}
                                    </div>
                                )
                            }

                            if (item.type === 'attachment') {
                                return (
                                    <div key={item.id} style={{ marginBottom: "1rem" }}>
                                        <div className="mb-3">
                                            <div className="mb-3">
                                                <label style={{ color: "#42c194" }}
                                                    htmlFor="formFileMultiple"
                                                    className="form-label">{item.label}</label>
                                                <input
                                                    ref={this.uploadRef}
                                                    onChange={(e) => this.uploadFiles(item, e)}
                                                    className="form-control" type="file"
                                                    accept={`${item.attachment_type == 'file' ? 'application/pdf' : item.attachment_type + '/*'}`}
                                                    size={item.attachment_size}
                                                    id="formFileMultiple"
                                                    multiple={true} />
                                                {
                                                    this.props.lng === 'ar' ?
                                                        <small>الملف المطلوب {item.attachment_type} بحجم {item.attachment_size} ميجا</small> :
                                                        <small>file required {item.attachment_type} with size {item.attachment_size} Mega</small>
                                                }

                                            </div>
                                        </div>
                                        {
                                            this.state.form_data_errors[this.props.form.name + "_" + item.key] === true &&
                                            <small style={{ color: 'red' }}>{this.props.lng === 'ar' ? `${item.label} مطلوب` : `${item.label_en} is required`}</small>
                                        }
                                    </div>
                                )
                            }
                            if (item.type === 'textarea') {
                                return (
                                    <div style={{ marginBottom: "1rem" }}>
                                        <Form.Label style={{ color: "#42c194" }}>{this.props.lng === 'ar' ? item.label : item.label_en}</Form.Label>
                                        <Form.Control
                                            value={this.state.form_data[this.props.form.name + '_' + item.key] !== undefined ? this.state.form_data[this.props.form.name + '_' + item.key].value : ''}
                                            onBlur={() => this.handleBlur(item, this.state.form_data[this.props.form.name + "_" + item.key])}
                                            isInvalid={this.state.form_data_errors[this.props.form.name + "_" + item.key] === true ? true : false}
                                            as={item.type}
                                            onChange={(e) => this.onChangeHandler(item, e.target.value)} type={item.type} name={item.key}
                                            required={item.required} />
                                        <Form.Control.Feedback type="invalid">{this.props.lng === 'ar' ? `${item.label} مطلوب` : `${item.label_en} is required`}</Form.Control.Feedback>
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div style={{ marginBottom: "1rem" }}>
                                        <Form.Label style={{ color: "#42c194" }}>{this.props.lng === 'ar' ? item.label : item.label_en}</Form.Label>
                                        <Form.Control
                                            isInvalid={this.state.form_data_errors[this.props.form.name + "_" + item.key] === true ? true : false}
                                            onBlur={() => this.handleBlur(item, this.state.form_data[this.props.form.name + "_" + item.key])}
                                            value={this.state.form_data[this.props.form.name + '_' + item.key] !== undefined ? this.state.form_data[this.props.form.name + '_' + item.key].value : ''}
                                            onChange={(e) => this.onChangeHandler(item, e.target.value)}
                                            type={item.type}
                                            name={item.key}
                                            required={item.required} />
                                        <Form.Control.Feedback type="invalid">{this.props.lng === 'ar' ? `${item.label} مطلوب` : `${item.label_en} is required`}</Form.Control.Feedback>
                                        {
                                            item.type === 'email' &&
                                            this.state.form_data[this.props.form.name + "_" + item.key] &&
                                            this.state.form_data[this.props.form.name + "_" + item.key].value !== '' &&
                                            <Form.Control.Feedback type="invalid">{this.props.lng === 'ar' ? `${item.label} غير صحيح` : `${item.label_en} is invalid`}</Form.Control.Feedback>
                                        }
                                    </div>
                                )
                            }
                        })
                    }
                </Form>
                {
                    JSON.parse(this.props.images) !== null &&
                    <Carousel className='row' interval={0} controls={true} indicators={true} >
                        {
                            JSON.parse(this.props.images).map((value, index) => {
                                return (
                                    <Carousel.Item key={index}>
                                        <img
                                            className="d-block col col-lg-8 col-md-12  m-auto"
                                            src={BASE_URL + '/uploads/' + value}
                                            alt="First slide"
                                            onClick={() => this.handleShow(value)}
                                            style={{ "cursor": "pointer" }}
                                        />
                                    </Carousel.Item>
                                )
                            })
                        }
                    </Carousel>
                }
                {
                    <Modal dialogClassName="annualReports-container" size="xl" show={this.state.showModalImg} onHide={() => this.handleClose()}>
                        <ContainerCard>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                                <div className="shareOn">
                                    <CpaSharing url={this.state.url + '/' + this.state.modalImg} />
                                </div>
                                <div className="img-body">

                                    <img src={this.state.url + '/' + this.state.modalImg} />
                                </div>
                            </Modal.Body>
                        </ContainerCard>
                    </Modal>
                }
            </>
        )
    }
}

CustomPageContent.propTypes = {
    lng: PropTypes.string.isRequired,
    form: PropTypes.object,
    'form.form': PropTypes.object,
    'form.form_fields': PropTypes.array,
    'form.form_fields.map': PropTypes.func,
    'onChangeConditionalQ': PropTypes.func,
    'checkExsitNext': PropTypes.func,
    'form.name': PropTypes.string,
    formData: PropTypes.object,
    'formData[].value': PropTypes.object,
    'formData[].id': PropTypes.number,
    nextForm: PropTypes.object,
    nextFormData: PropTypes.object,
    'nextFormData[].value': PropTypes.string,
    'nextFormData[].id': PropTypes.string,
    images: PropTypes.array,
};

export default CustomPageContent