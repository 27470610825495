const EN = {
    homePage: "Home",
    mainMenu: "Main Menu",
    ourPrograms: "Our Programs",
    moreAbout: "More About Us",
    compare1: "Compare",
    compare: "Compare",
    test: "Start",
    testIntro:
        "Knowing your rights as a consumer ensures your right and increases the quality of services provided to you.test your knowledge  to know   your right as a consumer and learn how to prevent fraud and manipulation of goods and services",
    testResult: "Test Result",
    servicesMayHelp: "Services May help you",
    servicesDes:
        "We provide a bundle of services and products dedicated for your help",
    LearnMore: "More ...",
    theMore: "More",
    last_updated: "Last updated at : ",
    showDetails: "Show details",
    MoreServices: "more about our services",
    moreServicesDesc: "Register with us and discover more services",
    actualStatistics: "actual statistics",
    statisticsDesc:
        "By collecting and presenting business data reports, we aim to support",
    consumer: "Consumer",
    followers: "Followers",
    services: "servises",
    join: "Join more than ",
    provides: "The association provides more than ",
    agree: "Ok",

    maillingList: "mailling List",
    maillingListDesc: "Join Our Mailing List",
    maillingListDesc2: "+100000 Subscribers",
    youremail: "write your email address",
    subscribe: "Subscribe",
    follow: "Follow Us",
    commonquestions: "FAQS",
    privacyPolicy: "Privacy Policy",
    TermsConditions: "Terms and Conditions",
    developedDesigned: "Developed and Designed by",
    seamlabs: "SeamLabs",
    copyright: "Copyrights Reserved To",
    cpa: "Consumer Protection Association",
    cpadesc:
        "The Consumer Protection Association is a civil society organization",

    comming: "This page Will be Coming Soon",
    notexistpage: "This Page Is Not Exist Now",
    home: "Home Page",
    goback: "Go Back to",

    signIn: "Sign in",
    signRequired:
        "Kindly enter your correct data to complete your registerations",
    email_signup: "E-mail address",
    email: "E-mail address : ",
    email_confirmation: "Confirm E-mail address",
    email_confirmation_mismatch: "Emails don't match",
    password_confirmation_mismatch: "Passwords don't match",
    password: "Password",
    password_confirmation: "Confirm password",
    remember: "Remember Me",
    forgotPass: "Forgot yout password",
    newUser: "New User",
    firstName: "First Name",
    fullName: "Full name",
    lastName: "Last Name",
    phoneNumber: "phoneNumber",
    userName: "User Name",
    currentUser: "Current User",
    changePassword: "Change Password",
    createAccount: "Create Account",
    association: "Association",
    aboutUs: "About us",
    specialties: "The Functions of the Association",
    associationOrganization: "Mandate",
    Members: "Top Management",
    ChairmanBoard: "Chairman",
    councilMembers: "Executive Council Members",
    reserveMembers: "Reserve Board members",
    generalSecretariat: "General Secretariat",
    committees: "Committees",
    standingCommittees: "Standing committees",
    temporaryCommittees: "Temporary committees",
    programsAndActivities: "Programs And Activities",
    programs: "Programs",
    recognize: "Know",
    versedIntro:
        "The Consumer Protection Association offers a range of services in a competent program",
    versed: "Skilled",
    subscribed: "subscribed",
    registered: "Registered",
    register: "Register",
    takeKnowledge: "Now you Know",
    consumerOffice: "Consumer Office",
    consumerConsultant: "Consumer Advisor",
    ldalel_lshaml_sub_category: "Comprehensive guide",
    episodes: "Episodes",
    testYourKnowledge: "Test Your Knowledge",
    legalAdvocacy: "Legal Advocacy",
    fileComplaint: "Submit a Complaint",
    pleaseSelect: "Please Select",
    selectIssue: "Complaint Topic",
    case: "case",
    downloadGuidear: "Download GuideBook For Each Ministry ",
    downloadGuideen: "Download GuideBook For Each Ministry ",
    // "downloadGuidear": "Download GuideBook For Each Ministry Arabic",
    // "downloadGuideen": "Download GuideBook For Each Ministry English",
    anotherCase: "Other",
    personalData: "Personal Data",
    city: "City",
    identityNumber: "Residency / Identity Number",
    AttachResidence: "Attach Residency/Identity Photocopy",
    ComplainantData: "Complaining About",
    NameFacilty: "Entity/Trader",
    activityType: "Activity Type",

    address: "Address : ",
    faciltyNumber: "Telephone",
    complainDetials: "Complainant Details",
    requests: "Requests",
    attachments: "Attachments",
    filedComplainBefore:
        "Have you previously submitted a complaint to one of the relevant entities?",
    nameSubmittingCompetent:
        "Name of the entity to which the complaint is submitted",
    complainNumber: "Complaint Number",
    complainDate: "Complaint Date",
    complain: "Submit",
    // "sideDrawerComplain": "Submit a complain",
    sideDrawerComplain: "Consumer Service Center",
    sector: "Sector",
    competent: "Competent",
    contracts: "Proposed Contractual Agreement Templates",
    impLinks: "Important Links",
    from: "From",
    to: "To",

    guidedUs: "Let's Hear You",
    guideUsLoggedIn: "Let's Hear You",
    guideUsLoggedOut: "Log in first to submit",
    notExist: "Not Exist",
    compareAndRate: "Compare And Rate",
    initiatives: "Initiatives",
    comprehensiveGuide: "Consumer Rights Comprehensive Guide",
    consumerAward: "Consumer Protection Award",
    platforms: "Platforms and Applications",
    worldDays: "International Days",
    shareYourRating: "Share Your Rating",
    thePreviousRatings: "Previous Ratings",

    world_days_workshops: "Workshops",
    info_graphic_view: "View the info graphic",
    related_news: "Related news",

    mediaCenter: "Media Center",
    news: "Recent News",
    data: "Statements",
    annualReports: "Annual Reports",
    MediaAppearances: "Media Appearances",

    callUs: "Be near us",
    connectWithUs: "Send A Message",
    membership: "Membership",

    more: "More",
    less: "Less",
    of: "of",
    share: "Share",
    yes: "Yes",
    no: "No",
    new: "new",
    close: "close",
    next: "Next",
    previous: "Previous",
    thousand: "K",
    details: "Details",
    viewers: "Viewers",
    finish: "Finish",
    specifications: "Specifications",
    generalTips: "General Tips",
    previousRatings: "Previous Ratings",
    ChooseToCompare: "Choose the services / products to compare",
    CompareServices: "Compare services / products",
    // "compare": "Compare",
    ServiceProduct: "Service / Product",
    notFound: "not Found",
    successfullyRegistered: "successfully Registered",
    NumberRegistered: "Registered Number",
    DateActivity: "Activity Date",
    Loading: "Loading....",
    another: "another",
    incorrectData: "Incorrect Data",
    // "PleaseContact ":"Please contact the Unified Call Center for Consumer Communications (PEN) at (935) or via the website.",
    orContatct: "Or application ... or Twitter account",
    selectSector: "select Sector",
    select: "Select...",
    ChooseProblem: "Choose Problem Type",
    SelectDestination: "Select Destination",
    DetailsComplaint: "Complaint Details",
    AddAttachments: "Add Attachments",
    ImportantLinks: "Important links",
    Fatwrty: "'Fatoraty' Application",
    AppDesc:
        "Proposing and developing relevant regulations for consumer protection",
    scam: "Scam Platform",
    RelatedNews: "Related News",
    assoNews: "Association News",
    moreNews: "More News",
    relatedNews: "Related News",
    assocData: "Recent Statements",
    moreData: "More Statements",
    download: "Download",
    view: "View",
    TelephoneIntervention: "Telephone Intervention",
    ShareTVreport: "Share on TV Report",
    AttendanceStudio: "Attendance Studio",
    attendance_studio: "Attendance Studio",
    ShareTVnews: "Share on TV news",
    followComplaint: "Track your complaint",
    ComplaintNumber: "Complaint Number",
    noComplaint: "No Complaints",

    newaccount: "new account",
    Token_is_Invalid: "Token is Invalid",
    // Validation messages

    // Create Complain validation messages
    identifier_int_msg: "Organization number must be a valid phone number",
    phone_valid_msg: "Phone number must be a valid phone number",
    siteMap: "Site Map",
    subscribed_succsess: "subscribed Successfully",
    noComment: "not commented yet",

    name: "Your Name",
    topic: " Select topic",
    message: "Type your message here",
    complainHeader: "If you have a complain or suggestion please press here ",

    CPA_Address: "Postal Address : ",
    Contact_Info: "Contact Information",
    Phone: "Phone number : ",
    Free_number: "Free number for inquires and complaints : ",
    Contact_us: "Contact Us",
    form1_header: "The Association hopes to fill in the following data",
    volunteer: "Volunteering",
    volunteering_field: "Volunteering field",
    Personal_info: "Personal Information",
    gender: "Gender",
    social_status: "Social status",
    male: "Male",
    Male: "Male",
    female: "Female",
    males: "Males only",
    females: "Females only",
    bothMalesAndFemales: "Males and females",
    left: "left",
    day: "day",
    educational_qualifications: "Educational Qualifications",
    voulnteer_time: "Time to join",
    job: "Job",
    specs: "Specialization",
    birth_date: "Birth Date",
    nationality: "nationality",
    area: "area",
    country: "Country",
    educational_degree: "Degree",
    educational_specialization: "Specialization",
    educational_school: "School / Collage",
    educational_gratuation_date: "Graduation date",
    educational_gpa: "GPA",
    educational_gpa_total: "Total",
    educational_language: "Main language",
    educational_language_other: "Other languages",
    language_level: "Level",
    language_reading: "Reading",
    language_writing: "Writing",
    language_speaking: "Speaking",
    month: "month",
    till_now: "Till now",
    year: "year",
    no_jop_applize: "Sorry there is no available jobs for now.",
    job_start_date: "Job start date",
    job_end_date: "Job leave date",
    have_professional_experience: "Do you have professional experience?",
    experience_site: "Site",
    experience_comapny_name: "Company name",
    experience_comapny_field: "Company field",
    experience_job_role: "Job role",
    experience_job_title: "Job title",
    experience_job_description: "Job Description",
    training_add_type: "What do you want to add?",
    training: "Training",
    certificate: "Certificate",

    certficateExist: "There is a certficate",
    noCertificate: "No certificate",
    training_title: "Training title",
    training_institute_name: "Training institute name",
    training_start_date: "Start date",
    training_total_hours: "Total hours",
    training_name_certificate: "Certificate name",
    training_name_certificate_date: "Release date",
    training_name_certificate_valid_to: "Valid until",
    cv_attachment: "Resume",
    next_step: "Next step",
    nextstep: "Next step",
    volunteer_q1: "Are you a member in the association?",
    volunteer_q2: "Are you interested in the association activities?",
    volunteer_q3:
        "What are your suggestions for volunteerism in the association? ",
    volunteer_q4: "City you want to volunteer in",
    volunteer_q5: "Volunteering available time",
    volunteer_day: "Morning",
    volunteer_night: "Night",
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    writeSuggestions: " Write your suggestions",
    jobs: "Jobs",
    current_jobs_available: "Current available jobs :",
    current_volunteer_available: "Current volunteer available",
    submit_a_form: "Submit a form",
    nojobs: "There's no available jobs at this time",
    appologize_msg:
        "Sorry there's no available jobs at this time, you could leave your e-mail address",
    job_app: "Job Application",
    app_msg: "please fill the fields below",
    upload_cv: "Upload your CV",
    cv_msg: "You can apply for a job by uploading your CV also",
    mp3: "media",
    maxProductsReached: "You can't choose more than 3 products",

    GuideLines: "Guide Lines",
    UserProfile: "user Profile",
    userData: "User Data",
    accountInfo: "Account info",
    personalInfo: "Personal info",
    educationalInfo: "Educational info",
    experience: "Experience",
    trainingsAndCertificates: "Trainings & Certificates",
    cvResume: "CV/Resume",
    complainNo: "Complain No",
    description: "Description",
    status: "Status",
    response: "Response",
    oldPass: "Old Password",
    NewPass: "New Password",
    submit: "Submit",
    changePass: "Change Password",
    noItem: "No Data Right Now",
    awardHeader: "Winners of the Consumer Protection Award",
    openningHours:
        "The Association's official working hours from Sunday to Thursday 8:30 a.m. – 3:30 p.m.",
    msg: "Write your msg Here",
    send: "Send",
    communicateThrough:
        "You can also communicate with us through the following form and will reply as soon as possible",
    CpaIntro: "Consumer Protection Association Welcome",
    content: "Content",
    en: "إنجليزي",
    ar: "عربي",
    genralTopic: "Genral Topic",
    noData: "No Data For Now",
    noProducts: "No Products To Compare Right Now",
    noProductsFor: "No Products To Compare Right Now For",
    beta: "Beta Version",
    serviceintro:
        "A comprehensive guide to the consumer's most significant rights and duties as settled by the regulating laws.",
    authAlert: "To continue, please log in first",
    complainAlert: "Please make sure you are not a robot",
    organizeTitle: "The Consumer Protection Association Mandate",
    signOut: "sign Out",
    SignToComplain: "Sign In To Complain",
    underDevelopment: "Under Development",
    complainSucess: "Complain Sucess",
    complainfail: "complain Fail please check try again",
    complainfailAuth: "Complain Fail please sign in",
    quizIntro:
        "Knowing your rights as a consumer increases the quality of services provided to you",
    quizIntro2: "Test your knowledge of your rights as a consumer",
    to_do: "To Do",
    solved: "Solved",
    closed: "Closed Without Solution",
    in_progress: "In Progress",
    directed_to: "Directed",
    closed_sol: "Closed With Solution",
    out_of_speciality: "Out Of Agency specilization",
    legal_advice: "Legal Advice",
    solved_by_company: "Solved By The Company",
    solved_by_ministry: "Solved By The Ministry",
    sent_to: "Sent To",
    switched_of_mobile: "Mobile Is Closed",
    no_reply: "No Reply",
    directed_to_court: "Directed To Judgment",
    notAuth: "Not Authorized, please login",
    technicalProblem: "  There is a technical Problem",
    willgoback: "we will go back soon ",
    fileSize: "5 MB Maximum, Accepted file types: jpg, png",
    fileSizePdf: "5 MB Maximum, Accepted file types: jpg, png, pdf",
    complainPending: "Your complain will be send right now",
    pleasetypecomplaintnumber: "Please type the complaint number",
    numbererr: "Number must start with 1 or 2",
    numLimit: "Number must not exceed 10 digits or be less than 10 digits",
    cityErr: "Must not contain any number",
    formError: "Please fill in the required missing fields!",
    customerGuideTitle: "Comprehensive Guide to Consumer Rights",
    TheMinistryofInvestment: "The Ministry of Investment",
    ChiefofExecutiveBoard: "Chairman",
    "Eng.AbdullahbinAliAlna'eim": "Eng. Abdullah Ali Alna'eim",
    ExecutiveBoardMembers: "Executive Board Members",
    "Eng.AnasbinNaserAlhmeid": "Eng. Anas Naser Alhmeid",
    "Eng.MagidbinSa'dAl'Esamy": "Eng. Magid Sa'd Al'Esamy",
    ExecutiveBoardMember: "Executive Board Member",
    "Mr.FaysalbinAbdelwahabAlseif": "Mr. Faysal Abdelwahab Alseif",
    "Eng.MagidbinSalihAlmarzam": "Eng. Magid Salih Almarzam",
    "Eng.SaudbinRashidAl'Askar": "Eng. Saud Rashid Al'Askar",
    "Dr.FahdbinMohammadAlmagid": "Dr. Fahd Mohammad Almagid",
    "Mr.FaysalAbdullahAlabdAlkarim": "Mr. Faysal Abdullah Alabd Alkarim",
    "Mr.MagidbinIbrahimAlmuhaymid": "Mr. Magid Ibrahim Almuhaymid",
    GeneralSecretariat: "General Secretariat",
    "Dr.SamarbinAbdullahAlqahtani": "Dr. Samar Abdullah Alqahtani",
    GeneralSecretary: "General Secretary",
    ExecutiveBoardReserveMembers: "Executive Board Reserve Members",
    "Dr.MohammadbinSaqirAl'Ureini": "Dr. Mohammad Saqir Al'Ureini",
    ReserveMember: "Reserve Member",
    "Dr.TurkibinAbdulazizAlthnayyan": "Dr. Turki Abdulaziz Althnayyan",
    "Mrs.FaridahbintMohammadAlsuheil": "Mrs. Faridaht Mohammad Alsuheil",
    DownloadTheApp: "Download The App",
    appIdea:
        "This application is developed to facilitate storing photocopies of invoices and warranties in an easily distinguished manner due to what the Association has observed regarding that many consumers fail to keep the documents of invoices and warranties which results in losing the privilege to demand for their rights whenever these documents are required.",
    appDef:
        "'Fatorty' is an application to store photocopies of the consumer's invoices and warranties to be retrieved, shared, and printed whenever required.",
    appObjective: "Objective:",
    appFunc: "Function:",
    appChar: "Characteristics:",
    appChar1: "Storing photocopies of the purchases' invoices and warranties.",
    appChar2:
        "Issuing notifications to the consumers before the warranties' deadlines.",
    appChar3: "Uploading photocopies to the consumer's Google Drive account.",
    appChar4:
        "Browsing, downloading, and sharing educational and awareness content.",
    weHearYou:
        "Drawing on the role of the Consumer Protection Association of caring about consumers' affairs and supporting their cases before the public and private entities, and due to the significant role of consumers in consolidating the Association's efforts by sharing it in whatever related to consumers for the sake of reading and studying, the Association launched Nasma'ak service for proposing any topic to be discussed. Below you can read through the complaints currently examined by the Association:",
    search: "Search..",
    complain1: "Complain",
    servicese: "Services",
    employmentVolunteer: "Employment & Volunteering",
    coursesAndLectures: "Courses and lectures",
    settings: "Settings",
    edit: "Edit",
    save: "Save",
    Ministry: "Ministry",
    departements: "Departements",
    MinistryOfHealth: "Ministry Of Health",
    publicRights: "Public Rights",
    fatortyIntro:
        "'Fatoraty' is an application to store copies of the consumer's bills and guarantees to be retrieved, shared, and printed whenever required.",
    compareIntro:
        "'Qarin & Qayyimha' program helps consumers inspect the prices of commodities and services at some organizations and companies to choose the most appropriate for them then evaluate what they purchased according to a set of parameters",
    mainBranch: "The Main Office – Riyadh",
    assocAddress:
        "Al Sulaymaniyah District - Prince Abdulaziz Street, Mas'ad Jalawi (Al Dhabab) - before the intersection of King Abdul Aziz Road. Building No. 8775 Second Floor - Office No. 11-12",
    assocAddress2:
        "Prince Abdulaziz Musaed Jalawi - Sulaymaniyah, Unit No. (1) Riyadh 12234-2949",
    fax: "Fax : ",
    weheardyou: "We Heard You",
    complains: "Complains",
    suggestions: "Suggestions",
    viewCertificate: "Certificate",
    catFilter: "Filter By Category",
    yearFilter: "Fitler By Year",
    succsess: "Done successfully",
    fillAllFields: "Please fill all fields*",
    newNews: "Recent News",
    RecentDeclarations: "Recent Statements",
    newMedia: "Recent Media Appearances",
    complaintNumber: "Complaint Number",
    aboutComplaint: "The subject of the complaint",
    complaintDate: "Complaint Date",
    complaintStatus: "Complaint Status",
    telephoneNumber: "Telephone Number:",
    website: "Website:",
    twitterAccount: "Twitter Account:",
    downloadFile: "To review Files and download",
    suggestionCount:
        "CPA received {{count}} complaints proposing to investigate this issue",
    emailExist: "The email has already been taken.",
    phoneExist: "The phone has already been taken.",
    emailInCorrect: "Email is incorrect",
    phone1: "2792368 11 966+",
    fax1num: "2790160 11 966+",
    phone2: "8001160087",
    egy_code: "+20",
    sa_code: "+966",
    forgotPassword: "Forgot Password?",
    resetPassword: "Reset Password",
    passwordChanged: "Password Changed",
    sendCode: "Password recovery link will send to your e-mail",
    sendCodePhone: "Password verification code will send to your mobile",
    codeSended: "Password recovery link is sent to your e-mail",
    codePhoneSended: "Password verification code is sent to your mobile",
    sendAttendanceCode: "We Will send an attendance code on your e-mail",
    fillFirstName: "First name is required",
    fillLastName: "Last Name is required",
    fillPassword: "Password is required",
    fillPhone: "Phone is required",
    fillCity: "City is required",
    fillEmail: "Email is required",
    verification: "Verification",
    verificationCode: "Verification Code",
    newPassword: "New Password",
    retryPassword: "Retry Password",
    retryPasswordError: "Password not match",
    verificationMsg: "Please set your verification code, So you can sign in",
    verification_error: "Plaese Check your verification code",
    emailMissing: "please enter your email",
    types: "Types",
    models: "Models",
    pleaseChoose: "-Choose-",
    set_suggestion: "Set a suggestion",
    worked_on: "done working on ............",
    already_working_on: "now working on",
    recivedComplains: "received complains",
    gotResived: "had received.............",
    ComplainantsNo: "no. of Complainants",
    statistics: "statistics",
    login_for_submit: "Login To Submit",
    cookieMessage:
        "Data gathering and storage are not limited to the personal information provided by the user. However, they include the data sent by the user's browser when logging into the website (Log Data) besides the user's workstation IP, browser, CPA website pages that the user logs, date and time of the log, the duration spend by the user on these pages, and other statistical factors.",
    do_you_have_trainig: "Do you have any training or certificates ? ",
};

export default EN;
