import React from 'react';
import Collapse from 'react-bootstrap/Collapse';
import './CpaCollapse.scss';
import PropTypes from 'prop-types';
class CpaCollapse extends React.Component {
    constructor(props, context) {
      super(props, context);
  
      this.state = {
        open: false,
      };
    }
    handleClick=()=>{

      if (!this.state.open) {
        document.addEventListener('click', this.handleOutsideClick, false);
      } else {
        document.removeEventListener('click', this.handleOutsideClick, false);
      }
      this.setState({ open: !this.state.open });
    }
    handleOutsideClick=(e)=> {
      // ignore clicks on the component itself
      if (this.node.contains(e.target)) {
        return;
      }
  
      this.handleClick();
    }

    render() {
      const { open } = this.state;
      return (
        <>
        <div ref={node => { this.node = node; }}> 
            <h5 className="collapse-head shadow-sm bg-white"
              onClick={() =>this.handleClick()}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
              {this.props.title}
            </h5>
            <Collapse in={this.state.open} >
              <p className="collapse-content" id="example-collapse-text shadow-sm">
                  {this.props.children}
              </p>
            </Collapse>
          </div>
        </>
      );
    }
  }
  CpaCollapse.propTypes = {
    children: PropTypes.children,
    title:PropTypes.string
  };
export default CpaCollapse;