export default {
  fuelSavings: {
    newMpg: '',
    tradeMpg: '',
    newPpg: '',
    tradePpg: '',
    milesDriven: '',
    milesDrivenTimeframe: 'week',
    displayResults: false,
    dateModified: null,
    necessaryDataIsProvidedToCalculateSavings: false,
    savings: {
      monthly: 0,
      annual: 0,
      threeYear: 0
    }
  },
  home: {
    homePage: null,
    homePageAlert: null
  },
  navigation: {
    pages: [],
    landingPages: null,
    page: null,
    buiderPage: null,
    buiderPageData: [],
    buiderPageForms: []
  },
  programs_and_activities: {
    programs: {
      motamaken: {
        categories: [],
        activities: [],
        activity: {}
      },
      complain: {
        cases: [],
        complain: {},
        activityTypes: []
      },
      ershedna: {
        sections: [],
        problems: [],
        organizations: [],
        ershdnaCategories: [],
        ershdnaCategory: [],
        ershdnaSubs: [],
        head: {},
        desc: {},
        guideUsDesc: {}
      },
      compare_and_rate: {
        categories: [],
        item: {
          specs: [],
          advices_media: [],
          evaluations: []
        },
        firstCollapseIndex: 0,
        firstCollapseId: 0,
        secondCollapseIndex: 0,
        secondCollapseId: 0,
        multiCategories: [],
        comparisonProducts: [],
        toBeComparedObjects: [],
        diableObjectCompareBtn: [],
        comparison: {
          specs: [],
          comparePoints: []
        },
        generalTips: {},
        prevEvaluations: {}
      },
      recognize: {
        subjects: []
      },
      TakeKnowledge: {
        knowledgeArray: []
      },
      quizes: {
        quizesArray: []
      },
      legal_advocay: {
        imp_links: {
          links: []
        },
        follow_complain: {
          status: null,
          status_en: null,
          admin_response: null
        },
        contracts: {
          contractsArray: []
        },
        guideUs: {
          apiResponse: null
        }
      },
      consumer_office: {
        office_data: {
          consumer_items: []
        }
      },
      consumer_consult: {
        consult_data: [],
        consult_data_by_id: null
      },
    },
    platforms: {
      applications: [],
      application: {}
    },
    world_days: {
      worldDaysArray: [],
      worldDayPage: {}
    },
    comprehensive_guide: {
      comprehensiveGuideArray: [],
      attachments: {}
    },
    consumer_award: {
      awards: []
    },
    guide_book: {
      guideBookobject: null,
      guideBookChildren: null,
      guideBookCategoryContent: null,
    }
  },
  information_center: {
    news: {
      new: [],
      more: null,
    },
    data: {
      new: [],
      more: []
    },
    annual_reports: {
      annual_reports_array: []
    },
    media_interview: {
      new: [],
      more: null,
    },
    single_news: {
      object: {
        mediaCenter: {
          title: '',
          title_en: '',
          description: '',
          description_en: '',
          attachments: '[]',
          date: ''
        },
        related: []
      }
    }
  },
  el_gam3ya: {
    about: {
      about_array: []
    },
    spectializations: {
      spectializations_array: []
    },
    committees: {
      Committees_array: []
    },
    organize: {
      organizationsArray: [],
      organizationsPara: null
    },
    members: {
      membersArray: []
    }
  }
  ,
  post_form: {
    returnedMsg: null
  },
  user: {
    id: null,
    email: null,
    first_name: null,
    last_name: null,
    auth_token: null,
    is_authenticated: null,
    lng: null,
    identification_number: null,
    identification_path: null,
    city: null,
    error: null,
    verified: null
  },
  profile: {},
  filters: {
    filterObject: null
  },
  contactUs: {
    sendamessage: {
      msg: null
    },
    contactInfo: null
  }
};
