import {
  GET_MOTAMKN_ACTIVITIES_DATA,
  GET_MOTAMKN_LANDING_DATA,
  GET_MOTAMKN_SINGLE_ACTIVITY_DATA,
  USER_REGISTER_IN_ACTIVITY
} from '../../../constants/actionTypes';
import { apiFetch } from '../../../utils/http.helpers';
import { BASE_URL } from '../../../constants/config';

// import { getMotamakenLandingDataApi } from '../../../utils/services/programsAndActivities';
// import * as axios from 'axios';

export function getMotamakenLandingData() {
  // console.log('tok in req', localStorage.getItem('_cpaJwt'))
  // const hVal = "Bearer " + localStorage.getItem('_cpaJwt')
  return function (dispatch) {
    return apiFetch(BASE_URL + '/api/motamaken/category/search', {
      method: "GET",
      // mode: "cors",
      withCredentials: true,
      headers: {
        "Accept": "application/json",
        // "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
      }
    })
      .then(response => response.json())
      .then(res => {
        // console.log('Res in fetch', res);
        dispatch({ type: GET_MOTAMKN_LANDING_DATA.SUCCESS, payload: res.motamaken_categories });
      }).catch(() => {
        // console.log('Error is', error);
      });
  };
}

export function getMotamakenActivitiesData(id) {
  return function (dispatch) {
    return apiFetch(BASE_URL + '/api/motamaken/activity/search?category_id='
      + id
      + '&instructors=' + 1, {
        method: "GET",
        // mode: "cors",
        headers: {
          "Accept": "application/json",
        }
      })
      .then(response => response.json())
      .then(res => {
        // console.log('Res in fetch act', res);
        dispatch({ type: GET_MOTAMKN_ACTIVITIES_DATA.SUCCESS, payload: res.motamaken_activities });
      }).catch(() => {
        // console.log('Error is act ', error);
      });
  }
}

export function getMotamakenSingleActivityData(id) {  
  return function (dispatch) {
    return fetch(BASE_URL + '/api/motamaken/activity/find?id=' + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Accept": "application/json",
      }
    })
      .then(response => response.json())
      .then(res => {
        // console.log('Res in fetch single act', res);
        dispatch({ type: GET_MOTAMKN_SINGLE_ACTIVITY_DATA.SUCCESS, payload: res.motamaken_activity });
      }).catch(() => {
        // console.log('Error is single act ', error);
      });
  };
}

export function registerInActivity(activity) {
  return function (dispatch) {
    return fetch(BASE_URL + '/api/users/register_to_activity', {
      method: 'POST',
      // mode: "cors",
      headers: {
        "Accept": "application/json",
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
      },
      body: JSON.stringify({ activity_id: activity.id })
    })
      .then(() => {
        dispatch({ type: USER_REGISTER_IN_ACTIVITY.SUCCESS, payload: activity });
        // alert('تم االتسجيل');
      }).catch((err) => {
        throw err
        // console.log('Error in login', error);
      });
  }
}
