import {
    GET_PROFILE_DATA
} from '../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './../initialState';

export default function userProfileReducer(state = initialState.profile, action) {  switch (action.type) {
    case GET_PROFILE_DATA.SUCCESS:
        return objectAssign({}, state, action.payload);
    default:
        return state;
}
}
