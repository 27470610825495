import React from 'react';
import HomeSectionFC from "../HomeSection/HomeSectionFC";
import './post-section.scss';
import { Card, Container, Row, Col, Form, Modal } from 'react-bootstrap';
import { Select } from 'element-react';
import 'element-theme-default';

import { t } from 'i18next';
import * as actions from '../../../actions/postForm';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContainerCard from '../ContainerCard/containerCard';
import { ToastContainer, toast } from 'react-toastify';
import validate from '../../../utils/validation';

class PostForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      show: false,
      maillingList: 'القائمة البريدية',
      maillingList_en: 'mailing List',
      maillingListDesc: 'انضم إلى خدمة الرسائل البريدية',
      maillingListDesc_en: 'Join Our malling services',
      youremail: 'أكتب بريدك الإلكتروني',
      youremail_en: 'write your email address',
      subscribe: 'اشترك الآن',
      subscribe_en: 'Subscribe',
      subscribed_succsess: 'لقد تم الاشتراك بنجاح',
      subscribed_succsess_en: 'subscribed Successfully',
      options: [{
        value: 'متمكن',
        label: 'متمكن',
        label_en: 'versed'
      }, {
        value: 'العمل التطوعي',
        label: 'العمل التطوعي',
        label_en: 'volunteering'
      }, {
        value: 'التوظيف',
        label: 'التوظيف',
        label_en: 'employment'
      }, {
        value: 'الاخبار',
        label: 'الاخبار',
        label_en: 'news'
      }, {
        value: 'البيانات',
        label: 'البيانات',
        label_en: 'data'
      }],
      subscribtionList: [],
      value: []
    };
  }

  handleSubmit = event => {
    let data = {
      email: this.state.email,
      types: this.state.subscribtionList
    }
    // console.log(data);
    event.preventDefault();
    if (this.state.email != '') {
      this.props.actions.postPostForm(data)
        .then(() => {
          this.setState({ ...this.state, show: true });
        }).catch(() => {
          this.setState({ ...this.state, show: true });
        });
    } else {
      this.showError('emailMissing');
    }

  }
  handleClose = () => {
    this.setState({ show: false });
    window.location.reload();
  }

  handleShow = () => {
    this.setState({ show: true });
  }
  handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target
    this.setState({
      [name]: value
    })
  }
  showError(errMsg) {
    // notify.show(errmsg, "custom", 3500, errColor);
    toast.error(t(errMsg), {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  }

  selectListHandler(listArray) {
    this.setState({ subscribtionList: listArray });
  }

  render() {
    // to check the validity of the email enterd by user

    let wrongEmail;
    let disableLink = null;

    if (this.state.email && validate("email", this.state.email) === false) {
      wrongEmail = <p className="error">{t('emailInCorrect')}</p>;
      disableLink = "disabled";
    }

    return (
      <HomeSectionFC >
        <ToastContainer />
        <Container className="post-wrapper">
          <Row>
            {/* <Col xs={12} sm={4} className="post-img">
            <Image src="https://gdurl.com/0IlA" />
          </Col> */}
            <Col xs={12} className="">
              <div className="card shakwa-card">
                <Row className="card-body">
                  <Col xs={12} className="card-title">
                    {this.props.lng == 'ar' ? this.state.maillingList : this.state.maillingList_en}
                  </Col>
                  <Col xs={12} className="card-text">
                    {this.props.lng == 'ar' ? this.state.maillingListDesc : this.state.maillingListDesc_en}
                  </Col>
                  <Col xs={12}>
                    <Form>
                      <Form.Row>
                        {/* <Col xs={12} lg={2}>
                        </Col> */}
                        <Col xs={12} lg={6}>
                          <input
                            placeholder={this.props.lng == 'ar' ? this.state.youremail : this.state.youremail_en}
                            name="email"
                            type="email"
                            onChange={this.handleChange}
                            className="form-control"
                            required />
                          {wrongEmail}
                          <i className="icon-shape-18 newsletter-icon"></i>
                        </Col>
                        <Col xs={12} lg={3}>
                          <Select
                            onChange={(value) => { this.selectListHandler(value) }}
                            placeholder={this.props.lng === "ar" ? "اختر" : "Select"}
                            value={this.state.value}
                            multiple={true}>
                            {
                              this.state.options.map(el => {
                                return <Select.Option key={el.value} label={this.props.lng === "ar" ? el.label : el.label_en} value={el.value} />
                              })
                            }
                          </Select>
                        </Col>
                        <Col xs={12} lg={3} className="submit-btn">
                          <Card.Link disabled onClick={this.handleSubmit} className={"btn btn-outline-success btn-lg " + disableLink}>
                            {this.props.lng == 'ar' ? this.state.subscribe : this.state.subscribe_en}
                          </Card.Link>
                        </Col>
                        {/* <Col xs={12} lg={2}></Col> */}
                      </Form.Row>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <Modal dialogClassName="subscribe-modal" size="xl" show={this.state.show} onHide={this.handleClose}>
          <ContainerCard>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="notFound-content text-center">
                <div className="inner">
                  <h3>
                    {this.props.lng == 'ar' ? this.state.subscribed_succsess : this.state.subscribed_succsess_en}
                  </h3>
                </div>
              </div>
            </Modal.Body>
          </ContainerCard>
        </Modal>
      </HomeSectionFC>
    )
  }
}
PostForm.propTypes = {
  actions: PropTypes.object.isRequired,
  returnedMsg: PropTypes.object.isRequired,
  lng: PropTypes.string.isRequired,

};
function mapStateToProps(state) {
  return {
    returnedMsg: state.postForm.returnedMsg,
    lng: state.user.lng
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PostForm)

