import React from 'react';
import './complain.scss';
import { Row, Col, Modal } from "react-bootstrap";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../../../actions/program-and-activities/programs/legalAdvocacy';
import PropTypes from "prop-types";
import Recaptcha from 'react-recaptcha';
import { t } from 'i18next';
import Translate from '../../../../GlobalComponent/translate/translate';
import { validateFormData } from "../../../../../utils/validation.helpers";
import { createComplainRules } from "../../../../../constants/validationRules";
import { userLogout } from "../../../../../utils/auth.helpers";
import { store } from "../../../../../index";
import { USER_LOGOUT } from "../../../../../constants/actionTypes";
import CpaSelect from '../../../../GlobalComponent/cpaSelect/cpa-select';
import { toast } from 'react-toastify';
import DatePicker from 'react-date-picker';
import ReactHtmlParser from 'react-html-parser';


var FormData = require('form-data');


let attachmentsArray = [];

class Complain extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.complaintDataRef = React.createRef();
        this.selectTopicRef = React.createRef();

        this.state = {
            autherized: "",
            loadingToast: null,
            loading: false,
            errMsgs: "",
            values: {
                content: "",
                requests: "",
                city: "",
                identification_number: "",
                phone: "",
                name: "",
                address: "",
                activity_type: "",
                user_id: "",
                identification: [],
                case_id: "",
                complaint_organization: "",
                complain_date: "",
                attachments: null,
                complaint_number: "",
                complained_before: 0,
                old_complain: "no",
                appear_contact: 0,
                appear_message: ''
            },
            identification_file: null,
            attachments_file: null,
            validate_msg: t("incorrectData"),
            isVerified: false,
            showComplainNumberModal: false,
            another_case_data: {
                msg: "يرجى التواصل مع مركز الاتصال الموحد لبلاغات المستهلك.",
                msg_en: "Please contact the Unified Call Center for Consumer Communications."
            },
            errMsgID: "",
            errMsgCity: ""
        };

        this.verifyCallback = this.verifyCallback.bind(this);
        this.validateComplainData = this.validateComplainData.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.removeIDFile = this.removeIDFile.bind(this);
    }

    removeFile(id) {
        for (var i = 0; i < attachmentsArray.length; i++) {
            if (attachmentsArray[i].id == id) {
                attachmentsArray.splice(i, 1);
                break;
            }
        }
        this.setState({
            value: { ...this.state.values, [name]: attachmentsArray },
        })
    }

    handleChange = event => {
        event.preventDefault();
        const { name, value } = event.target;
        let fileURl;
        let attachedObject;
        let files;
        switch (name) {
            case "identification":
                fileURl = URL.createObjectURL(event.target.files[0]);
                if (event.target.files[0].type.includes("image")) {
                    this.setState({
                        values: {
                            ...this.state.values, [name]: event.target.files[0],
                        },
                        identification_file: fileURl
                    })
                }
                break;
            case "attachments":
                files = event.target.files
                if (files[0].type !== 'image/png' && files[0].type !== 'image/jpeg' && files[0].type !== 'application/pdf') {
                    this.props.lng === 'en' ? alert('File type not supported') : alert('نوع الملف غير مدعوم')
                    this.showLoadingNotification('info', true, 'error')
                }
                else {
                    for (let index = 0; index < files.length; index++) {
                        if (files[index].type != "application/x-msdownload") {
                            attachedObject = {
                                file: files[index],
                                id: attachmentsArray.length
                            }
                            attachmentsArray.push(attachedObject);
                        }
                    }
                    this.setState({
                        //values: { ...this.state.values, [name]: event.target.files[0] },
                        value: { ...this.state.values, [name]: attachmentsArray },
                        //attachments_file: fname
                    });
                }
                break;
            case "old_complain":
                this.setState({
                    values: { complained_before: event.target.value === "yes" ? 1 : 0 },
                })
                break;
            case "identification_number":
                // ||( value[0]!='۲' && value[0]!='١')
                if (value[0] != 1 && value[0] != 2) {
                    this.setState({
                        errMsgID: <span Style="color:red"><Translate text="numbererr" /></span>,
                        values: { ...this.state.values, [name]: value }
                    })
                }
                else if (value.length != 10) {
                    this.setState({
                        errMsgID: <span Style="color:red"><Translate text="numLimit" /></span>,
                        values: { ...this.state.values, [name]: value }
                    })
                }
                else {
                    this.setState({
                        errMsgID: null,
                        values: { ...this.state.values, [name]: value }
                    })
                }
                break;
            default:
                this.setState({
                    values: { ...this.state.values, [name]: value },
                })
                break;
        }
    };

    removeIDFile = (event) => {
        event.preventDefault();
        this.setState({
            identification_file: null
        })
    }

    scrollToError = () => {
        if (this.state.values.case_id === "") {
            window.scrollTo(0, 550);
        }
        else if (this.state.values.identification_number === "" || this.state.errMsgID !== null || this.state.values.identification.length != 0 || this.state.identification_file === null) {
            if (this.selectTopicRef.current) {
                this.selectTopicRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                })
            }
        } else if (this.state.values.name === "" || this.state.values.activity_type === "" || this.state.values.content === "" || this.state.values.requests === "") {
            if (this.complaintDataRef.current) {
                this.complaintDataRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                })
            }
        }

        if (this.state.values.identification_number === "") {
            let elements = document.getElementsByName("identification_number");
            let idn = elements[0];
            idn.style.border = "1px solid #ffafaf";
        }

        if (this.state.values.name === "") {
            let elements = document.getElementsByName("name");
            let name = elements[0];
            name.style.border = "1px solid #ffafaf";
        }

        if (this.state.values.activity_type === "") {
            let elements = document.getElementsByName("activity_type");
            let act = elements[0];
            act.style.border = "1px solid #ffafaf";
        }

        if (this.state.values.identification.length == 0) {
            let elements = document.getElementsByName("identification");
            let identification = elements[0];
            identification.style.border = "1px solid #ffafaf";
        }

        if (this.state.values.content === "") {
            let elements = document.getElementsByName("content");
            let content = elements[0];
            content.style.border = "1px solid #ffafaf";
        }

        if (this.state.values.requests === "") {
            let elements = document.getElementsByName("requests");
            let req = elements[0];
            req.style.border = "1px solid #ffafaf";
        }
    }


    handleBlur = event => {
        if (event.target.value === "") {
            event.target.style.border = "1px solid #c14242";
        } else {
            event.target.style.border = "1px solid #42C194";
        }
    }

    logout = () => {
        userLogout();
        store.dispatch({ type: USER_LOGOUT.SUCCESS, payload: {} });
    };

    componentDidMount() {
        this.setState({
            loading: true,
            values: {
                ...this.state.values,
                city: localStorage.getItem('city'),
                identification_number: localStorage.getItem('identification_number'),
                identification_path: localStorage.getItem('identification_path')
            }
        })
        this.props.actions.getDescriptionComplaints();
        if (!this.props.user.is_authenticated) {
            this.setState({
                autherized: "unauthorized",
            })
        }
        // this.props.actions.getCasesOfComplains()
        //     .then(() => { })
        //     .catch(error => {
        //         if (error.message === "unauthorized") {
        //             this.logout();
        //             this.setState({
        //                 autherized: "unauthorized"
        //             })
        //         }
        //     });
        this.props.actions.getActivityType();
    }

    handleOptionChange = (changeEvent) => {
        this.setState({
            values: { ...this.state.values, old_complain: changeEvent.target.value, complained_before: changeEvent.target.value === "yes" ? 1 : 0 },
        });
    };

    // handleSubmit(event) {

    //     event.preventDefault();
    //     const data = this.state;
    //     this.props.actions.createComplain({ data })
    //         .then(() => {

    //         }).catch(() => {
    //         });
    // }

    validateComplainData() {
        const values = this.state.values;
        const fields = [
            'content', 'requests', 'city',
            'identification_number', 'name', 'phone',
            'address', 'activity_type', 'user_id',
            'identification', 'case_id', 'complaint_organization',
            'complaint_date', 'attachments', 'complained_before'
        ];
        return validateFormData(
            fields,
            [...Object.values(values)],
            createComplainRules,
        );
    }

    showComplainNumber = () => this.setState({ showComplainNumberModal: true });
    closeComplainModal = () => {
        this.setState({ showComplainNumberModal: false, errMsgs: '' });
        window.location.reload();
    }

    /**
     * @param event
     *
     * */
    handleCreateComplain = (event) => {
        event.preventDefault();
        const formData = new FormData();
        Object.keys(this.state.values).forEach(key => {
            if (key == "attachments") {
                attachmentsArray.forEach(elment => {
                    formData.append('attachments[]', elment.file)
                })
            } else {
                formData.append(key, this.state.values[key])
            }
        });
        let loadingmsg = t('Loading');
        this.setState({
            loadingToast: toast(loadingmsg, { autoClose: false, type: toast.TYPE.INFO, position: toast.POSITION.BOTTOM_RIGHT })
        });
        if (this.state.isVerified
            &&
            //  this.state.errMsgID === null &&
            this.state.values.case_id !== ""
            &&
            // this.state.identification_file !== null &&
            this.state.values.activity_type !== "") {
            this.showLoadingNotification(loadingmsg, false, 'info')
            this.props.actions.createComplain(formData)
                .then(() => {
                    if (this.props.complain.status === 'Token is Invalid') {
                        if (this.props.lng === "en") {
                            this.setState({
                                errMsgs: "Your time is out, Please re login again"
                            });
                        } else {
                            this.setState({
                                errMsgs: "أنتهي الوقت المسموح,برجاء اعادة تسجيل الدخول"
                            })
                        }
                        this.showLoadingNotification(this.state.errMsgs, true, 'error');
                        setTimeout(() => {
                            this.logout();
                        }, 3000);
                        return;
                    } else if (this.props.complain.requests == "The requests field is required." || this.props.complain.content == "The content field is required.") {
                        if (this.props.lng == 'en') {
                            this.setState({
                                errMsgs: "Please fill all required * fields"
                            })
                        } else {
                            this.setState({
                                errMsgs: "برجاء ملئ كل الخانات المطلوبة*"
                            })
                        }
                        this.showLoadingNotification(this.state.errMsgs, true, 'error');
                        this.scrollToError();
                        return
                    }
                    if (this.props.lng === "en") {
                        this.setState({
                            errMsgs: "Complained successfully"
                        });
                    } else {
                        this.setState({
                            errMsgs: "تمت الشكوي بنجاح"
                        });
                    }

                    this.showLoadingNotification(this.state.errMsgs, true, 'sucsess')
                    //this.showNotificationSuccess(this.props.complain.complaint_number_constant);
                    this.showComplainNumber();
                    for (const key in this.state.values) {
                        this.setState({
                            values: {
                                [key]: null
                            }
                        })
                    }
                })
                .catch();
        } else {
            this.scrollToError();
            if (this.state.values.case_id === "") {
                if (this.props.lng == 'en') {
                    this.setState({
                        errMsgs: "Please choose complaint topic!"
                    }, () => {
                        this.showLoadingNotification(this.state.errMsgs, true, 'error')
                        this.setState({
                            errMsgs: ""
                        });
                    });
                } else {
                    this.setState({
                        errMsgs: "!الرجاء اختيار موضوع الشكوى"
                    }, () => {
                        this.showLoadingNotification(this.state.errMsgs, true, 'error')
                        this.setState({
                            errMsgs: ""
                        });
                    });
                }
            }
            if (this.state.values.activity_type === "") {
                if (this.props.lng == 'en') {
                    this.setState({
                        errMsgs: "Please choose activity type!"
                    }, () => {
                        this.showLoadingNotification(this.state.errMsgs, true, 'error')
                        this.setState({
                            errMsgs: ""
                        });
                    });
                } else {
                    this.setState({
                        errMsgs: "!الرجاء اختيار نوع النشاط "
                    }, () => {
                        this.showLoadingNotification(this.state.errMsgs, true, 'error')
                        this.setState({
                            errMsgs: ""
                        });
                    });
                }
            }
            else if (this.state.isVerified === false) {
                if (this.props.lng == 'en') {
                    this.setState({
                        errMsgs: "Please verify you're not a robot!"
                    }, () => {
                        this.showLoadingNotification(this.state.errMsgs, true, 'error')
                        this.setState({
                            errMsgs: ""
                        });
                    });
                } else {
                    this.setState({
                        errMsgs: "يرجى التحقق من أنك لست روبوتًا"
                    }, () => {
                        this.showLoadingNotification(this.state.errMsgs, true, 'error')
                        this.setState({
                            errMsgs: ""
                        });
                    });
                }
            }
            //errMsgID validation
            // else if (this.state.errMsgID !== null) {
            //     if (this.props.lng == 'en') {
            //         this.setState({
            //             errMsgs: "Number must not exceed 10 digits or be less than 10 digits, and Number must start with 1 or 2"
            //         }, () => {
            //             this.showLoadingNotification(this.state.errMsgs, true, 'error')
            //             this.setState({
            //                 errMsgs: ""
            //             });
            //         });
            //     } else {
            //         this.setState({
            //             errMsgs: "يجب ألا يتجاوز الرقم 10 أرقام أو أن يكون أقل من 10 أرقام ، ويجب أن يبدأ الرقم برقم واحد أو اثنين"
            //         }, () => {
            //             this.showLoadingNotification(this.state.errMsgs, true, 'error')
            //             this.setState({
            //                 errMsgs: ""
            //             });
            //         });
            //     }
            // }
            //identificattion file validation
            // else if (this.state.identification_file === null) {
            //     if (this.props.lng == 'en') {
            //         this.setState({
            //             errMsgs: "Attach Residency/Identity Photocopy"
            //         }, () => {
            //             this.showLoadingNotification(this.state.errMsgs, true, 'error')
            //             this.setState({
            //                 errMsgs: ""
            //             });
            //         });
            //     } else {
            //         this.setState({
            //             errMsgs: "يرجى ارفاق صورة الإقامة/الهوية "
            //         }, () => {
            //             this.showLoadingNotification(this.state.errMsgs, true, 'error')
            //             this.setState({
            //                 errMsgs: ""
            //             });
            //         });
            //     }
            // }

        }
    }

    siginFormOpen = () => {
        this.props.toggleModal();
    }
    loadCallback() {
    }
    expiredCallback = () => {
        this.setState({ ...this.state, isVerified: false });
    }

    verifyCallback = () => {
        this.setState({ ...this.state, isVerified: true });
    }
    Selection = (value) => {
        this.setState({
            values: {
                ...this.state.values,
                case_id: value.id,
                appear_contact: value.appear_contact,
                appear_message: value.appear_message
            }
        })
    }
    showLoadingNotification(msg, doneBoolean, type) {
        if (!doneBoolean) {
            toast.update(this.state.loadingToast, {
                render: msg,
                type: toast.TYPE.INFO,
                autoClose: 500
            });
        } else {
            if (type == 'error') {
                toast.update(this.state.loadingToast, {
                    render: msg,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            } else if (type == 'sucsess') {
                toast.update(this.state.loadingToast, {
                    render: msg,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000
                });
            }

        }

    }
    onChange = date => this.setState({ values: { ...this.state.values, complain_date: date } })
    render() {
        const { complain } = this.props;
        // البيانات الشخصية only entered for one time
        // there are two button of اشتكي one for any case and the other one of type button when the use select other case
        //TODO: open a modal when shoose option: another case
        return (

            <div className="complain-container">

                <div className="row">
                    <div className="col-12">


                        <p className="complain-intro">
                            {this.props.lng == 'ar' ?
                                this.props.complainParagraph && this.props.complainParagraph.text &&
                                ReactHtmlParser(this.props.complainParagraph.text)




                                :
                                this.props.complainParagraph && this.props.complainParagraph.text_en &&
                                ReactHtmlParser(this.props.complainParagraph.text_en)

                            }
                        </p>
                    </div>
                </div>
                <form className="complain-form" method="post">
                    <div className="choose-case ">
                        <Row className="justify-content-start select-case">
                            {!this.props.user.is_authenticated && <button type="button" className="signin-button btn submit-cpa-btn" onClick={this.siginFormOpen}>{t("signIn")}</button>}
                            <Modal show={this.state.showComplainNumberModal} size="lg" aria-labelledby="example-modal-sizes-title-sm">
                                <Modal.Header closeButton onClick={this.closeComplainModal} />
                                <Modal.Body >
                                    <div className={"Authentication-container"}>

                                        {complain.complaint_number_constant ?
                                            <Row className="justify-content-center">
                                                <Col xs={"auto"}>

                                                    <h4>{t("complainSucess")}</h4>
                                                </Col>
                                                <Col xs={12}>
                                                    <span className={'col-sm-12'}>{t('complainNo')}</span>
                                                    <div className={'col-sm-12'}>cpa{complain.complaint_number_constant}</div>
                                                </Col>
                                            </Row> :

                                            <Row className="justify-content-center">
                                                <Col xs={"auto"}>
                                                    {this.props.user.is_authenticated ? <h4>{this.state.errMsgs}</h4> : <h4>{t('complainfailAuth')}</h4>}
                                                </Col>
                                            </Row>
                                        }
                                    </div>
                                </Modal.Body>
                            </Modal>
                            <Col xs={12} lg={3}>
                                <h5 ref={this.selectTopicRef}><Translate text="selectIssue" /></h5>
                            </Col>
                            <Col xs={12} lg={8}>
                                {
                                    this.props.cases.length > 0 &&
                                    <CpaSelect defaultSelect="selectIssue" options={this.props.cases} selectValue={this.Selection} />
                                }
                            </Col>
                        </Row>
                        <Row className={"justify-content-start data-complain " + (this.state.values.appear_contact != 1 ? "" : "display-none")}>
                            <Col xs={12} lg={3}>
                                <h5 ref={this.personalDataRef}><Translate text="personalData" />: </h5>
                            </Col>
                            <Col className="new-user" xs={11} >
                                <div className="form-group row">
                                    <label htmlFor="inputPassword" className="col-12 col-lg-3 col-form-label"><Translate text="city" /></label>
                                    <div className="col-12 col-lg-9">
                                        <div className="form-input">
                                            <i className="icon-map-o"></i>
                                            <input
                                                name="city"
                                                placeholder={t("city")}
                                                value={this.state.values.city}
                                                onChange={this.handleChange}
                                                type="text" required
                                            />
                                            {/*<span className="cpa-hidden">{this.state.validate_msg}</span>*/}
                                            {this.state.errMsgCity}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col className="new-user" xs={11} style={{display:'none'}} >
                                <div className="form-group row">
                                    <label htmlFor="inputPassword" className="col-12 col-lg-3 col-form-label"><Translate text="identityNumber" /><span Style="color:red">*</span></label>
                                    <div className="col-12 col-lg-9">
                                        <div className="form-input">
                                            <i className="icon-address-book-o"></i>
                                            <input
                                                name="identification_number"
                                                placeholder={t("identityNumber")}
                                                value={this.state.values.identification_number}
                                                onChange={this.handleChange}
                                                type="number" required
                                                onBlur={this.handleBlur} />
                                            {/*<span className="cpa-hidden">{this.state.validate_msg}</span>*/}
                                            {this.state.errMsgID}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col className="new-user" xs={11} style={{display:'none'}}>
                                <div className="form-group row">
                                    <label htmlFor="inputPassword" className="col-12 col-lg-3 col-form-label"><Translate text="AttachResidence" /><span Style="color:red">*</span></label>
                                    <div className="col-12 col-lg-9">
                                        <div className="form-input file-input">
                                            <i className="icon-picture"></i>
                                            <div className="custom-input-file"><Translate text="AttachResidence" /></div>
                                            <input
                                                name="identification"
                                                onChange={this.handleChange}
                                                type="file" required
                                                accept="image/*" />
                                            <span><Translate text="fileSize" /></span>
                                        </div>
                                        <div>
                                            {this.state.identification}
                                        </div>
                                        {this.state.identification_file !== null ?
                                            <div>
                                                <button className="btnFileName deleteFileBtn attachedFile" onClick={this.removeIDFile}>x</button>
                                                <img className="image-preview" src={this.state.identification_file} />
                                            </div>
                                            :
                                            <div></div>
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <h5 ref={this.complaintDataRef}><Translate text="ComplainantData" />: </h5>
                            </Col>
                            <Col xs={11}>
                                <div className="form-group row">
                                    <label htmlFor="inputPassword" className="col-12 col-lg-3 col-form-label"><Translate text="NameFacilty" /><span Style="color:red">*</span></label>
                                    <div className="col-12 col-lg-9">
                                        <div className="form-input">
                                            <input
                                                name="name"
                                                placeholder={t("NameFacilty")}
                                                value={this.state.values.name}
                                                onChange={this.handleChange}
                                                type="text" required
                                                onBlur={this.handleBlur} />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={11}>
                                <div className="form-group row">
                                    <label htmlFor="inputPassword" className="col-12 col-lg-3 col-form-label"><Translate text="activityType" /><span Style="color:red">*</span></label>
                                    <div className="col-12 col-lg-9">
                                        <div className="form-input">

                                            {
                                                this.props.types.length > 0 &&
                                                <select name="activity_type" onChange={this.handleChange} required onBlur={this.handleBlur}>
                                                    <option disabled value="" selected>{t("activityType")}</option>
                                                    {
                                                        this.props.types.map(type => {
                                                            return (
                                                                <option key={type.id} value={type.title}>{this.props.lng === 'ar' ? type.title : type.title_en}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            }
                                            {/* <input
                                                name="activity_type"
                                                placeholder={t("activityType")}
                                                value={this.state.values.activity_type}
                                                onChange={this.handleChange}
                                                type="text" required
                                                onBlur={this.handleBlur} /> */}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={11}>
                                <div className="form-group row">
                                    <label htmlFor="inputPassword" className="col-12 col-lg-3 col-form-label"><Translate text="address" /></label>
                                    <div className="col-12 col-lg-9">
                                        <div className="form-input">

                                            <input
                                                name="address"
                                                placeholder={t("address")}
                                                value={this.state.values.address}
                                                onChange={this.handleChange}
                                                type="text" />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={11}>
                                <div className="form-group row">
                                    <label htmlFor="inputPassword" className="col-12 col-lg-3 col-form-label"><Translate text="faciltyNumber" /></label>
                                    <div className="col-12 col-lg-9">
                                        <div className="form-input">

                                            <input
                                                name="phone"
                                                placeholder={t("faciltyNumber")}
                                                value={this.state.values.phone}
                                                onChange={this.handleChange}
                                                type="number" required />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={11}>
                                <div className="form-group row">
                                    <label htmlFor="inputPassword" className="col-12 col-lg-3 col-form-label"><Translate text="complainDetials" /><span Style="color:red">*</span></label>
                                    <div className="col-12 col-lg-9">
                                        <div className="form-input">

                                            <textarea
                                                name="content"
                                                placeholder={t("complainDetials")}
                                                value={this.state.values.content}
                                                onChange={this.handleChange}
                                                type="text" required
                                                onBlur={this.handleBlur} />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={11}>
                                <div className="form-group row">
                                    <label htmlFor="inputPassword" className="col-12 col-lg-3 col-form-label"><Translate text="requests" /><span Style="color:red">*</span></label>
                                    <div className="col-12 col-lg-9">
                                        <div className="form-input">

                                            <textarea
                                                name="requests"
                                                placeholder={t("requests")}
                                                value={this.state.values.requests}
                                                onChange={this.handleChange}
                                                type="text" required
                                                onBlur={this.handleBlur} />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={11}>
                                <div className="form-group row">
                                    <label htmlFor="inputPassword" className="col-12 col-lg-3 col-form-label"><Translate text="attachments" /></label>
                                    <div className="col-12 col-lg-9">
                                        <div className="form-input file-input">
                                            <i className="icon-special"></i>
                                            <div className="custom-input-file">{this.state.attachments_file ? <span>{this.state.attachments_file}</span> : <Translate text="attachments" />}</div>
                                            <input
                                                name="attachments"
                                                onChange={this.handleChange}
                                                type="file" multiple="multiple"
                                                accept="image/*,application/pdf" />
                                            <span><Translate text="fileSizePdf" /></span>
                                            <div className="attachedFilesPreview">
                                                <Row>
                                                    {attachmentsArray.map((value, index) => {
                                                        return (
                                                            <Col xs={12} md={6} lg={4} key={index}>
                                                                <div className="attachedFile" key={index} onClick={() => this.removeFile(value.id)}>
                                                                    <span className="deleteFileBtn">x</span>
                                                                    <span className="btnFileName">{value.file.name}</span>
                                                                </div>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Col>
                            <Col xs={11}>
                                <div className="form-group">
                                    <Row className=" justtify-content-center">
                                        <Col xs={12}>
                                            <label className="col-form-label pt-0"><Translate text="filedComplainBefore" /></label>
                                        </Col>
                                        <Col id="radio-answers" style={{ right: "16rem" }} xs={9}>
                                            <div className="form-check">
                                                <input className="form-check-input" value="no" type="radio" onChange={this.handleOptionChange} checked={this.state.values.old_complain === 'no'} name="complained_before" id="old_complain" />
                                                <label className="form-check-label" htmlFor="complained_before">
                                                    <Translate text="no" />
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" value="yes" type="radio" onChange={this.handleOptionChange} checked={this.state.values.old_complain === 'yes'} name="complained_before" id="old_complain" />
                                                <label className="form-check-label" htmlFor="complained_before">
                                                    <Translate text="yes" />
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={11} className="mb-4">
                                <Row className={"old-complain " + (this.state.values.old_complain == "no" ? "" : "height-auto")}>
                                    <Col xs={12}>
                                        <div className="form-group row">
                                            <label htmlFor="inputPassword" className="col-12 col-lg-3 col-form-label"><Translate text="nameSubmittingCompetent" /><span Style="color:red">*</span></label>
                                            <div className="col-12 col-lg-9">
                                                <div className="form-input">

                                                    <input
                                                        name="complaint_organization"
                                                        placeholder={t("nameSubmittingCompetent")}
                                                        value={this.state.values.complaint_organization}
                                                        onChange={this.handleChange}
                                                        type="text" required
                                                        onBlur={this.handleBlur} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="form-group row">
                                            <label htmlFor="inputPassword" className="col-12 col-lg-3 col-form-label"><Translate text="complainNumber" /><span Style="color:red">*</span></label>
                                            <div className="col-12 col-lg-9">
                                                <div className="form-input">

                                                    <input
                                                        name="complaint_number"
                                                        placeholder={t("complainNumber")}
                                                        value={this.state.values.complaint_number}
                                                        onChange={this.handleChange}
                                                        type="text" required
                                                        onBlur={this.handleBlur} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="form-group row">
                                            <label htmlFor="inputPassword" className="col-12 col-lg-3 col-form-label"><Translate text="complainDate" /><span Style="color:red">*</span></label>
                                            <div className="col-12 col-lg-9">
                                                <div className="form-input">
                                                    <DatePicker
                                                        onChange={this.onChange}
                                                        value={this.state.values.complain_date}
                                                        locale={this.props.lng}
                                                        format="d / M / yyyy"
                                                    />
                                                    {/* <input
                                                        name="complain_date"
                                                        placeholder={t("complainDate")}
                                                        value={this.state.values.complain_date}
                                                        onChange={this.handleChange}
                                                        type="text" required
                                                        onBlur={this.handleBlur} /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            {/* <Col xs={10} className="text-left complain-btn-contain">
                                <button
                                  className={"btn submit-cpa-btn " + (this.state.case != "" && this.state.case != "قضية اخري" ? "display-none" : "")}
                                  type="button"
                                  onClick={this.handleSubmit}
                                >اشتكي</button>
                                <button
                                    className={"btn submit-cpa-btn " + (this.state.case != "" && this.state.case != "قضية اخري" ? "" : "display-none")}
                                    type="submit"
                                    onClick={this.handleSubmit}
                                >اشتكي</button>
                            </Col> */}
                            <Col xs={11} className="submit-row">
                                <Recaptcha
                                    sitekey="6LdgyaQUAAAAAP773vGEpAbayR1-QSsN7ZZTV4-V"
                                    render="explicit"
                                    verifyCallback={this.verifyCallback}
                                    onloadCallback={this.loadCallback}
                                    expiredCallback={this.expiredCallback}
                                    hl={this.props.lng}
                                />
                            </Col>
                            <Col xs={11} className="submit-row btn">
                                {this.props.user.is_authenticated ?
                                    <button className="btn submit-cpa-btn" type="submit" onClick={this.handleCreateComplain}>
                                        <Translate text="complain" />
                                    </button>
                                    :
                                    <button className="btn submit-cpa-btn" type="button" onClick={this.siginFormOpen}>
                                        <Translate text="SignToComplain" />
                                    </button>
                                }
                            </Col>
                        </Row>
                        {
                            this.state.values.appear_message &&
                            <Row className={"another-case-content justify-content-center " + (this.state.values.appear_contact == 1 ? "" : "display-none")}>
                                <Col xs={12} md={8}>
                                    <Row className={"another-case-content justify-content-center " + (this.state.values.appear_contact == 1 ? "" : "display-none")}>{ReactHtmlParser(this.props.lng === 'ar' ? this.state.values.appear_message.text : this.state.values.appear_message.text_en)}</Row>
                                    {/* <Row className={"another-case-content justify-content-center " + (this.state.values.appear_contact == 1 ? "" : "display-none")}><i id="customIcon" className="icon-phone-1"></i><strong><Translate text="telephoneNumber" /></strong><span>&nbsp;935</span></Row> */}
                                    {/* <Row className={"another-case-content justify-content-center " + (this.state.values.appear_contact == 1 ? "" : "display-none")}><i id="customIcon" className="icon-link"></i><strong><Translate text="website" /></strong><a href="https://bainah.sa">&nbsp;bainah.sa</a></Row> */}
                                    {/* <Row className={"another-case-content justify-content-center " + (this.state.values.appear_contact == 1 ? "" : "display-none")}><i id="customIcon" className="icon-twitter"></i><strong><Translate text="twitterAccount" /></strong><a href="https://twitter.com/bainah_sa">&nbsp;https://twitter.com/bainah_sa</a></Row> */}
                                </Col>
                            </Row>
                        }
                    </div>
                </form>
            </div>
        )
    }

}

Complain.propTypes = {
    actions: PropTypes.object.isRequired,
    cases: PropTypes.array.isRequired,
    complain: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    toggleModal: PropTypes.func.isRequired,
    lng: PropTypes.string.isRequired,
    showError: PropTypes.func.isRequired,
    showSuccess: PropTypes.func.isRequired,
    types: PropTypes.array,
    "types.length": PropTypes.number,
    "types.map": PropTypes.func,
    complainParagraph: PropTypes.object,
    'complainParagraph.text': PropTypes.object,
    'complainParagraph.text_en': PropTypes.object,
};

function mapStateToProps(state) {
    return {
        user: state.user,
        cases: state.complain.cases,
        complain: state.complain.complain,
        types: state.complain.activityTypes,
        lng: state.user.lng,
        complainParagraph: state.complain.complainParagraph
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Complain)
