import React from 'react';
import BreadCrumb from '../../../GlobalComponent/BreadCrumb/BreadCrumb';
import ImgHeader from '../../../GlobalComponent/ImgHeader/ImgHeader';
import LandingCard from '../../../GlobalComponent/LandingCard/LandingCard';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../../actions/program-and-activities/programs/motamken';
import { Container, Row, Col } from 'react-bootstrap';
import './motamaken.scss';
import PropTypes from 'prop-types';
// import Translate from '../../../GlobalComponent/translate/translate';
import * as navigationActions from '../../../../actions/navigation';


class Motamaken extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      lng: localStorage.getItem('lng'),
      categories: [],
      routes: []
    };
  }

  componentDidMount() {
    // console.log('Do it for m');
    this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
      () => {
        this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
        this.props.actions.getMotamakenLandingData()
          .then(() => {
            this.setState({
              ...this.state, loading: false,
              content: true
            });
          }).catch(() => {
          });
      })
  }
  setRoutesFormApi(page) {

    let routes = []
    let temp = page.pread_crumb
    for (let index = 0; index < 3; index++) {
      routes.push({
        name: temp.menu_name,
        name_en: temp.menu_name_en,
        link: temp.slug
      })
      if (temp.pread_crumb) {
        temp = temp.pread_crumb
      } else {
        return routes.reverse();
      }
    }
  }
  render() {
    return (
      this.props.page &&
      <div className="motamaken-container" >
        <ImgHeader page={this.props.page} />
        <BreadCrumb
          routes={this.state.routes}
          filterbyYear={false}
          filterbySubject={false} />
        <div className="motamaken-landing">
          <Container>
            <Row>
              <Col xs={12}>
                {/* <p><Translate text="versedIntro" /></p> */}
              </Col>
              {this.state.loading && <div><p>Loading ...</p></div>}
              {this.state.content &&
                this.props.categories.map((obj, i) =>
                  <Col key={i} xs={12} sm={6} md={3}>
                    <LandingCard category={obj} />
                  </Col>
                )
              }
            </Row>
          </Container>
        </div>
      </div>
    );
  }

}

Motamaken.propTypes = {
  actions: PropTypes.object.isRequired,
  fuelSavings: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  page: PropTypes.object.isRequired,
  navigationActions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    categories: state.motamaken.categories,
    page: state.navigation.page
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    navigationActions: bindActionCreators(navigationActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Motamaken)
