import { GET_MEDIA_INTERVIEW } from '../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../initialState';

export default function mediaInterviewReducer(state = initialState.information_center.media_interview, action) {
    switch (action.type) {
        case GET_MEDIA_INTERVIEW.SUCCESS:
            return objectAssign({}, state, {
                new: action.payload.new,
                more: action.payload.more


            });
        default:
            return state;
    }
}