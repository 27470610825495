import { USER_LOGOUT, USER_LOGIN, REGISTER_ERROR, USER_REGISTER } from '../../constants/actionTypes';
// import axios from 'axios';

import { apiFetch } from '../../utils/http.helpers';
import { BASE_URL } from '../../constants/config';

export function login(data) {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/users/login', {
            method: 'POST',
            // mode: "cors",
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then((res) => {
                if (res.data.verified) {
                    dispatch({ type: USER_LOGIN.SUCCESS, payload: res.data });
                    localStorage.setItem('_cpaJwt', res.data.token);
                    localStorage.setItem('id', res.data.id);
                    localStorage.setItem('first_name', res.data.first_name);
                    localStorage.setItem('last_name', res.data.last_name);
                    localStorage.setItem('email', res.data.email);
                    localStorage.setItem('city', res.data.city);
                    localStorage.setItem('identification_number', res.data.identification_number);
                    localStorage.setItem('identification_path', res.data.identification_path);
                    localStorage.setItem('verified', res.data.verified);
                    dispatch(checkAuthTimeout(res.data.expire_time * 1000))
                }
                // else {
                //     localStorage.setItem('_cpaJwt', res.data.token);
                // }
            }).catch((err) => {
                throw err
                // console.log('Error in login', error);
            });
    }
}
export function register(data) {
    return function (dispatch) {
        return fetch(BASE_URL + '/api/users/register', {
            method: 'POST',
            // mode: "cors",
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(res => {
                // console.log("res", res)
                if (res.errors) {
                    dispatch({ type: REGISTER_ERROR.SUCCESS, payload: res.errors });
                } else {
                    dispatch({ type: USER_REGISTER.SUCCESS, payload: res.data });

                }
            }).catch((error) => {
                throw error;
            });
    }
}
export function requestForgotPassword(data) {
    // return function (dispatch) {
    return function () {
        return fetch(BASE_URL + '/api/users/forgot_password_' + Object.keys(data)[0], {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                response.json()
                // console.log(response);
            })
            .then(() => {
                // console.log(res);
            }).catch(() => {
                // console.log(err);

                // throw err
            });
    }
}

export function requestNewPassword(data) {
    // return function (dispatch) {
    return function () {
        return fetch(BASE_URL + '/api/users/change_password', {
            method: 'post',
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                // console.log(response;
                return response;
            })
        // .then(() => {
        // console.log(res);
        // store.dispatch({ type: USER_LOGOUT.SUCCESS, payload: {} });
        // localStorage.clear()
        // window.location.reload();
        // }).catch(() => { });
    }
}

export function verificationRequest(data) {
    return function (dispatch) {
        return fetch(BASE_URL + '/api/users/send/verification_code', {
            method: 'PUT',
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                // "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
            },
            body: JSON.stringify(data)
        })
            .then(Response => { return Response.json() })
            .then(Response => {
                if (Response.status === 200) {
                    dispatch({ type: USER_LOGIN.SUCCESS, payload: Response.data });
                }
                return Response;
            })
            .catch(() => {
            });
    }
}

export function checkAuthTimeout(expirationTime) {
    return function (dispatch) {
        setTimeout(() => {
            localStorage.removeItem('_cpaJwt');
            localStorage.removeItem('id');
            localStorage.removeItem('first_name');
            localStorage.removeItem('last_name');
            localStorage.removeItem('email');
            localStorage.removeItem('city');
            localStorage.removeItem('identification_number');
            localStorage.removeItem('identification_path');
            localStorage.removeItem('verified');
            // localStorage.clear()

            dispatch({ type: USER_LOGOUT.SUCCESS });
        }, expirationTime)
    }
}
