import { GET_SPEC_DATA } from '../../constants/actionTypes';
import { apiFetch } from '../../utils/http.helpers';
import { BASE_URL } from '../../constants/config';

export function getSpectializationsData() {
  return function (dispatch) {
    return apiFetch(BASE_URL+'/api/elgam3ya/search/?type=الاختصاصات', {
      method: "GET",
      // mode: "cors",
      // withCredentials: true,
      headers: {
        "Accept": "application/json",
        'Content-Type': 'application/json',
        // "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
      }
    })
      .then(response => response.json())
      .then(res => {
        // console.log('Res in fetch', res);
        dispatch({ type: GET_SPEC_DATA.SUCCESS, payload: res });
      }).catch(() => {
        // console.log('Error is', error);
      });
  };
}
