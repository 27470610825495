import { GET_CONSUMER_OFFICE_DATA } from '../../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './../../initialState';

export default function consumerOfficeReduser(state = initialState.programs_and_activities.programs.consumer_office, action) {
    switch (action.type) {
        case GET_CONSUMER_OFFICE_DATA.SUCCESS:
            return objectAssign({}, state, {
                office_data: action.payload,
            });
        default:
            return state;
    }
}