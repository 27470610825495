import React from 'react';
// import { Link } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import './pdfFiles.scss';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { BASE_URL } from '../../../constants/config';
import { withRouter } from 'react-router-dom';


const PdfFiles = props => {
    let filesArray = props.files
    let url = BASE_URL + '/uploads/';

    let pdfContent =
        props.match.url.includes('general-tips') || props.match.url.includes('previous-ratings') ?
            <Row>
                <Col className="pdfHeader" xs={12}>
                    <h4>{t('downloadFile')}</h4>
                </Col>
                {filesArray.map((value, index) => {
                    return (
                        <Col xs={12} sm={6} lg={4} key={index}>
                            <div className="card-file">
                                <a to={url + value.download_link} target="_blank" onClick={() => {
                                    window.open(url + value.download_link);
                                }} >
                                    <Row>
                                        <Col xs={2}>
                                            <i className="icon-special"></i>
                                        </Col>
                                        <Col xs={7}>
                                            <div className="cpa-tooltip"><h4>{props.lng === "ar" ? value.original_name : value.original_name_en}</h4>
                                                <span className="cpa-tooltiptext">{props.lng === "ar" ? value.original_name : value.original_name_en}</span>
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <i className="icon-download"></i>
                                        </Col>
                                    </Row>
                                </a>
                            </div>
                        </Col>
                    )
                })}
            </Row>
            :
            filesArray !== null &&
            <Row>
                <Col xs={12}>
                    <h4>{t('downloadFile')}</h4>
                </Col>
                {
                    filesArray.map((value, index) => {
                        return (
                            <Col xs={12} sm={6} lg={4} key={index}>
                                <div className="card-file">
                                    <a to={url + value.download_link} target="_blank" onClick={() => {
                                        window.open(url + value.download_link);
                                    }} >
                                        <Row>
                                            <Col xs={2}>
                                                <i className="icon-special"></i>
                                            </Col>
                                            <Col xs={7}>
                                                <div className="cpa-tooltip">
                                                    <h4>{value.original_name}</h4>
                                                    <span className="cpa-tooltiptext">{value.original_name}</span>
                                                </div>
                                            </Col>
                                            <Col xs={3}>
                                                <i className="icon-download"></i>
                                            </Col>
                                        </Row>
                                    </a>
                                </div>
                            </Col>
                        )
                    })}
            </Row>
    return (pdfContent);
}
PdfFiles.propTypes = {
    files: PropTypes.array,
    lng: PropTypes.string.isRequired
}
function mapStateToProps(state) {
    return {
        lng: state.user.lng
    };
}

export default withRouter(connect(mapStateToProps, null)(PdfFiles))
