const AR = {
    homePage: "الرئيسية",
    mainMenu: "القائمة الرئيسية",
    ourPrograms: "برامجنا",
    moreAbout: "المزيد عنا",
    test: "ابدأ",
    testIntro:
        "دائما وابدا نهتم بك ونسعى لحل مشكلتك فى اسرع وقت .. اذا كان لديك شكوى. من شئ معين لاتتردد فى تقديم شكوتك",
    testResult: "نتيجة الأختبار",
    servicesMayHelp: "خدمات تساعدك",
    servicesDes: "تقدم الجمعية مجموعة من الخدمات والمنتجات تساعدك دائما",
    LearnMore: "المزيد",
    showDetails: "قراءة التفاصيل",
    MoreServices: "اكتشف المزيد من الخدمات",
    moreServicesDesc: "سجل معنا واكتشف المزيد من الخدمات",
    actualStatistics: "احصائيات",
    statisticsDesc: "من خلال جمع وعرض تقارير البيانات التجارية، نهدف إلى دعم",
    consumer: "مستهلك",
    last_updated: "اخر تحديث في : ",
    followers: "متابع",
    services: "خدمة",
    join: "انضم الي اكثر من ",
    provides: "تقدم الجمعية اكثر من ",
    maillingList: "القائمة البريدية",
    maillingListDesc: "انضم إلى خدمة الرسائل البريدية",
    maillingListDesc2: "أكثر من 100 ألف مشترك",
    youremail: "أكتب بريدك الإلكتروني",
    subscribe: "اشترك الآن",
    follow: "تابعنا عبر",
    commonquestions: "الأسئلة الشائعة",
    privacyPolicy: "شروط الخصوصية",
    TermsConditions: "شروط الاستخدام",
    developedDesigned: "برمجة و تصميم شركة",
    seamlabs: "سييم لابز",
    copyright: "جميع الحقوق محفوظة لـ",
    cpa: "جمعية حماية المستهلك",
    cpadesc: "تعد جمعية حماية المستهلك إحدى مؤسسات المجتمع المدني",

    comming: "هذه الصفحة ستجدونها قريباً",
    goback: "الرجوع الي",
    notexistpage: "هذه الصفحة ليست موجوده حاليا",
    home: "الصفحه الرئيسيه",

    signIn: "تسجيل دخول",
    signRequired: "برجاء ادخال بياناتك الصحيحه لاكمال عملية التسجيل",
    email: "البريد الالكتروني :",
    email_signup: "البريد الالكتروني",
    email_confirmation: "تأكيد البريد الالكتروني",
    email_confirmation_mismatch: "عنواني البريد الالكتروني غير متطابقين",
    password_confirmation_mismatch: "كلمتي المرور غير متطابقين",
    password: "كلمة المرور",
    password_confirmation: "تأكيد كلمة المرور",
    remember: "تذكرني",
    forgotPass: "استرجاع كلمة المرور",
    newUser: "مستخدم جديد",
    firstName: "الأسم الأول",
    fullName: "الاسم ثلالثي",
    lastName: "اسم العائلة",
    phoneNumber: "رقم الجوال",
    userName: "اسم المستخدم",
    email_and_pass: "البريد الالكتروني وكلمة المرور",
    currentUser: "مستخدم حالي",
    changePassword: "تعديل كلمة المرور",
    createAccount: "انشاء حساب",

    association: "الجمعية",
    aboutUs: "من نحن",
    specialties: "الإختصاصات",
    associationOrganization: "التنظيم",
    Members: " الإدارة العليا",
    ChairmanBoard: " رئيس المجلس التنفيذي",
    councilMembers: "أعضاء المجلس التنفيذي",
    reserveMembers: "أعضاء المجلس التنفيذي الاحتياطيين",
    generalSecretariat: "الأمانة العامة",
    committees: "اللجان",
    standingCommittees: "لجان  دائمة",
    temporaryCommittees: "لجان مؤقتة",

    programsAndActivities: "البرامج والأنشطة",
    programs: "البرامج",
    recognize: "تعرف",
    versed: "متمكن",
    versedIntro: "تقدم جمعية حماية المستهلك مجموعة من الخدمات فى برنامج متمكن",
    subscribed: "مشترك",
    registered: "تم التسجيل",
    register: "سجل الآن",
    takeKnowledge: "خذ العلم",
    consumerOffice: "ديوان المستهلك",
    consumerConsultant: "مستشار المستهلك",
    ldalel_lshaml_sub_category: "الدليل الشامل",
    episodes: "الحلقات",
    testYourKnowledge: "اختبر معرفتك",
    legalAdvocacy: "المناصرة القانونية",
    fileComplaint: "قدم شكوى",
    pleaseSelect: "فضلا قم بإختيار ",
    selectIssue: "موضوع الشكوى",
    case: "القضية",
    downloadGuidear: " تحميل الدليل الخاص بكل وزارة  ",
    downloadGuideen: "تحميل الدليل الخاص بكل وزارة  ",
    // "downloadGuidear": " تحميل الدليل الخاص بكل وزارة باللغه العربيه",
    // "downloadGuideen": "تحميل الدليل الخاص بكل وزارة باللغه الانجليزيه",
    anotherCase: "اخري",
    personalData: "البيانات الشخصية",
    city: "المدينة",
    country: "الدولة",
    identityNumber: "رقم الإقامة/الهوية",
    AttachResidence: "ارفاق صورة الإقامة/الهوية",
    ComplainantData: "بيانات المشتكي عليه",
    NameFacilty: "اسم المنشأة/التاجر",
    activityType: "نوع النشاط",

    address: "العنوان :",
    faciltyNumber: "رقم هاتف المنشأة",
    complainDetials: "تفاصيل المشتكي",
    requests: "الطلبات",
    attachments: "المرفقات",
    filedComplainBefore: "هل سبق أن تقدمت بشكوى لأحد الجهات المختصة ؟",
    nameSubmittingCompetent: "اسم الجهة المقدم لها الشكوى",
    complainNumber: "رقم الشكوى",
    complainDate: "تاريخ تقديم الشكوى",
    complain: "اشتكي",
    // "sideDrawerComplain": "قدم شكوى",
    sideDrawerComplain: "مركز خدمة المستهلك ",
    sector: "قطاع",
    competent: "الجهة",
    details: "تفاصيل",
    contracts: "العقود الإسترشادية",

    impLinks: "روابط مهمة",
    notExist: "لا يوجد",

    guidedUs: "نسمعك",
    guideUsLoggedIn: "نسمعك",
    guideUsLoggedOut: "التقديم بعد الدخول",

    compareAndRate: "قارن و قيمها",
    "landing/3": "المبادرات",
    comprehensiveGuide: "الدليل الشامل لحقوق المستهلك",
    consumerAward: "جائزة حماية المستهلك",
    platforms: "المنصات والتطبيقات الإلكترونية",
    worldDays: "الأيام العالمية",

    world_days_workshops: "ورش عمل",
    info_graphic_view: "الاطلاع على الانفوجرافيك",
    related_news: "اخبار ذات صلة",
    agree: "موافق",

    mediaCenter: "المركز الإعلامي",
    news: "الأخبار",
    data: "البيانات",
    annualReports: "التقارير السنوية",
    MediaAppearances: "اللقاءات الإعلامية",
    callUs: "كن قريبا منا",
    connectWithUs: "تواصل معنا",
    membership: "العضوية",

    theMore: "المزيد",
    more: "مزيد",
    less: "أقل",
    of: "من",
    share: "مشاركة",
    yes: "نعم",
    no: "لا",
    new: "جديد",
    close: "خروج",
    finish: "إنهاء",
    next: "التالي",
    previous: "السابق",
    thousand: "الف",
    viewers: ":عدد المشاهدات",
    specifications: "مواصفات",
    generalTips: "نصائح عامه",
    previousRatings: "تقيمات سابقه",
    thePreviousRatings: "التقيمات السابقه",
    shareYourRating: "شاركنا تقييمك",
    ChooseToCompare: "اختر الخدمات/المنتجات المراد مقارنتها",
    CompareServices: "مقارنة الخدمات/المنتجات",
    // "compare": "قارن",
    ServiceProduct: "الخدمة/المنتج",
    notFound: "لا يوجد",
    successfullyRegistered: "تم التسجيل بنجاح",
    NumberRegistered: "عدد المسجلين",
    DateActivity: "تاريخ النشاط",
    Loading: "....تحميل",
    another: "اخري",
    incorrectData: "هذه البيانات غير صحيحة",
    // "PleaseContact":"يرجى التواصل مع مركز الاتصال الموحد لبلاغات المستهلك ' بينة ' على الرقم (935) او عبر الموقع الالكتروني.",
    orContatct: "او التطبيق ... او حساب تويتر",
    selectSector: "اختيار القطاع",
    select: "...اختر",
    ChooseProblem: "اختيار نوع الشكوى",
    SelectDestination: "اختيار الجهة",
    DetailsComplaint: "تفاصيل الشكوى",
    AddAttachments: "اضافة مرفقات",
    ImportantLinks: "روابط هامة",
    Fatwrty: "تطبيق فاتورتي",
    FatwrtyDesc: "اقتراح الأنظمة ذات الصلة بحماية المستهلك وتطويرها",
    scam: "منصة احتيال",
    RelatedNews: "أخبار ذات صلة",
    assoNews: "أخبار الجمعية",
    moreNews: "المزيد من الأخبار",
    relatedNews: "اخبار ذات صلة",
    assocData: "بيانات الجمعية",
    moreData: "المزيد من البيانات",
    download: "تحميل",
    view: "عرض",
    TelephoneIntervention: "مداخلة هاتفية",
    attendance_studio: "حضور استديو",
    ShareTVreport: "مشاركة في تقرير تلفزيوني",
    AttendanceStudio: "حضور استديو",
    ShareTVnews: "مشاركة في خبر تلفزيوني",
    AppDesc: "اقتراح وتطوير اللوائح ذات الصلة لحماية المستهلك",
    followComplaint: "تابع شكواك",
    ComplaintNumber: "رقم الشكوى",
    newaccount: "حساب جديد",
    noComplaint: "لا يوجد شكاوي",
    // Validation messages

    // Create Complain validation messages
    identifier_int_msg: "رقم الهوية يجب ان يكون رقم صحيح",
    phone_valid_msg: "رقم الهاتف يجب ان يكون رقم هاتف صحيح",
    siteMap: "خريطة الموقع",
    subscribed_succsess: "لقد تم الاشتراك بنجاح",
    noComment: "لم يتم التعليق",

    name: "إسمك الكريم",
    topic: "نوع الموضوع",
    message: "أكتب رسالتك هنا",
    complainHeader: "اذا كان لديك شكوي أو مقترح برجاء الضغط علي",

    CPA_Address: "عنوان بريدي :",
    Contact_Info: "تواصل معنا عبر",
    Phone: "هاتف : ",
    Free_number: "الرقم المجاني للاستفسارات والشكاوى :",
    Contact_us: "اتصل بنا",
    form1_header:
        "وتأمل الجمعية ممن لديهم الرغبة في التسجيل تعبئة البيانات التالية:",
    volunteer: "العمل التطوعي",
    volunteering_field: "مجال التطوع",
    Personal_info: "البيانات الشخصية",
    gender: "الجنس",
    social_status: "الحالة الاجتماعية",
    male: "رجل",
    Male: "ذكر",
    female: "أنثى",
    males: "رجال",
    females: "نساء",
    bothMalesAndFemales: "رجال و نساء",
    left: "متبقي",
    day: "يوم",
    educational_qualifications: "المؤهل التعليمي",
    voulnteer_time: "الوقت المناسب للتطوع",
    job: "المهنة",
    specs: " التخصص",
    birth_date: "تاريخ الميلاد",
    nationality: "الجنسية",
    area: "المنطقة",
    educational_degree: "الدرجة العلمية",
    educational_specialization: "التخصص",
    educational_school: "المدرسة / الجامعة",
    educational_gratuation_date: "تاريخ التخرج",
    educational_gpa: "المعدل التراكمي",
    educational_gpa_total: "من مجموع",
    educational_language: "اللغة الاساسية",
    educational_language_other: "اللغات الاخري",
    language_level: "المستوي",
    language_reading: "القراءة",
    language_writing: "الكتابة",
    language_speaking: "التحدث",
    month: "شهر",
    till_now: "حتي الان",
    year: "سنة",
    no_jop_apologize: "عفوا، لاتوجد وظائف حاليا.",
    job_start_date: "تاريخ بدا العمل",
    job_end_date: "تاريخ ترك العمل",
    have_professional_experience: "هل لديك خبرة مهنية؟",
    experience_site: "الموقع",
    experience_comapny_name: "اسم الشركة",
    experience_comapny_field: "قطاع الشركة",
    experience_job_role: "الدور الوظيفي",
    experience_job_title: "مسمى المنصب في الشركة",
    experience_job_description: "وصف العمل",
    training_add_type: "تود اضافة؟",
    training: "تدريب",
    certificate: "شهادة",
    certficateExist: "يوجد شهادة",
    noCertificate: "لا يوجد شهادة",
    training_title: "عنوان التدريب",
    training_institute_name: "اسم معهد التدريب",
    training_start_date: "تاريخ البدء",
    training_total_hours: "مجموع الساعات",
    training_name_certificate: "اسم الشهادة",
    training_name_certificate_date: "تاريخ اصدار الشهادة",
    training_name_certificate_valid_to: "صالحة الى",
    cv_attachment: "السيرة الذاتية",
    next_step: "الخطوة التالية",
    nextStep: "الخطوة التالية",
    volunteer_q1: "هل أنت عضو في الجمعية؟",
    volunteer_q2: "هل أنت مهتم بأنشطة الجمعية؟",
    volunteer_q3: "ما هي مقترحاتك للعمل التطوعي في الجمعية؟",
    volunteer_q4: "المدينة المرغوب التطوع بها",
    volunteer_q5: "أوقات التطوع المناسبة",
    volunteer_day: "الصباحية",
    volunteer_night: "المسائية",
    sunday: "الاحد",
    monday: "الاثنين",
    tuesday: "الثلاثاء",
    wednesday: "الاربعاء",
    thursday: "الخميس",
    friday: "الجمعة",
    saturday: "السبت",
    writeSuggestions: "أكتب اقتراحاتك",
    jobs: "التوظيف",
    current_jobs_available: "الوظائف المتوفرة الحالية :",
    current_volunteer_available: "الفرص التطوعية الحالية :",
    submit_a_form: "تقديم استمارة",
    nojobs: "لا يوجد وظائف متاحة في الوقت الحالي",
    appologize_msg:
        "عفواً لا يوجد وظائف متاحة في الوقت الحالي، يمكنك ترك البريد الإلكتروني",
    job_app: "التقديم على وظيفة",
    app_msg: "برجاء تعبئة البيانات التالية بالكامل لإكمال الوظيفة",
    upload_cv: "إرفاق السيرة الذاتية",
    cv_msg: "يمكنك أيضاَ التقديم على وظيفة من خلال إرفاق سيرتك الذاتية",
    mp3: "الصوتيات",
    maxProductsReached: "لا يمكنك اختيار أكثر من 3 منتجات",

    GuideLines: "الأرشادات",
    UserProfile: "ملف تعريفي للمستخدم",
    userData: "بيانات المستخدم",
    accountInfo: "بيانات الحساب",
    personalInfo: "بيانات شخصية",
    educationalInfo: "المعلومات التعليمية",
    experience: "الخبرة",
    trainingsAndCertificates: "التدريب والشهادات",
    cvResume: "السيرة الذاتية",
    complainNo: "رقم الشكوي",
    description: "الوصف",
    status: "الحالة",
    response: "الرد",
    oldPass: "كلمة المرور القديمة",
    NewPass: "كلمة المرور الجديدة",
    submit: "ارسال",
    changePass: "تغير كلمة المرور",
    noItem: "لا يوجد اي بيانات فالوقت الحالي",
    awardHeader: "الفائزين بجائزه حماية المستهلك",
    openningHours:
        "ساعات الدوام الرسمي من الساعة 8:30 صباحاً حتى الساعة 3:30مساء من الأحد إلى الخميس",
    msg: "أكتب رسالتك هنا",
    send: "إرسال",
    communicateThrough:
        "كما يمكنك التواصل معنا من خلال النموذج التالي، وسيكون الرد في أقرب وقت ممكن",
    CpaIntro: "جمعية حماية المستهلك ترحب بكم",
    content: "المحتويات",
    en: "English",
    ar: "Arabic",
    genralTopic: "موضوع عام",
    noData: "لا يوجد بيانات فالوقت الحالي",
    noProducts: "لا يوجد منتجات للمقارنة في الوقت الحالي",
    noProductsFor: "  لا يوجد منتجات للمقارنة في الوقت الحالي ل",
    beta: "نسخه تجريبية",
    serviceintro:
        "المرجع الشامل لأهم حقوق وواجبات المستهلكين التي كفلتها لهم الأنظمة",
    authAlert: "للمتابعة يرجي تسجيل الدخول أولا",
    complainAlert: "من فضلك قم بالتأكيد بأنك لست روبوت",
    organizeTitle: "تنظيم جمعية حماية المستهلك",
    signOut: "تسجيل خروج",
    SignToComplain: "تسجيل الدخول للمتابعة",
    underDevelopment: "قيد التطوير",
    complainSucess: "تم انشاء الشكوي بنجاح",
    complainfail: "حدث خطأ في تقديم الشكوي, برجاءالمحاوله مرة اخر",
    complainfailAuth: "حدث خطأ في تقديم الشكوي, برجاء تسجيل الدخول",
    quizIntro: "معرفة حقوقك كمستهلك يزيد من جودة الخدمات المقدمة لك",
    quizIntro2: "اختبر الآن درجة معرفتك بحقك كمستهلك",
    from: "من",
    to: "الي",
    to_do: "سيتم العمل عليها",
    solved: "تم حلها",
    closed: "اغلقت بدون حل",
    in_progress: "قيد التنفيذ",
    directed_to: "تم التوجيه",
    closed_sol: "مغلقة",
    out_of_speciality: "خارج اختصاص الجمعية",
    legal_advice: "استشارة قانونية",
    solved_by_company: "تم الحل من قبل الشركة",
    solved_by_ministry: "تم الحل من قبل الوزارة",
    sent_to: "تمت الاحالة",
    switched_of_mobile: "الجوال مغلق",
    no_reply: "لم يتم الرد",
    directed_to_court: "تم توجيهه للقضاء",
    notAuth: "غير مصرح به ، يرجى تسجيل الدخول",
    fileSize: "حجم الملف يجب ألا يزيد عن 5 ميجا نوع الملف المقبول:jpg/png",
    fileSizePdf:
        "حجم الملف يجب ألا يزيد عن 5 ميجا نوع الملف المقبول:jpg/png/pdf",
    complainPending: "جار ارسال شكواك",
    guideUsIntro:
        'انطلاقاً من دور جمعية حماية المستهلك بالعناية بشؤون المستهلك وتبني قضاياه لدى الجهات العامة والخاصة ولما للمستهلك من دور مهم في مساندة جهود الجمعية عن طريق مشاركتها بكل ما يمس المستهلك بغرض الاطلاع عليه ودراسته، أنشأنا خدمة " نسمعك" لأي موضوع تقترح تسليط الضوء عليه، وبإمكانكم الاطلاع على الشكاوى المتخصصة التي تدرسها جمعية حماية المستهلك حاليا',
    pleasetypecomplaintnumber: "يرجى كتابة رقم الشكوى",
    numbererr: "يجب أن يبدأ الرقم بالرقم 1 أو 2",
    numLimit: "يجب ألا يتجاوز الرقم 10 أرقام أو أن يكون أقل من 10 أرقام",
    cityErr: "يجب ألا يحتوي على أي رقم",
    formError: "يرجى ملء الحقول المطلوبة!",
    customerGuideTitle: "الدليل الشامل لحقوق المستهلك",
    TheMinistryofInvestment: "وزارة الاستثمار",
    ChiefofExecutiveBoard: "رئيس المجلس التنفيذي",
    "Eng.AbdullahbinAliAlna'eim": "م. عبد الله علي النعيم",
    ExecutiveBoardMembers: "أعضاء المجلس التنفيذي",
    "Eng.AnasbinNaserAlhmeid": "م. أنس ناصر الحميد",
    "Eng.MagidbinSa'dAl'Esamy": "م. ماجد سعد العصيمي",
    ExecutiveBoardMember: "عضو المجلس التنفيذي",
    "Mr.FaysalbinAbdelwahabAlseif": "أ. فيصل عبد الوهاب السيف",
    "Eng.MagidbinSalihAlmarzam": "م. ماجد صالح المرزم",
    "Eng.SaudbinRashidAl'Askar": "م. سعود راشد العسكر",
    "Dr.FahdbinMohammadAlmagid": "د.فهد محمد الماجد",
    "Mr.FaysalAbdullahAlabdAlkarim": "أ.فيصل عبدالله العبدالكريم",
    "Mr.MagidbinIbrahimAlmuhaymid": "أ.ماجد ابراهيم المحيميد",
    GeneralSecretariat: "الأمانة العامة",
    "Dr.SamarbinAbdullahAlqahtani": "د. سمرعبد الله القحطاني",
    GeneralSecretary: "الأمين العام",
    ExecutiveBoardReserveMembers: "أعضاء المجلس التنفيذي الاحتياطيين",
    "Dr.MohammadbinSaqirAl'Ureini": "د. محمد صقير العريني",
    ReserveMember: "عضوأً احتياطيا",
    "Dr.TurkibinAbdulazizAlthnayyan": "د. تركي عبد العزيز الثنيان",
    "Mrs.FaridahbintMohammadAlsuheil": "أ. فريدةمحمد الصهيل",
    DownloadTheApp: "تنزيل التطبيق",
    appIdea:
        "تم إنشاء هذا التطبيق لتسهيل حفظ الفواتير والضمانات بطريقة مبسطة ومميزة، ويأتي ذلك نظراً لما رصدته الجمعية من عدم احتفاظ العديد من المستهلكين بالفواتير والضمانات ومن ثم فقدان مطالبتهم بحقوقهم عند الحاجة لها.",
    appDef:
        "هو تطبيق لحفظ صور فواتير المشتريات والضمانات الخاصة بالمستهلك ويمكنه من استرجاعها ومشاركتها وطباعتها متى شاء.",
    appObjective: "فكرة التطبيق:",
    appFunc: "التعريف بالتطبيق:",
    appChar: "مميزات التطبيق:",
    appChar1: "حفظ صور فواتير المشتريات والضمانات.",
    appChar2: "يصدر التطبيق إشعارات لتنبيه المستهلك قبل انتهاء مدة الضمان.",
    appChar3: "إمكانية رفع الصور على خدمة “جوجل درايف” الخاص بالمستخدم",
    appChar4: "إمكانية مطالعة المواد التوعوية وتحميلها ومشاركتها.",
    weHearYou:
        'انطلاقاً من دور جمعية حماية المستهلك بالعناية بشؤون المستهلك وتبني قضاياه لدى الجهات العامة والخاصة ولما للمستهلك من دور مهم في مساندة جهود الجمعية عن طريق مشاركتها بكل ما يمس المستهلك بغرض الاطلاع عليه ودراسته، أنشأنا خدمة " نسمعك" لأي موضوع تقترح تسليط الضوء عليه، وبإمكانكم الاطلاع على الشكاوى المتخصصة التي تدرسها جمعية حماية المستهلك حاليا',
    search: "بحث..",
    complain1: "اشتكي",
    servicese: "الخدمات",
    employmentVolunteer: "الوظائف و التطوع",
    coursesAndLectures: "الدورات التدريبية والمحاضرات",
    settings: "الاعدادات",
    edit: "تحرير",
    save: "حفظ",
    Ministry: "وزارة",
    departements: "الاقسام",
    MinistryOfHealth: "وزارة الصحة",
    publicRights: "الحقوق العامة",
    // "fatortyIntro": "هو تطبيق لحفظ صور فواتير المشتريات والضمانات الخاصة بالمستهلك يُمكنه من استرجاعها ومشاركتها وطباعتها متى شاء.",
    compareIntro:
        "هو برنامج يتيح للمستهلك الاطلاع على مقارنات أسعار عددٍ من المنتجات والخدمات لدى بعض المؤسسات والشركات، واختيار ما يناسبه منها. ومن ثّم تقييم المنتجات والخدمات التي قاموا بشرائها وفق عددٍ من المعايير",
    mainBranch: "المركز الرئيسي / مدينة الرياض",
    compare1: "قارنها",
    compare: "قارن",
    assocAddress:
        "حي السليمانية – شارع الأمير عبدالعزيز مساعد جلوي (الضباب) – قبل تقاطع طريق الملك عبد العزيز. مبنى رقم 8775 الدور الثاني – مكتب رقم 11-12",
    assocAddress2:
        "8775 الأمير عبدالعزيز مساعد جلوي – السليمانية، وحدة رقم (1) الرياض 12234-2949",
    fax: "فاكس :",
    weheardyou: "سمعنا لك",
    complains: "الشكاوى",
    suggestions: "المقترحات",
    viewCertificate: "الشهادة",
    catFilter: "تصنيف حسب القطاع",
    yearFilter: "تصنيف حسب العام",
    succsess: "تمت بنجاح",
    fillAllFields: "برجاء ملئ كل الخانات المطلوبة*",
    Token_is_Invalid: "انتهي الوقت المسموح,برجاء اعادة الدخول",
    RecentDeclarations: "جديد البيانات",
    newNews: "جديد الاخبار",
    newMedia: "جديد اللقاءات الإعلامية ",
    complaintNumber: "رقم الشكوى",
    aboutComplaint: "موضوع الشكوى",
    complaintDate: "تاريخ الشكوى",
    complaintStatus: "حالة الشكوى",
    telephoneNumber: "تليفون رقم:",
    website: "الموقع الكتروني:",
    twitterAccount: "حساب تويتر:",
    suggestionCount:
        "مجموع ما ورد للجمعية هو {{count}} شكوى تقترح النظر في هذا الموضوع",
    downloadFile: "لتحميل و عرض الملفات",
    emailExist: "البريد الإلكتروني  تم أخذه.",
    phoneExist: "الهاتف  تم أخذه.",
    emailInCorrect: "البريد الالكتروني غير صحيح",
    phone1: " ٢٧٩٢٣٦٨ ١١ ٩٦٦+",
    fax1num: "٢٧٩٠١٦٠ ١١ ٩٦٦+",
    phone2: "٨٠٠١١٦٠٠٨٧",
    egy_code: "+۲۰",
    sa_code: "+۹٦٦",
    forgotPassword: "هل نسيت كلمة المرور؟",
    resetPassword: "إعادة تعيين كلمة المرور",
    resetByEmail: "بواسطة البريد الالكتروني",
    resetByMobile: "بواسطة الجوال",
    sendCode: "سوف يتم ارسال رابط اعاده تعيين كلمه السر علي البريد الخاص بك",
    sendCodePhone: "سوف يتم ارسال كود الحمايه علي الجوال الخاص بك",
    codeSended: "تم ارسال رابط اعاده تعيين كلمه السر علي البريد الخاص بك",
    codePhoneSended: "تم ارسال كود الحمايه علي الجوال الخاص بك",

    sendAttendanceCode: "سيتم ارسال الكود الخاص بالحضور علي البريد الخاص بك",
    fillFirstName: "الاسم الاول مطلوب",
    fillLastName: "الاسم الاخير مطلوب",
    fillPassword: "الرقم السري مطلوب",
    fillPhone: "رقم الهاتف مطلوب",
    fillCity: "المدينه مطلوبه",
    fillEmail: "البريد الالكتروني مطلوب",
    verification: "التحقق",
    verificationCode: "كود الحمايه",
    newPassword: "الرقم السري الجديد",
    retryPassword: "تأكيد الرقم السري",
    retryPasswordError: "الرقم السري غير مطابق",
    verificationMsg: "برجاء ادخال كود التحقق لكي تستطيع تسجيل دخولك",
    verification_error: "برجاء التأكد من كود التحقق",
    passwordChanged: "تم تغيير كلمة السر",
    verification_empty: "برجاء ادخال كود التحقق",
    emailMissing: " برجاء ادخال البريد الالكتروني",
    types: "الأنواع",
    models: "الموديلات",
    pleaseChoose: "-يرجي الاختيار-",
    set_suggestion: "تقديم اقتراح",
    worked_on: "تم العمل على ...........",
    already_working_on: "جاري العمل على ",
    recivedComplains: "الشكاوي المقدمة",
    gotResived: "تم استلا م.............",
    ComplainantsNo: "عدد المشتكين",
    statistics: "احصاءات",
    login_for_submit: "سجل الدخول للمتابعة",
    technicalProblem: "  توجد مشكله فنيه",
    willgoback: "سنعود بعد قليل ",
    cookieMessage:
        "لا يقتصر جمع البيانات وتخزينها على المعلومات الشخصية المقدمة من قبل المستخدم، بل تشمل أيضاً، البيانات التي ترسل من قبل متصفح المستخدم عند زيارة الموقع (Log Data) بالإضافة لـرقم عنوان الانترنت الـ (IP)  للجهاز المستخدم ونوع المتصفح ، وإصدار المتصفح ، وصفحات موقعنا التي تزورها ، ووقت وتاريخ زيارتك ، والوقت الذي تقضيه على هذه الصفحات وغيرها الإحصاء. بالإضافة إلى ذلك.",
    do_you_have_trainig: "هل لديك خبرة تدريبية أو شهادات ؟ ",
};

export default AR;
