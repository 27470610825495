import {GET_CONSUMER_AWARDS} from '../../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../../initialState';

export default function consumerAwardsReducer(state = initialState.programs_and_activities.consumer_award, action) {
    switch (action.type) {
        case GET_CONSUMER_AWARDS.SUCCESS:
          return objectAssign({}, state, {awards: action.payload});
        default:
          return state;
      }
}