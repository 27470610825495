import React from 'react';
import './IdCard.scss';
import PropTypes from 'prop-types';
import Translate from '../../GlobalComponent/translate/translate'
import { BASE_URL } from '../../../constants/config';



const IdCard = props => {
    let userTypeAr;
    let userTypeEn;
    if (props.user.type === "رئيس المجلس التنفيذي") {
        userTypeAr = 'رئيس المجلس التنفيذي';
        userTypeEn = 'Chief of Executive Board';
    }
    else if (props.user.type === "أعضاء المجلس التنفيذي") {
        userTypeAr = 'عضو المجلس التنفيذي';
        userTypeEn = 'Executive Board Member';
    }
    else if (props.user.type === "الأمانة العامة") {
        userTypeAr = 'الأمين العام';
        userTypeEn = 'General Secretary';
    }
    else if (props.user.type === "أعضاء المجلس التنفيذي الاحتياطيين") {
        userTypeAr = 'عضوأً احتياطيا';
        userTypeEn = 'Reserve Member';
    }

    let cardContent =
        localStorage.getItem('lng') === "ar" ?
            <div className="card-content col-12 col-md-6 pr-0 pl-0">
                <h4><Translate text={props.user.name} /></h4>
                {/* <p><Translate text={props.user.type} /></p> */}
                {userTypeAr && <p>{userTypeAr}</p>}
                <div className="card-links">{props.children}</div>
            </div>
            :
            <div className="card-content col-12 col-md-6 pr-0 pl-0">
                <h4><Translate text={props.user.name_en} /></h4>

                {/* <p><Translate text={props.user.type} /></p> */}
                {userTypeEn && <p>{userTypeEn}</p>}

                <div className="card-links">{props.children}</div>
            </div>

    return (
        <div className="container-id-card">
            <div className="card-row row">
                <div className="card-img col-12 col-md-6">
                    <img className="shadow-sm" src={props.user.image ? BASE_URL + '/uploads/' + props.user.image : BASE_URL + '/uploads/' + "Images/default_image.jpg"} />
                </div>
                {cardContent}
            </div>
        </div>
    );
}
IdCard.propTypes = {
    user: PropTypes.object,
    main: PropTypes.bool,
    children: PropTypes.children
};
export default IdCard;
