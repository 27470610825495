import React from 'react';
import ImgHeader from '../GlobalComponent/ImgHeader/ImgHeader';
import { Container } from "react-bootstrap";
import ReactHtmlParser from 'react-html-parser';
import BreadCrumb from '../GlobalComponent/BreadCrumb/BreadCrumb';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import './PrivacyPolicy.scss';
import { apiFetch } from '../../utils/http.helpers';
import { BASE_URL } from '../../constants/config';


class PrivacyAndPolicy extends React.Component {
  constructor() {
    super();
    this.state = {
      contentAr: null,

      contentEn: null,
      routes: [
        {
          name: 'شروط الخصوصية',
          name_en: 'Privacy Policy',
          link: 'privacy-policy'
        }
      ],
      page: {
        name: 'شروط الخصوصية',
        name_en: 'Privacy Policy'
      }
    }
  }

  componentDidMount() {
    apiFetch(BASE_URL + '/api/paragraph/getPrivacyTerms', {
      method: "GET",
      Accept: "application/json"
    })
      .then(response => response.json())
      .then(response => {
        this.setState({
          contentAr: response.text,
          contentEn: response.text_en
        })
      })
  }
  render() {
    return (
      <div>
        <ImgHeader page={this.state.page} />
        <BreadCrumb
          routes={this.state.routes}
          filterbyYear={false}
          filterbySubject={false} />
        <Container className="privacyPolicy-container">
          {this.props.lng === 'ar' ?
            ReactHtmlParser(this.state.contentAr)
            :
            ReactHtmlParser(this.state.contentEn)
          }
        </Container>
      </div>
    )
  }
}

PrivacyAndPolicy.propTypes = {
  lng: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
  return {
    lng: state.user.lng,
  };
}
export default connect(mapStateToProps)(PrivacyAndPolicy);
