import { GET_MEDIA_INTERVIEW } from '../../constants/actionTypes';
import { apiFetch } from '../../utils/http.helpers';
import { BASE_URL } from '../../constants/config';

export function getMediaInterviewData(pageNumber, year, category) {
  return function (dispatch) {
    return apiFetch(BASE_URL + `/api/media_center/search?page=${pageNumber}&type=meetings 
    ${year !== undefined ? `&filters[year]=${year}` : ""}
    ${category !== undefined ? `&filters[category]=${category}` : ""}`
      , {
        method: "GET",
        // mode: "cors",
        withCredentials: true,
        headers: {
          "Accept": "application/json",
          // "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
        }
      })
      .then(response => response.json())
      .then(res => {
        // console.log('Res in fetch', res);
        dispatch({ type: GET_MEDIA_INTERVIEW.SUCCESS, payload: res });
      }).catch(() => {
        // console.log('Error is', error);
      });
  };
}
