import React from 'react';
import './contacts-info.scss';
import { Col } from "react-bootstrap";
import { t } from 'i18next';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';


class ContactAddress extends React.Component {
    constructor() {
        super();
    }
    render() {
        return (
            <Col xs={12} sm={12} lg={6}>
                <div style={{ marginBottom: "14rem" }} className="address-content">
                    <Col xs={12} sm={12} lg={11}>
                        <div className="address-container">
                            <p><i className="icon-location"></i><strong>{t("address")}</strong>{this.props.main_center_address}</p>
                        </div>
                        <Col xs={12} sm={9} lg={12}>
                            {
                                ReactHtmlParser(this.props.location)
                            }
                        </Col>

                        {/* <MapContainer></MapContainer> */}
                    </Col>
                </div>
            </Col>
        )
    }
}

ContactAddress.propTypes = {
    main_center_address: PropTypes.string.isRequired,
    main_center_name: PropTypes.string.isRequired,
    mail_address: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
}
export default ContactAddress;