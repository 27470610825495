import React from 'react';
import './searchResult.scss';
import ImgHeader from '../ImgHeader/ImgHeader';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { BASE_URL } from '../../../constants/config';
import { apiFetch } from '../../../utils/http.helpers';
import { t } from 'i18next';



class SearchResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            routes: [
                {
                    name: "نتائج البحث",
                    name_en: "Search Result",
                    link: "",
                }
            ],
            consumerAdvisor: [],
            el3okod: [],
            complaints: [],
            menuBuilder: [],
            mediaCenter: [],
            motamaken: [],
            recognize: [],
            takeKnowledge: [],
            worldDays: [],
            importantLinks: [],
            ldalel_lshaml_sub_category: []
        }
    }

    getSearchResult() {
        apiFetch(BASE_URL + '/api/ElasticSearch/getElasticSearch?searchText=' + this.props.match.params.search, {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json"
            }
        })
            .then(response => response.json())
            .then(response => {
                this.setState({
                    loading: false,
                    consumerAdvisor: response.consumerAdvisor,
                    el3okod: response.el3okod,
                    complaints: response.ershdna_categories,
                    menuBuilder: response.menuBuilder,
                    mediaCenter: response.mediaCenter,
                    motamaken: response.motamaken,
                    recognize: response.ta3araf,
                    takeKnowledge: response.takeKnowledge,
                    worldDays: response.worldDays,
                    importantLinks: response.importantLinks,
                    ldalel_lshaml_sub_category: response.ldalel_lshaml_sub_category
                })
            })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.search !== this.props.match.params.search && this.props.match.params.search !== null) {
            this.setState({ loading: true })
            this.getSearchResult();
        }
    }

    componentDidMount() {
        this.getSearchResult();
    }

    render() {

        moment.locale(this.props.lng);
        return (
            <>
                {
                    this.state.loading &&
                    <div className="over-lay" >
                        {this.props.lng === 'en' ? 'Loading...' : 'تحميل...'}
                    </div>
                }
                <ImgHeader page={"نتائج البحث"} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                <div className="search-container">

                    {
                        this.state.consumerAdvisor.length === 0 &&
                        this.state.el3okod.length === 0 &&
                        this.state.complaints.length === 0 &&
                        Object.values(this.state.menuBuilder).length === 0 &&
                        Object.values(this.state.mediaCenter).length === 0 &&
                        this.state.motamaken.length === 0 &&
                        this.state.recognize.length === 0 &&
                        this.state.takeKnowledge.length === 0 &&
                        this.state.worldDays.length === 0 &&
                        this.state.importantLinks.length === 0 &&
                        this.state.ldalel_lshaml_sub_category.length == 0 &&
                        <h4>{this.props.lng === 'en' ? 'No Search Results' : 'لا توجد نتائج للبحث'}</h4>
                    }

                    {
                        this.state.ldalel_lshaml_sub_category.length > 0 &&
                        <h4>{t('ldalel_lshaml_sub_category')}</h4>
                    }
                    {
                        this.state.ldalel_lshaml_sub_category.length > 0 &&
                        this.state.ldalel_lshaml_sub_category.map(ldalel => {

                            return (
                                <Link key={ldalel.id} to={`/guide-book/${ldalel.category_id}/${ldalel.id}`} >
                                    <div className="consumer-container bg-white shadow-sm" >
                                        <div className="card-img">
                                            <img src={ldalel.image !== null ? (BASE_URL + '/uploads/' + ldalel.image) : (BASE_URL + '/uploads//Images/default_image.jpg')} />
                                        </div>
                                        <div className="card-content bg-white">
                                            <div className="cpa-tooltip"><h4>{this.props.lng === "ar" ? ldalel.title : ldalel.title_en}</h4>
                                                <span className="cpa-tooltiptext">{this.props.lng === "ar" ? ldalel.title : ldalel.title_en}</span>
                                            </div>
                                            <div className="card-viewData row">
                                                <div>
                                                    <i className="icon-calendar"></i><span>{moment(ldalel.updated_at).format('dddd Do MMMM YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })
                    }
                    {
                        this.state.consumerAdvisor.length > 0 &&
                        <h4>{t('consumerConsultant')}</h4>
                    }
                    {
                        this.state.consumerAdvisor.length > 0 &&
                        this.state.consumerAdvisor.map(consumer => {
                            return (
                                <Link key={consumer.id} to={`/consumer-advisor/${consumer.id}`} >
                                    <div className="consumer-container bg-white shadow-sm" >
                                        <div className="card-img">
                                            <img src={consumer.image !== null ? (BASE_URL + '/uploads/' + consumer.image) : (BASE_URL + '/uploads//Images/default_image.jpg')} />
                                        </div>
                                        <div className="card-content bg-white">
                                            <div className="cpa-tooltip"><h4>{this.props.lng === "ar" ? consumer.title : consumer.title_en}</h4>
                                                <span className="cpa-tooltiptext">{this.props.lng === "ar" ? consumer.title : consumer.title_en}</span>
                                            </div>
                                            <div className="card-viewData row">
                                                <div>
                                                    <i className="icon-calendar"></i><span>{moment(consumer.updated_at).format('dddd Do MMMM YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })
                    }

                    {
                        Object.values(this.state.mediaCenter).length > 0 &&
                        Object.values(this.state.mediaCenter).filter(media => media.type == 'news').length > 0 &&
                        <h4> {t('news')}</h4>
                    }
                    {
                        Object.values(this.state.mediaCenter).length > 0 &&
                        Object.values(this.state.mediaCenter).map(media => {
                            if (media.type === "news") {
                                return (
                                    <Link key={media.id} to={`/single-news/${media.id}`}>
                                        <div className="consumer-container bg-white shadow-sm" >
                                            <div className="card-img">
                                                <img src={media.image !== null ? (BASE_URL + '/uploads/' + media.image) : (BASE_URL + '/uploads//Images/default_image.jpg')} />
                                            </div>
                                            <div className="card-content bg-white">
                                                <div className="cpa-tooltip"><h4>{this.props.lng === "ar" ? media.title : media.title_en}</h4>
                                                    <span className="cpa-tooltiptext">{this.props.lng === "ar" ? media.title : media.title_en}</span>
                                                </div>
                                                <div className="card-viewData row">
                                                    <div>
                                                        <i className="icon-calendar"></i><span>{moment(media.updated_at).format('dddd Do MMMM YYYY')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            }
                        })
                    }

                    {
                        Object.values(this.state.mediaCenter).length > 0 &&
                        Object.values(this.state.mediaCenter).filter(media => media.type === 'data').length > 0 &&
                        < h4 > {t('data')}</h4>
                    }
                    {
                        Object.values(this.state.mediaCenter).length > 0 &&
                        Object.values(this.state.mediaCenter).map(media => {
                            if (media.type === "data") {
                                return (
                                    <Link key={media.id} to={`/single-data/${media.id}`}>
                                        <div className="consumer-container bg-white shadow-sm" >
                                            <div className="card-img">
                                                <img src={media.image !== null ? (BASE_URL + '/uploads/' + media.image) : (BASE_URL + '/uploads//Images/default_image.jpg')} />
                                            </div>
                                            <div className="card-content bg-white">
                                                <div className="cpa-tooltip"><h4>{this.props.lng === "ar" ? media.title : media.title_en}</h4>
                                                    <span className="cpa-tooltiptext">{this.props.lng === "ar" ? media.title : media.title_en}</span>
                                                </div>
                                                <div className="card-viewData row">
                                                    <div>
                                                        <i className="icon-calendar"></i><span>{moment(media.updated_at).format('dddd Do MMMM YYYY')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            }
                        })
                    }
                    {/* {
                        this.state.mediaCenter.length > 0 &&
                        <h4> {t('meetings')}</h4>
                    }
                    {
                        this.state.mediaCenter.length > 0 &&
                        this.state.mediaCenter.map(media => {
                            if (media.type === "meetings") {
                                return (
                                    <Link to={``}>

                                    </Link>
                                    <div className="consumer-container bg-white shadow-sm" >
                                        <div className="card-img">
                                            <img src={media.image !== null ? (BASE_URL + '/' + media.image) : (BASE_URL + '/Images/default_image.jpg')} />
                                        </div>
                                        <div className="card-content bg-white">
                                            <div className="cpa-tooltip"><h4>{this.props.lng === "ar" ? media.title : media.title_en}</h4>
                                                <span className="cpa-tooltiptext">{this.props.lng === "ar" ? media.title : media.title_en}</span>
                                            </div>
                                            <div className="card-viewData row">
                                                <div>
                                                    <i className="icon-calendar"></i><span>{moment(media.date).format('dddd Do MMMM YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    } */}

                    {/* {
                        this.state.mediaCenter.length > 0 &&
                        <h4> {t('reports')}</h4>
                    }
                    {
                        this.state.mediaCenter.length > 0 &&
                        this.state.mediaCenter.map(media => {
                            if (media.type === "reports") {
                                return (
                                    <div className="consumer-container bg-white shadow-sm" >
                                        <div className="card-img">
                                            <img src={media.image !== null ? (BASE_URL + '/' + media.image) : (BASE_URL + '/Images/default_image.jpg')} />
                                        </div>
                                        <div className="card-content bg-white">
                                            <div className="cpa-tooltip"><h4>{this.props.lng === "ar" ? media.title : media.title_en}</h4>
                                                <span className="cpa-tooltiptext">{this.props.lng === "ar" ? media.title : media.title_en}</span>
                                            </div>
                                            <div className="card-viewData row">
                                                <div>
                                                    <i className="icon-calendar"></i><span>{moment(media.date).format('dddd Do MMMM YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    } */}
                    {
                        this.state.motamaken.length > 0 &&
                        <h4>{t('versed')}</h4>
                    }
                    {
                        this.state.motamaken.length > 0 &&
                        this.state.motamaken.map(motamaken => {
                            return (
                                <Link key={motamaken.id} to={`/skilled/activity/${motamaken.id}`}>
                                    <div className="consumer-container bg-white shadow-sm" >
                                        <div className="card-img">
                                            <img src={motamaken.image !== null ? (BASE_URL + '/uploads/' + motamaken.image_path) : (BASE_URL + '/uploads//Images/default_image.jpg')} />
                                        </div>
                                        <div className="card-content bg-white">
                                            <div className="cpa-tooltip"><h4>{this.props.lng === "ar" ? motamaken.title : motamaken.title_en}</h4>
                                                <span className="cpa-tooltiptext">{this.props.lng === "ar" ? motamaken.title : motamaken.title_en}</span>
                                            </div>
                                            <div className="card-viewData row">
                                                <div>
                                                    <i className="icon-calendar"></i><span>{moment(motamaken.updated_at).format('dddd Do MMMM YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })
                    }
                    {
                        this.state.recognize.length > 0 &&
                        <h4>{t('recognize')}</h4>
                    }
                    {
                        this.state.recognize.length > 0 &&
                        this.state.recognize.map(recognize => {
                            return (
                                <Link key={recognize.id} to={'/Know'}>
                                    <div className="consumer-container bg-white shadow-sm" >
                                        <div className="card-img">
                                            <img src={recognize.image !== null && recognize.image !== undefined ? (BASE_URL + '/uploads/' + recognize.image) : (BASE_URL + '/uploads//Images/default_image.jpg')} />
                                        </div>
                                        <div className="card-content bg-white">
                                            <div className="cpa-tooltip"><h4>{this.props.lng === "ar" ? recognize.title : recognize.title_en}</h4>
                                                <span className="cpa-tooltiptext">{this.props.lng === "ar" ? recognize.title : recognize.title_en}</span>
                                            </div>
                                            <div className="card-viewData row">
                                                <div>
                                                    <i className="icon-calendar"></i><span>{moment(recognize.updated_at).format('dddd Do MMMM YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })
                    }

                    {
                        this.state.takeKnowledge.length > 0 &&
                        <h4>{t('takeKnowledge')}</h4>
                    }
                    {
                        this.state.takeKnowledge.length > 0 &&
                        this.state.takeKnowledge.map(takeKnowledge => {
                            return (
                                <Link key={takeKnowledge.id} to={`/now-you-know`}>

                                    <div className="consumer-container bg-white shadow-sm" >
                                        <div className="card-img">
                                            <img src={takeKnowledge.image && takeKnowledge.image !== null ? (BASE_URL + '/uploads/' + takeKnowledge.image) : (BASE_URL + '/uploads//Images/default_image.jpg')} />
                                        </div>
                                        <div className="card-content bg-white">
                                            <div className="cpa-tooltip"><h4>{this.props.lng === "ar" ? takeKnowledge.title : takeKnowledge.title_en}</h4>
                                                <span className="cpa-tooltiptext">{this.props.lng === "ar" ? takeKnowledge.title : takeKnowledge.title_en}</span>
                                            </div>
                                            <div className="card-viewData row">
                                                <div>
                                                    <i className="icon-calendar"></i><span>{moment(takeKnowledge.updated_at).format('dddd Do MMMM YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            )
                        })
                    }

                    {
                        this.state.worldDays.length > 0 &&
                        <h4>{t('worldDays')}</h4>
                    }
                    {
                        this.state.worldDays.length > 0 &&
                        this.state.worldDays.map(worldDay => {
                            return (
                                <Link key={worldDay.id} to={`/international-day/${worldDay.title}`}>

                                    <div className="consumer-container bg-white shadow-sm" >
                                        <div className="card-img">
                                            <img src={worldDay.image !== null ? (BASE_URL + '/uploads/' + worldDay.image) : (BASE_URL + '/uploads//Images/default_image.jpg')} />
                                        </div>
                                        <div className="card-content bg-white">
                                            <div className="cpa-tooltip">
                                                <h4>{this.props.lng === "ar" ? worldDay.title : worldDay.title_en}</h4>
                                                <p className="cpa-tooltiptext">{this.props.lng === "ar" ? worldDay.title : worldDay.title_en}</p>
                                            </div>
                                            <div className="card-viewData row">
                                                <div>
                                                    <i className="icon-calendar"></i><span>{moment(worldDay.updated_at).format('dddd Do MMMM YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            )
                        })
                    }
                    {
                        this.state.el3okod.length > 0 &&
                        <h4>{t('contracts')}</h4>
                    }
                    {
                        this.state.el3okod.length > 0 &&
                        this.state.el3okod.map(contract => {
                            return (
                                <Link key={contract.id} to={`/legal-advocacy/proposed-contractual-agreement-templates/${contract.id}`}>
                                    <div className="consumer-container bg-white shadow-sm" >
                                        <div className="card-img">
                                            <img src={contract.image && contract.image !== null ? (BASE_URL + '/uploads/' + contract.image) : (BASE_URL + '/uploads//Images/default_image.jpg')} />
                                        </div>
                                        <div className="card-content bg-white">
                                            <div className="cpa-tooltip">
                                                <h4>{this.props.lng === "ar" ? contract.title : contract.title_en}</h4>
                                                <p className="cpa-tooltiptext">{this.props.lng === "ar" ? contract.title : contract.title_en}</p>
                                            </div>
                                            <div className="card-viewData row">
                                                <div>
                                                    <i className="icon-calendar"></i><span>{moment(contract.updated_at).format('dddd Do MMMM YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            )
                        })
                    }

                    {
                        this.state.complaints.length > 0 &&
                        <h4>{t('recivedComplains')}</h4>
                    }
                    {
                        this.state.complaints.length > 0 &&
                        (this.state.complaints).map(complaint => {
                            return (
                                <Link key={complaint.id} to={`/legal-advocacy/LetUsHearYou/${complaint.id}`}>
                                    <div className="consumer-container bg-white shadow-sm" >
                                        <div className="card-img">
                                            <img src={complaint.image && complaint.image !== null ? (BASE_URL + '/uploads/' + complaint.image) : (BASE_URL + '/uploads//Images/default_image.jpg')} />
                                        </div>
                                        <div className="card-content bg-white">
                                            <div className="cpa-tooltip">
                                                <h4>{this.props.lng === "ar" ? complaint.name : complaint.name_en}</h4>
                                                <p className="cpa-tooltiptext">{this.props.lng === "ar" ? complaint.title : complaint.title_en}</p>
                                            </div>
                                            <div className="card-viewData row">
                                                <div>
                                                    <i className="icon-calendar"></i><span>{moment(complaint.updated_at).format('dddd Do MMMM YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            )
                        })
                    }

                    {
                        this.state.importantLinks.length > 0 &&
                        <h4>{t('ImportantLinks')}</h4>
                    }
                    {
                        this.state.importantLinks.length > 0 &&
                        this.state.importantLinks.map(importantLink => {
                            return (
                                <a target="_blank" rel="noopener noreferrer" key={importantLink.id} href={`${importantLink.link}`}>
                                    <div className="consumer-container bg-white shadow-sm" >
                                        <div className="card-img">
                                            <img src={importantLink.image && importantLink.image !== null ? (BASE_URL + '/uploads/' + importantLink.image) : (BASE_URL + '/uploads//Images/default_image.jpg')} />
                                        </div>
                                        <div className="card-content bg-white">
                                            <div className="cpa-tooltip">
                                                <h4>{this.props.lng === "ar" ? importantLink.title : importantLink.title_en}</h4>
                                                <p className="cpa-tooltiptext">{this.props.lng === "ar" ? importantLink.title : importantLink.title_en}</p>
                                            </div>
                                            <div className="card-viewData row">
                                                <div>
                                                    <i className="icon-calendar"></i><span>{moment(importantLink.updated_at).format('dddd Do MMMM YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>

                            )
                        })
                    }
                    {/* {
                        (this.state.complaints).length > 0 &&
                        <h4>{t('departements')}</h4>
                    } */}
                    {
                        (this.state.menuBuilder).length > 0 &&
                        <h4>{t('departements')}</h4>
                    }
                    {
                        Object.values(this.state.menuBuilder).length > 0 &&
                        Object.values(this.state.menuBuilder).map(singleMenu => {
                            return (
                                <Link key={singleMenu.id} to={`/${singleMenu.slug}`}>
                                    <div className="consumer-container bg-white shadow-sm" >
                                        <div className="card-img">
                                            <img src={singleMenu.image && singleMenu.image !== null ? (BASE_URL + '/uploads/' + singleMenu.image) : (BASE_URL + '/uploads//Images/default_image.jpg')} />
                                        </div>
                                        <div className="card-content bg-white">
                                            <div className="cpa-tooltip">
                                                <h4>{this.props.lng === "ar" ? singleMenu.menu_name : singleMenu.menu_name_en}</h4>
                                                <p className="cpa-tooltiptext">{this.props.lng === "ar" ? singleMenu.menu_name : singleMenu.menu_name_en}</p>
                                            </div>
                                            <div className="card-viewData row">
                                                <div>
                                                    <i className="icon-calendar"></i><span>{moment(singleMenu.updated_at).format('dddd Do MMMM YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            )
                        })
                    }
                </div>
            </>
        )
    }
}

SearchResult.propTypes = {
    match: PropTypes.object,
    "match.params": PropTypes.object,
    "match.params.search": PropTypes.string,
    lng: PropTypes.string
}

function mapStateToProps(state) {
    return {
        lng: state.user.lng,
    };
}

export default connect(mapStateToProps)(SearchResult);