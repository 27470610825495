import React from 'react';
import './Authentication.scss';
import { Row, Col, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { t } from 'i18next';
import Translate from '../GlobalComponent/translate/translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { validateFormData } from '../../utils/validation.helpers';
import * as actions from '../../actions/auth/auth';
import { loginConstraints, registerConstraints } from '../../constants/validationRules';
import validate from '../../utils/validation';
import { BASE_URL } from '../../constants/config'
// import { cities } from '../../utils/json/citiesOfSaudiArabia';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags'

class Authentication extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            resetType: 'email',
            forgetPasswordButtonClass: "disabled",
            show: false,
            first_name: '',
            last_name: '',
            email: null,
            password: '',
            phone: '',
            city: '',
            first_name_error: false,
            last_name_error: false,
            email_error: false,
            password_error: false,
            phone_error: false,
            city_error: false,
            first_name_error_value: null,
            last_name_error_value: null,
            email_error_value: null,
            password_error_value: null,
            phone_error_value: null,
            city_error_value: null,
            error_msg: null,
            // addressId: "",
            verificationCode: null,
            verificationCodeError: null,
            new_password: null,
            retryPassword: null,
            retryPasswordError: false,
            image: '',
            user_type: "current_user",
            disableCloseBtn: false,
            // user_email: '',
            // user_password: '',
            countryCodes: [
                {
                    name: 'sa_code',
                    value: '+966'
                }
                , {
                    name: 'egy_code',
                    value: '+20'
                }
            ],
            selectedCountryCode: null,
            password_icon: 'icon-group-1',
            show_pass_type: 'password'
        };
        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.setCountryCode = this.setCountryCode.bind(this);
    }
    showModal = () => {
        this.setState({ show: true })
    };

    changeUserType = () => {
        this.setState({
            user_type: this.state.user_type == "current_user" ? "new_user" : "current_user",
            first_name_error: false,
            last_name_error: false,
            email_error: false,
            password_error: false,
            phone_error: false,
            city_error: false,
            error_msg: "",
            error_msg_en: ""
        })
    };

    //// current user clicked to open the forget password icon
    forgotPassword = (step) => {
        this.setState({
            user_type: "forgot-password-" + step
        })
    }

    /// the current user clicked on the submit button to reset password
    SbmitForgotPassword = (step, type) => {
        this.setState({ verificationCodeError: '' })
        if (step == 'step2') {
            this.props.actions.requestForgotPassword({ [type]: this.state[type], url: BASE_URL + '/ForgotPasswordAuth' })
                .then(() => {
                    this.setState({
                        user_type: "forgot-password-" + step
                    })
                    // this.handleClose();
                }).catch(() => {

                })
        } else {
            if (!this.state.retryPasswordError) {
                this.props.actions.requestNewPassword({ code: this.state.verificationCode, new_password: this.state.new_password })
                    .then((response) => {
                        if (response.data) {
                            this.handleClose();
                        }
                        else {
                            this.setState({ verificationCodeError: <p>{t('verification_error')}</p> })
                        }
                    }).catch(() => {
                    })
            }
        }
    }

    handleChange = event => {
        // event.preventDefault();
        const { name, value } = event.target;

        this.setState({ ...this.state, [name]: value })

        if (name == "retryPassword" || name == "new_password") {
            this.setState({
                retryPasswordError: value != this.state.new_password
            })
        }
    };

    validateLoginData() {
        const values = this.state;
        const fields = ['email', 'password'];
        return validateFormData(
            fields,
            [values.email, values.password],
            loginConstraints,
        );
    }

    validateRegisterData() {
        const values = this.state;
        const fields = ['email', 'password', 'first_name', 'last_name', 'phone'];
        return validateFormData(
            fields,
            [values.email, values.password, values.first_name, values.last_name, values.phone],
            registerConstraints,
        );
    }

    handleRegister(event) {
        event.preventDefault();
        if (this.state.first_name === '') {
            document.getElementById("registerFirstName").style.border = "2px solid #ffafaf";
        }
        if (this.state.last_name === '') {
            document.getElementById("registerLastName").style.border = "2px solid #ffafaf";
        }

        if (this.state.email === '') {
            document.getElementById("registerEmail").style.border = "2px solid #ffafaf";
        }

        // if (this.state.city === '') {
        //     document.getElementById("registerCity").style.border = "2px solid #ffafaf";
        // }

        if (this.state.phone === '') {
            document.getElementById("registerPhone").style.border = "2px solid #ffafaf";
        }

        if (this.state.password === '') {
            document.getElementById("registerPassword").style.border = "2px solid #ffafaf";
        }

        const validationResult = this.validateRegisterData();
        if (validationResult.result !== true) {
            // console.log("not validate")
            this.setState(Object.assign({}, validationResult.state));
        } else {
            this.props.actions.register({
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                // city: this.state.city,
                mobile: this.state.phone,
                password: this.state.password,
                country_code: this.state.selectedCountryCode,
            }).then(() => {
                
                if (!this.props.user.error) {
                    this.setState({
                        first_name_error: false,
                        last_name_error: false,
                        email_error: false,
                        password_error: false,
                        phone_error: false,
                        city_error: false,
                        user_type: 'verify'
                    })
                }


            }).catch(() => {

            });
        }
    }

    handleLogin(event) {
        event.preventDefault();
        if (this.state.email === '') {
            document.getElementById("loginEmailID").style.border = "2px solid #ffafaf";
        }

        if (this.state.password === '') {
            document.getElementById("loginPasswordID").style.border = "2px solid #ffafaf";
        }

        const validationResult = this.validateLoginData();
        if (validationResult.result !== true) {
            this.setState(Object.assign({}, validationResult.state));
        } else {
            this.props.actions.login({
                email: this.state.email,
                password: this.state.password
            }).then(() => {
                this.setState({
                    error_msg: "",
                    error_msg_en: ""
                })
                if (this.props.user.verified) {
                    this.props.toggleModal();
                } else {
                    this.setState({
                        user_type: 'verify'
                    })
                }
            }).catch((err) => {

                if (err) {
                    this.setState({
                        error_msg: "اسم المستخدم او كلمة المرور خطأ",
                        error_msg_en: "please check your email or password"
                    })
                }
            });
        }
    }


    submitVerification = () => {
        this.props.actions.verificationRequest({ email: this.state.email, code: this.state.verification_code })
            .then(() => {
                // this.props.toggleModal();
                this.props.actions.verificationRequest({ email: this.state.email, code: this.state.verificationCode })
                    .then((Response) => {
                        if (Response.data) {
                            this.props.toggleModal();
                            localStorage.setItem('_cpaJwt', Response.data.token);
                            localStorage.setItem('id', Response.data.id);
                            localStorage.setItem('first_name', Response.data.first_name);
                            localStorage.setItem('last_name', Response.data.last_name);
                            localStorage.setItem('email', Response.data.email);
                            // localStorage.setItem('city', Response.data.city);
                            localStorage.setItem('identification_number', Response.data.identification_number);
                            localStorage.setItem('identification_path', Response.data.identification_path);
                            localStorage.setItem('verified', Response.data.verified);

                            this.props.actions.login({
                                email: this.state.email,
                                password: this.state.password
                            })
                        }
                        else {
                            this.setState({
                                verificationCodeError: true
                            })
                        }
                    }).catch(() => {
                        this.setState({
                            verificationCodeError: true
                        })
                    })
            })
    }
    componentDidMount() {
        const route = window.location.pathname;
        const userIsOnComplainRoute = route.indexOf('legalAdvocacy') !== -1;
        this.setState({ ...this.state, disableCloseBtn: userIsOnComplainRoute, error_msg: "", error_msg_en: "", email_error: false });
    }

    handleClose = () => {
        // this.setState({
        //     first_name_error: false,
        //     last_name_error: false,
        //     email_error: false,
        //     password_error: false,
        //     phone_error: false,
        //     city_error: false,
        //     verefication_error: false,
        //     error_msg: "",
        //     error_msg_en: "",
        //     user_type: "current_user"
        // })

        this.setState({
            forgetPasswordButtonClass: "disabled",
            resetType: 'email',
            show: false,
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            phone: '',
            city: '',
            first_name_error: false,
            last_name_error: false,
            email_error: false,
            password_error: false,
            phone_error: false,
            city_error: false,
            first_name_error_value: null,
            last_name_error_value: null,
            email_error_value: null,
            password_error_value: null,
            phone_error_value: null,
            city_error_value: null,
            error_msg: null,
            // addressId: "",
            verificationCode: null,
            verificationCodeError: null,
            new_password: null,
            retryPassword: null,
            retryPasswordError: false,
            image: '',
            user_type: "current_user",
            disableCloseBtn: false,
            // user_email: '',
            // user_password: '',
            countryCodes: [
                {
                    name: 'sa_code',
                    value: '+966'
                }
                , {
                    name: 'egy_code',
                    value: '+20'
                }
            ],
            selectedCountryCode: null,
            password_icon: 'icon-group-1',
            show_pass_type: 'password'
        })


        this.props.toggleModal()
    }

    serverEmailErrorResponse() {
        if (this.props.user.error.email) {
            return (
                < Col className="new-user" xs={12} sm={8} >
                    <p className="error">{t('emailExist')}</p>
                </Col >
            )
        }
    }
    servermobileErrorResponse() {
        if (this.props.user.error.mobile) {
            return (
                <Col className="new-user" xs={12} sm={8} >
                    <p className="error">{t('phoneExist')}</p>
                </Col>
            )
        }
    }
    setCountryCode = event => {
        this.setState({
            selectedCountryCode: event.target.value,
        })
    }

    showHidePass = () => {
        if (this.state.show_pass_type == "password") {
            this.setState({
                show_pass_type: 'text',
                password_icon: 'icon-eye'
            })
        } else {
            this.setState({
                show_pass_type: 'password',
                password_icon: 'icon-group-1'
            })
        }
    }

    render() {
        // console.log("prop", this.props)
        let lgClose = () => this.setState({ show: false });
        let phoneDir = this.props.user.lng === "en" ?
            { direction: "ltr", width: "100%" } :
            { direction: "rtl", width: "100%" }

        //display message error based on password
        let showPasswordError = this.state.password_error === true ? <p className="error">{t('fillPassword')}</p> : null;

        let signinEmailError;
        if (this.state.email_error === true) {
            if (this.state.email === "") {
                signinEmailError = <p className="error">{t('fillEmail')}</p>;
            }
            else if (this.state.email && validate("email", this.state.email) === false) {
                signinEmailError = <p className="error">{t('emailInCorrect')}</p>;
            }
            else signinEmailError = null;
        }

        //display message error based on email
        let showEmailError;

        if (this.state.email === "") {
            showEmailError = <p className="error">{t('fillEmail')}</p>;
        }
        else if (this.state.email && validate("email", this.state.email) === false) {
            showEmailError = <p className="error">{t('emailInCorrect')}</p>
        }
        else showEmailError = null;

        // add disabled class to the button if there is no mail entered
        let forgetPasswordButtonClass = this.state.email ? null : "disabled";
        let forgetPasswordButtonClassMobile = this.state.phone ? null : "disabled";

        // // add disabled class to the button if there is verification entered
        // let VerficationButtonClass = this.state.verificationCode && this.state.new_password
        //     && this.state.retryPassword ?
        //     null : "disabled";

        // add disabled class to the button if there is no verification entered
        let verificationCodeDisabled = this.state.verificationCode ? null : "disabled";

        return (
            <>
                <Modal
                    size="xl"
                    show={this.props.showModal}
                    onHide={lgClose}
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    {/* { ! this.state.disableCloseBtn &&  */}
                    <Modal.Header closeButton onClick={() => {
                        // const route = window.location.pathname;
                        // const userIsOnComplainRoute = route.indexOf('legalAdvocacy') !== -1;
                        // if(userIsOnComplainRoute) {
                        //     alert(t('authAlert'));
                        // } else {
                        this.handleClose()
                        // }
                    }} />
                    {/* } */}
                    {this.state.disableCloseBtn && <Modal.Header />}
                    <Modal.Body >
                        <div className={"Authentication-container " + this.state.user_type}>
                            <div className="current-user-form">
                                <Row className="justify-content-center">
                                    <Col xs={"auto"}>
                                        <h4>
                                            {this.state.user_type != 'current_user' ? <Translate text="newaccount" /> : <Translate text="signIn" />}</h4>
                                    </Col>
                                    <Col xs={12}>
                                        <p><Translate text="signRequired" /></p>
                                        {/* {this.state.email_error && <p className="error"> {t('fillEmail')} </p>} */}
                                        {/* {this.state.password_error && <p className="error"> {t('fillPassword')} </p>} */}
                                        {signinEmailError}
                                        {showPasswordError}
                                        <span className="error_msg">{this.props.user.lng == "ar" ? this.state.error_msg : this.state.error_msg_en}</span>
                                    </Col>
                                </Row>
                                <form onSubmit={this.handleLogin}>
                                    <Row className="justify-content-center">
                                        <Col xs={12} sm={8} >
                                            <div className="input-label">
                                                <label>{t("email_signup")}</label>
                                            </div>
                                            <div className="form-input">
                                                <i className="icon-account-details" />
                                                <input
                                                    id="loginEmailID"
                                                    name="email"
                                                    onChange={this.handleChange}
                                                    type="text" required />
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={8} className="password-input-container">
                                            <div className="input-label">
                                                <label>{t("password")}</label>
                                            </div>
                                            <div className="form-input">
                                                <i className="icon-change-password"></i>
                                                <input
                                                    id="loginPasswordID"
                                                    name="password"
                                                    onChange={this.handleChange}
                                                    type={this.state.show_pass_type} required />
                                            </div>
                                            <div className="show-password">
                                                <i className={this.state.password_icon} onClick={this.showHidePass}></i>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="forgot-remimber-row justify-content-center">
                                        {/* <Col xs={12} sm={8} lg={4} className="remimber-check current-user">
                                            <label className="check-box">
                                                <input type="checkbox" />
                                                <span className="checkmark"></span>
                                                <span className="font-fixed">تذكرني</span>
                                            </label>
                                        </Col> */}
                                        <Col xs={12} sm={8} className="forgot-password current-user pointer" onClick={() => this.forgotPassword('step1')} >

                                            <span className="font-fixed">{t('forgotPassword')}</span>
                                            <i className="icon-info-circled-alt"></i>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center actions-controllers" >
                                        <Col xs={10} sm={8} className="mb-4 sumbit-cation">
                                            <button type="submit" className="btn" onClick={this.handleLogin}><Translate text="signIn" /></button>
                                        </Col>
                                        <Col className="current-user" xs={10} sm={8}>
                                            <button type="button" onClick={this.changeUserType}> <Translate text="newUser" />
                                            </button>
                                        </Col>
                                    </Row>
                                </form>
                            </div>

                            <div className="new-user-form">
                                <Row className="justify-content-center">
                                    <Col xs={"auto"}>
                                        <h4>
                                            {this.state.current_user === 'current_user' ? <Translate text="newaccount" /> : <Translate text="signIn" />}</h4>
                                    </Col>
                                    <Col xs={12}>
                                        <p><Translate text="signRequired" /></p>
                                        {this.state.first_name_error && <p className="error"> {t('fillFirstName')} </p>}
                                        {this.state.last_name_error && <p className="error"> {t('fillLastName')} </p>}
                                        {this.state.email_error && <p className="error"> {t('emailInCorrect')} </p>}
                                        {this.state.password_error && <p className="error"> {t('fillPassword')} </p>}
                                        {this.state.phone_error && <p className="error"> {t('fillPhone')} </p>}
                                        {this.state.city_error && <p className="error"> {t('fillCity')} </p>}
                                        <span className="error_msg">{this.props.user.lng == "ar" ? this.state.error_msg : this.state.error_msg_en}</span>
                                    </Col>
                                </Row>
                                <form className="new-user-form">
                                    <Row className="justify-content-center">

                                        {this.props.user.error &&
                                            this.servermobileErrorResponse()
                                        }
                                        {this.props.user.error &&
                                            this.serverEmailErrorResponse()
                                        }
                                        <Col className="new-user" xs={12} sm={8} >
                                            <div className="input-label">
                                                <label>{t("firstName")}</label>
                                            </div>
                                            <div className="form-input">
                                                <i className="icon-account-details"></i>
                                                <input
                                                    id="registerFirstName"
                                                    name="first_name"
                                                    onChange={this.handleChange}
                                                    type="text" required />
                                            </div>
                                        </Col>
                                        <Col className="new-user" xs={12} sm={8} >
                                            <div className="input-label">
                                                <label>{t("lastName")}</label>
                                            </div>
                                            <div className="form-input">
                                                <i className="icon-account-details"></i>
                                                <input
                                                    id="registerLastName"
                                                    name="last_name"
                                                    onChange={this.handleChange}
                                                    type="text" required />
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={8} >
                                            <div className="input-label">
                                                <label>{t("email_signup")}</label>
                                            </div>
                                            <div className="form-input">
                                                <i className="icon-mail" />
                                                <input
                                                    id="registerEmail"
                                                    name="email"
                                                    onChange={this.handleChange}
                                                    type="text" required />
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={8} className="password-input-container">
                                            <div className="input-label">
                                                <label>{t("password")}</label>
                                            </div>
                                            <div className="form-input">
                                                <i className="icon-change-password"></i>
                                                <input
                                                    id="registerPassword"
                                                    name="password"
                                                    onChange={this.handleChange}
                                                    type="password" required />
                                            </div>
                                            <div className="show-password">
                                                <i className={this.state.password_icon} onClick={this.showHidePass}></i>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={8} >
                                            <div className="input-label">
                                                <label>{t("phoneNumber")}</label>
                                            </div>
                                            <div className="form-input">
                                                <i className="icon-smartphone" />
                                                <PhoneInput
                                                    flags={flags}
                                                    country='SA'
                                                    style={phoneDir}
                                                    id="registerPhone"
                                                    name="phone"

                                                    value={this.state.phone}
                                                    onChange={(phone) => { this.setState({ phone }) }} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center actions-controllers" >
                                        <Col xs={10} sm={8} className="mb-4 sumbit-cation">
                                            <button type="submit" className="btn btn-success" onClick={this.handleRegister}><Translate text="createAccount" /></button>
                                        </Col>
                                        <Col className="new-user current-user mb-4" xs={10} sm={8}>
                                            <button type="button" className="btn" onClick={this.changeUserType}><Translate text="currentUser" /></button>
                                        </Col>

                                    </Row>
                                </form>
                            </div>

                            <div className="forgot-password-form-step1">
                                <Row>
                                    <Col>
                                        <h4>
                                            {t('resetPassword')}
                                        </h4>
                                        <p>
                                            {this.state.resetType === 'email' ? t('sendCode') : t('sendCodePhone')}
                                        </p>
                                    </Col>
                                </Row>
                                <form>
                                    <Row className="justify-content-center" >
                                        <Col xs={12} sm={8} style={{ marginBottom: "2rem", display: "flex", flexDirection: "column", direction: "rtl" }}>
                                            <div style={{ textAlign: "right", marginBottom: "1rem" }}>
                                                <input onClick={(event) => {
                                                    this.setState({
                                                        resetType: event.target.value,
                                                        phone: null
                                                    })
                                                }}
                                                    type="radio" id="email" name="code" value="email" checked={this.state.resetType === 'email'} />
                                                <label>{t('resetByEmail')}</label>
                                            </div>
                                            <div style={{ textAlign: "right" }}>
                                                <input onClick={(event) => {
                                                    this.setState({
                                                        resetType: event.target.value,
                                                        email: null
                                                    })
                                                }}
                                                    type="radio" id="mobile" name="code" value="mobile" />
                                                <label>{t('resetByMobile')}</label>
                                            </div>
                                        </Col>
                                        {
                                            this.state.resetType === 'email' &&
                                            <Col xs={12} sm={8} >
                                                <div className="form-input">
                                                    <i className="icon-user" />
                                                    <input
                                                        name="email"
                                                        placeholder={t("email")}
                                                        onChange={this.handleChange}
                                                        value={this.state.email}
                                                        type="text" required />
                                                </div>
                                            </Col>
                                        }
                                        {
                                            this.state.resetType === 'mobile' &&
                                            <Col xs={12} sm={8} >
                                                <div className="form-input">
                                                    <i className="icon-smartphone" />
                                                    <PhoneInput
                                                        style={phoneDir}
                                                        id="registerPhone"
                                                        name="phone"
                                                        onChange={(value) => { this.setState({ phone: value }) }} />
                                                </div>
                                            </Col>
                                        }
                                        <Col className="mb-4" xs={10}>
                                            {
                                                this.state.resetType === 'email' &&
                                                < button type="button" disabled={!this.state.email} className={"btn submit-cpa-btn " + forgetPasswordButtonClass} onClick={() => this.SbmitForgotPassword('step2', 'email')}>
                                                    {t('submit')}</button>
                                            }
                                            {
                                                this.state.resetType === 'mobile' &&
                                                <button type="button" disabled={!this.state.phone} className={"btn submit-cpa-btn " + forgetPasswordButtonClassMobile} onClick={() => this.SbmitForgotPassword('step2', 'phone')}>
                                                    {t('submit')}</button>
                                            }

                                            {showEmailError}
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                            <div className="forgot-password-form-step2">
                                <Row>
                                    <Col>
                                        <h4>{this.state.resetType === 'email' ? t('codeSended') : t('codePhoneSended')}</h4>
                                    </Col>
                                </Row>

                            </div>

                            {/* <div className="forgot-password-form-step2">
                                <Row>
                                    <Col>
                                        <h4>
                                            {t('resetPassword')}
                                        </h4>
                                    </Col>
                                </Row>
                                <form>
                                    <Row className="justify-content-center">
                                        <Col xs={12} sm={8} >
                                            <div className="form-input">
                                                <i className="icon-user" />
                                                <input
                                                    name="verificationCode"
                                                    placeholder={t("verificationCode")}
                                                    onChange={this.handleChange}
                                                    type="text" required />
                                                {this.state.verificationCodeError}
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={8} >
                                            <div className="form-input">
                                                <i className="icon-lock"></i>
                                                <input
                                                    name="new_password"
                                                    placeholder={t("newPassword")}
                                                    onChange={this.handleChange}
                                                    type="password" required />
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={8} >
                                            <div className="form-input">
                                                <i className="icon-lock"></i>
                                                <input
                                                    name="retryPassword"
                                                    placeholder={t("retryPassword")}
                                                    onChange={this.handleChange}
                                                    type="password" required />
                                                {this.state.retryPasswordError &&
                                                    <small>{t('retryPasswordError')}</small>
                                                }

                                            </div>
                                        </Col>
                                        <Col className="mb-4" xs={10}>
                                            <button disabled={!this.state.verificationCode || !this.state.new_password || !this.state.retryPassword}
                                                type="button" className={"btn submit-cpa-btn " + VerficationButtonClass} onClick={this.SbmitForgotPassword}>{t('submit')}</button>
                                        </Col>
                                    </Row>
                                </form>
                            </div> */}

                            <div className="verify-form">
                                <Row>
                                    <Col>
                                        <h4>
                                            {t('verification')}
                                        </h4>
                                        <p>
                                            {t('verificationMsg')}
                                        </p>
                                        {this.state.verefication_error && <p className="error">{t('verification_error')}</p>}
                                    </Col>
                                </Row>
                                <form>
                                    <Row className="justify-content-center">
                                        <Col xs={12} sm={8} >
                                            <div className="form-input">
                                                <i className="icon-lock" />
                                                <input
                                                    name="verificationCode"
                                                    placeholder={t("verificationCode")}
                                                    onChange={this.handleChange}
                                                    type="text" required />
                                            </div>
                                        </Col>
                                        <Col className="mb-4" xs={10}>
                                            <button
                                                disabled={!this.state.verificationCode}
                                                type="button" className={"btn submit-cpa-btn " + verificationCodeDisabled} onClick={this.submitVerification}>{t('submit')}</button>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
Authentication.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    showModal: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        user: state.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Authentication)