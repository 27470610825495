import {GET_COMMITTEES_DATA} from '../../constants/actionTypes';
import {apiFetch} from '../../utils/http.helpers';
import { BASE_URL } from '../../constants/config';

export function get_committees () {
    return function (dispatch) {
        return apiFetch(BASE_URL+'/api/elgam3ya/search/?type=اللجان', {
          method: "GET",
          // mode: "cors",
          withCredentials: true,
          headers: {
            "Accept": "application/json",
            // "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
          }})
          .then(response => response.json())
          .then(res => {
          //  console.log('Res in fetch', res);
            dispatch({ type: GET_COMMITTEES_DATA.SUCCESS, payload:res });
          }).catch(() => {
            // console.log('Error is', error);
          });
      };
}