import React from 'react';
import './ImgHeader.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BASE_URL } from '../../../constants/config';

const ImgHeader = props => {
    return (
        <div className="img-header" >
            <div className="img-container">
                <img className="img-responsive" src={BASE_URL + '/Images/test.jpg'} />
            </div>
            <div className="img-header-content">
                <h3>{props.lng == "ar" ? props.page.menu_name : props.page.menu_name_en}</h3>
            </div>
        </div>
    );
};
ImgHeader.propTypes = {
    page: PropTypes.object,
    children: PropTypes.children,
    lng: PropTypes.string.isRequired
};
function mapStateToProps(state) {
    return {
        lng: state.user.lng
    };
}

export default connect(mapStateToProps)(ImgHeader)
