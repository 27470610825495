import React from 'react';
import './home-section.scss';
import PropTypes from 'prop-types';
const HomeSection = (props) => {
  return (
    <div className="home-section">
      {props.children}
    </div>

  );
};
HomeSection.propTypes = {
  children: PropTypes.children
};
export default HomeSection;
