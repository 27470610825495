import { GET_QUIZES_DATA } from '../../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../../initialState';

export default function quizesReducer(state = initialState.programs_and_activities.programs.quizes, action) {
  switch (action.type) {
    case GET_QUIZES_DATA.SUCCESS:
      return objectAssign({}, state, { quizesArray: action.payload });
    default:
      return state;
  }
}