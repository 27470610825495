import { GET_CONTACTINFO } from '../../constants/actionTypes';
import { apiFetch } from '../../utils/http.helpers';
import { BASE_URL } from '../../constants/config';
export function getData(){
    return function (dispatch){
        return apiFetch(BASE_URL+'/api/contactInfo/get', {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(res => {
            dispatch({ type: GET_CONTACTINFO.SUCCESS, payload: res});
        })
        .catch((error) => {
            throw error;
        });
    }
}