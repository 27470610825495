import React from 'react';
import './fatwrty.scss';
import ImgHeader from '../../../GlobalComponent/ImgHeader/ImgHeader';
import BreadCrumb from '../../../GlobalComponent/BreadCrumb/BreadCrumb';
import Translate from '../../../GlobalComponent/translate/translate';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { BASE_URL } from '../../../../constants/config';
import * as navigationActions from '../../../../actions/navigation';
import * as platformsActions from '../../../../actions/program-and-activities/platformsandapps';
import { bindActionCreators } from 'redux';
import ReactHtmlParser from 'react-html-parser';
import CpaSharing from '../../../GlobalComponent/cpaSharing/cpaSharing';


class Application extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            show: false,
            routes: []
        };
    }
    componentDidMount() {
        this.props.navigationActions.getPageInfoApi('/platforms-and-applications').then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                this.props.platformsActions.getApplicationById(parseInt(this.props.match.params.id));
            }
        )
    }
    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }
    render() {
        return (
            this.props.page &&
            <div className="fatwrty-container">
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                <div className="container">
                    <CpaSharing active='active' />
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe className="embed-responsive-item" width="100%" height="500px" src={this.props.application.youtube_link}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>

                    <div className="idea-container content-app">
                        <h4><Translate text="appObjective" /></h4>
                        {this.props.lng === "ar" ? ReactHtmlParser(this.props.application.idea) : ReactHtmlParser(this.props.application.idea_en)}
                    </div>
                </div>
                <div className="bg-white content-app idea-container">
                    <div className="container">
                        <h4><Translate text="appFunc" /></h4>
                        {this.props.lng === "ar" ? ReactHtmlParser(this.props.application.definition) : ReactHtmlParser(this.props.application.definition_en)}
                    </div>
                </div>
                <div className="content-app features-container">
                    <div className="container">
                        <h4><Translate text="appChar" /></h4>
                        {this.props.lng === "ar" ? ReactHtmlParser(this.props.application.features) : ReactHtmlParser(this.props.application.features_en)}
                    </div>
                </div>
                <div className="content-app download-container">
                    <div className="container">
                        <h4><Translate text="DownloadTheApp" /></h4>
                        <div className="imgs-download">
                            <a target="blank" href={this.props.application.ios_app_link}><img className="appstore" src={this.props.lng === 'ar' ? BASE_URL + "/uploads//Images/ar.png" : BASE_URL + "/uploads//Images/download-on-the-app-store.png"} /></a>
                            <a target="blank" href={this.props.application.android_app_link}><img className="googleplay" src={this.props.lng === 'ar' ? BASE_URL + "/uploads//Images/google-play-badge-3.png" : BASE_URL + "/uploads//Images/google-play-badge.png"} /></a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Application.propTypes = {
    lng: PropTypes.string.isRequired,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    platformsActions: PropTypes.object.isRequired,
    "platformsActions.getApplicationById": PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    "match.params": PropTypes.object.isRequired,
    "match.params.id": PropTypes.number.isRequired,
    application: PropTypes.object,
    "application.youtube_link": PropTypes.string,
    "application.idea": PropTypes.string,
    "application.idea_en": PropTypes.string,
    "application.definition": PropTypes.string,
    "application.definition_en": PropTypes.string,
    "application.features": PropTypes.string,
    "application.features_en": PropTypes.string,
    "application.ios_app_link": PropTypes.string,
    "application.android_app_link": PropTypes.string
}

function mapStateToProps(state) {
    return {
        lng: state.user.lng,
        page: state.navigation.page,
        application: state.platforms.application
    };
}

function mapDispatchToProps(dispatch) {
    return {
        navigationActions: bindActionCreators(navigationActions, dispatch),
        platformsActions: bindActionCreators(platformsActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Application);
