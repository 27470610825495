import React from 'react';
import './ContainerCard.scss';
import PropTypes from 'prop-types';
const ContainerCard= props =>{
    return (
        <div className="container-card rounded-sm">
            <div className="inner">{props.children}</div>
        </div>
    );
};
ContainerCard.propTypes = {
    children: PropTypes.children
  };
export default ContainerCard;