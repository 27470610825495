import { GET_GUIDE_BOOK_DATA, GET_GUIDE_BOOK_CHILDREN, GET_comprehensive_Guide_DATA, GET_GUIDE_BOOK_CATEGORY_CONTENT } from '../../../constants/actionTypes';
import { apiFetch } from '../../../utils/http.helpers';
import { BASE_URL } from '../../../constants/config';





export function guideBookobjectData(id, search) {

  return function (dispatch) {
    let url = search === null ? `/api/ldalel_elshaml/category/?id=${id}` : `/api/ldalel_elshaml/searchSubCategories/?id=${id}&search=${search}`
    return apiFetch(BASE_URL + url, {
      method: "GET",
      withCredentials: true,
      headers: {
        "Accept": "application/json",
      }
    })
      .then(
        response => response.json())
      .then(res => {
        // console.log("suc", res)
        dispatch({ type: GET_GUIDE_BOOK_DATA.SUCCESS, payload: res });
      }).catch((err) => {
        throw err
      });
  };
}

export function getChildren(id) {
  // console.log("id", id)
  return function (dispatch) {
    // console.log("entered");

    return apiFetch(BASE_URL + '/api/ldalel_elshaml/subjects/?child_id=' + id, {
      method: "GET",
      // mode: "cors",
      withCredentials: true,
      headers: {
        "Accept": "application/json",
        // "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
      }
    })
      .then(response => response.json())
      .then(res => {
        // console.log("ch", res)
        dispatch({ type: GET_GUIDE_BOOK_CHILDREN.SUCCESS, payload: res });
      }).catch((err) => {
        throw err
        // console.log('Error is', err);
      });
  };
}

export function comprehensiveGuideArray() {
  return function (dispatch) {
    return apiFetch(BASE_URL + '/api/ldalel_elshaml/categories', {
      method: "GET",
      // mode: "cors",
      withCredentials: true,
      headers: {
        "Accept": "application/json",
        // "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
      }
    })
      .then(response => response.json())
      .then(res => {
        // console.log("comprehensiveGuideArray", res)
        localStorage.setItem('guideList', JSON.stringify(res));
        //            console.log('Res in fetch', res);
        dispatch({ type: GET_comprehensive_Guide_DATA.SUCCESS, payload: res });
      }).catch(() => {
        // console.log('Error is', error);
      });
  };
}

export function getGuideBookContent(id) {
  return function (dispatch) {
    // console.log("entered");

    return apiFetch(BASE_URL + '/api/ldalel_elshaml/subjects/?sub_category_id=' + id, {
      method: "GET",
      // mode: "cors",
      withCredentials: true,
      headers: {
        "Accept": "application/json",
        // "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
      }
    })
      .then(response => response.json())
      .then(res => {
        // console.log('getGuideBookContent', res);
        dispatch({ type: GET_GUIDE_BOOK_CATEGORY_CONTENT.SUCCESS, payload: res });
      }).catch((err) => {
        throw err
        // console.log('Error is', err);
      });
  };
}
