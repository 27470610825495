import {POST_POST_FORM} from '../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';

export default function postFormReduser (state = initialState.post_form, action) {
    switch (action.type) {
        case POST_POST_FORM.SUCCESS:
          return objectAssign({}, state, {returnedMsg: action.payload});
        default:
          return state;
      }
}