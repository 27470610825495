import React from 'react';
import ImgHeader from '../../../GlobalComponent/ImgHeader/ImgHeader';
import BreadCrumb from '../../../GlobalComponent/BreadCrumb/BreadCrumb';
import './quizes.scss';
import { Container, Row, Col } from "react-bootstrap";
import * as actions from '../../../../actions/program-and-activities/programs/quizes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as navigationActions from '../../../../actions/navigation';

class Quizes extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.nextQuizeHandler = this.nextQuizeHandler.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.openTest = this.openTest.bind(this);
    this.state = {
      show: false,
      selectedOption: '',
      checked: false,
      num: 1,
      percent: 20,
      quizCount: 0,
      btnQuiz: 'next',
      loading: true,
      showResult: false,
      result: 0,
      quiz: [
        {
          question: '',
          answers: []
        }
      ],
      guideBooks: [
        {
          name: 'القطاع الصحي',
        },
        {
          name: 'قطاع وزارة العمل',
        },
        {
          name: 'هيئة الطيران المدني',
        },
      ],
      modalContent: {
        name: '',
      },
      routes: []
    };
  }
  handleClose = () => {
    this.setState({ show: false });
  }

  openTest = (id) => {
    this.props.history.push(`/test-your-knowledge/tests/${id}`)
  }

  handleShow = (value) => {
    this.setState({
      quiz: value,
      show: true,
      selectedOption: '',
      checked: false,
      num: 1,
      result: 0,
      percent: (1 / value.length) * 100,
      quizCount: 0,
      btnQuiz: 'next',
    })
  }
  handleOptionChange(changeEvent) {
    this.setState({
      selectedOption: changeEvent.target.value,
    })
  }
  componentDidMount() {
    // console.log('Do it for m');
    this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
      () => {
        this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
        this.props.actions.getQuizesData()
          .then(() => {
            this.setState({ ...this.state, loading: false });
            // console.log("return data: ", this.state);

          }).catch(() => {
          });
      })
  }

  nextQuizeHandler() {
    let answers = this.state.quiz[this.state.quizCount].answers;
    for (let index = 0; index < answers.length; index++) {
      if (answers[index].answer == this.state.selectedOption) {
        if (answers[index].right) {
          let result = this.state.result
          result++;
          this.setState({ result })
        }
      }
    }
    this.setState({ selectedOption: '' })
    this.setState({ selectedOption: '' })
    if (this.state.num === this.state.quiz.length - 1) {
      this.setState({
        num: this.state.num + 1,
        percent: this.state.percent + + ((1 / this.state.quiz.length) * 100),
        checked: false,
        quizCount: this.state.quizCount + 1,
        btnQuiz: "finish"
      })
    }
    if (this.state.num === this.state.quiz.length) {
      this.setState({
        showResult: true,
        num: 0,
        percent: 0,
        checked: true,
        quizCount: 0
      });
    } else {
      this.setState({
        num: this.state.num + 1,
        percent: this.state.percent + ((1 / this.state.quiz.length) * 100),
        checked: false,
        quizCount: this.state.quizCount + 1,
      })
    }
  }

  setResult = (value) => {
    if (value.right) {
      return 'rightAns'
    } else {
      return 'wrongAns'
    }
  }
  setRoutesFormApi(page) {

    let routes = []
    let temp = page.pread_crumb
    for (let index = 0; index < 3; index++) {
      routes.push({
        name: temp.menu_name,
        name_en: temp.menu_name_en,
        link: temp.slug
      })
      if (temp.pread_crumb) {
        temp = temp.pread_crumb
      } else {
        return routes.reverse();
      }
    }
  }

  render() {
    return (
      this.props.page &&
      <div className="quizes-container landing-container">
        <ImgHeader page={this.props.page} />
        <BreadCrumb
          routes={this.state.routes}
          filterbyYear={false}
          filterbySubject={false} />
        <Container className="quizes-content">
          <Row className="landing-row">
            {this.props.quizesArray.length > 0 && this.props.quizesArray.map((value, index) => {

              return (
                <Col className="mb-4" xs={12} md={6} lg={4} key={index}
                  onClick={() => (value.subcategories.length > 0 && this.openTest(value.id))}>
                  <div className="guide-card landing-card">
                    <ul>
                      <li>
                        <i className="icon-compalins"></i>
                      </li>
                      <li>
                        <h3>{this.props.lng == "ar" ? value.name : value.name_en}</h3>
                      </li>
                    </ul>
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
    );
  }
}

Quizes.propTypes = {
  actions: PropTypes.object.isRequired,
  quizesArray: PropTypes.array.isRequired,
  lng: PropTypes.string.isRequired,
  page: PropTypes.object.isRequired,
  navigationActions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  'history.push': PropTypes.func.isRequired
};
function mapStateToProps(state) {
  return {
    quizesArray: state.quizes.quizesArray,
    lng: state.user.lng,
    page: state.navigation.page
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    navigationActions: bindActionCreators(navigationActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Quizes)