import React from 'react';
import { Row, Col } from "react-bootstrap";
// import { Link } from 'react-router-dom';
// import {t} from 'i18next';
import PropTypes from "prop-types";
import './impLinks.scss'
import * as actions from '../../../../../actions/program-and-activities/programs/legal_advocay/impLinks';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Translate from '../../../../GlobalComponent/translate/translate';
class ImpLinks extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            lng: localStorage.getItem('lng'),
        }
    }
    // let linksArray= props.links;
    componentDidMount() {
        // console.log('Do it for m');
        this.props.actions.getLinksData()
            .then(() => {
                // console.log(this.props.links);

                // this.setState({ ...this.state, loading: false });
                // console.log("return data: ", this.state);

            }).catch(() => {
            });
    }
    render() {
        return (
            <div className="impLinks-container">
                <div className="choose-case height-auto">
                    <Row>
                        <Col xs={12}>
                            <h4><Translate text="ImportantLinks" /></h4>
                        </Col>
                        {
                            this.props.links.map((value, index) => {
                                return (
                                    <Col xs={12} sm={6} lg={4} key={index}>

                                        <div className="card-file" >
                                            <a href={value.link} target="blank">
                                                <Row>
                                                    <Col xs={2}>
                                                        <i className="icon-link"></i>
                                                    </Col>
                                                    <Col xs={10}>
                                                        <div className="cpa-tooltip"><h4>
                                                            {this.props.lng === 'ar' ? value.title : value.title_en}</h4>
                                                            <span className="cpa-tooltiptext">{this.props.lng === 'ar' ? value.title : value.title_en}</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </a>
                                        </div>
                                    </Col>
                                )
                            })}
                    </Row>
                </div>
            </div>

        )

    }
}
ImpLinks.propTypes = {
    // links:PropTypes.array,
    actions: PropTypes.object.isRequired,
    links: PropTypes.array.isRequired,
    lng: PropTypes.string.isRequired
};
function mapStateToProps(state) {
    return {
        links: state.imp_links.links,
        lng: state.user.lng,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImpLinks)
