import { GET_CONTACTINFO } from '../../constants/actionTypes';
import objectAssgin from 'object-assign';
import initialState from '../initialState';

export default function ContactInfoReducer(state = initialState.contactUs, action){
    switch (action.type){
        case GET_CONTACTINFO.SUCCESS:
            return objectAssgin({}, state, {contactInfo: action.payload});
        default:
            return state;
    }
}