import React from 'react';
import NewsCard from '../../GlobalComponent/newsCard/newsCard';
import ImgHeader from '../../GlobalComponent/ImgHeader/ImgHeader';
import BreadCrumb from '../../GlobalComponent/BreadCrumb/BreadCrumb';
// import CpaPagination from '../../GlobalComponent/CpaPagination/CpaPagination';
import '../../GlobalComponent/CpaPagination/CpaPagination.scss'
import { Link } from 'react-router-dom';
// import Dropdown from 'react-bootstrap/Dropdown';
import './data.scss';
import Translate from '../../GlobalComponent/translate/translate';
import * as actions from '../../../actions/information-center/data';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Pagination from 'react-bootstrap/Pagination';
import { BASE_URL } from '../../../constants/config';
import * as navigationActions from '../../../actions/navigation';
// import filter from '../../../actions/FilterBy';
import { t } from 'i18next';
class Data extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            show: false,
            loading: true,
            lng: localStorage.getItem('lng'),
            pageNumber: 1,
            tableToBeRendered: null,
            selectedPage: 1,
            elementsPerPage: 3,
            routes: [],
            mainNews: [
                {
                    img: BASE_URL + '/uploads//Images/default_image.jpg',
                    header: 'بيان جمعية حماية المستهلك حول عقود التمويل العقاري “سايبر” لعملاء بنك ساب',
                    date: ' الاحد 24 فبراير 2019',
                    viewers: '5,624'
                }
            ],
            moreNews: [

                {
                    img: BASE_URL + '/uploads//Images/default_image.jpg',
                    header: 'بيان الجمعية لليوم العالمي للمستهلك 2018 تحت شعار “جعل الأسواق الرقمية أكثر عدلاً”',
                    date: ' الخميس 15 مارس 2018',
                    viewers: '1٬194'
                }
            ],
            new: null,
            more: null,
            newNoData: null,
            moreNoData: null
        };

        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.setPage = this.setPage.bind(this);
    }

    componentDidMount() {
        this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                this.props.actions.getDataOfData(this.state.pageNumber + 1, this.state.lastSelectedYear, this.state.lastSelectedCategory)
                    .then(() => {
                        this.setState({
                            ...this.state,
                            loading: false,
                            pageNumber: 2,
                            new: this.props.new,
                            more: this.props.more.data
                        }, () => {
                            this.renderTable(1);
                        });
                    }).catch(() => {
                    });
            })
    }

    renderTable(cpn) {
        if (this.state.more !== null) {
            //console.log(this.props.more[0]);
            let startFrom = (cpn * this.state.elementsPerPage) - this.state.elementsPerPage;
            let renderTo = startFrom + (this.state.elementsPerPage - 1);
            let elements = [];
            for (var x = startFrom; x <= renderTo; x++) {
                let element = this.state.more[x];
                if (element != null) {
                    let id = element.id;
                    let toBeRendered =
                        <Link to={"/single-data/" + id} key={x}>
                            <NewsCard newscard={element} />
                        </Link>
                    elements.push(toBeRendered);
                } else {
                    break;
                }
            }
            this.setState({
                tableToBeRendered: elements
            });
        }
    }
    filterByYear = (year) => {
        this.props.actions.getDataOfData(this.state.pageNumber + 1, year, this.state.lastSelectedCategory)
            .then(() => {
                this.setState({
                    ...this.state,
                    loading: false,
                    pageNumber: 2,
                    new: this.props.new,
                    more: this.props.more.data,
                    lastSelectedYear: year,
                    selectedPage: 1
                }, () => {
                    this.renderTable(1);
                });
                setTimeout(() => {
                    this.CheckSubjuctNoData()
                }, 1);
            }).catch(() => {
            });


        // this.setState({
        //     new: filter.filterByYear(this.props.new, year, this.state.lastSelectedCategory),
        //     more: filter.filterByYear(this.props.more.data, year, this.state.lastSelectedCategory),
        //     lastSelectedYear: year,
        //     selectedPage: 1
        // },
        //     () => {
        //         this.setState({
        //             pageNumber: Math.ceil(this.state.more.length / this.state.elementsPerPage),
        //         })
        //         this.renderTable(1);
        //     })
        // setTimeout(() => {
        //     this.CheckSubjuctNoData()
        // }, 1);
    }

    filterByCategory = (catgeory) => {


        this.props.actions.getDataOfData(this.state.pageNumber + 1, this.state.lastSelectedYear, catgeory)
            .then(() => {
                this.setState({
                    ...this.state,
                    loading: false,
                    pageNumber: 2,
                    new: this.props.new,
                    more: this.props.more.data,
                    lastSelectedCategory: catgeory,
                    selectedPage: 1
                }, () => {
                    this.renderTable(1);
                });
                setTimeout(() => {
                    this.CheckSubjuctNoData()
                }, 1);
            }).catch(() => {
            });

        // this.setState({
        //     new: filter.filterByCategory(this.props.new, catgeory, this.state.lastSelectedYear),
        //     more: filter.filterByCategory(this.props.more.data, catgeory, this.state.lastSelectedYear),
        //     lastSelectedCategory: catgeory,
        //     selectedPage: 1
        // },
        //     () => {
        //         this.setState({
        //             pageNumber: Math.ceil(this.state.more.length / this.state.elementsPerPage),
        //         })
        //         this.renderTable(1);
        //     })
        // setTimeout(() => {
        //     this.CheckSubjuctNoData()
        // }, 1);
    }
    CheckSubjuctNoData = () => {
        if (this.state.new.length == 0) {
            this.setState({
                newNoData:
                    <div className="notFound-container">
                        <div className="notFound-content text-center">
                            <div className="inner">
                                <h3>
                                    {t("noData")}
                                </h3>
                            </div>
                        </div>
                    </div>,
            })
        } else {
            this.setState({
                newNoData: '',
            })
        }
        if (this.state.more.length == 0) {
            this.setState({
                moreNoData:
                    <div className="notFound-container">
                        <div className="notFound-content text-center">
                            <div className="inner">
                                <h3>
                                    {t("noData")}
                                </h3>
                            </div>
                        </div>
                    </div>,
            })
        } else {
            this.setState({
                moreNoData: '',
            })
        }
    }
    nextPage() {
        if (this.state.selectedPage < this.props.more.last_page) {
            let nextPageValue = this.state.selectedPage + 1;
            this.props.actions.getDataOfData(nextPageValue + 1, this.state.lastSelectedYear, this.state.lastSelectedCategory)
                .then(() => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        pageNumber: nextPageValue,
                        new: this.props.new,
                        more: this.props.more.data,
                        selectedPage: nextPageValue,
                    }, () => {
                        this.renderTable(1);
                    })
                })
        }
    }

    previousPage() {
        if (this.state.selectedPage > 1) {
            let prevPageValue = this.state.selectedPage - 1;
            this.props.actions.getDataOfData(prevPageValue + 1, this.state.lastSelectedYear, this.state.lastSelectedCategory)
                .then(() => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        pageNumber: prevPageValue,
                        new: this.props.new,
                        more: this.props.more.data,
                        selectedPage: prevPageValue,
                    }, () => {
                        this.renderTable(1);
                    })
                })
        }
    }

    setPage(number) {
        this.props.actions.getDataOfData(number + 1, this.state.lastSelectedYear, this.state.lastSelectedCategory)
            .then(() => {
                this.setState({
                    ...this.state,
                    loading: false,
                    pageNumber: number,
                    new: this.props.new,
                    more: this.props.more.data,
                    selectedPage: number,
                }, () => {
                    this.renderTable(1);
                })
            })
    }

    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }
    render() {
        return (
            this.props.page &&
            <div className="data-conatiner" >
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={true}
                    filterbySubject={true}
                    filterByYearfun={this.filterByYear}
                    filterByCategoryfun={this.filterByCategory} />
                <div className="container">
                    <h5 className="title"><Translate text="RecentDeclarations" /> </h5>

                    <div className="row new-news">
                        {this.state.loading && <div><p>Loading ...</p></div>}
                        {this.state.new && this.state.new.map((value, index) => {
                            return (
                                <div className="col-12 col-md-4" key={index}>
                                    <Link to={"/single-data/" + value.id}>
                                        <NewsCard newscard={value} />
                                    </Link>
                                </div>
                            )
                        })}
                        {this.state.newNoData}
                    </div>
                    <div className="row more-container">

                        <div className="col-12 col-lg-10 more-news" >
                            <h5 className="sub-title"><Translate text="moreData" /></h5>

                            {this.state.loading && <div><p>Loading ...</p></div>}
                            {this.state.more &&
                                this.props.more.data !== null ?
                                this.state.tableToBeRendered
                                :
                                <div></div>
                            }
                            {this.state.moreNoData}
                            {this.state.more && this.state.more.length > 0 &&
                                <div className="news-pag">
                                    {this.state.lng === 'ar' ?
                                        <Pagination>
                                            <Pagination.Next onClick={this.nextPage} />

                                            <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.selectedPage + 2)}>{this.state.selectedPage + 2}</Pagination.Item>

                                            <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.selectedPage + 1)}>{this.state.selectedPage + 1}</Pagination.Item>

                                            <Pagination.Item active>{this.state.selectedPage}</Pagination.Item>

                                            {this.state.selectedPage - 1 > 0 &&
                                                <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.selectedPage - 1)}>{this.state.selectedPage - 1}</Pagination.Item>
                                            }

                                            {
                                                this.state.selectedPage - 2 > 0 &&
                                                <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.selectedPage - 2)}>{this.state.selectedPage - 2}</Pagination.Item>
                                            }

                                            <Pagination.Prev onClick={this.previousPage} />

                                        </Pagination>
                                        :
                                        <Pagination>
                                            <Pagination.Next onClick={this.previousPage} />

                                            {this.state.selectedPage - 2 > 0 &&
                                                <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.selectedPage - 2)}>{this.state.selectedPage - 2}</Pagination.Item>}

                                            {this.state.selectedPage - 1 > 0 &&
                                                <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.selectedPage - 1)}>{this.state.selectedPage - 1}</Pagination.Item>}

                                            <Pagination.Item active>{this.state.selectedPage}</Pagination.Item>
                                            <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.selectedPage + 1)}>{this.state.selectedPage + 1}</Pagination.Item>
                                            <Pagination.Item className="customPaginationItem" onClick={() => this.setPage(this.state.selectedPage + 1)}>{this.state.selectedPage + 2}</Pagination.Item>
                                            <Pagination.Prev onClick={this.nextPage} />

                                        </Pagination>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    {/* <div className="notFound-container">
                    <div className="notFound-content text-center">
                        <div className="inner">
                            <h3>
                                {t("noData" )}
                            </h3>
                        </div>
                    </div>
                </div> */}
                </div>
            </div>
        )
    }
}
Data.propTypes = {
    actions: PropTypes.object.isRequired,
    new: PropTypes.array.isRequired,
    more: PropTypes.array.isRequired,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired
};
function mapStateToProps(state) {
    return {
        new: state.data.new,
        more: state.data.more,
        page: state.navigation.page
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Data)