import { GET_TAKEkNOWLEDGE_SUBJECTS } from '../../../constants/actionTypes';
import { apiFetch } from '../../../utils/http.helpers';
import { BASE_URL } from '../../../constants/config';

export function getKnowledgeData() {
  return function (dispatch) {
    return apiFetch(BASE_URL+'/api/ta3araf/search?target=knowledge', {
      method: "GET",
      // mode: "cors",
      withCredentials: true,
      headers: {
        "Accept": "application/json",
        // "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
      }
    })
      .then(response => response.json())
      .then(res => {
        // console.log('Res in fetch', res);
        dispatch({ type: GET_TAKEkNOWLEDGE_SUBJECTS.SUCCESS, payload: res });
      }).catch(() => {
        // console.log('Error is', error);
      });
  };
}