import { GET_NAVIGATION_PAGES, GET_LANDING_PAGES, GET_PAGE_INFO, GET_BUILDER_PAGE, SAVE_CURRENT_FORM, SAVE_CURRENT_FORM_STR } from '../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';

export default function navigationReduser(state = initialState.navigation, action) {
  switch (action.type) {
    case GET_NAVIGATION_PAGES.SUCCESS:
      return objectAssign({}, state, { pages: action.payload });
    case GET_LANDING_PAGES.SUCCESS:
      return objectAssign({}, state, { landingPages: action.payload });
    case GET_PAGE_INFO.SUCCESS:
      return objectAssign({}, state, { page: action.payload });
    case GET_BUILDER_PAGE.SUCCESS:
      return objectAssign({}, state, { buiderPage: action.payload });
    case SAVE_CURRENT_FORM:
      if (state.buiderPageData.findIndex(singleData => singleData.form_id === action.payload.form_id) === -1) {
        return objectAssign({}, state, { buiderPageData: [...state.buiderPageData, action.payload] });
      }
      else {
        let temp = [...state.buiderPageData]
        temp = temp.filter(singleData => singleData.form_id !== action.payload.form_id)
        return objectAssign({}, state, { buiderPageData: [...temp, action.payload] });
      }

    case SAVE_CURRENT_FORM_STR:
      if (state.buiderPageForms.findIndex(singleData => singleData.form_id === action.payload.form_id) === -1) {
        return objectAssign({}, state, { buiderPageForms: [...state.buiderPageForms, action.payload] });
      }
      else {
        let temp = [...state.buiderPageForms]
        temp = temp.filter(singleData => singleData.form_id !== action.payload.form_id)
        return objectAssign({}, state, { buiderPageForms: [...temp, action.payload] });
      }
    default:
      return state;
  }
}