import React from 'react';
import ImgHeader from '../../../GlobalComponent/ImgHeader/ImgHeader';
import BreadCrumb from '../../../GlobalComponent/BreadCrumb/BreadCrumb';
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import Complain from './complain/complain';
import GuideUs from './guideUs/guideUs';
import IndicativeContracts from './indicativeContracts/indicativeContracts';
import ImpLinks from './impLinks/impLinks';
import CpaSharing from '../../../GlobalComponent/cpaSharing/cpaSharing';
import './LegalAdvocacy.scss';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Translate from '../../../GlobalComponent/translate/translate';
import FollowComplaint from './followComplaint/followComplaint';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as navigationActions from '../../../../actions/navigation';
import * as actions from '../../../../actions/program-and-activities/programs/legalAdvocacy';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

let myDivToFocusStyle = {
    paddingTop: "66px"
}
class LegalAdvocacy extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.myDivToFocus = React.createRef();
        this.letUsHearYou = React.createRef();
        this.state = {
            show: false,
            case: "",
            subjectContent: "",
            links: [
                {
                    name: "أمانة منطقة الرياض",
                    link: ""
                },
                {
                    name: "البريد السعودي",
                    link: ""
                },
                {
                    name: "المؤسسة العامة للتدريب التقني والمهني – الإدارة العامة للتدريب الأهلي",
                    link: ""
                },
                {
                    name: "الهيئة السعودية للمهندسين",
                    link: ""
                }
            ],
            routes: []
        }
    }
    handleChange = event => {
        event.preventDefault();
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    }

    handleScrollToDiv = () => {
        if (this.myDivToFocus.current) {

            // setTimeout(() => {

            this.myDivToFocus.current.scrollIntoView({
                behavior: "smooth",
                // block: "start"
            })

            // }, 0)
        }
    }

    // UNSAFE_componentWillMount() {

    //     const { tab, id } = this.props.match.params;
    //     console.log(tab, id, "tab-id")
    //     if (tab && !id) {
    //         this.setState({ selectSubject: tab }, () => {
    //             this.handleScrollToDiv();
    //         });
    //     }
    //     else {
    //         window.scrollTo({
    //             top: 0
    //         })

    //     }
    // }

    showError(errmsg) {
        toast.error(errmsg, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    showSuccess(errmsg) {
        toast.success(errmsg, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    componentDidMount() {
        // console.log("did")
        this.props.actions.getCasesOfComplains()
            .then(() => { })
            .catch(error => {
                if (error.message === "unauthorized") {
                    this.logout();
                    this.setState({
                        autherized: "unauthorized"
                    })
                }
            });
        let selectedTab = this.props.match.params.tab;
        // console.log("setab", selectedTab)
        this.setState({
            selectSubject: selectedTab
        })
        this.props.navigationActions.getPageInfoApi('/legal-advocacy').then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
            }
        )
        // console.log("did", this.props.location.pathname)
        let path = this.props.location.pathname;
        if (path == '/legal-advocacy' || path == '/legal-advocacy/') {
            // console.log("'/legal-advocacy'")
            window.scrollTo({
                top: 0
            })
        }
        // const { tab, id } = this.props.match.params;
        // if (!tab || !id) {
        //     console.log(true)
        //     window.scrollTo({
        //         top: 0
        //     })
        //  }
    }

    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }
    render() {
        // console.log(this.state.selectSubject)
        // if (this.props.match.params.id && this.props.match.params.tab == "LetUsHearYou") {
        //     if (window.innerWidth >= 998) {
        //         window.scrollTo({
        //             top: 1100,
        //             left: 0,
        //             behavior: 'smooth'
        //         })
        //     }

        //     else if (window.innerWidth < 998 && window.innerWidth >= 750) {
        //         window.scrollTo({
        //             top: 1900,
        //             left: 0,
        //             behavior: 'smooth'
        //         })
        //     }
        //     else {
        //         window.scrollTo({
        //             top: 2000,
        //             left: 0,
        //             behavior: 'smooth'
        //         })
        //     }
        // }
        return (
            this.props.page &&
            <div className="landing-container LegalAdvocacy-container">
                {/* <Notifications /> */}
                <ToastContainer />
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                <Container>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={this.state.selectSubject} onSelect={this.handleScrollToDiv}>
                        <Row className="landing-row justify-content-center">
                            <Col xs={12}>
                                <Nav variant="pills" className="flex-row">
                                    <Nav.Item className="col-6 col-md-6 col-lg-3 mb-4">
                                        <Nav.Link eventKey="SubmitAComplaint">
                                            <Link to="/legal-advocacy/SubmitAComplaint" onClick={this.handleScrollToDiv}>
                                                <div className="landing-card">
                                                    <ul>
                                                        <li><i className="icon-compalins"></i></li>
                                                        <li><h5><Translate text="fileComplaint" /></h5></li>
                                                    </ul>
                                                </div>
                                            </Link>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="col-6 col-md-6 col-lg-2 mb-4">
                                        <Nav.Link eventKey="trackyourcomplaint">
                                            <Link to="/legal-advocacy/trackyourcomplaint" onClick={this.handleScrollToDiv}>
                                                <div className="landing-card">
                                                    <ul>
                                                        <li><i className="icon-menu-about"></i></li>
                                                        <li><h5><Translate text="followComplaint" /></h5></li>
                                                    </ul>
                                                </div>
                                            </Link>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="col-6 col-md-6 col-lg-2 mb-4">
                                        <Nav.Link eventKey="LetUsHearYou">
                                            <Link ref={this.letUsHearYou} to="/legal-advocacy/LetUsHearYou" onClick={this.handleScrollToDiv}>
                                                <div className="landing-card">
                                                    <ul>
                                                        <li><i className="icon-resal"></i></li>
                                                        <li><h5><Translate text="guidedUs" /></h5></li>
                                                        {/* <li><p>اقتراح الأنظمة ذات الصلة بحماية المستهلك وتطويرها</p></li> */}
                                                    </ul>
                                                </div>
                                            </Link>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="col-6 col-md-6 col-lg-3 mb-4">
                                        <Nav.Link eventKey="proposed-contractual-agreement-templates">
                                            <Link to="/legal-advocacy/proposed-contractual-agreement-templates" onClick={this.handleScrollToDiv}>
                                                <div className="landing-card">
                                                    <ul>
                                                        <li><i className="icon-special"></i></li>
                                                        <li><h5><Translate text="contracts" /></h5></li>
                                                        {/* <li><p>اقتراح الأنظمة ذات الصلة بحماية المستهلك وتطويرها</p></li> */}
                                                    </ul>
                                                </div>
                                            </Link>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="col-6 col-md-6 col-lg-2 mb-4">
                                        <Nav.Link eventKey="impLinks">
                                            <Link to="/legal-advocacy/impLinks" onClick={this.handleScrollToDiv}>
                                                <div className="landing-card">
                                                    <ul>
                                                        <li><i className="icon-link"></i></li>
                                                        <li><h5><Translate text="impLinks" /></h5></li>
                                                        {/* <li><p>اقتراح الأنظمة ذات الصلة بحماية المستهلك وتطويرها</p></li> */}
                                                    </ul>
                                                </div>
                                            </Link>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col xs={11}>
                                <CpaSharing />
                            </Col>
                            <Col xs={12}>
                                <div ref={this.myDivToFocus} style={myDivToFocusStyle}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="SubmitAComplaint">
                                            <Complain toggleModal={this.props.toggleModal} showError={this.showError} showSuccess={this.showSuccess} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="LetUsHearYou">
                                            <GuideUs toggleModal={this.props.toggleModal} showError={this.showError} showSuccess={this.showSuccess} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="proposed-contractual-agreement-templates">
                                            <IndicativeContracts />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="impLinks" className="impLinks-container">
                                            <ImpLinks links={this.state.links} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="trackyourcomplaint" className="impLinks-container">
                                            <FollowComplaint />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>

            </div>
        )
    }
}
LegalAdvocacy.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            tab: PropTypes.string,
            id: PropTypes.string
        })
    }),
    toggleModal: PropTypes.func.isRequired,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    location: PropTypes.object,
    "location.pathname": PropTypes.string,
    "location.pathname.substring": PropTypes.func,
};

function mapStateToProps(state) {
    return {
        page: state.navigation.page
    };
}

function mapDispatchToProps(dispatch) {
    return {
        navigationActions: bindActionCreators(navigationActions, dispatch),
        actions: bindActionCreators(actions, dispatch)
    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LegalAdvocacy))