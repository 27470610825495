import React from 'react';
import './worldDays.scss';
// import { t } from 'i18next';
import ImgHeader from '../../GlobalComponent/ImgHeader/ImgHeader';
import BreadCrumb from '../../GlobalComponent/BreadCrumb/BreadCrumb';
import { Container, Row } from "react-bootstrap";
import * as actions from '../../../actions/program-and-activities/worlddays';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ContainerCard from "../../GlobalComponent/ContainerCard/containerCard";
import WorldDaysCard from "../../GlobalComponent/worldDaysCard/worldDaysCard.js";
import { BASE_URL } from '../../../constants/config';
import * as navigationActions from '../../../actions/navigation';
import Translate from '../../GlobalComponent/translate/translate';


class WorldDays extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            indexOfLastCard: 6,
            indexOfFirstCard: 0,
            loading: true,
            img_url: BASE_URL + '/uploads/',
            cards: [],
            show: false,
            imgs_array: [],
            routes: []
        };
    }
    componentDidMount() {
        this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                fetch(BASE_URL + "/api/world_days/getAllWorldDays")
                    .then(res => res.json())
                    .then(
                        (result) => {
                            this.setState({ cards: result });
                            this.setState({ loading: false });
                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    );
            })
    }
    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }
    handleClose() {
        this.setState({ show: false });
    }

    handleShow(index) {
        this.setState({ imgs_array: this.state.cards[index].main_imgs });
        this.setState({ show: true });
    }

    addCards() {
        this.setState({ indexOfLastCard: this.state.indexOfLastCard + 6 })
    }


    render() {
        let currentCards = [];
        if (this.state.cards !== null) {
            currentCards = this.state.cards.slice(this.state.indexOfFirstCard, this.state.indexOfLastCard)
        }
        let moreButton;
        if (this.state.cards) {
            moreButton =
                currentCards.length !== this.state.cards.length &&
                <button style={{ marginTop: "1rem" }} onClick={() => { this.addCards() }} className="green" type="button"><Translate text="theMore" /></button>
        }
        return (
            this.props.page &&
            <div className="worldDays-container" >
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                <Container className="worldDays-container" >
                    <Row>
                        {this.state.loading && <div><p>Loading ...</p></div>}
                        <ContainerCard>
                            {
                                currentCards.length > 0 ? (
                                    <WorldDaysCard col lg="4" sm="12" day={currentCards}>
                                        <a className="pointer"><i className="icon-angle-double-left"></i></a>
                                    </WorldDaysCard>
                                ) : ""
                            }
                        </ContainerCard>
                    </Row>
                </Container>
                {moreButton}
            </div>
        )
    }
}

WorldDays.propTypes = {
    actions: PropTypes.object.isRequired,
    worldDaysArray: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    lng: PropTypes.string.isRequired,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired
};
function mapStateToProps(state) {
    return {
        worldDaysArray: state.world_days.worldDaysArray,
        lng: state.user.lng,
        page: state.navigation.page
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(WorldDays)
