import {GET_COMMITTEES_DATA} from '../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../initialState';

export default function committeesReducer(state = initialState.el_gam3ya.committees, action) {
    switch (action.type) {
        case GET_COMMITTEES_DATA.SUCCESS:
          return objectAssign({}, state, {
            Committees_array: action.payload,
        });
        default:
          return state;
      }
}
