import React from 'react';
import './Authentication.scss';
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { t } from 'i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/auth/auth';
import "./forgotPasswordAuth.scss"
class ForgotPasswordAuth extends React.Component {
    state = {
        verificationCodeError: null,
        retryPasswordError: false,
        verificationCode: null,
        new_password: null,
        retryPassword: null
    }

    componentDidMount() {
        // window.scrollTo(10)
        window.scrollTo({
            top: 50,
            // left: 100,
            behavior: 'smooth'
        });
        this.setState({
            verificationCode: this.props.match.params.code
        })
    }

    handleClose = () => {
        this.setState({
            verificationCodeError: null,
            retryPasswordError: false,
            verificationCode: null,
            new_password: null,
            retryPassword: null
        }, () => {
            this.props.history.push('/')
        })
    }

    handleChange = event => {
        // event.preventDefault();
        const { name, value } = event.target;
        this.setState({ ...this.state, [name]: value })
        if (name == "retryPassword" || name == "new_password") {
            this.setState({
                retryPasswordError: value != this.state.new_password
            })
        }
    };

    SbmitForgotPassword = () => {
        this.setState({ verificationCodeError: '' })
        if (!this.state.retryPasswordError) {
            this.props.actions.requestNewPassword({ code: this.state.verificationCode, password: this.state.new_password })
                .then((response) => {
                    if (response.status === 200) {
                        this.setState({ verificationCodeError: <p>{t('passwordChanged')}</p> })
                        setTimeout(() => {
                            this.handleClose();
                        }, 2000);
                    }
                    else {
                        this.setState({ verificationCodeError: <p>{t('verification_error')}</p> })
                    }
                }).catch(() => {
                })
        }
    }
    render() {
        // add disabled class to the button if there is verification entered
        let VerficationButtonClass = this.state.verificationCode && this.state.new_password
            && this.state.retryPassword ?
            null : "disabled";
        return (
            <div className="forgot-password-form-step2" style={{ position: "relative", top: "10rem", textAlign: "center" }}>
                <Row>
                    <Col>
                        <h4>
                            {t('resetPassword')}
                        </h4>
                    </Col>
                </Row>
                <form>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={8} >
                            <div className="form-input">
                                <i className="icon-lock"></i>
                                <input
                                    name="new_password"
                                    placeholder={t("newPassword")}
                                    onChange={this.handleChange}
                                    type="password" required />
                            </div>
                        </Col>
                        <Col xs={12} sm={8} >
                            <div className="form-input">
                                <i className="icon-lock"></i>
                                <input
                                    name="retryPassword"
                                    placeholder={t("retryPassword")}
                                    onChange={this.handleChange}
                                    type="password" required />
                                {this.state.retryPasswordError &&
                                    <small>{t('retryPasswordError')}</small>
                                }
                                {this.state.verificationCodeError}
                            </div>
                        </Col>
                        <Col className="mb-4" xs={10}>
                            <button disabled={!this.state.verificationCode || !this.state.new_password || !this.state.retryPassword}
                                type="button" className={"btn submit-cpa-btn " + VerficationButtonClass} onClick={this.SbmitForgotPassword}>{t('submit')}</button>
                        </Col>
                    </Row>
                </form>
            </div>
        )
    }
}

ForgotPasswordAuth.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    showModal: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    history: PropTypes.object,
    'history.push': PropTypes.func
};

function mapStateToProps(state) {
    return {
        user: state.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordAuth);