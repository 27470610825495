import React from 'react';
import moment from 'moment';
import './comapreAndRate.scss';
import './categoryPage.scss';
import BreadCrumb from '../../../GlobalComponent/BreadCrumb/BreadCrumb';
import ImgHeader from '../../../GlobalComponent/ImgHeader/ImgHeader';
import { t } from 'i18next';
import { Container, Row, Col, Button, Collapse, Card } from 'react-bootstrap';
import PropTypes from "prop-types";
import * as actions from "../../../../actions/program-and-activities/programs/compareAndRate";
import * as navigationActions from '../../../../actions/navigation'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Translate from '../../../GlobalComponent/translate/translate';
import { BASE_URL } from '../../../../constants/config';
import { toast } from 'react-toastify';
import CpaSharing from '../../../GlobalComponent/cpaSharing/cpaSharing';
// import DefaultPage from '../../../GlobalComponent/defaultPage/DefaultPage';

class CategoryPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            menuOpen: false,
            menuOpen1: false,
            arrowDown: 'icon-down-dir',
            arrowUp: 'icon-up-dir',
            arrow: 'icon-down-dir',
            arrowDown1: 'icon-down-dir',
            arrowUp1: 'icon-up-dir',
            arrow1: 'icon-down-dir',
            filtersArray: [],
            filtersToBeRendered: null,
            show: false,
            tableToBeRendered: null,
            modalVisibility: false,
            toBeComparedObjects: [],
            diableObjectCompareBtn: [],
            cards: [
                {
                    icon: 'icon-board',
                    title: 'Title1',
                    hover: [
                        {
                            name: 'btn1',
                            link: '/compare-and-rate/categories/1'
                        },
                        {
                            name: 'btn2',
                            link: '/compare-and-rate/categories/2',
                        },
                        {
                            name: 'btn3',
                            link: '/compare-and-rate/categories/3',
                        },
                    ]
                },
                {
                    icon: 'icon-board',
                    title: 'Title2',
                    hover: [
                        {
                            name: 'btn1',
                            link: '/compare-and-rate/categories/4'
                        },
                        {
                            name: 'btn2',
                            link: '/compare-and-rate/categories/5',
                        },
                        {
                            name: 'btn3',
                            link: '/compare-and-rate/categories/6',
                        },
                    ]
                },
                {
                    icon: 'icon-board',
                    title: 'Title1',
                    hover: [
                        {
                            name: 'btn1',
                            link: '/compare-and-rate/categories/1'
                        },
                        {
                            name: 'btn2',
                            link: '/compare-and-rate/categories/2',
                        },
                        {
                            name: 'btn3',
                            link: '/compare-and-rate/categories/3',
                        },
                    ]
                },
                {
                    icon: 'icon-board',
                    title: 'Title1',
                    hover: [
                        {
                            name: 'btn1',
                            link: '/compare-and-rate/categories/1'
                        },
                        {
                            name: 'btn2',
                            link: '/compare-and-rate/categories/2',
                        },
                        {
                            name: 'btn3',
                            link: '/compare-and-rate/categories/3',
                        },
                    ]
                },
            ],
            routes: [],
            comparisonProducts: [
                {
                    name: 'Product 1',
                    img: 'https://www.snb.com/stuff/contentmgr/files/0/39deaa2de12c64deca866c0cce11045c/misc/personal_credit_card.png',
                    description: [
                        {
                            filterName: 'Filter1',
                            value: 'asd'
                        },
                        {
                            filterName: 'Filter2',
                            value: 'qwe'
                        }
                    ]
                },
                {
                    name: 'Product 2',
                    img: 'https://www.snb.com/stuff/contentmgr/files/0/39deaa2de12c64deca866c0cce11045c/misc/personal_credit_card.png',
                    description: [
                        {
                            filterName: 'Filter3',
                            value: 'asd'
                        },
                        {
                            filterName: 'Filter2',
                            value: 'qwe'
                        }
                    ]
                },
                {
                    name: 'Product 3',
                    img: 'https://www.snb.com/stuff/contentmgr/files/0/39deaa2de12c64deca866c0cce11045c/misc/personal_credit_card.png',
                    description: [
                        {
                            filterName: 'Filter1',
                            value: 'asd'
                        },
                        {
                            filterName: 'Filter2',
                            value: 'qwe'
                        }
                    ]
                },
            ],

            availableProducts: [
                {
                    name: 'New Product 1',
                    img: 'https://www.snb.com/stuff/contentmgr/files/0/39deaa2de12c64deca866c0cce11045c/misc/personal_credit_card.png',
                    description: [
                        {
                            filterName: 'Filter1',
                            value: 'asd'
                        },
                        {
                            filterName: 'Filter2',
                            value: 'qwe'
                        }
                    ]
                },
                {
                    name: 'New Product 2',
                    img: 'https://www.snb.com/stuff/contentmgr/files/0/39deaa2de12c64deca866c0cce11045c/misc/personal_credit_card.png',
                    description: [
                        {
                            filterName: 'Filter3',
                            value: 'asd'
                        },
                        {
                            filterName: 'Filter2',
                            value: 'qwe'
                        }
                    ]
                },
                {
                    name: 'New Product 3',
                    img: 'https://www.snb.com/stuff/contentmgr/files/0/39deaa2de12c64deca866c0cce11045c/misc/personal_credit_card.png',
                    description: [
                        {
                            filterName: 'Filter1',
                            value: 'asd'
                        },
                        {
                            filterName: 'Filter2',
                            value: 'qwe'
                        }
                    ]
                },
            ]
        }

        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.toggleCollapse1 = this.toggleCollapse1.bind(this);
        this.handleFilters = this.handleFilters.bind(this);
        this.renderTable = this.renderTable.bind(this);
        this.renderFilters = this.renderFilters.bind(this);
        this.addProductToComparison = this.addProductToComparison.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.firstCollapseHandler = this.firstCollapseHandler.bind(this);
        this.secondCollapseHandler = this.secondCollapseHandler.bind(this);
        this.addobjectToCompareList = this.addobjectToCompareList.bind(this);
        this.removeobjectFromCompareList = this.removeobjectFromCompareList.bind(this);
        this.compareObjects = this.compareObjects.bind(this);
    }

    compareObjects() {
        let ids = [];
        this.props.toBeComparedObjects.map(value => {
            ids.push(value.id);
        })
        this.props.history.push(`/compare-and-rate/compare/${parseInt(this.props.match.params.branchId)}`, {
            IdsArray: ids,
            productCategoryId: this.props.match.params.id
        });
    }
    // adding product to compare list
    addobjectToCompareList(value) {
        let obejcetsArrayCopy = [...this.props.toBeComparedObjects];
        if (obejcetsArrayCopy.length < 3) {
            let checkAddedObject = obejcetsArrayCopy.find(element => element.id === value.id);
            if (checkAddedObject === undefined) {
                obejcetsArrayCopy.push(value);
                this.props.actions.addToBeComparedObjectsList(obejcetsArrayCopy);

                let disabledObjectButtonsIds = [...this.props.diableObjectCompareBtn];
                disabledObjectButtonsIds.push(value.id);
                this.props.actions.addButtonIdOfAnAddedObject(disabledObjectButtonsIds);
            }
        }
        else {
            toast.error(t('maxProductsReached'), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    }
    // removing object from comparing list
    removeobjectFromCompareList(value) {
        let objectsArrayCopy = [...this.props.toBeComparedObjects];
        let objectToBeDeletedIndex = objectsArrayCopy.findIndex(od => od.id === value.id)
        objectsArrayCopy.splice(objectToBeDeletedIndex, 1);
        this.props.actions.removeSpecificObjectFromToBeComparedObjects(objectsArrayCopy);
        let buttonsOfAddedObjects = [...this.props.diableObjectCompareBtn];
        let index = buttonsOfAddedObjects.findIndex(bi => bi === value.id);
        buttonsOfAddedObjects.splice(index, 1);
        this.props.actions.removeButtonIdOfAnAddedObject(buttonsOfAddedObjects);
    }

    // the function to do when the first collapse is changed
    firstCollapseHandler(value) {
        // console.log("v", value)
        let index = this.props.multiCategory[0].multi_categories_many.findIndex(category => category.id === value);
        if (index != -1) {
            this.setState({ show: false })
            this.props.actions.setFirstCollapseIndex(index);
            this.props.actions.setFirstCollapseId(value);
            // console.log("m", index)
            if (index != -1) {
                this.setState({ firstCollapseIndex: index, loading: false })

            }
            //  else {

            // }
            this.setState({ firstCollapseIndex: index })
            this.setState({ firstCollapseId: value })
            this.props.history.push(`/compare-and-rate/categories/${parseInt(this.props.match.params.branchId)}/${value}`)
        }

    }

    // the function to do when the second collapse is changed
    secondCollapseHandler(value) {
        // console.log(value, this.props.firstCollapseIndex)
        let index = this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many.findIndex(category => category.id === value.id);
        this.props.actions.setSecondCollapseIndex(index);
        this.props.actions.setSecondCollapseId(value.id);
        this.setState({ show: true })
        this.setState({ secondCollapseIndex: index })
    }

    toggleCollapse() {
        if (this.state.arrow === this.state.arrowDown) {
            this.setState({
                menuOpen: true,
                arrow: this.state.arrowUp
            });
        } else {
            this.setState({
                menuOpen: false,
                arrow: this.state.arrowDown
            });
        }

    }

    toggleCollapse1() {
        if (this.state.arrow1 === this.state.arrowDown1) {
            this.setState({
                menuOpen1: true,
                arrow1: this.state.arrowUp1
            });
        } else {
            this.setState({
                menuOpen1: false,
                arrow1: this.state.arrowDown1
            });
        }

    }

    initSecondCollapse() {
        this.props.actions.setSecondCollapseIndex(0);
        this.props.actions.setSecondCollapseId(0);
    }

    handleFilters = event => {
        let tempArray = this.state.filtersArray;
        if (event.target.checked) {
            tempArray.push(event.target.value);
        } else {
            for (var x = 0; x < tempArray.length; x++) {
                if (tempArray[x] === event.target.value) {
                    tempArray.splice(x, 1);
                    break;
                }
            }
        }
        this.setState({
            filtersArray: tempArray
        }, () => {
            this.renderTable();
        })
    }

    renderFilters() {
        let tempArray1 = [];
        let tempArray = [];
        var i = 0;
        this.state.comparisonProducts.map((value) => {
            return (
                value.description.map((value1) => {
                    let filterName = value1.filterName;
                    if (!tempArray1.includes(filterName)) {
                        tempArray1.push(filterName);
                        let element = <span>
                            <input type="checkbox" id={'c' + i} className='chk-btn' value={filterName} onClick={this.handleFilters} />
                            <label htmlFor={'c' + i} >{filterName}</label>
                        </span>
                        i++;
                        tempArray.push(element);
                    }
                })
            )
        });
        this.setState({
            filtersToBeRendered: tempArray,
        })
    }

    renderTable() {
        let elementsArray = [];
        let allFilters = [];
        this.state.comparisonProducts.map((value) => {
            return (
                value.description.map((value1) => {
                    let tempFilterName = value1.filterName;
                    if (!allFilters.includes(tempFilterName)) {
                        allFilters.push(tempFilterName);
                    }
                })
            )
        })
        for (var x = 0; x < this.state.filtersArray.length; x++) {
            let elementRow = [];
            let filter = this.state.filtersArray[x];
            if (allFilters.includes(filter)) {
                elementRow.push(filter);
                for (var y = 0; y < this.state.comparisonProducts.length; y++) {
                    let productDesc = this.state.comparisonProducts[y].description;
                    let productFilters = [];
                    for (var z = 0; z < productDesc.length; z++) {
                        let tempFilter = productDesc[z];
                        productFilters.push(tempFilter.filterName);
                    }
                    if (productFilters.includes(filter)) {
                        for (var w = 0; w < productDesc.length; w++) {
                            let temp = productDesc[w];
                            if (temp.filterName === filter) {
                                elementRow.push(temp.value);
                                break;
                            }
                        }
                    } else {
                        elementRow.push("");
                    }
                }
            }

            let rowToBeRendered = <tr>
                {elementRow.map((value, index) => {
                    return (
                        <td key={index}>{value}</td>
                    )
                })}
            </tr>;
            elementsArray.push(rowToBeRendered);
        }

        this.setState({
            tableToBeRendered: elementsArray,
        })
    }

    addProductToComparison() {
        let newObject = {
            name: 'Product 3',
            img: 'https://www.snb.com/stuff/contentmgr/files/0/39deaa2de12c64deca866c0cce11045c/misc/personal_credit_card.png',
            description: [
                {
                    filterName: 'Filter1',
                    value: 'asd'
                },
                {
                    filterName: 'Filter2',
                    value: 'qwe'
                }
            ]
        };

        let tempArray = this.state.comparisonProducts;
        tempArray.push(newObject);

        this.setState({
            comparisonProducts: tempArray,
        }, () => {
            this.closeModal();
            this.renderFilters();
            this.renderTable();
        })
    }

    closeModal() {
        this.setState({
            modalVisibility: false,
        })
    }

    showModal() {
        this.setState({
            modalVisibility: true,
        })
    }


    componentDidMount() {
        this.props.navigationActions.getPageInfoApi('/compare-and-rate/category').then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) }, () => {
                    let categoryId = parseInt(this.props.match.params.id);
                    this.props.actions.getMultiCategoriesByIdWithEachSub(parseInt(this.props.match.params.branchId)).then(() => {
                        if (this.props.multiCategory[0].multi_categories_many.length > 0 &&
                            this.props.multiCategory[0].multi_categories_many[0].multi_categories_many.length === 0) {
                            this.props.actions.setFirstCollapseId(categoryId);
                            let index = this.props.multiCategory[0].multi_categories_many.findIndex(category => category.id === categoryId);
                            this.props.actions.setFirstCollapseIndex(index);
                        }
                        else {
                            let index = this.props.multiCategory[0].multi_categories_many.findIndex(category => category.id === categoryId);
                            let parentCat;
                            if (index === -1) {
                                for (let i = 0; i < this.props.multiCategory.length; i++) {
                                    this.props.multiCategory[i].multi_categories_many.map(oneCat => {
                                        if (oneCat.multi_categories_many.length > 0) {
                                            index = oneCat.multi_categories_many.findIndex(category => category.id === parseInt(this.props.match.params.id));
                                            if (index !== -1) {
                                                parentCat = oneCat;
                                            }
                                        }
                                    })
                                }
                            }
                            if (parentCat !== undefined) {
                                for (let i = 0; i < this.props.multiCategory.length; i++) {
                                    index = this.props.multiCategory[i].multi_categories_many.findIndex(category => category.id === parentCat.id);
                                }
                                this.props.actions.setFirstCollapseId(parentCat.id)
                                this.props.actions.setFirstCollapseIndex(index)
                            }
                            else {
                                this.props.actions.setFirstCollapseId(this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].id)
                                this.props.actions.setFirstCollapseIndex(index)
                            }

                            if (this.props.secondCollapseId !== 0) {
                                this.setState({ show: true })
                            }
                        }
                    })

                    let routesCopy = [...this.state.routes];
                    let newRoutes = routesCopy.concat({ name: "قارن", name_en: "Compare", link: this.props.match.url })
                    this.setState({ routes: newRoutes })
                });
            })
    }
    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }

    render() {
        // console.log(" start of catogry page")

        // console.log("proProps", this.props)
        // console.log("proState", this.state)
        // console.log(" end of catogry page")

        return (
            // rendering the header and the breadcrumb and the two collapses



            this.props.page &&
            <div className="comapreAndRate-container landing-container">
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                <Container>
                    {this.props.multiCategory.length > 0 &&
                        <div className="pageContent">
                            <Row>
                                <Col sm={12} className="TopMenu">
                                    <button onClick={() => this.props.history.push('/compare-and-rate/category/general-tips/' + parseInt(this.props.match.params.branchId) + '/' + this.props.firstCollapseId)} className="btn submit-cpa-btn"><Translate text="generalTips" /></button>
                                    <button onClick={() => this.props.history.push('/compare-and-rate/shareYourRating/' + parseInt(this.props.match.params.branchId) + '/' + this.props.firstCollapseId)} className="btn submit-cpa-btn"><Translate text="shareYourRating" /></button>
                                    {
                                        this.props.secondCollapseId === 0 ?
                                            <button onClick={() => this.props.history.push('/compare-and-rate/category/previous-ratings/' + parseInt(this.props.match.params.branchId) + '/' + this.props.firstCollapseId)} className="btn submit-cpa-btn"><Translate text="thePreviousRatings" /></button>
                                            :
                                            <button onClick={() => this.props.history.push('/compare-and-rate/category/previous-ratings/' + parseInt(this.props.match.params.branchId) + '/' + this.props.firstCollapseId)} className="btn submit-cpa-btn"><Translate text="thePreviousRatings" /></button>
                                    }
                                    <button className="btn submit-cpa-btn active"><Translate text="compare" /></button>
                                </Col>
                                {/* <a className="share-link">
                                    <div className="resp-sharing-button resp-sharing-button--download resp-sharing-button--small">
                                        <div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
                                            <svg xmlns="http://www.w3.org/2000/svg" id="share" width="32.616" height="34.316" fontSize="2rem" viewBox="0 0 32.289 34.252">
                                                <path id="Path_104" data-name="Path 104" d="M39.958,22a6.129,6.129,0,0,0-4.85,2.39L25.7,19.139a6.144,6.144,0,0,0,.341-2.02,6.009,6.009,0,0,0-.348-2.027l9.4-5.249a6.117,6.117,0,1,0-1.266-3.72,5.979,5.979,0,0,0,.348,2.027L24.781,13.4a6.118,6.118,0,1,0,.014,7.432l9.4,5.256A6.12,6.12,0,1,0,39.958,22Zm0-20.077a4.2,4.2,0,1,1-4.2,4.2A4.207,4.207,0,0,1,39.958,1.927ZM19.931,21.322a4.2,4.2,0,1,1,4.2-4.2A4.207,4.207,0,0,1,19.931,21.322Zm20.027,11a4.2,4.2,0,1,1,4.2-4.2A4.207,4.207,0,0,1,39.958,32.324Z" transform="translate(-13.8)" fill="#6be2b7" />
                                            </svg>
                                        </div>
                                    </div>
                                </a> */}
                                {
                                    <CpaSharing active={'active'} />
                                }
                            </Row>
                            <Row>
                                {
                                    this.props.multiCategory &&
                                    this.props.multiCategory[0].multi_categories_many[0] &&
                                    this.props.multiCategory[0].multi_categories_many[0].multi_categories_many.length > 0 &&
                                    <Col xs={12} md={3}>
                                        <h1>{this.props.lng === "ar" ? this.props.multiCategory[0].name : this.props.multiCategory[0].name_en}</h1>
                                        <div className="sideMenu">
                                            <label><Translate text="types" /></label>
                                            <Button
                                                onClick={this.toggleCollapse}
                                                variant="menu">
                                                {/* the first collapse */}
                                                <div className="btnContent">
                                                    {/* {console.log(this.props.firstCollapseIndex)} */}
                                                    {
                                                        this.props.firstCollapseIndex === -1 ?
                                                            <label className="sideMenu-label"><Translate text="pleaseChoose" /></label> :
                                                            this.props.multiCategory[0].multi_categories_many.length > 0 &&
                                                                this.props.lng === "ar" ?
                                                                this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].name :
                                                                this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].name_en
                                                    }
                                                    <i className={this.state.arrow}></i>
                                                </div>
                                            </Button>
                                            <Collapse in={this.state.menuOpen}>
                                                <div id="menuCollapseArea">
                                                    {
                                                        this.props.multiCategory.length > 0 &&
                                                        (this.props.multiCategory[0].multi_categories_many.sort()).map(value => {
                                                            return (
                                                                <Button key={value.id}
                                                                    onClick={() => {
                                                                        this.firstCollapseHandler(value.id),
                                                                            this.toggleCollapse()
                                                                    }}
                                                                    variant="subMenu">
                                                                    {this.props.lng === 'ar' ? <p>{value.name}</p> : <p>{value.name_en}</p>}
                                                                </Button>
                                                            )
                                                        })}
                                                </div>
                                            </Collapse>
                                            {
                                                this.props.multiCategory[0].multi_categories_many.length > 0 &&
                                                    this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[0].multi_categories_many.length > 0 ?
                                                    <>
                                                        <label><Translate text="departements" /></label>
                                                        <Button
                                                            onClick={this.toggleCollapse1}
                                                            variant="menu">
                                                            <div className="btnContent">
                                                                {
                                                                    this.props.multiCategory[0].multi_categories_many.length > 0 &&
                                                                        this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[0].multi_categories_many.length > 0 &&
                                                                        this.props.actions.setSecondCollapseId(this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[this.props.secondCollapseIndex].id) &&
                                                                        this.props.lng === "ar" ?
                                                                        this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[this.props.secondCollapseIndex].name :
                                                                        this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[this.props.secondCollapseIndex].name_en
                                                                }
                                                                <i className={this.state.arrow1}></i>
                                                            </div>
                                                        </Button>
                                                    </>
                                                    :
                                                    this.initSecondCollapse()
                                            }
                                            <Collapse in={this.state.menuOpen1}>
                                                <div id="menuCollapseArea">
                                                    {
                                                        this.props.multiCategory[0].multi_categories_many.length > 0 &&
                                                        this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[0].multi_categories_many.length > 0 &&
                                                        (this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many.sort()).map(value => {
                                                            return (
                                                                <Button key={value.id}
                                                                    onClick={() => {
                                                                        this.secondCollapseHandler(value)
                                                                        this.toggleCollapse1()
                                                                    }
                                                                    }
                                                                    variant="subMenu">
                                                                    {this.props.lng === 'ar' ? <p>{value.name}</p> : <p>{value.name_en}</p>}
                                                                </Button>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Collapse>
                                        </div>
                                    </Col>
                                }
                                {
                                    /* the products inside the list directly */
                                    this.props.multiCategory[0].multi_categories_many.length > 0 &&
                                    this.props.multiCategory[0].multi_categories_many[0].multi_categories_many.length === 0 &&
                                    <Col className="productsList--directly" xs={12} md={9}>
                                        {
                                            this.props.multiCategory[0].multi_categories_many.map(product => {
                                                if (product.name !== "lorem") {
                                                    return (
                                                        <Row key={product.id} className="creditCardContainer">
                                                            <Col lg={4}>
                                                                {
                                                                    product.image &&
                                                                    <img style={{ marginRight: this.props.lng === "en" ? "5rem" : "0", width: "40%", maxWidth: "40%", maxHeight: "80%", cursor: "pointer", boxShadow: "0px 0px 10px -2px #707070" }}
                                                                        src={JSON.parse(product.image).length === 0 ? '' : BASE_URL + '/uploads/' + JSON.parse(product.image)[0].download_link}
                                                                    />
                                                                }
                                                            </Col>
                                                            <Col lg={8}>
                                                                <Row>
                                                                    <Col onClick={() => this.props.history.push(`/compare-and-rate/product/${product.id}`,
                                                                        {
                                                                            product_id: product.id,
                                                                            product_name: product.name,
                                                                            product_name_en: product.name_en,
                                                                            product_desc: product.description,
                                                                            product_desc_en: product.description_en,
                                                                            product_image: product.image
                                                                        }
                                                                    )} lg={9}>
                                                                        <h2>{this.props.lng === "ar" ? product.name : product.name_en}</h2>

                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <button disabled={this.props.diableObjectCompareBtn.includes(product.id) ? true : false}
                                                                            className={this.props.diableObjectCompareBtn.includes(product.id) ? 'btn submit-cpa-btn disabled' : 'btn submit-cpa-btn'}
                                                                            type="button" onClick={() => this.addobjectToCompareList(product)}>
                                                                            <Row style={{ flexWrap: "nowrap" }} className="compareBtn">
                                                                                <Col xs={8} className="btnLabel"><Translate text="compare" /></Col>
                                                                                <Col xs={4}><i className="icon-exchange"></i></Col>
                                                                            </Row>
                                                                        </button>
                                                                    </Col>
                                                                </Row>
                                                                <p>{this.props.lng === "ar" ? product.description : product.description_en}</p>
                                                                {
                                                                    product.updated_at &&
                                                                    <div>
                                                                        <span>{t('last_updated')}</span>
                                                                        <span>{moment(product.updated_at).format('DD/MM/YYYY')}</span>
                                                                    </div>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    )
                                                }
                                                else {
                                                    {/* console.log(product) */ }
                                                    return (
                                                        <div className="notFound-container">
                                                            <Container>
                                                                <div className="notFound-content text-center">
                                                                    <div className="inner">
                                                                        <h3>
                                                                            <Translate text="noProducts" />
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </Container>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </Col>
                                }
                                <Col xs={12} md={9} style={{ top: "2.3rem", overflowY: "auto" }}>
                                    {/* {
                                        this.props.multiCategory[0].multi_categories_many.length > 0 &&
                                        this.props.multiCategory[0].multi_categories_many[0].multi_categories_many.length === 0 &&
                                        this.props.multiCategory[0].multi_categories_many.map(product => {
                                            return (
                                                <Row key={product.id} className="creditCardContainer">
                                                    <Col lg={4}>
                                                        <img onClick={() => this.props.history.push(`/compare-and-rate/product/${product.id}`,
                                                            {
                                                                product_id: product.id,
                                                                product_name: product.name,
                                                                product_name_en: product.name_en,
                                                                product_desc: product.description,
                                                                product_desc_en: product.description_en,
                                                                product_image: product.image
                                                            }
                                                        )} style={{ marginRight: this.props.lng === "en" ? "5rem" : "0", width: "40%", maxWidth: "40%", maxHeight: "80%", cursor: "pointer", boxShadow: "0px 0px 10px -2px #707070" }} src={BASE_URL + '/' + product.image} />
                                                    </Col>
                                                    <Col lg={8}>
                                                        <Row>
                                                            <Col lg={9}><h2>{this.props.lng === "ar" ? product.name : product.name_en}</h2></Col>
                                                            <Col lg={3}>
                                                                <button disabled={this.props.diableObjectCompareBtn.includes(product.id) ? true : false}
                                                                    className={this.props.diableObjectCompareBtn.includes(product.id) ? 'btn submit-cpa-btn disabled' : 'btn submit-cpa-btn'}
                                                                    type="button" onClick={() => this.addobjectToCompareList(product)}>
                                                                    <Row className="compareBtn">
                                                                        <Col xs={8} className="btnLabel"><Translate text="compare" /></Col>
                                                                        <Col xs={4}><i className="icon-exchange"></i></Col>
                                                                    </Row>
                                                                </button>
                                                            </Col>
                                                        </Row>
                                                        <p>{this.props.lng === "ar" ? product.description : product.description_en}</p>
                                                    </Col>
                                                </Row>
                                            )
                                        })

                                    } */}

                                    {/* the user entered the page for the first time and didnt choose anything */}
                                    {
                                        this.props.firstCollapseIndex === -1 &&
                                        this.props.multiCategory[0].multi_categories_many.length > 0 &&
                                        this.props.multiCategory[0].multi_categories_many.map(value => {
                                            if (value.multi_categories_many.length > 0) {
                                                {/* console.log(value) */ }
                                                return (
                                                    value.multi_categories_many.map(sub_value => {
                                                        {/* console.log(sub_value) */ }
                                                        if (sub_value.multi_categories_many.length > 0) {
                                                            return (
                                                                sub_value.multi_categories_many.map(item => {
                                                                    if (item.name !== "lorem") {
                                                                        return (
                                                                            <Row key={item.id} className="creditCardContainer">
                                                                                <Col lg={4}>
                                                                                    {
                                                                                        item.image &&
                                                                                        <img style={{ marginRight: this.props.lng === "en" ? "5rem" : "0", width: "40%", maxWidth: "40%", maxHeight: "80%", cursor: "pointer", boxShadow: "0px 0px 10px -2px #707070" }}
                                                                                            src={JSON.parse(item.image).length === 0 ? '' : BASE_URL + '/uploads/' + JSON.parse(item.image)[0].download_link}
                                                                                        />
                                                                                    }
                                                                                </Col>
                                                                                <Col lg={8}>
                                                                                    <Row>
                                                                                        <Col
                                                                                            onClick={() => this.props.history.push(`/compare-and-rate/product/${item.id}`,
                                                                                                {
                                                                                                    product_id: item.id,
                                                                                                    product_name: item.name,
                                                                                                    product_name_en: item.name_en,
                                                                                                    product_desc: item.description,
                                                                                                    product_desc_en: item.description_en,
                                                                                                    product_image: item.image
                                                                                                }
                                                                                            )}
                                                                                            lg={9}><h2>{this.props.lng === "ar" ? item.name : item.name_en}</h2>

                                                                                        </Col>
                                                                                        <Col lg={3}>
                                                                                            <button disabled={this.props.diableObjectCompareBtn.includes(item.id) ? true : false}
                                                                                                className={this.props.diableObjectCompareBtn.includes(item.id) ? 'btn submit-cpa-btn disabled' : 'btn submit-cpa-btn'}
                                                                                                type="button" onClick={() => this.addobjectToCompareList(item)}>
                                                                                                <Row className="compareBtn">
                                                                                                    <Col xs={8} className="btnLabel"><Translate text="compare" /></Col>
                                                                                                    <Col xs={4}><i className="icon-exchange"></i></Col>
                                                                                                </Row>
                                                                                            </button>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <p>{this.props.lng === "ar" ? item.description : item.description_en}</p>
                                                                                    {
                                                                                        item.updated_at &&
                                                                                        <div>
                                                                                            <span>{t('last_updated')}</span>
                                                                                            <span>{moment(item.updated_at).format('DD/MM/YYYY')}</span>

                                                                                        </div>
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    }
                                                                    else {
                                                                        {/* console.log(item) */ }
                                                                        return (
                                                                            <div className="notFound-container">
                                                                                <Container>
                                                                                    <div className="notFound-content text-center">
                                                                                        <div className="inner">
                                                                                            <h3>
                                                                                                <Translate text="noProductsFor" />
                                                                                                <span>{sub_value.name}</span>
                                                                                            </h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </Container>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })
                                                            )
                                                        }
                                                        else {
                                                            if (sub_value.name !== "lorem") {
                                                                return (
                                                                    <Row key={sub_value.id} className="creditCardContainer">
                                                                        <Col lg={4}>
                                                                            {
                                                                                sub_value.image &&
                                                                                <img style={{ marginRight: this.props.lng === "en" ? "5rem" : "0", width: "40%", maxWidth: "40%", maxHeight: "80%", cursor: "pointer", boxShadow: "0px 0px 10px -2px #707070" }}
                                                                                    src={JSON.parse(sub_value.image).length === 0 ? '' : BASE_URL + '/uploads/' + JSON.parse(sub_value.image)[0].download_link}
                                                                                />
                                                                            }
                                                                        </Col>
                                                                        <Col lg={8}>
                                                                            <Row>
                                                                                <Col
                                                                                    onClick={() => this.props.history.push(`/compare-and-rate/product/${sub_value.id}`,
                                                                                        {
                                                                                            product_name: sub_value.name,
                                                                                            product_name_en: sub_value.name_en,
                                                                                            product_desc: sub_value.description,
                                                                                            product_desc_en: sub_value.description_en,
                                                                                            product_image: sub_value.image
                                                                                        }
                                                                                    )}
                                                                                    lg={9}>
                                                                                    <h2>{this.props.lng === "ar" ? sub_value.name : sub_value.name_en}</h2>

                                                                                </Col>
                                                                                <Col lg={3}>
                                                                                    <button disabled={this.props.diableObjectCompareBtn.includes(sub_value.id) ? true : false}
                                                                                        className={this.props.diableObjectCompareBtn.includes(sub_value.id) ? 'btn submit-cpa-btn disabled' : "btn submit-cpa-btn"}
                                                                                        type="button" onClick={() => this.addobjectToCompareList(sub_value)}>
                                                                                        <Row className="compareBtn">
                                                                                            <Col xs={8} className="btnLabel"><Translate text="compare" /></Col>
                                                                                            <Col xs={4}><i className="icon-exchange"></i></Col>
                                                                                        </Row>
                                                                                    </button>
                                                                                </Col>
                                                                            </Row>
                                                                            <p>{this.props.lng === "ar" ? sub_value.description : sub_value.description_en}</p>
                                                                            {
                                                                                sub_value.updated_at &&
                                                                                <div>
                                                                                    <span>{t('last_updated')}</span>
                                                                                    <span>{moment(sub_value.updated_at).format('DD/MM/YYYY')}</span>
                                                                                </div>

                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            }
                                                            else {
                                                                {/* console.log(sub_value) */ }
                                                                return (
                                                                    <div className="notFound-container">
                                                                        <Container>
                                                                            <div className="notFound-content text-center">
                                                                                <div className="inner">
                                                                                    <h3>
                                                                                        <Translate text="noProductsFor" />
                                                                                        <span>{value.name}</span>
                                                                                    </h3>
                                                                                </div>
                                                                            </div>
                                                                        </Container>
                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                    })
                                                )
                                            }
                                            else {
                                                return (<h1>list the products directly</h1>);
                                            }
                                        })
                                    }
                                    {/* the user choosed brand from the first drop down the object has one more branching mobile > samsung > note   galaxy */}
                                    {
                                        this.state.show === false &&
                                        this.props.multiCategory[0].multi_categories_many.length > 0 &&
                                        this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many.length > 0 &&
                                        /* this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many.every(node => node.name !== "lorem") && */
                                        this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many.map(value => {
                                            {/* console.log(value) */ }
                                            if (value.multi_categories_many.length > 0) {
                                                return (
                                                    value.multi_categories_many.map(node => {
                                                        {/* console.log(node) */ }
                                                        if (node.name !== "lorem") {
                                                            return (
                                                                <Row key={node.id} className="creditCardContainer">
                                                                    <Col lg={4}>
                                                                        {
                                                                            node.image &&
                                                                            <img
                                                                                style={{ marginRight: this.props.lng === "en" ? "5rem" : "0", width: "40%", maxWidth: "40%", maxHeight: "80%", cursor: "pointer", boxShadow: "0px 0px 10px -2px #707070" }}
                                                                                src={JSON.parse(node.image).length === 0 ? '' : BASE_URL + '/uploads/' + JSON.parse(node.image)[0].download_link}
                                                                            />
                                                                        }
                                                                    </Col>
                                                                    <Col lg={8}>
                                                                        <Row>
                                                                            <Col
                                                                                onClick={() => this.props.history.push(`/compare-and-rate/product/${node.id}`,
                                                                                    {
                                                                                        product_name: node.name,
                                                                                        product_name_en: node.name_en,
                                                                                        product_desc: node.description,
                                                                                        product_desc_en: node.description_en,
                                                                                        product_image: node.image
                                                                                    }
                                                                                )} lg={9}><h2>{this.props.lng === "ar" ? node.name : node.name_en}</h2>

                                                                            </Col>
                                                                            <Col lg={3}>
                                                                                <button disabled={this.props.diableObjectCompareBtn.includes(node.id) ? true : false}
                                                                                    className={this.props.diableObjectCompareBtn.includes(node.id) ? 'btn submit-cpa-btn disabled' : "btn submit-cpa-btn"}
                                                                                    type="button" onClick={() => this.addobjectToCompareList(node)}>
                                                                                    <Row className="compareBtn">
                                                                                        <Col xs={8} className="btnLabel"><Translate text="compare" /></Col>
                                                                                        <Col xs={4}><i className="icon-exchange"></i></Col>
                                                                                    </Row>
                                                                                </button>
                                                                            </Col>
                                                                        </Row>
                                                                        <p>{this.props.lng === "ar" ? node.description : node.description_en}</p>
                                                                        {
                                                                            node.updated_at &&
                                                                            <div>
                                                                                <span>{t('last_updated')}</span>
                                                                                <span>{moment(node.updated_at).format('DD/MM/YYYY')}</span>
                                                                            </div>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        }
                                                        else {
                                                            {/* console.log(node) */ }
                                                            return (
                                                                <Row className="creditCardContainer">
                                                                    <Col lg={4}></Col>
                                                                    <Col lg={8}>
                                                                        <Row>
                                                                            <Col>
                                                                                <Translate text="noProductsFor" />
                                                                                <span>{value.name}</span>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        }
                                                    })
                                                )
                                            }
                                            else {
                                                if (value.name !== "lorem") {
                                                    return (
                                                        <Row key={value.id} className="creditCardContainer">
                                                            <Col lg={4}>
                                                                {
                                                                    value.image &&
                                                                    <img style={{ marginRight: this.props.lng === "en" ? "5rem" : "0", width: "40%", maxWidth: "40%", maxHeight: "80%", cursor: "pointer", boxShadow: "0px 0px 10px -2px #707070" }}
                                                                        src={JSON.parse(value.image).length === 0 ? '' : BASE_URL + '/uploads/' + JSON.parse(value.image)[0].download_link}
                                                                    />
                                                                }
                                                            </Col>
                                                            <Col lg={8}>
                                                                <Row>
                                                                    <Col onClick={() => this.props.history.push(`/compare-and-rate/product/${value.id}`,
                                                                        {
                                                                            product_name: value.name,
                                                                            product_name_en: value.name_en,
                                                                            product_desc: value.description,
                                                                            product_desc_en: value.description_en,
                                                                            product_image: value.image
                                                                        }
                                                                    )} lg={9}><h2>{this.props.lng === "ar" ? value.name : value.name_en}</h2>

                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <button disabled={this.props.diableObjectCompareBtn.includes(value.id) ? true : false}
                                                                            className={this.props.diableObjectCompareBtn.includes(value.id) ? 'btn submit-cpa-btn disabled' : "btn submit-cpa-btn"}
                                                                            type="button" onClick={() => this.addobjectToCompareList(value)}>
                                                                            <Row className="compareBtn">
                                                                                <Col xs={8} className="btnLabel"><Translate text="compare" /></Col>
                                                                                <Col xs={4}><i className="icon-exchange"></i></Col>
                                                                            </Row>
                                                                        </button>
                                                                    </Col>
                                                                </Row>
                                                                <p>{this.props.lng === "ar" ? value.description : value.description_en}</p>
                                                                {
                                                                    value.updated_at &&
                                                                    <div>
                                                                        <span>{t('last_updated')}</span>
                                                                        <span>{moment(value.updated_at).format('DD/MM/YYYY')}</span>
                                                                    </div>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    )
                                                }
                                                else {
                                                    {/* console.log(value) */ }
                                                    return (
                                                        <Row className="creditCardContainer">
                                                            <Col lg={4}></Col>
                                                            <Col lg={8}>
                                                                <Row>
                                                                    <Col>
                                                                        <Translate text="noProducts" />
                                                                        {/* <span>{value.name}</span> */}
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    )
                                                }
                                            }
                                        })
                                    }
                                    {
                                        this.state.show === true &&
                                        this.props.multiCategory[0].multi_categories_many[this.props.firstCollapseIndex].multi_categories_many[this.props.secondCollapseIndex].multi_categories_many.map((product) => {
                                            {/* console.log(product) */ }
                                            if (product.name !== "lorem") {
                                                return (
                                                    <Row key={product.id} className="creditCardContainer">
                                                        <Col lg={4}>
                                                            {
                                                                product.image &&
                                                                <img style={{ marginRight: this.props.lng === "en" ? "5rem" : "0", width: "40%", maxWidth: "40%", maxHeight: "80%", cursor: "pointer", boxShadow: "0px 0px 10px -2px #707070" }}
                                                                    src={JSON.parse(product.image).length === 0 ? '' : BASE_URL + '/uploads/' + JSON.parse(product.image)[0].download_link}
                                                                />
                                                            }
                                                        </Col>
                                                        <Col lg={8}>
                                                            <Row>
                                                                <Col
                                                                    onClick={() => this.props.history.push(`/compare-and-rate/product/${product.id}`,
                                                                        {
                                                                            product_name: product.name,
                                                                            product_name_en: product.name_en,
                                                                            product_desc: product.description,
                                                                            product_desc_en: product.description_en,
                                                                            product_image: product.image
                                                                        }
                                                                    )}
                                                                    lg={9}><h2>{this.props.lng === "ar" ? product.name : product.name_en}</h2>

                                                                </Col>
                                                                <Col lg={3}>
                                                                    <button disabled={this.props.diableObjectCompareBtn.includes(product.id) ? true : false}
                                                                        className={this.props.diableObjectCompareBtn.includes(product.id) ? 'btn submit-cpa-btn disabled' : "btn submit-cpa-btn"}
                                                                        type="button" onClick={() => this.addobjectToCompareList(product)}>
                                                                        <Row className="compareBtn">
                                                                            <Col xs={8} className="btnLabel"><Translate text="compare" /></Col>
                                                                            <Col xs={4}><i className="icon-exchange"></i></Col>
                                                                        </Row>
                                                                    </button>
                                                                </Col>
                                                            </Row>
                                                            <p>{this.props.lng === "ar" ? product.description : product.description_en}</p>
                                                            {
                                                                product.updated_at &&
                                                                <div>
                                                                    <span>{t('last_updated')}</span>
                                                                    <span>{moment(product.updated_at).format('DD/MM/YYYY')}</span>
                                                                </div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                            else {
                                                {/* console.log(product) */ }
                                                return (
                                                    <Row className="creditCardContainer">
                                                        <Col lg={4}></Col>
                                                        <Col lg={8}>
                                                            <Row>
                                                                <Col>
                                                                    <Translate text="noProducts" />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                        })
                                    }
                                </Col>
                            </Row>
                        </div>
                    }
                </Container>
                {
                    this.props.multiCategory.length > 0 &&
                    <Row className="comparingBar"
                        // style={{ position: "fixed", top: "88%", zIndex: "999", display: this.props.toBeComparedObjects.length > 0 ? 'flex' : 'none', marginRight: "0rem", alignItems: "center" }} >
                        style={{ display: this.props.toBeComparedObjects.length > 0 ? 'flex' : 'none' }} >
                        {
                            this.props.toBeComparedObjects.map(value => {
                                return (
                                    <Card style={{ height: "3rem" }} key={value.id}>
                                        {
                                            value.image &&
                                            <Card.Img style={{ width: "20%" }} variant="top"
                                                src={JSON.parse(value.image).length === 0 ? '' : BASE_URL + '/uploads/' + JSON.parse(value.image)[0].download_link}
                                            />
                                        }
                                        <Card.Body style={{ padding: "0" }}>
                                            <Card.Title style={{ fontSize: "0.9rem" }}>{this.props.lng === "ar" ? value.name : value.name_en}</Card.Title>
                                        </Card.Body>
                                        <button
                                            style={{ height: "3rem" }}
                                            className="btn submit-cpa-btn"
                                            onClick={() => this.removeobjectFromCompareList(value)}
                                        >X</button>
                                    </Card>
                                )
                            })
                        }
                        <button style={{ fontSize: "0.9rem" }} className="btn submit-cpa-btn" onClick={() => this.compareObjects()}>
                            <Translate text="compare" />
                        </button>
                    </Row>
                }
            </div >

        )
    }
}

CategoryPage.propTypes = {
    actions: PropTypes.object,
    match: PropTypes.object,
    "match.params": PropTypes.object,
    "match.params.id": PropTypes.number,
    "actions.getMultiCategoriesByIdWithEachSub": PropTypes.func,
    multiCategory: PropTypes.array,
    "multiCategory.length": PropTypes.number,
    toBeComparedObjects: PropTypes.array,
    "toBeComparedObjects.map": PropTypes.func,
    "toBeComparedObjects.length": PropTypes.number,
    history: PropTypes.object,
    "history.push": PropTypes.func,
    diableObjectCompareBtn: PropTypes.array,
    "diableObjectCompareBtn.includes": PropTypes.func,
    firstCollapseIndex: PropTypes.number,
    firstCollapseId: PropTypes.number,
    secondCollapseIndex: PropTypes.number,
    secondCollapseId: PropTypes.number,
    lng: PropTypes.string,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        lng: state.user.lng,
        multiCategory: state.compare_and_rate.multiCategories,
        toBeComparedObjects: state.compare_and_rate.toBeComparedObjects,
        diableObjectCompareBtn: state.compare_and_rate.diableObjectCompareBtn,
        firstCollapseIndex: state.compare_and_rate.firstCollapseIndex,
        firstCollapseId: state.compare_and_rate.firstCollapseId,
        secondCollapseIndex: state.compare_and_rate.secondCollapseIndex,
        secondCollapseId: state.compare_and_rate.secondCollapseId,
        page: state.navigation.page
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(CategoryPage);