import React from 'react';
import './contacts-info.scss';
import { Col } from "react-bootstrap";
import { t } from 'i18next';
import PropTypes from 'prop-types';

class Contacts extends React.Component {
  render() {    
    return (
      <Col xs={12} sm={12} lg={6} class="contact-info-conatiner">
        <div className="info-container">
          <div className="col-12">
            <h5>{t("Contact_Info")}</h5>
            <p><i className="icon-phone"></i><strong>{t("Phone")}</strong>{this.props.mobile} &nbsp;</p>
            <p><i className="icon-mail"></i><strong> {t("email")}</strong><a>{this.props.email}</a></p>
            <p><i className="icon-phone-1"></i> <strong>{t("Free_number")}</strong>{this.props.free_number}</p>
            <p><i className="icon-fax"></i> <strong>{t("fax")}</strong>{this.props.fax}</p>
            <p><i className="icon-mail"></i> <strong>{t('CPA_Address')}</strong>{this.props.mail_address}</p>

            {/* <p><i className="pen_2"></i><strong><a className="btn submit-cpa-btn" href="/legal-advocacy/SubmitAComplaint"><Translate text="complain1" /></a></strong></p> */}
          </div>
        </div>
      </Col>
    );
  }
}

Contacts.propTypes = {
  mobile: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  free_number: PropTypes.string.isRequired,
  mail_address: PropTypes.string,
  fax:PropTypes.string
}

export default Contacts;