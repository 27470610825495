/* eslint-disable import/default */

import React from 'react';
import { i18n } from 'element-react'
import locale from 'element-react/src/locale/lang/en'

import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import configureStore, { history } from './store/configureStore';
import Root from './components/Root';
import './styles/styles.scss';
import { LOAD_USER_DATA } from "./constants/actionTypes"; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
require('./favicon.ico'); // Tell webpack to load favicon.ico
export const store = configureStore();
i18n.use(locale);
const lng = localStorage.getItem('lng') || 'ar'
store.dispatch({
  type: LOAD_USER_DATA.SUCCESS,
  payload: {
    auth_token: localStorage.getItem('_cpaJwt'), //turn it into intial state
    first_name: localStorage.getItem('first_name'),
    last_name: localStorage.getItem('last_name'),
    email: localStorage.getItem('email'),
    id: localStorage.getItem('id'),
    city: localStorage.getItem('city'),
    identification_number: localStorage.getItem('identification_number'),
    identification_path: localStorage.getItem('identification_path'),
    verified: localStorage.getItem('verified'),
    is_authenticated: localStorage.getItem('_cpaJwt') !== null,
    lng: lng
  }
});
// console.log('tokk', localStorage.getItem('_cpaJwt'));

render(
  <AppContainer>
    <Root store={store} history={history} />
  </AppContainer>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const NewRoot = require('./components/Root').default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById('app')
    );
  });
}
