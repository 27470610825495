import React from 'react';
import './servicesSection.scss';
import ServiceCard from './serviceCard/serviceCard';
import { Link } from 'react-router-dom';
import Translate from '../../GlobalComponent/translate/translate';
import PropTypes from 'prop-types';
// import serviceCardImg from '../../../img/asset_2.png';
import { connect } from 'react-redux';
import { BASE_URL } from '../../../constants/config';

const ServicesSection = (props) => {
    return (
        <div className="services-container">
            <div className="container">
                <div className="row justify-content-center">
                    {
                        props.homeCards[0] &&
                        props.homeCards[0] !== props.homeCards[props.homeCards.length-1] &&
                        <div className="col-9 col-sm-8 col-md-6 col-lg-3 mb-3 mb-md-0">
                            <ServiceCard card={props.homeCards[0]} />
                        </div>
                    }
                    {
                        props.homeCards[1] &&
                        props.homeCards[1] !== props.homeCards[props.homeCards.length-1] &&
                        <div className="col-9 col-sm-8 col-md-6 col-lg-3 mb-3 mb-md-0">
                            <ServiceCard card={props.homeCards[1]} />
                        </div>
                    }
                    <div className="col-10 col-sm-10 col-md-12 col-lg-6">
                        <div className="custom-row">
                            {
                                props.homeCards[2] &&
                                props.homeCards[2] !== props.homeCards[props.homeCards.length-1] &&
                                < div className="custom-card">
                                    <ServiceCard card={props.homeCards[2]} />
                                </div>
                            }

                            {
                                props.homeCards[props.homeCards.length - 1] &&
                                <div className="custom-card animate card-2">
                                    <div className="serciceCard-container rounded shadow-sm know-more-card">
                                        <div className="card-back-img">
                                            <img src={BASE_URL + '/uploads/' + props.homeCards[props.homeCards.length - 1].image} />
                                        </div>
                                        <div className="card-content">
                                            <h4>
                                                {
                                                    props.lng === 'ar' ? props.homeCards[props.homeCards.length - 1].title : props.homeCards[props.homeCards.length - 1].title_en
                                                }
                                            </h4>
                                            <p>
                                                {/* <Translate text="moreServicesDesc"/>     */}
                                            </p>
                                            <div className="moreBtnCard">
                                                <Link to={props.homeCards[props.homeCards.length - 1].link} onClick={() => { this.scrollToTop(); }}>
                                                    <button className="rounded custom-button"><Translate text="LearnMore" /></button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};


ServicesSection.propTypes = {
    homeCards: PropTypes.array,
    lng: PropTypes.string
};

function mapStateToProps(state) {
    return {
        lng: state.user.lng
    }
}
export default connect(mapStateToProps, null)(ServicesSection);
