import React from 'react';
import HomeSection from "../../GlobalComponent/HomeSection/HomeSection";
import './shakwa-section.scss';
import { Circle } from 'rc-progress';
import { Card, Container, Row, Col, Modal, Button } from 'react-bootstrap';
import Translate from '../../GlobalComponent/translate/translate';
import { t } from 'i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class ShakawaSection extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.nextQuizeHandler = this.nextQuizeHandler.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
      showResult: false,
      result: 0,
      selectedOption: '',
      checked: false,
      num: 1,
      percent: null,
      quizCount: 0,
      btnQuiz: 'التالي',
      quiz: [
        {
          question: "من حقك كمستهلك التقدم بشكوى على أي جهة لم تقم بخدمتك بشكل صحيح، ولكن يجب عليك أولا الاطلاع على:",
          question_en: '-	It is your right as a consumer to submit a complaint to any entity that failed to provide service for you properly. However, you should inspect:',
          answers: [
            {
              id: 1,
              answer: "العقد المبرم بين الطرفين، وتنظيمات ولوائح مقدم الخدمة.",
              answer_en: "The contract concluded between the two parties besides the laws and regulations of the service provider."
            },
            {
              id: 2,
              answer: 'دليل حقوق المستهلك.',
              answer_en: 'Consumer Rights Guide.'
            },
            {
              id: 3,
              answer: 'التواصل مع الجهة المسؤولة عن مقدم الخدمة.',
              answer_en: 'Contacting the competent entity responsible for the service provider.'
            },
            {
              id: 4,
              answer: 'كل ما ذكر أعلاه.',
              answer_en: 'All the above'
            }
          ],
          rightAns: 4
        },
        {
          question: "يحق للمستهلك مطالبة الناقل الجوي بسكن فندقي ومواصلات من وإلى المطار إذا تجاوزت مدة التأخير ------ من الوقت الأصلي المحدد للمغادرة:",
          question_en: '-	A consumer has the right to issue a claim against an airway company to have hotel accommodation and transportation to the airport if the delay exceeds ……. over the original time of departure:',
          answers: [
            {
              id: 1,
              answer: '3 ساعات',
              answer_en: '3 hours'
            },
            {
              id: 2,
              answer: '6 ساعات',
              answer_en: '6 hours'
            },
            {
              id: 3,
              answer: '12 ساعة ',
              answer_en: '12 hours'
            },
            {
              id: 4,
              answer: '24 ساعة',
              answer_en: '24 hours'
            }
          ],
          rightAns: 2
        },
        {
          question: "يحق للمستهلك الحصول على جهاز (كهربائي أو الكتروني) بديل جديد عند:",
          question_en: '-	A consumer has the right to have a new replacement (electric or electronic) equipment when:',
          answers: [
            {
              id: 1,
              answer: 'ترتب على العيب عدم الانتفاع من الجهاز.',
              answer_en: 'There is a defect preventing the equipment usage'
            },
            {
              id: 2,
              answer: 'استنفد الوكيل محاولتين (2) لإصلاح عيب الجهاز المتكرر.',
              answer_en: "The agent uses the (2) attempts afforded to repair the equipment's repeated defect"
            },
            {
              id: 3,
              answer: 'استغرقت محاولات الإصلاح مدة تزيد عن (15) يوم ليس من ضمنها مدة توفير قطع الغيار.',
              answer_en: 'The repairing process consumes more than (15) days other than the period dedicated to provide the spare parts.'
            },
            {
              id: 4,
              answer: 'كل ما ذكر أعلاه.',
              answer_en: 'All the above.'
            }
          ],
          rightAns: 4
        },
        {
          question: "عند مراجعتك لعيادات الأسنان وطلب منك إجراء أشعة سينية للأسنان يجب على العيادة عمل الآتي:",
          question_en: "When visiting the dentist clinic, you were required to provide an X-Ray picture of your teeth, the clinic should do the following:",
          answers: [
            {
              id: 1,
              answer: 'تثقيف المريض بصحة الفم والأسنان',
              answer_en: 'Educate the patient about the hygiene of mouth and teeth'
            },
            {
              id: 2,
              answer: 'لبس الواقي الرصاصي',
              answer_en: 'Putting on the lead shield'
            },
            {
              id: 3,
              answer: 'لبس واقي الغدة الدرقية',
              answer_en: 'Putting on the shield to protect the thyroid gland'
            },
            {
              id: 4,
              answer: '2 + 3 صحيحة',
              answer_en: '2 and 3'
            }
          ],
          rightAns: 4
        },
        {
          question: "عند اعتراض المستهلك عند هيئة الاتصالات على مزود الخدمة بسبب فاتورة الهاتف، يجب أن يتم ذلك خلال:",
          question_en: "When a consumer issues a complaint at the Communication and Information Technology Commission (CITC) against the service provider because of the telephone bill, the complaint should be submitted through a period of:",
          answers: [
            {
              id: 1,
              answer: '30 يوم ',
              answer_en: '30 days'
            },
            {
              id: 2,
              answer: '40يوم',
              answer_en: '40 days'
            },
            {
              id: 3,
              answer: '60 يوم',
              answer_en: '60 days'
            },
            {
              id: 4,
              answer: '90يوم ',
              answer_en: '90 days'
            }
          ],
          rightAns: 4
        },
        {
          question: "يحق للشركات العاملة في نشاط التأجير اليومي أو المنتهي بالتمليك عند تعذر المستهلك سداد المستحقات المالية أن:",
          question_en: "When a consumer fails to pay the financial dues, the companies of daily rental/rental ending with possession have the right to:",
          answers: [
            {
              id: 1,
              answer: 'سحب السيارة من قبل التاجر دون صدور أمر قضائي.',
              answer_en: 'Withdraw the car without issuing of a judicial order'
            },
            {
              id: 2,
              answer: 'إيقاف خدمات المستهلك.',
              answer_en: 'Postpone the Consumer Services'
            },
            {
              id: 3,
              answer: 'سحب السيارة بعد صدور أمر تنفيذ قضائي',
              answer_en: 'Withdraw the car after issuing a judicial executive order'
            },
            {
              id: 4,
              answer: 'كل ما ذكر أعلاه.',
              answer_en: 'All the above'
            }
          ],
          rightAns: 3
        }
      ]

    };
  }

  componentDidMount() {
    this.setState({ result: 0 });
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({
      show: true,
      selectedOption: '',
      checked: false,
      num: 1,
      percent: (1 / this.props.knowledgeQuestions.length * 100),
      quizCount: 0,
      result: 0,
      btnQuiz: <Translate text="next" />
    })
  }

  handleOptionChange(changeEvent) {
    this.setState({
      selectedOption: changeEvent.target.value,
    })
  }

  nextQuizeHandler() {
    let rightAnswer = this.props.knowledgeQuestions[this.state.quizCount].knowledge_answer_home.filter(answer => answer.right_answer === 1);
    if (this.state.selectedOption == rightAnswer[0].id) {
      let result = +this.state.result;
      result++;
      this.setState({ result })
    }
    this.setState({ selectedOption: '' })
    if (this.state.num === this.props.knowledgeQuestions.length - 1) {
      this.setState({
        num: this.state.num + 1,
        percent: this.state.percent + + ((1 / this.props.knowledgeQuestions.length) * 100),
        checked: false,
        quizCount: this.state.quizCount + 1,
        btnQuiz: <Translate text="finish" />
      })
    }
    else if (this.state.num === this.props.knowledgeQuestions.length) {
      this.setState({
        showResult: true,
        num: 0,
        percent: 0,
        checked: true,
        quizCount: 0
      });
    }
    else {
      this.setState({
        num: this.state.num + 1,
        percent: this.state.percent + + ((1 / this.props.knowledgeQuestions.length) * 100),
        checked: false,
        quizCount: this.state.quizCount + 1,
      })
    }
  }

  setResult = (value) => {
    let rightAnswer = this.props.knowledgeQuestions[this.state.quizCount].knowledge_answer_home.filter(answer => answer.right_answer === 1);
    if (value == rightAnswer[0].id) {
      return 'rightAns'
    } else {
      return 'wrongAns'
    }
  }
  render() {
    return (
      this.props.knowledgeQuestions &&
      <HomeSection>
        <Container>
          <Row className="shakwa-row">
            <Col xs={12} className="">
              <Card className="shakwa-card">
                <Card.Body>
                  <Card.Title>
                    {t("testYourKnowledge")}
                  </Card.Title>
                  <Card.Text>
                    <p>{this.props.lng === "ar" ? this.props.testYourKnowlegde : this.props.testYourKnowlegde_en}</p>
                    {/* <p>{t("quizIntro2")}</p> */}
                  </Card.Text>
                  <Card.Link className="btn btn-lg" onClick={this.handleShow}><Translate text="test" /></Card.Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal dialogClassName="quiz-section" show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <div className="quiz-header">
              <h4>{t("testYourKnowledge")}</h4>
              <p>{t("quizIntro")}</p>
              <p>{t("quizIntro2")}</p>
            </div>
            <div className={"quiz-content " + (!this.state.showResult ? "" : "d-none")}>
              <p>{this.props.lng == "ar" ? this.props.knowledgeQuestions[this.state.quizCount].question : this.props.knowledgeQuestions[this.state.quizCount].question_en}</p>
              <div className="quiz-answers">
                <container>
                  <Row>
                    <Col xs={12} md={9} >
                      <form>
                        {this.props.knowledgeQuestions[this.state.quizCount].knowledge_answer_home.map((value, index) => {
                          return (
                            <div className={"form-check " + (this.state.selectedOption == "" ? '' : this.setResult(value.id))} key={index}>
                              <label>
                                <input
                                  type="radio"
                                  name="react-tips"
                                  value={value.id}
                                  disabled={this.state.selectedOption == "" ? false : true}
                                  checked={this.state.selectedOption == value.id}
                                  onChange={this.handleOptionChange}
                                  className="form-check-input"
                                />
                                {this.props.lng == 'ar' ? value.answer : value.answer_en}
                                <div className="check"></div>
                              </label>
                              <div className="ans-icon">
                                <i className="icon-ok"></i>
                                <i className="icon-cancel"></i>
                              </div>
                            </div>
                          )
                        })}
                      </form>
                    </Col>
                    <Col xs={12} md={3} className="progress-wrapper">
                      <div className="progress-section">
                        <span>{this.state.num}/{this.props.knowledgeQuestions.length}</span>
                        <Circle percent={this.state.percent} strokeWidth="6" strokeColor="#42C194" trailWidth="6" trailColor="#F0F0F0" />
                      </div>
                    </Col>
                  </Row>
                </container>
              </div>
            </div>
            <div className={this.state.showResult ? "" : "d-none"}>
              <div className="quiz-answers result">
                <container className="text-center result-view">
                  <h3><Translate text="testResult" /></h3>
                  <p>
                    <span>{this.state.result}</span>
                    <span>/</span>
                    <span>{this.props.knowledgeQuestions.length}</span>
                  </p>
                </container>
              </div>
            </div>
            <div className="quiz-button">

              <Button className={!this.state.showResult ? "" : "d-none"} variant="success" disabled={this.state.selectedOption == "" ? true : false} onClick={this.nextQuizeHandler}>{t('next')}</Button>
              <Button className={this.state.showResult ? "" : "d-none"} variant="success"
                onClick={() => {
                  this.setState({
                    show: false,
                    num: this.state.num + 1,
                    showResult: false
                  })
                }}><Translate text="finish" /></Button>
            </div>
          </Modal.Body>
        </Modal>
      </HomeSection>
    );
  }
}


ShakawaSection.propTypes = {
  lng: PropTypes.string.isRequired,
  knowledgeQuestions: PropTypes.array,
  "knowledgeQuestions.length": PropTypes.length,
  testYourKnowlegde: PropTypes.string,
  testYourKnowlegde_en: PropTypes.string

};
function mapStateToProps(state) {
  return {
    lng: state.user.lng
  };
}


export default connect(mapStateToProps)(ShakawaSection)