import { GET_ABOUT_DATA } from '../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../initialState';

export default function aboutReducer(state = initialState.el_gam3ya.about, action) {
    switch (action.type) {
        case GET_ABOUT_DATA.SUCCESS:
            return objectAssign({}, state, {
                about_array: action.payload,


            });
        default:
            return state;
    }
}