import { GET_APPLICATIONS, GET_APPLICATION_BY_ID } from '../../constants/actionTypes';
import { apiFetch } from '../../utils/http.helpers';
import { BASE_URL } from '../../constants/config';

export function getAllApplications() {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/Applications/getApplications', {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                // "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
            }
        })
            .then(response => response.json())
            .then(response => {
                dispatch({
                    type: GET_APPLICATIONS, payload: response
                })
            })
    }
}

export function getApplicationById(id) {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/Applications/getApplicationById?application_id=' + id, {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                // "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
            }
        })
            .then(response => response.json())
            .then(response => {
                dispatch({
                    type: GET_APPLICATION_BY_ID, payload: response
                })
            })
    }
}