import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Provider, connect } from 'react-redux';
import App from './App';
import i18n from './i18n';
import { CHANGE_USER_LNG } from './../constants/actionTypes';
import { store } from './../index';

class Root extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      lng: 'en',
      local: null
    }
  }


  componentDidMount = () => {

    let lng = localStorage.getItem('lng') || 'ar';
    localStorage.setItem('lng', lng)
    let local = lng == 'ar' ? 'rtl' : 'ltr'
    document.documentElement.lang = lng == 'ar' ? 'ar' : 'en'
    i18n.changeLanguage(lng);
    this.setState({
      local: local,
      lng: lng
    })
    store.dispatch({
      type: CHANGE_USER_LNG.SUCCESS,
      payload: {
        lng: lng
      }
    })
  }
  changeLanguage = () => {
    // console.log(this.state.lng);

    if (this.state.lng == "ar") {
      i18n.changeLanguage('en');
      this.setState({
        local: 'ltr',
        lng: 'en'
      })
      document.documentElement.lang = "en"
      document.getElementsByTagName('title')[0].innerHTML = "Consumer Protection Association"
      localStorage.setItem('lng', 'en');
      store.dispatch({
        type: CHANGE_USER_LNG.SUCCESS,
        payload: {
          lng: 'en'
        }
      })
      // window.location.reload();
      // console.log("at en",this.state);
      // window.location.reload();

    } else {
      i18n.changeLanguage('ar');
      this.setState({
        local: 'rtl',
        lng: 'ar'
      })
      document.documentElement.lang = "ar"
      document.getElementsByTagName('title')[0].innerHTML = "جمعية حماية المستهلك"
      localStorage.setItem('lng', 'ar');
      store.dispatch({
        type: CHANGE_USER_LNG.SUCCESS,
        payload: {
          lng: 'ar'
        }
      });
      // window.location.reload();
      // console.log("at ar",this.state);
      // window.location.reload();
    }

  }
  render() {
    const { store, history } = this.props;
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <div className="cpa-body" dir={this.state.local}>
            <App changeLanguage={this.changeLanguage} />
          </div>
        </ConnectedRouter>
      </Provider>
    );
  }
}

function mapStateToProps(state) {
  return {
    lng: state.user.lng
  };
}
Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
export default connect(mapStateToProps)(Root)