import React from 'react'
import PropTypes from 'prop-types';
import './DrowerToggleButton.scss'

const DrowerToggleButton = (props) => {
		return (
    <button onClick={props.click} className="toggle-button"  >
      <i className="icon-align-right"></i>
    </button>
    )
};

DrowerToggleButton.propTypes = {
    click: PropTypes.func.isRequired
};


export default DrowerToggleButton;
