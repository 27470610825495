import React from 'react';
import './cpa-select.scss';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';

let style = null;

class CpaSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
            open: false,
            defualtValue: ''
        }
    }
    Selection = (value) => {        
        let tempValue = null;
        if(this.props.lng === 'ar'){
            tempValue = value.name;
        }else{
            tempValue = value.name_en;
        }
        this.setState({
            value: value,
            open: !this.state.open,
            defualtValue: tempValue
        })
        this.props.selectValue(value)
    }
    open = () => {
        this.setState({
            open: !this.state.open
        }, () => {
            style = {
                color: "#215C80"
            };
        })
    }
    componentDidMount() {
        this.setState({
            defualtValue: this.props.defaultSelect,
        })
    }
    render() {        
        return (
            <div className={"cpa-select " + (this.state.open && "open")}>
                <div className="input-select" onClick={this.open}>
                    <i className="icon-menu-media"></i>
                    <input className="text-form-control-plaintext" style={style} value={t(this.state.defualtValue)} disabled />
                    <i className="icon-down-open"></i>
                </div>
                <div className="select-options">
                    {this.props.options.map((value, index) => {
                        return (
                            <div className="option" key={index} onClick={() => this.Selection(value)}>
                                <span>{this.props.lng=="ar"?value.name:value.name_en}</span>
                            </div>
                        )
                    })}
                    {/* <div className="option" onClick={() => this.Selection({ name: 'anotherCase', id: -1 })}>
                        <span>{t('anotherCase')}</span>
                    </div> */}
                </div>
            </div>
        )
    }

}
CpaSelect.propTypes = {
    selectValue: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    defaultSelect: PropTypes.string.isRequired,
    lng:PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {
        lng: state.user.lng,
    };
}
export default connect(mapStateToProps)(CpaSelect)