import React from 'react';
import './tawzeef.scss';
import BreadCrumb from './../../GlobalComponent/BreadCrumb/BreadCrumb';
import ImgHeader from './../../GlobalComponent/ImgHeader/ImgHeader';
import { Container, Row, Col } from "react-bootstrap";
import { t } from 'i18next';
import ImgCard from '../../GlobalComponent/ImgCard/ImgCard';
import CpaSharing from '../../GlobalComponent/cpaSharing/cpaSharing';
import * as navigationActions from '../../../actions/navigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

let img = 'https://gdurl.com/5HlC';


class SingleJob extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            routes: []
        }
    }
    componentDidMount() {
        // step to add
        this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
            }
        )
    }
    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }
    render() {
        return (
            this.props.page &&
            <div className="singlejob-container">
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                <div className="separator"> </div>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} lg={12}>
                            <div className="job-card-opened">
                                <Row>
                                    <Col xs={12} sm={12} lg={6}>
                                        <h5>عنوان الوظيفة عنوان الوظيفة</h5>
                                        <p>نبذة مختصرة عن الوظيفة نبذة مختصرة عن
                                            الوظيفة نبذة مختصرة عن الوظيفة نبذة مختصرة عن الوظيفة
                                            نبذة مختصرة عن الوظيفة نبذة مختصرة عن الوظيفة نبذة مختصرة عن الوظيفة</p>
                                        <CpaSharing />
                                    </Col>
                                    <Col xs={12} sm={12} lg={6}>
                                        <ImgCard imgSrc={img} />
                                    </Col>
                                </Row>
                            </div>
                            <div className="job-form">
                                <h5>{t("job_app")}</h5>
                                <p>{t("app_msg")}</p>
                                <form>
                                    <Row>
                                        <Col xs={12} sm={12} lg={3}>
                                            <div className="form-input">
                                                <i className="icon-user"></i>
                                                <input placeholder={t('name')}></input>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} lg={3}>
                                            <div className="form-input">
                                                <i className="icon-mail"></i>
                                                <input placeholder={t("youremail")}></input>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} lg={3}>
                                            <div className="form-input">
                                                <i className="icon-mobile"></i>
                                                <input type="number" placeholder={t("phoneNumber")}></input>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} lg={3}>
                                            <div className="form-input">
                                                <i className="icon-transgender"></i>
                                                <select name="gender_id">
                                                    <option value="" disabled selected> {t("gender")} </option>
                                                    <option>{t("male")}</option>
                                                    <option>{t("female")}</option>

                                                </select>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} lg={3}>
                                            <div className="form-input">
                                                <i className="icon-graduation-cap"></i>
                                                <select name="qualification_id">
                                                    <option value="" disabled selected> {t("educational_qualifications")} </option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} lg={3}>
                                            <div className="form-input">
                                                <i className="icon-location"></i>
                                                <select name="city_id">
                                                    <option disabled selected>{t('city')}</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                    <h5>{t("upload_cv")}</h5>
                                    <p>{t("cv_msg")}</p>
                                    <Col xs={12} sm={12} lg={12}>

                                     </Col>
                                    </Row> */}
                                    <button >{t('send')}<i className="icon-left"></i></button>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>

        )
    }

}

SingleJob.propTypes = {
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired
};
function mapStateToProps(state) {
    return {
        page: state.navigation.page
    };
}

function mapDispatchToProps(dispatch) {
    return {
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SingleJob)