import { combineReducers } from 'redux';
import fuelSavings from './fuelSavingsReducer';
import homePageReducer from './homePageReducer';
import motamkenReducer from './programs-and-activities/programs/motamkenReducer';
import compareAndRateReducer from './programs-and-activities/programs/compareAndRateReducer';
import authReducer from './auth/auth';
import recognizeReducer from './programs-and-activities/programs/recognizeReducer';
import complainReducer from './programs-and-activities/programs/complainReducer';
import ershednaShakwaReducer from './programs-and-activities/programs/ershednaShakwaReducer';
import takeKnowledgeReducer from './programs-and-activities/programs/takeknwoledgeReducer';
import quizesReducer from './programs-and-activities/programs/quizesReduser';
import newsReducer from './InformationCenter/newsReducer';
import dataReducer from './InformationCenter/dataReducer';
import annualReportsReducer from './InformationCenter/annualReportsReducer';
import mediaInterviewReducer from './InformationCenter/mediaInterviewReducer';
import aboutReducer from './El-Gam3ya/aboutReducer';
import spectializationsReducer from './El-Gam3ya/spectializationsReducer';
import worlddaysReducer from './programs-and-activities/worlddayReducer';
import comprehensiveGuideReducer from './programs-and-activities/initiatives/comprehensiveGuideReducer';
import guideBookReducer from './programs-and-activities/initiatives/guideBookReducer';
import impLinksReducer from './programs-and-activities/programs/legacyAdvocacy/impLinksReduser';
import committeesReducer from './El-Gam3ya/committeesReducer';
import followComplainReducer from './programs-and-activities/programs/legacyAdvocacy/followComplainReduser';
import contractsReducer from './programs-and-activities/programs/legacyAdvocacy/contractsReducer';
import postFormReduser from './postFormReduser';
import singleNewsReducer from './InformationCenter/singleNewsReducer';
import consumerAwardsReducer from './programs-and-activities/initiatives/consumerAwardReducer';
import userProfileReducer from './user-profile/userProfileReducer';
import organizeReducer from './El-Gam3ya/organizeReducer'
import consumerOfficeReduser from './programs-and-activities/programs/consumerOfficeReduser';
import consumerConsutantReduser from './programs-and-activities/programs/consumerConsutantReduser';
import GerFiltersDataReduser from './filterReduser';
import guideUsReducer from './programs-and-activities/programs/legacyAdvocacy/guideUsReducer';
import sendamessageReducer from './ContactUs/sendamessageReducer';
import contactInfoReducer from './ContactUs/ContactInfoReducer';
import membersReducer from './El-Gam3ya/membersReducer';
import navigationReduser from './navigationReduser';
import platformsReducer from './programs-and-activities/platformsReducer';
const rootReducer = combineReducers({
  fuelSavings,
  home: homePageReducer,
  motamaken: motamkenReducer,
  user: authReducer,
  profile: userProfileReducer,
  news: newsReducer,
  data: dataReducer,
  annualreports: annualReportsReducer,
  mediainterview: mediaInterviewReducer,
  about: aboutReducer,
  spectializations: spectializationsReducer,
  compare_and_rate: compareAndRateReducer,
  recognize: recognizeReducer,
  complain: complainReducer,
  ershedna: ershednaShakwaReducer,
  world_days: worlddaysReducer,
  comprehensive_guide: comprehensiveGuideReducer,
  guide_book: guideBookReducer,
  TakeKnowledge: takeKnowledgeReducer,
  quizes: quizesReducer,
  imp_links: impLinksReducer,
  committees: committeesReducer,
  follow_complain: followComplainReducer,
  contracts: contractsReducer,
  postForm: postFormReduser,
  single_news: singleNewsReducer,
  consumer_award: consumerAwardsReducer,
  organize: organizeReducer,
  consumerOffice: consumerOfficeReduser,
  consumerConsult: consumerConsutantReduser,
  filters: GerFiltersDataReduser,
  guideUs: guideUsReducer,
  sendamessage: sendamessageReducer,
  contactInfo: contactInfoReducer,
  members: membersReducer,
  navigation: navigationReduser,
  platforms: platformsReducer
});

export default rootReducer;
