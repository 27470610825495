import React from 'react';
import './ScrollButton.scss';
import PropTypes from 'prop-types';
export default class ScrollButton extends React.Component {
    constructor() {
        super();

        this.state = {
            intervalId: 0
        };
    }

    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }

    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
        this.setState({ intervalId: intervalId });
    }

    render() {
        return <button title='Back to top' className='scroll d-lg-block'
            onClick={() => { this.scrollToTop(); }}>
            <span className='arrow-up icon-down-open'></span>
        </button>;
    }
} 
ScrollButton.propTypes = {
    scrollStepInPx: PropTypes.string,
    delayInMs:PropTypes.string
};