import React from 'react';
// import { Link } from 'react-router-dom';
import ImgHeader from './GlobalComponent/ImgHeader/ImgHeader';
import { Container } from "react-bootstrap";
import Translate from './GlobalComponent/translate/translate';

let page = {
  name: 'هذه الصفحة ستجدونها قريباً',
  name_en: 'This page Will be Coming Soon'
}
class NotFoundPage extends React.Component {
  render() {
    return (
      <div className="notFound-container">
        <ImgHeader page={page} />
        <Container>
          <div className="notFound-content text-center">
            <div className="inner">
              <h3>
                <Translate text="comming" />
              </h3>
              {/* <Link to="/"><Translate text="goback"/> <Translate text="homePage"/> </Link> */}
            </div>
          </div>
        </Container>
      </div>

    )
  }
}

export default NotFoundPage;
