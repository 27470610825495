import React from 'react';
import './indicativeContracts.scss';
import { Tab, Nav, Row, Col } from "react-bootstrap";
import AutoContracts from './AutoContracts';
import * as actions from '../../../../../actions/program-and-activities/programs/legal_advocay/contracts';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';

class IndicativeContracts extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.myDivToFocus = React.createRef();
        this.state = {
            content: false
        };
    }


    handleScrollToDiv = () => {
        //.current is verification that your element has rendered
        if (this.myDivToFocus.current) {
            this.myDivToFocus.current.scrollIntoView({
                behavior: "smooth",
                block: "start"
            })
        }
    }

    onSelectHandler(id) {
        this.props.history.push('/legal-advocacy/proposed-contractual-agreement-templates/' + id)
        this.handleScrollToDiv();
    }

    componentDidMount() {
        this.props.actions.getLinksData()
            .then(() => {
                this.setState({
                    content: true
                }, () => {
                    this.handleScrollToDiv()
                })
            }).catch(() => {
            });
    }
    render() {
        return (
            //Data of each select will be found in folder of data in cpa-project
            <div id="indicativeContracts-container">
                <div className="choose-case height-auto">
                    {this.state.content &&
                        <Tab.Container id="left-tabs-example">
                            <Row className="justify-content-center">
                                <Col xs={10}>
                                    <Nav variant="pills" className="flex-row">
                                        {
                                            this.props.contractsArray.map((value, index) => {
                                                return (
                                                    <Nav.Item className="col-12 col-md-6 col-lg-4" key={index}>
                                                        <Nav.Link active={this.props.match.params.id == value.id}
                                                            onSelect={() => {
                                                                this.onSelectHandler(value.id)
                                                            }}
                                                            eventKey={"id" + value.id}><span>{this.props.lng === 'ar' ? value.title : value.title_en}</span></Nav.Link>
                                                    </Nav.Item>
                                                )
                                            })}
                                        {/* <Nav.Item className="col-12 col-md-6 col-lg-3">
                                        <Nav.Link eventKey="second_case"><span>عقود البناء والمقاولات</span></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="col-12 col-md-6 col-lg-3">
                                        <Nav.Link eventKey="third_case"><span>عقود المطابخ المنزلية</span></Nav.Link>
                                    </Nav.Item> */}
                                    </Nav>
                                </Col>
                                <div ref={this.myDivToFocus}>
                                    <Col xs={12}>
                                        <Tab.Content>
                                            {
                                                this.props.contractsArray.map((value, index) => {
                                                    return (
                                                        <Tab.Pane active={this.props.match.params.id == value.id} onClick={() => this.props.history.push(this.props.match.url + value.id)} eventKey={"id" + value.id} key={index}>
                                                            <AutoContracts contract={value} />
                                                        </Tab.Pane>
                                                    )
                                                })}
                                        </Tab.Content>
                                    </Col>
                                </div>
                            </Row>
                        </Tab.Container>}
                    {/* <div className="notFound-container">
                        <div className="notFound-content text-center">
                            <div className="inner">
                                <h3>
                                    {t("noData" )}
                                </h3>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }
}
IndicativeContracts.propTypes = {
    actions: PropTypes.object.isRequired,
    contractsArray: PropTypes.array.isRequired,
    lng: PropTypes.string.isRequired,
    match: PropTypes.object,
    "match.params": PropTypes.object,
    "match.params.id": PropTypes.string,
    history: PropTypes.object,
    "history.push": PropTypes.func,
    "match.url": PropTypes.string
};
function mapStateToProps(state) {
    return {
        contractsArray: state.contracts.contractsArray,
        lng: state.user.lng,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IndicativeContracts))