import React from 'react';
import './FAQ.scss';
import ImgHeader from '../GlobalComponent/ImgHeader/ImgHeader';
import { Container } from "react-bootstrap";
import ReactHtmlParser from 'react-html-parser';
import BreadCrumb from '../GlobalComponent/BreadCrumb/BreadCrumb';
import CpaCollapse from '../GlobalComponent/CpaCollpase/CpaCollapse';
import { apiFetch } from '../../utils/http.helpers';
import { BASE_URL } from '../../constants/config';

class FAQ extends React.Component {
  constructor() {
    super();
    this.state = {
      questions: [],
      routes: [
        {
          name: 'الرئيسية',
          name_en: 'Home',
          link: '',
        },
        {
          name: 'الأسئلة الشائعة',
          name_en: 'FAQS',
          link: 'FAQS',
        },
      ],
      page: {
        name: 'الأسئلة الشائعة',
        name_en: 'FAQS'
      }
    }
  }

  componentDidMount() {
    apiFetch(BASE_URL + '/api/commonQuestions/getAll', {
      method: "GET",
      withCredentials: true,
      headers: {
        "Accept": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        this.setState({
          questions: response
        })
      })
  }


  render() {
    return (
      <>
        <ImgHeader page={this.state.page} />
        <BreadCrumb
          routes={this.state.routes}
          filterbyYear={false}
          filterbySubject={false} />
        <Container>
          <div className="fqa-container">
            {
              this.state.questions.map((value, index) => {
                return (
                  <div key={index}>
                    <CpaCollapse title={localStorage.getItem('lng') === "ar" ? ReactHtmlParser(value.question) : ReactHtmlParser(value.question_en)} >
                      {localStorage.getItem('lng') === "ar" ? ReactHtmlParser(value.answer) : ReactHtmlParser(value.answer_en)}
                    </CpaCollapse>
                  </div>
                )
              })
            }
          </div>
        </Container>
      </>
    )
  }
}
export default FAQ;