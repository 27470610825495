import React from 'react';
import ImgHeader from '../../../../GlobalComponent/ImgHeader/ImgHeader';
import BreadCrumb from '../../../../GlobalComponent/BreadCrumb/BreadCrumb';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import * as navigationActions from '../../../../../actions/navigation';
import * as actions from '../../../../../actions/program-and-activities/programs/quizes';
import Translate from '../../../../GlobalComponent/translate/translate';
import CpaSharing from '../../../../GlobalComponent/cpaSharing/cpaSharing';
import ReactHtmlParser from 'react-html-parser';
import { Circle } from 'rc-progress';
import { t } from 'i18next';
import './tests.scss';
import PropTypes from 'prop-types';
import { apiFetch } from '../../../../../utils/http.helpers';
import { BASE_URL } from '../../../../../constants/config';

class Tests extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.nextQuizeHandler = this.nextQuizeHandler.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            quizId: null,
            show: false,
            selectedOption: '',
            answersArr: [],
            selectedQuizObject: {},
            checked: false,
            num: 1,
            percent: 20,
            quizCount: 0,
            btnQuiz: 'next',
            loading: true,
            showResult: false,
            result: 0,
            quiz: [
                {
                    question: '',
                    answers: []
                }
            ],
            guideBooks: [
                {
                    name: 'القطاع الصحي',
                },
                {
                    name: 'قطاع وزارة العمل',
                },
                {
                    name: 'هيئة الطيران المدني',
                },
            ],
            modalContent: {
                name: '',
            },
            routes: []
        };
    }
    handleClose = () => {
        this.setState({ show: false });
        this.props.history.push(`/test-your-knowledge/tests/${this.props.match.params.id}`)
    }

    handleShow = (value, id) => {
        this.setState({
            quizId: id,
            quiz: value,
            show: true,
            selectedOption: '',
            checked: false,
            num: 1,
            result: 0,
            percent: (1 / value.length) * 100,
            quizCount: 0,
            btnQuiz: 'next',
        }, () => this.props.history.push(`/test-your-knowledge/tests/${this.props.match.params.id}/${this.state.quizId}`))
    }

    handleOptionChange = (changeEvent) => {
        this.setState({
            selectedOption: changeEvent.target.value
        })
    }
    componentDidMount() {
        this.props.navigationActions.getPageInfoApi('/test-your-knowledge').then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                this.props.actions.getQuizesData()
                    .then(() => {
                        this.setState({ ...this.state, loading: false });
                        let selectedQuizObject = this.props.quizesArray.find(quiz => quiz.id == this.props.match.params.id);
                        this.setState({ ...this.state, selectedQuizObject: selectedQuizObject })
                        if (this.props.match.params.testId) {
                            let quiz = this.props.quizesArray.find(quiz => quiz.id == this.props.match.params.id);
                            let quizTest = quiz.subcategories.find(subCategory => subCategory.id == this.props.match.params.testId);
                            this.handleShow(quizTest.questions, this.props.match.params.testId);
                        }
                    }).catch(() => {
                    });
            })
    }

    nextQuizeHandler() {
        let answers = this.state.quiz[this.state.quizCount].answers;
        for (let index = 0; index < answers.length; index++) {
            if (answers[index].answer == this.state.selectedOption) {
                let answersArrCopy = [...this.state.answersArr]
                answersArrCopy.push(answers[index])
                this.setState({
                    answersArr: answersArrCopy
                })
                if (answers[index].right) {
                    let result = this.state.result
                    result++;
                    this.setState({ result })
                }
            }
        }
        this.setState({ selectedOption: '' })
        this.setState({ selectedOption: '' })
        if (this.state.num === this.state.quiz.length - 1) {
            this.setState({
                num: this.state.num + 1,
                percent: this.state.percent + + ((1 / this.state.quiz.length) * 100),
                checked: false,
                quizCount: this.state.quizCount + 1,
                btnQuiz: "finish"
            })
        }
        if (this.state.num === this.state.quiz.length) {
            this.setState({
                showResult: true,
                num: 0,
                percent: 0,
                checked: true,
                quizCount: 0
            });
        } else {
            this.setState({
                num: this.state.num + 1,
                percent: this.state.percent + ((1 / this.state.quiz.length) * 100),
                checked: false,
                quizCount: this.state.quizCount + 1,
            })
        }
    }

    setResult = (value) => {
        if (value.right) {
            return 'rightAns'
        } else {
            return 'wrongAns'
        }
    }
    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }

    finishBtnHandler() {
        this.setState({
            show: false,
            num: this.state.num + 1,
            showResult: false
        })
        let result = [];
        for (let index = 0; index < this.state.quiz.length; index++) {
            result.push({
                'question_id': this.state.quiz[index].id,
                'answer_id': this.state.answersArr[index].id
            })
        }
        apiFetch(BASE_URL + `/api/a5tbr_m3rftak/answer`, {
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
            },
            body: JSON.stringify({
                'exam_id': this.state.quizId,
                'answers': result
            })
        })
    }

    render() {
        return (
            this.props.page &&
            <div className="tests-container landing-container">
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                <Container className="tests-content">
                    <CpaSharing />
                    <Row className="landing-row">
                        {this.state.selectedQuizObject !== undefined && this.state.selectedQuizObject.subcategories !== undefined && this.state.selectedQuizObject.subcategories.length > 0 && this.state.selectedQuizObject.subcategories.map((value, index) => {
                            return (
                                <Col className="mb-4" xs={12} md={6} lg={4} key={index} onClick={() => (value.questions.length > 0 && this.handleShow(value.questions, value.id))}>
                                    <div className="guide-card landing-card">
                                        <ul>
                                            <li>
                                                <i className="icon-compalins"></i>
                                            </li>
                                            <li>
                                                <h3>{this.props.lng == "ar" ? value.name : value.name_en}</h3>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
                <Modal dialogClassName="test-section" size="xl" show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <CpaSharing />
                        <div className="test-header">
                            <h4><Translate text="testYourKnowledge" /></h4>
                        </div>
                        <div style={this.props.lng === 'ar' ? { textAlign: "right" } : { textAlign: "left" }} className={"test-content " + (!this.state.showResult ? "" : "d-none")}>
                            <p>{ReactHtmlParser(this.props.lng == "ar" ? this.state.quiz[this.state.quizCount].question : this.state.quiz[this.state.quizCount].question_en)}</p>
                            <div className="test-answers">
                                <container>
                                    <Row>
                                        <Col xs={12} md={9} >
                                            <form>
                                                {this.state.quiz[this.state.quizCount].answers.map((value, index) => {
                                                    return (
                                                        <div className={"form-check " + (this.state.selectedOption == "" ? '' : this.setResult(value))} key={index}>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    name="react-tips"
                                                                    value={value.answer}
                                                                    checked={this.state.selectedOption === value.answer}
                                                                    onChange={this.handleOptionChange}
                                                                    disabled={this.state.selectedOption == "" ? false : true}
                                                                    className="form-check-input"
                                                                />
                                                                <p>{ReactHtmlParser(this.props.lng == "ar" ? value.answer : value.answer_en)}</p>
                                                                <div style={this.props.lng === 'en' ? { left: '-2rem' } : null} className="check"></div>
                                                            </label>
                                                            <div className="ans-icon">
                                                                <i className="icon-ok"></i>
                                                                <i className="icon-cancel"></i>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </form>
                                        </Col>
                                        <Col xs={12} md={3} className="progress-wrapper">
                                            <div className="progress-section">
                                                <span>{this.state.num}/{this.state.quiz.length}</span>
                                                <Circle percent={this.state.percent} strokeWidth="6" strokeColor="#42C194" trailWidth="6" trailColor="#F0F0F0" />
                                            </div>
                                        </Col>
                                    </Row>
                                </container>
                            </div>
                        </div>
                        <div className={this.state.showResult ? "" : "d-none"}>
                            <div className="test-answers result">
                                <container className="text-center result-view">
                                    <h3><Translate text="testResult" /></h3>
                                    <p>
                                        <span>{this.state.result}</span>
                                        <span>/</span>
                                        <span>{this.state.quiz.length}</span>
                                    </p>
                                </container>
                            </div>
                        </div>
                        <div className="test-button">
                            <Button className={!this.state.showResult ? "" : "d-none"} variant="success" disabled={this.state.selectedOption == "" ? true : false} onClick={this.nextQuizeHandler}>{t('next')}</Button>
                            <Button className={this.state.showResult ? "" : "d-none"} variant="success"
                                onClick={() => this.finishBtnHandler()}
                            ><Translate text="finish" /></Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

Tests.propTypes = {
    actions: PropTypes.object.isRequired,
    quizesArray: PropTypes.array.isRequired,
    lng: PropTypes.string.isRequired,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    'history.push': PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    'match.params': PropTypes.object.isRequired,
    'match.params.id': PropTypes.string,
    'match.params.testId': PropTypes.string
};

function mapStateToProps(state) {
    return {
        quizesArray: state.quizes.quizesArray,
        lng: state.user.lng,
        page: state.navigation.page
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Tests);