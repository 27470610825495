import React from 'react';
import {t} from 'i18next';
import PropTypes from 'prop-types'; 
const Translate=props=>{
    return (
        <i>
            {t(props.text)}
        </i>
    )
}
Translate.propTypes = {
    text:PropTypes.string
};
export default Translate;
