import React from 'react';
import BreadCrumb from '../../GlobalComponent/BreadCrumb/BreadCrumb';
import ImgHeader from '../../GlobalComponent/ImgHeader/ImgHeader';
import './board.scss';
import { t } from 'i18next';
import * as actions from '../../../actions/El-Gam3ya/committees';
import * as navigationActions from '../../../actions/navigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { BASE_URL } from '../../../constants/config';

class Board extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            img_url: BASE_URL + '/uploads/',
            standingCommitteesType: "لجان دائمة",
            tempCommitteesType: "لجان مؤقتة",
            standingCommittees: [
                {
                    icon: "spreading",
                    name: "اللجنة المالية",
                    desc: "تهدف اللجنة المالية إلى مساعدة المجلس التنفيذي لجمعية حماية المستهلك، في الوفاء بمسؤوليات المجلس الإشرافية بما في ذلك التحقق من كفاية وفاعلية الرقابة المالية، في أداء دور الرقابة للتحقق من سلامة ودقة وموثوقية التقارير المالية، وكفاية وفاعلية واستقلال أعمال المراجعة الخارجية، والالتزام بالمتطلبات النظامية والرقابية وقواعد سلوك العمل."
                },

            ],
            tempboards: [
                {
                    icon: "spreading",
                    name: "لجنة متضرري عقود التمويل العقاري المرتبط بمؤشر الفائدة المتغيرة (السايبر)",
                    desc: "لجنة مؤقتة لمناقشة قضايا وشكاوى المستهلكين التي تخص عقود التمويل العقاري المرتبط بمؤشر السايبر، وتشمل اللجنة عددً من المختصين القانونيين والقانونيين الماليين ومحاسبين وماليين، وكذلك الإعلاميين والمهتمين بقضايا الرأي العام."
                },
                {
                    icon: "search",
                    name: "لجنة البيوت المغشوشة",
                    desc: "لجنة مؤقتة لمناقشة ظاهرة انتشار البيوت المغشوشة ومعالجتها، وترتكز أعمال اللجنة على محورين رئيسيين أولاً توعية المقبلين على شراء البيوت، والإجراءات الواجب اتباعها وتحقيقها قبل الشراء، ثانياً مساعدة المتضررين وتوضيح حقوقهم من تحمل مسؤولية الأخطاء وتكاليف الإصلاح."
                },
                {
                    icon: "Observation",
                    name: "لجنة العمالة المنزلية",
                    desc: "لجنة مؤقتة لمناقشة مشكلة تزايد أسعار العمالة المنزلية مقابل تردي جودة الخدمة المقدمة من قبل بعض مكاتب الاستقدام والمشاكل المتعلقة بالتأخر في فترة تسليم العمالة للمستفيدين كذلك سوء في نوعية اختيار العمالة المنزلية."
                }
            ],
            routes: []
        }
    }
    setRoutesFormApi(page) {

        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }
    componentDidMount() {
        // console.log('Do it for m');
        this.props.navigationActions.getPageInfoApi(window.location.pathname).then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                this.props.actions.get_committees()
                    .then(() => {
                        this.setState({ ...this.state, loading: false });
                        // console.log("return data: ", this.state);
                        // console.log(this.props.Committees_array);
                    }).catch(() => {
                    });
            })

    }
    render() {

        return (
            this.props.page &&
            <div className="board-container landing-container">
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                <div className="container">


                    {/* { this.props.Committees_array.length > 0 
                            &&
                      this.props.Committees_array.map((value,index)=>{
                    return(
                        <div className="baord-content row justify-content-between landing-row" key={index}>
                        <h2 className="board-title">{value.title}</h2>
                        <div className="col-12 col-lg-6" key={index}>
                                    <div className="landing-card">
                                        <ul>
                                            <li><i className={value.icon}></i></li>
                                            <li><h3>{value.name}</h3></li>
                                            <li><p>{value.description}</p></li>
                                        </ul>
                                    </div>
                                </div>
                          </div>
                    )
                    })} */}
                    <h2 className="board-title">{t("standingCommittees")}</h2>
                    <div className="baord-content row justify-content-between landing-row">
                        {this.state.loading && <div><h3>Loading ......</h3></div>}
                        {this.props.Committees_array.length > 0 &&
                            this.props.Committees_array.map((value, index) => {
                                return (
                                    <div className={"col-12 col-lg-6 " + (this.state.standingCommitteesType == value.type ? "" : "d-none")} key={index}>
                                        <div className="landing-card">
                                            <ul>
                                                <li><i className={"icon-" + value.icon}></i></li>
                                                <li><h3>{this.props.lng == 'ar' ? value.title : value.title_en}</h3></li>
                                                <li><p>{ReactHtmlParser(this.props.lng == 'ar' ? value.description : value.description_en)}</p></li>
                                            </ul>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                    <h2 className="board-title">{t("temporaryCommittees")}</h2>
                    <div className="baord-content row justify-content-between landing-row">
                        {this.state.loading && <div><h3>Loading ......</h3></div>}
                        {this.props.Committees_array.length > 0 &&
                            this.props.Committees_array.map((value, index) => {
                                return (
                                    <div style={{ marginTop: "1rem", marginBottom: "1rem" }} className={"col-12 col-lg-6 " + (this.state.tempCommitteesType == value.type ? "" : "d-none")} key={index}>
                                        <div className="landing-card">
                                            <ul>
                                                <li><i className={"icon-" + value.icon}></i></li>
                                                <li><h3>{this.props.lng == 'ar' ? value.title : value.title_en}</h3></li>
                                                <li><p>{ReactHtmlParser(this.props.lng == 'ar' ? value.description : value.description_en)}</p></li>
                                            </ul>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>
        )
    }
}

Board.propTypes = {
    actions: PropTypes.object.isRequired,
    Committees_array: PropTypes.array.isRequired,
    lng: PropTypes.string.isRequired,
    navigationActions: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired
};
function mapStateToProps(state) {
    return {
        Committees_array: state.committees.Committees_array,
        lng: state.user.lng,
        page: state.navigation.page
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Board)