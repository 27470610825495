import {GET_GUIDE_BOOK_DATA, GET_GUIDE_BOOK_CHILDREN, GET_GUIDE_BOOK_CATEGORY_CONTENT} from '../../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../../initialState';

export default function guideBookReducer(state = initialState.programs_and_activities.guide_book, action) {
    switch (action.type) {
        case GET_GUIDE_BOOK_DATA.SUCCESS:
          return objectAssign({}, state, {guideBookobject: action.payload});
        case GET_GUIDE_BOOK_CHILDREN.SUCCESS:
          return objectAssign({}, state, {guideBookCategoryContent: action.payload});
        case GET_GUIDE_BOOK_CATEGORY_CONTENT.SUCCESS:
          return objectAssign({}, state, {guideBookCategoryContent: action.payload});
        default:
          return state;
      }
}