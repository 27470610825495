import React from 'react';
import Translate from '../translate/translate';
import PropTypes from "prop-types";
// import { FacebookButton, FacebookCount } from "react-social";
const CpaSharing = props => {
    var facebookLink = `https://facebook.com/sharer/sharer.php?u=${props.url == null ? window.location.href : props.url}`
    const getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
            return 'iOS';
        }
        else if (userAgent.match(/Android/i)) {
            return 'Android';
        }
        else {
            return 'unknown';
        }
    }
    // console.log("android", `fb://faceweb/f?href=https%3A%2F%2Fm.facebook.com%2Fsharer.php${encodeURIComponent(window.location.href)}
    //`)
    // useEffect(() => {
    switch (getMobileOperatingSystem()) {
        case 'Android':
            facebookLink = `fb://faceweb/f?href=https%3A%2F%2Fm.facebook.com%2Fsharer.php%3Fu%3D${encodeURIComponent(props.url == null ? window.location.href : props.url)}`
            //`fb://faceweb/f?href=${encodeURI(props.url == null ? window.location.href : props.url)}sharer.php${props.url == null ? window.location.href : props.url}`
            // `fb://publish/profile/#ID#?text=#${props.url == null ? window.location.href : props.url}#`

            // facebookLink=`fb://faceweb/f?href={{urlencoded sharer.php url}}`
            break;
        case 'iOS':
            facebookLink = `fb://publish?text=${encodeURIComponent(props.url == null ? window.location.href : props.url)}`

            break;
        default:
            break;
    }
    // }, [])
    ///https://urlgeni.us/facebook/gdzW
    ///https://urlgeni.us/facebook/caV9
    // const publishOnFacebook = () => {
    //     console.log("ss")
    //     var xmlhttp = new XMLHttpRequest();
    //     console.log("xmlhttp", xmlhttp)

    //     xmlhttp.onreadystatechange = function () {
    //         if (xmlhttp.readyState == 4) {
    //             console.log("xml", xmlhttp.status);
    //         }
    //     }

    //     xmlhttp.open("POST", "https://graph.facebook.com/me/feed?" +
    //         "message=https://stackoverflow.com/q/25313299/1021970" +
    //         "&access_token=my_token", true);
    // }
    // console.log(window.location)
    let url = props.url == null ? window.location.href : props.url;
    let activeClass = props.active == null ? '' : props.active;
    // const share = function () {
    //     window.open('https://.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(location.href), 'facebook-share-dialog', 'width=626,height=436')
    // }

    // console.log("url", encodeURI(url))
    return (


        <div className={"share-control" + ' ' + activeClass}>
            <span><Translate text="share" /></span>
            {/* <!-- Sharingbutton Facebook --> */}
            {/* {console.log("ur", facebookLink)} */}
            <a className="resp-sharing-button__link"
                href={facebookLink}
                // href={`fb://sharer/sharer.php?u=${url}`}

                target="_blank" rel="noopener noreferrer" aria-label="Share on Facebook">
                <div className="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--small"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z" /></svg>
                </div>
                </div>
            </a>
            {/* <button onClick={share}>face</button> */}

            {/* <!-- Sharingbutton Twitter --> */}
            <a className="resp-sharing-button__link"
                href={"https://twitter.com/intent/tweet/?url=" + url}
                target="_blank" rel="noopener noreferrer" aria-label="Share on Twitter">
                <div className="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--small"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z" /></svg>
                </div>
                </div>
            </a>

            {/* <!-- Sharingbutton LinkedIn --> */}
            {/* <a className="resp-sharing-button__link"
                href={"https://www.linkedin.com/shareArticle?mini=true&url=" + url}
                target="_blank" rel="noopener noreferrer" aria-label="Share on Linkedin">
                <div className="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--small"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ backgroundColor: '#0077b5', borderColor: '#0077b5' }} width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" /></svg></div>
                </div>
            </a> */}
            <a className="resp-sharing-button__link" href={"https://www.linkedin.com/sharing/share-offsite/?url=" + url} target="_blank" rel="noopener noreferrer" aria-label="Share on Linkedin">
                <div className="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--small"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ backgroundColor: '#0077b5', borderColor: '#0077b5' }} width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" /></svg></div>
                </div>
            </a>
        </div>
    );

};
CpaSharing.propTypes = {
    url: PropTypes.string,
    active: PropTypes.string
}
export default CpaSharing;