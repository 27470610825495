import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import './BreadCrumb.scss';
import { Row, Col } from "react-bootstrap";
import PropTypes from 'prop-types';
import * as actions from '../../../actions/filters';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Translate from '../translate/translate';

class BreadCrumb extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            year: null,
            category: null
        }
    }
    filterByYear = event => {
        const { value } = event.target
        this.props.filterByYearfun(value, this.state.category)
    }
    filterByCategory = event => {
        const { value } = event.target
        this.props.filterByCategoryfun(value, this.state.year)
    }
    componentDidMount() {
        this.props.actions.getFiltersData()
    }
    render() {

        return (

            < div className="breadCrumb-container" >
                <Row>
                    <Col xs={12} lg={6}>
                        <Breadcrumb>
                            {this.props.routes &&
                                [{ name: "الرئيسية", name_en: "Home Page", link: "" }].concat(this.props.routes).map((value, index) => {
                                    return (
                                        <Breadcrumb.Item key={index} onClick={() => this.props.getId(value.link)}>
                                            <Link to={'/' + value.link}>
                                                {this.props.lng == "ar" ? value.name : value.name_en}
                                            </Link>
                                        </Breadcrumb.Item>
                                    )
                                })}
                        </Breadcrumb>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={12} lg={"auto"} className={this.props.filterbySubject ? "" : "d-none"}>
                                <div className="filter">
                                    {this.props.filterObject &&
                                        <select name="category" onChange={this.filterByCategory}>
                                            <option value=''><Translate text="catFilter" /></option>
                                            {this.props.filterObject.categories.map((value, index) => {
                                                return (

                                                    <option key={index} value={value.name} selected={value.name== this.props.selectedCat}>{this.props.lng === "ar" ? value.name : value.name_en}</option>
                                                )
                                            })}
                                        </select>
                                    }
                                </div>
                            </Col >
                            <Col xs={12} lg={"auto"} className={this.props.filterbyYear ? "" : "d-none"}>
                                <div className="filter">
                                    {this.props.filterObject &&
                                        <select name="year" onChange={this.filterByYear}>
                                            <option value=''><Translate text="yearFilter" /></option>
                                            {this.props.filterObject.years.map((value, index) => {
                                                return (
                                                    <option key={index} value={value.year} selected={value.year == this.props.selectedYear}>{value.year}</option>
                                                )
                                            })}
                                        </select>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </div >
        )
    }
}
BreadCrumb.propTypes = {
    routes: PropTypes.array,
    filterbyYear: PropTypes.bool,
    filterbySubject: PropTypes.bool,
    filterByYearfun: PropTypes.func,
    filterByCategoryfun: PropTypes.func,
    lng: PropTypes.string.isRequired,
    filterObject: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    getId: PropTypes.func,
    selectedCat: PropTypes.any,
    selectedYear: PropTypes.any
};
function mapStateToProps(state) {
    return {
        filterObject: state.filters.filterObject,
        lng: state.user.lng
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BreadCrumb)