import { GET_COMPLAIN_STATUS } from '../../../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../../../initialState';

export default function followComplainReducer(
  state = initialState.programs_and_activities.programs.legal_advocay.follow_complain, action) {
  switch (action.type) {
    case GET_COMPLAIN_STATUS.SUCCESS:
      return objectAssign({}, state, {
        status: action.payload.status,
        status_en: action.payload.status_en,
        admin_response: action.payload.admin_response
      });
    default:
      return state;
  }
}