var fetchDefaults = require('fetch-defaults');

export default function apiFetch(token) {
  if (token) {
    return fetchDefaults(fetch, {
      headers: { Authorization: `Bearer ${token}` }
    })
  } else {
    return fetchDefaults(fetch);
  }
}

export const userLogout = () => {
  localStorage.removeItem('_cpaJwt');
  localStorage.removeItem('id');
  localStorage.removeItem('first_name');
  localStorage.removeItem('last_name');
  localStorage.removeItem('email');
  localStorage.removeItem('city');
  localStorage.removeItem('identification_number');
  localStorage.removeItem('identification_path');
  localStorage.removeItem('verified');
  // localStorage.clear()
  window.location.reload();

};
