import { apiFetch } from "../../../utils/http.helpers";
import {
    GET_COMPARE_AND_RATE_LANDING_DATA,
    GET_COMPARE_AND_RATE_ITEM_DATA,
    GET_COMPARE_AND_RATE_ITEM_SIBLING,
    GET_COMPARE_AND_RATE_COMPARIOSN,
    GET_ALL_MULTI_CATEGORIES_WITH_EACH_SUB,
    GET_MULTI_CATEGORIES_BY_ID_WITH_EACH_SUB,
    COMPARE_OBJECTS, ADD_OBJETCS_COMPARE_LIST,
    REMOVE_TO_BE_COMPARED_OBJECTS,
    REMOVE_SPECIFIC_OBJECT_FROM_TO_BE_COMPARED_OBJECTS,
    ADD_BUTTON_ID_OF_ADDED_OBJECT_TO_COMPARISION,
    REMOVE_BUTTON_ID_OF_ADDED_OBJECT_TO_COMPARISION,
    REMOVE_DIABLE_OBJECT_COMPARE_BTN,
    REMOVE_SPECIFIC_OBJECT_FROM_COMPARISON_LIST,
    SET_FIRST_COLLAPSE_INDEX,
    SET_FIRST_COLLAPSE_ID,
    SET_SECOND_COLLAPSE_INDEX,
    SET_SECOND_COLLAPSE_ID,
    GET_GENERAL_TIPS,
    // GET_PREVIOUS_EVALUATIONS
} from "../../../constants/actionTypes";
import { BASE_URL } from "../../../constants/config";

export function getAllMultiCategoriesWithEachSub() {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/api/multiCategories/getAllMultiCategoriesWithEachSub', {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json"
            }
        })
            .then(response => response.json())
            .then(res => {
                dispatch({ type: GET_ALL_MULTI_CATEGORIES_WITH_EACH_SUB, payload: res })
            })
            .catch(() => {

            })
    }
}

export function getMultiCategoriesByIdWithEachSub(id) {
    return function (dispatch) {
        return apiFetch(BASE_URL +
            '/api/multiCategories/getMultiCategoriesByIdWithEachSub?multi_categories_id=' + id, {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json"
            }
        })
            .then(response => response.json())
            .then(response => {
                dispatch({ type: GET_MULTI_CATEGORIES_BY_ID_WITH_EACH_SUB, payload: response })
            })
            .catch((error) => {
                throw error;
            })
    }
}

export function compareObjects(ids) {
    let url;
    switch (ids.length) {
        case 1:
            url =
                `/api/multiCategories/compareMultiCategories?multi_categories_id[0]=${ids[0]}`;
            break;
        case 2:
            url = `/api/multiCategories/compareMultiCategories?multi_categories_id[0]=${ids[0]}&multi_categories_id[1]=${ids[1]}`;
            break;
        case 3:
            url = `/api/multiCategories/compareMultiCategories?multi_categories_id[0]=${ids[0]}&multi_categories_id[1]=${ids[1]}&multi_categories_id[2]=${ids[2]}`
            break;
    }
    return function (dispatch) {
        return apiFetch(BASE_URL + url, {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json"
            }
        })
            .then(response => { return response.json() })
            .then(response => {
                dispatch({ type: COMPARE_OBJECTS, payload: response })
                return response;
            })
    }
}

export function getGeneralTips(id) {
    return function (dispatch) {
        return apiFetch(BASE_URL + '/' + '/api/multiCategories/getGeneralTips?multi_categories_id=' + id, {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json"
            }
        })
            .then(response => response.json())
            .then(response => {
                dispatch({ type: GET_GENERAL_TIPS, payload: response })
            })
    }
}







export function addToBeComparedObjectsList(value) {
    return function (dispatch) {
        dispatch({ type: ADD_OBJETCS_COMPARE_LIST, payload: value })

    }
}

export function removeToBeComparedObjects() {
    return function (dispatch) {
        dispatch({ type: REMOVE_TO_BE_COMPARED_OBJECTS, payload: [] })
    }
}

export function removeSpecificObjectFromToBeComparedObjects(value) {
    return function (dispatch) {
        dispatch({ type: REMOVE_SPECIFIC_OBJECT_FROM_TO_BE_COMPARED_OBJECTS, payload: value })
    }
}

export function removeSpecificObjectFromComparisonList(value) {
    return function (dispatch) {
        dispatch({ type: REMOVE_SPECIFIC_OBJECT_FROM_COMPARISON_LIST, payload: value })
    }
}

export function addButtonIdOfAnAddedObject(value) {
    return function (dispatch) {
        dispatch({ type: ADD_BUTTON_ID_OF_ADDED_OBJECT_TO_COMPARISION, payload: value })
    }
}

export function removeButtonIdOfAnAddedObject(value) {
    return function (dispatch) {
        dispatch({ type: REMOVE_BUTTON_ID_OF_ADDED_OBJECT_TO_COMPARISION, payload: value })
    }
}

export function removeDiableObjectCompareBtn() {
    return function (dispatch) {
        dispatch({ type: REMOVE_DIABLE_OBJECT_COMPARE_BTN, payload: [] })
    }
}

export function setFirstCollapseIndex(value) {
    // console.log("Vv", value)
    if (value != -1) {

        return function (dispatch) {
            dispatch({ type: SET_FIRST_COLLAPSE_INDEX, payload: value })
        }
    }
}

export function setFirstCollapseId(value) {
    // console.log("id", value)
    return function (dispatch) {

        dispatch({ type: SET_FIRST_COLLAPSE_ID, payload: value })
    }
}

export function setSecondCollapseIndex(value) {
    return function (dispatch) {
        dispatch({ type: SET_SECOND_COLLAPSE_INDEX, payload: value })
    }
}

export function setSecondCollapseId(value) {
    return function (dispatch) {
        dispatch({ type: SET_SECOND_COLLAPSE_ID, payload: value })
    }
}

export function getCompareAndRateCategoriesData(id) {
    return function (dispatch) {
        return apiFetch('/api/caren/categories?id=' + id, {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
            }
        })
            .then(response => response.json())
            .then(res => {
                dispatch({ type: GET_COMPARE_AND_RATE_LANDING_DATA.SUCCESS, payload: res })
            }).catch(() => {
            })
    };
}

export const getCompareAndRateCategoryItemData = (id) => {
    return function (dispatch) {
        return apiFetch('/api/caren/category/?id=' + id, {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
            }
        })
            .then(response => response.json())
            .then(res => {
                dispatch({ type: GET_COMPARE_AND_RATE_ITEM_DATA.SUCCESS, payload: res })
            }).catch(() => {
            })
    };
}
export function getCompareAndRateSiblingsData(id) {
    return function (dispatch) {
        return apiFetch('/api/caren/items?id=' + id, {
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
            }
        })
            .then(response => response.json())
            .then(res => {
                dispatch({ type: GET_COMPARE_AND_RATE_ITEM_SIBLING.SUCCESS, payload: res })
            }).catch(() => {
            })
    };
}
export function getCompareAndRateCompareData(data) {
    return function (dispatch) {
        return apiFetch('/api/caren/compare', {
            method: "POST",
            // withCredentials: true,
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(res => {
                dispatch({ type: GET_COMPARE_AND_RATE_COMPARIOSN.SUCCESS, payload: res })
            }).catch((error) => {
                throw error
            })
    };
}