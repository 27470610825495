import React from 'react';
import ImgHeader from '../../../../GlobalComponent/ImgHeader/ImgHeader';
import BreadCrumb from '../../../../GlobalComponent/BreadCrumb/BreadCrumb';
import { Container, Row, Col, } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
// import * as actions from '../../../actions/program-and-activities/initiatives/guideBook';
import * as actions from '../../../../../actions/program-and-activities/initiatives/guideBook';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Translate from '../../../../GlobalComponent/translate/translate';
import SearchField from "react-search-field";
import './guideBookPage.scss';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import { t } from 'i18next';
import { BASE_URL } from '../../../../../constants/config';
import * as navigationActions from '../../../../../actions/navigation';
import CpaSharing from '../../../../GlobalComponent/cpaSharing/cpaSharing';
import CpaDownloading from '../../../../GlobalComponent/cpaDownloadingFiles/cpaDownload';

const options = ["Option 1", "Option 2", "Option 3", "Option 4", "Option 5"];
let arrowAr = <i className="icon-down-dir" Style="float: left;"></i>;
let arrowEn = <i className="icon-down-dir" Style="float: right;"></i>;
// let subArrowAr = <i className="icon-down-dir" Style="float: left;"></i>;
// let subArrowEn = <i className="icon-down-dir" Style="float: right;"></i>;
// let arrowAr1 = <i className="icon-down-dir" Style="float: left;"></i>;
// let arrowEn1 = <i className="icon-down-dir" Style="float: right;"></i>;

class GuideBookPage extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.myDivToFocus = React.createRef();
        this.state = {
            pageHeaderImg: "",
            pageId: 0,
            sectionId: 0,
            loading: true,
            ministryColOpen: false,
            collapseColOpen: false,
            menucol2open: false,
            img_url: BASE_URL + '/uploads/',
            selectSubject: null,
            content: null,
            bookId: null,
            selectedOption: options[0],
            toBeRendered: null,
            rerender: false,
            pageTitle: "",
            pageTitleEn: "",
            comprehensiveGuideArray: null,
            showSharingIcons: false,
            // child_id: 1,
            routes: [],
            pageContent: [
                {
                    icon: 'icon-fax',
                    description: 'ddd'
                },
                {
                    icon: 'icon-fax',
                    description: 'aaa'
                }
            ],
            ministries: [
                {
                    name: 'MinistryOfHealth',
                    id: '1',
                },
                {
                    name: 'MinistryOf.....0',
                    id: '2',
                },
                {
                    name: 'MinistryOf.....1',
                    id: '3',
                },
                {
                    name: 'MinistryOf.....2',
                    id: '4',
                }
            ],
            buttons: [
                {
                    name: 'btn1',
                    link: '',
                    id: '1'
                },
                {
                    name: 'btn2',
                    link: '',
                    id: '2'
                },
                {
                    name: 'btn3',
                    link: '',
                    id: '3'
                },
            ]
        }
    }

    handleSelect = (eventKey) => {
        this.setState({ selectedOption: options[eventKey] });
    }

    handleMinistryClick = () => {
        this.setState({ ministryColOpen: !this.state.ministryColOpen });
        if (this.state.ministryColOpen) {
            arrowAr = <i className="icon-down-dir" Style="float: left;"></i>
            arrowEn = <i className="icon-down-dir" Style="float: right;"></i>
        } else {
            arrowAr = <i className="icon-up-dir" Style="float: left;"></i>
            arrowEn = <i className="icon-up-dir" Style="float: right;"></i>
        }
    }

    hanldeCollapseClick = (value) => {
        this.props.history.push(`/guide-book/${value.category_id}/${value.id}`)
        if (!value.status) {
            value.status = true;
            value.subArrowAr = <i className="icon-up-dir" Style="float: left;"></i>
            value.subArrowEn = <i className="icon-up-dir" Style="float: right;"></i>
        }
        else {
            value.status = false;
            value.subArrowAr = <i className="icon-down-dir" Style="float: left;"></i>
            value.subArrowEn = <i className="icon-down-dir" Style="float: right;"></i>
        }
        this.setState({
            collapseColOpen: !this.state.collapseColOpen
            , sectionId: null
        });
    }

    handleChildButtonClick = (value) => {
        //Send this id to API.
        //Recieve JSON Data from api, Then set the state of pageContent to the received JSON
        if (this.props.lng === 'ar') {
            this.setState({
                pageTitle: value.title,
                pageTitleEn: value.title_en,
                pageHeaderImg: value.image
            })
        } else {
            this.setState({
                pageTitle: value.title,
                pageTitleEn: value.title_en,
                pageHeaderImg: value.image
            })
        }
        this.props.actions.getChildren(value.id)
        this.handleScrollToDiv();
    }

    handleButtonClick = (value) => {
        this.props.history.push(`/guide-book/${value.category_id}/${value.id}`)
        let subCatIndex = this.props.guideBookobject.sub_categories.findIndex(cat => cat.id === value.id);
        if (this.props.lng === 'ar') {
            this.setState({
                pageId: subCatIndex,
                sectionId: null,
                pageTitle: value.title,
                pageTitleEn: value.title_en,
                pageHeaderImg: value.image
            })
        } else {
            this.setState({
                pageId: subCatIndex,
                sectionId: null,
                pageTitle: value.title,
                pageTitleEn: value.title_en,
                pageHeaderImg: value.image
            })
        }
        this.props.actions.getGuideBookContent(value.id);
        this.handleScrollToDiv();
    }

    handleScrollToDiv = () => {
        //.current is verification that your element has rendered
        if (this.myDivToFocus.current) {
            this.myDivToFocus.current.scrollIntoView({
                behavior: "smooth",
                block: "start"
            })
        }
    }

    callApi = (id, search) => {
        this.setState({
            loading: true
        })
        this.props.actions.guideBookobjectData(id, search)
            .then(() => {
                this.setState({ loading: false, });
                if (!this.props.guideBookobject.sub_categories.length == 0) {
                    if (this.props.guideBookobject.sub_categories[0].children.length > 0) {
                        this.props.actions.getChildren(this.props.guideBookobject.sub_categories[0].children[0].id)
                            .then(() => {

                                if (this.props.lng === 'ar') {
                                    this.setState({
                                        pageTitle: this.props.guideBookobject.sub_categories[0].children[0].title,
                                        pageTitleEn: this.props.guideBookobject.sub_categories[0].children[0].title_en,
                                        pageHeaderImg: this.props.guideBookobject.sub_categories[0].children[0].image
                                    })
                                } else {
                                    this.setState({
                                        pageTitle: this.props.guideBookobject.sub_categories[0].children[0].title,
                                        pageTitleEn: this.props.guideBookobject.sub_categories[0].children[0].title_en,
                                        pageHeaderImg: this.props.guideBookobject.sub_categories[0].children[0].image
                                    })
                                }
                            })
                    }
                    else {

                        this.props.actions.getGuideBookContent(this.props.guideBookobject.sub_categories[0].id)
                            .then(() => {
                                if (this.props.lng === 'ar') {
                                    this.setState({
                                        pageTitle: this.props.guideBookobject.sub_categories[0].title,
                                        pageTitleEn: this.props.guideBookobject.sub_categories[0].title_en,
                                        pageHeaderImg: this.props.guideBookobject.sub_categories[0].image
                                    })
                                } else {
                                    this.setState({
                                        pageTitle: this.props.guideBookobject.sub_categories[0].title,
                                        pageTitleEn: this.props.guideBookobject.sub_categories[0].title_en,
                                        pageHeaderImg: this.props.guideBookobject.sub_categories[0].image
                                    })
                                }
                            })
                    }
                }

                this.handleMinistryClick()
            })
            .catch(() => {

            });
    }

    componentDidMount() {
        this.props.navigationActions.getPageInfoApi('/consumer-rights-comprehensive-guide').then(
            () => {
                this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
                var pageID = this.props.location.pathname;
                pageID = pageID.split('/')[2];
                this.setState({
                    comprehensiveGuideArray: JSON.parse(localStorage.getItem('guideList')),
                    sectionId: this.props.match.params.id
                })

                this.props.actions.guideBookobjectData(pageID, null)
                    .then(() => {
                        this.setState({ loading: false })
                        let pageIndex = this.props.guideBookobject.sub_categories.findIndex(cat => cat.id === parseInt(this.props.match.params.id));
                        if (this.props.lng === 'ar') {
                            this.setState({
                                pageTitle: this.props.guideBookobject.sub_categories[pageIndex].title,
                                pageTitleEn: this.props.guideBookobject.sub_categories[pageIndex].title_en
                            })
                        } else {
                            this.setState({
                                pageTitle: this.props.guideBookobject.sub_categories[pageIndex].title,
                                pageTitleEn: this.props.guideBookobject.sub_categories[pageIndex].title_en
                            })
                        }
                        if (this.props.guideBookobject.sub_categories[pageIndex].children.length === 0) {
                            this.props.actions.getGuideBookContent(this.props.guideBookobject.sub_categories[pageIndex].id)
                        }
                        else {
                            this.props.actions.getChildren(this.props.guideBookobject.sub_categories[pageIndex].children[0].id)
                                .then(
                                    this.setState({
                                        pageTitle: this.props.guideBookobject.sub_categories[pageIndex].children[0].title,
                                        pageTitleEn: this.props.guideBookobject.sub_categories[pageIndex].children[0].title_en,
                                        pageHeaderImg: this.props.guideBookobject.sub_categories[pageIndex].children[0].image
                                    })
                                )
                        }
                    })
                    .catch((err) => {
                        if (err) {
                            this.props.history.push("/")
                            window.location.reload(false);
                            // window.reload

                        }
                    });
            })
    }
    setRoutesFormApi(page) {

        let routes = []
        let temp = page && page.pread_crumb
        for (let index = 0; index < 3; index++) {
            routes.push({
                name: temp && temp.menu_name,
                name_en: temp && temp.menu_name_en,
                link: temp && temp.slug
            })
            if (temp && temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }

    onSearchHandler(event) {
        let id = this.props.match.params.bookId;
        this.callApi(id, event)

        // let result = this.props.guideBookobject.sub_categories.filter(cat=>cat.id)






        // let results = this.state.comprehensiveGuideArray.map(ministry => {
        //     if (this.props.lng === "ar") {
        //         return (
        //             ministry.title.includes(event)
        //         )
        //     }
        //     else {
        //         return (
        //             ministry.title_en.includes(event)
        //         )
        //     }

        // })
        // let indexMinistryFound = results.findIndex(result => result === true)
        // if (indexMinistryFound !== -1) {
        // }
        // else {
        //     toast.error('No Result', {
        //         position: toast.POSITION.BOTTOM_RIGHT
        //     })
        // }
    }

    render() {

        return (
            this.props.page &&
            <div className="guideBook-container">
                {this.state.loading &&
                    <div className="over-lay" >
                        {t('loading')}
                    </div>
                }
                <ImgHeader page={this.props.page} />
                <BreadCrumb
                    routes={this.state.routes}
                    filterbyYear={false}
                    filterbySubject={false} />
                <Container className="guideBook-content">
                    <Row>
                        <Col xs={12} md={4} className="menuCol">
                            <SearchField
                                placeholder={t('search')}
                                onEnter={(event) => this.onSearchHandler(event)}
                            />
                            <label><Translate text="Ministry" /></label>
                            <Button
                                onClick={this.handleMinistryClick}
                                variant="menu"
                            >
                                {this.props.guideBookobject !== null &&
                                    (this.props.lng === 'ar' ?
                                        <div>{this.props.guideBookobject.title}{arrowAr}</div>
                                        :
                                        <div>{this.props.guideBookobject.title_en}{arrowEn}</div>)}
                            </Button>

                            <Collapse in={this.state.ministryColOpen}>
                                <div id="example-collapse-text">
                                    {this.state.comprehensiveGuideArray !== null &&
                                        this.state.comprehensiveGuideArray.map((value, index) => {
                                            return (
                                                <Link to={"/guide-book/" + value.id + "/0"} key={index}>
                                                    <Button
                                                        onClick={() => this.callApi(value.id, null)}
                                                        variant="subMenu">
                                                        {this.props.lng === 'ar' ? value.title : value.title_en}
                                                    </Button>
                                                </Link>
                                            )
                                        })}
                                </div>
                            </Collapse>

                            <label><Translate text="departements" /></label>
                            <div id="example-collapse-text">
                                {
                                    this.props.guideBookobject !== null &&
                                    this.props.guideBookobject.sub_categories.map((value, index) => {
                                        if (value.children && value.children.length !== 0) {
                                            return (
                                                <React.Fragment key={value.id}>
                                                    <Button
                                                        className={value.id == this.state.sectionId ? 'active' : null}
                                                        onClick={() => this.hanldeCollapseClick(value)}
                                                        href="#"
                                                        variant="Menu1">
                                                        {
                                                            this.props.lng === "ar" ?
                                                                <>{value.title}{!value.subArrowAr ? <i className="icon-down-dir" Style="float: left;"></i> : value.subArrowAr}</> :
                                                                <>{value.title_en}{!value.subArrowEn ? <i className="icon-down-dir" Style="float: right;"></i> : value.subArrowEn}</>
                                                        }
                                                    </Button>
                                                    <Collapse in={value.status}>
                                                        <div id="example-collapse-text">
                                                            {
                                                                value.children.map((child, index) => {
                                                                    return (
                                                                        <Button
                                                                            href="#"
                                                                            variant="Menu1"
                                                                            key={index}
                                                                            ///handleChildClick
                                                                            onClick={() => this.handleChildButtonClick(child)}>
                                                                            {this.props.lng === 'ar' ? child.title : child.title_en}
                                                                        </Button>
                                                                    )
                                                                })}
                                                        </div>
                                                    </Collapse>
                                                </React.Fragment>
                                            )
                                        }
                                        else {
                                            return (
                                                <Button
                                                    className={value.id == this.state.sectionId ? 'active' : null}
                                                    href="#"
                                                    variant="Menu1"
                                                    key={index}
                                                    onClick={() => this.handleButtonClick(value)}>
                                                    {this.props.lng === 'ar' ? value.title : value.title_en}
                                                </Button>
                                            )
                                        }
                                    })}
                            </div>
                        </Col>
                        <Col xs={12} md={8}>
                            <Row>
                                <Col xs={12} className="PageTitle">
                                    <h1>{this.props.guideBookobject !== null &&
                                        (this.props.lng === 'ar' ? this.props.guideBookobject.title : this.props.guideBookobject.title_en)}</h1>
                                </Col>
                                <a className="share-link" onClick={() => {
                                    this.setState({ showSharingIcons: !this.state.showSharingIcons })
                                }}>
                                    <div className="resp-sharing-button resp-sharing-button--download resp-sharing-button--small"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
                                        <svg xmlns="http://www.w3.org/2000/svg" id="share" width="32.616" height="34.316" fontSize="2rem" viewBox="0 0 32.289 34.252">
                                            <path id="Path_104" data-name="Path 104" d="M39.958,22a6.129,6.129,0,0,0-4.85,2.39L25.7,19.139a6.144,6.144,0,0,0,.341-2.02,6.009,6.009,0,0,0-.348-2.027l9.4-5.249a6.117,6.117,0,1,0-1.266-3.72,5.979,5.979,0,0,0,.348,2.027L24.781,13.4a6.118,6.118,0,1,0,.014,7.432l9.4,5.256A6.12,6.12,0,1,0,39.958,22Zm0-20.077a4.2,4.2,0,1,1-4.2,4.2A4.207,4.207,0,0,1,39.958,1.927ZM19.931,21.322a4.2,4.2,0,1,1,4.2-4.2A4.207,4.207,0,0,1,19.931,21.322Zm20.027,11a4.2,4.2,0,1,1,4.2-4.2A4.207,4.207,0,0,1,39.958,32.324Z" transform="translate(-13.8)" fill="#6be2b7" />
                                        </svg>
                                    </div>
                                    </div>
                                </a>
                                <CpaSharing active={this.state.showSharingIcons ? 'active' : ''} />
                                {
                                    this.props.guideBookobject &&
                                    <CpaDownloading fileToDownload={this.props.lng === "ar" ? this.props.guideBookobject.attachments : this.props.guideBookobject.attachments_en} />
                                }

                            </Row>
                            <Row className="pageHeader">
                                <Col xs={12} lg={4} xl={3} className="imgBiggerContainer" >
                                    {(this.props && this.props.guideBookobject && this.props.guideBookobject.sub_categories && this.props.guideBookobject.sub_categories.length > 0) ? <div className="img-container1" ref={this.myDivToFocus}>


                                        <img className="logo" src={
                                            this.props && this.props.guideBookobject &&
                                                this.state.pageHeaderImg === "" && this.props.guideBookobject.sub_categories &&
                                                this.props.guideBookobject.sub_categories[this.state.pageId] &&
                                                this.props.guideBookobject.sub_categories[this.state.pageId].image ?
                                                BASE_URL + '/uploads/' + this.props.guideBookobject.sub_categories[this.state.pageId].image :
                                                BASE_URL + '/uploads/' + this.state.pageHeaderImg
                                        } />


                                    </div>
                                        :

                                        ""
                                    }

                                </Col>
                                <Col xs={12} lg={8} xl={9}>
                                    {this.props && this.props.guideBookobject && this.props.guideBookobject.sub_categories && this.props.guideBookobject.sub_categories.length > 0 && <div className="LogoDesc">
                                        {/* <h2><Translate text="publicRights"/></h2> */}
                                        <h2>{this.props.lng === "ar" ? this.state.pageTitle : this.state.pageTitleEn}</h2>
                                    </div>}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="renderArea">
                                    <div className="pageContent">
                                        {
                                            this.props && this.props.guideBookobject && this.props.guideBookobject.sub_categories && this.props.guideBookobject.sub_categories.length > 0 && (this.props.guideBookCategoryContent !== null && this.props.guideBookCategoryContent.length !== 0) ?
                                                this.props.guideBookCategoryContent.map((value, index) => {
                                                    return (
                                                        <div className="contentRow" key={index}>
                                                            <Row>
                                                                {
                                                                    <>
                                                                        <Col xs={2} md={1} className="contentIcon">
                                                                            <img className="iconImage" src={this.state.img_url + value.image} />
                                                                        </Col>
                                                                        <Col xs={10} md={11} className="contentMap">
                                                                            {this.props.lng === "ar" ? ReactHtmlParser(value.description) : ReactHtmlParser(value.description_en)}
                                                                        </Col>
                                                                    </>
                                                                }
                                                            </Row>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className="contentRow">
                                                    <Row>
                                                        <Col xs={3} md={2} className="contentIcon"><i className="icon-menu-about"></i></Col>
                                                        <Col xs={9} md={10} className="contentMap">{this.props.lng === 'ar' ? 'المحتوى غير موجود' : 'Content not found'}</Col>
                                                    </Row>
                                                </div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

GuideBookPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
            bookId: PropTypes.string
        })
    }),
    actions: PropTypes.object.isRequired,
    guideBookobject: PropTypes.object.isRequired,
    guideBookChildren: PropTypes.object.isRequired,
    guideBookCategoryContent: PropTypes.object.isRequired,
    // comprehensiveGuideArray: PropTypes.array.isRequired,
    lng: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    history: PropTypes.object,
    "history.push": PropTypes.func
};


function mapStateToProps(state) {
    return {
        guideBookobject: state.guide_book.guideBookobject,
        guideBookChildren: state.guide_book.guideBookChildren,
        guideBookCategoryContent: state.guide_book.guideBookCategoryContent,
        // comprehensiveGuideArray: state.comprehensive_guide.comprehensiveGuideArray,
        lng: state.user.lng,
        page: state.navigation.page
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        navigationActions: bindActionCreators(navigationActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(GuideBookPage)