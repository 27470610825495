import React from 'react';
import BreadCrumb from '../../../GlobalComponent/BreadCrumb/BreadCrumb';
import ImgHeader from '../../../GlobalComponent/ImgHeader/ImgHeader';
import SectionCard from './SectionCard/SectionCard';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../../actions/program-and-activities/programs/motamken';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import moment from 'moment';
import { t } from 'i18next'
// import { Link } from 'react-router-dom';
import './motamaken-section.scss';
import PropTypes from 'prop-types';
import Translate from '../../../GlobalComponent/translate/translate';
// import NoItem from '../../../GlobalComponent/noItemsComponent/noItems';
// import LandingCard from "../../../GlobalComponent/LandingCard/LandingCard";
import * as navigationActions from '../../../../actions/navigation';
import filter from '../../../../actions/FilterBy';

/*let newscard = {
    type: 'normal',
    img: 'https://gdurl.com/CnsA',
    header: 'محاضرات بخصوص توعية المستهلك في حال استقرار الأوضاع الحالية',
    date: 'أربعاء 23 يناير 2019',
    views: '200',
    smallImg: 'https://gdurl.com/cbKr',
    author: 'محمود عبد المقصود',
    local: 'فندق الملك سعود',
    desc: 'يوضح هذا الجزء من المحاضرة على تنمية الكثير من المهارات الخاصة بتوعية المستهلك عن طريق مجموعة من الإجراءات',
};*/

class MotamakenSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showActivityDataModal: false,
      activityInModal: {},
      content: false,
      routes: [],
      activities: null,
      lastSelectedYear: null,
      lastSelectedCategory: null
    };
  }

  componentDidMount() {
    this.props.navigationActions.getPageInfoApi('/skilled').then(
      () => {
        this.setState({ ...this.state, routes: this.setRoutesFormApi(this.props.page) });
        const { match: { params } } = this.props;
        this.props.actions.getMotamakenActivitiesData(params.id)
          .then(() => {
            this.setState({ ...this.state, contentNo: 9, loading: false, activities: this.props.activities });
            if (this.props.activities.length == 0) {
              this.setState({
                noData:
                  <div className="notFound-container">
                    <div className="notFound-content text-center">
                      <div className="inner">
                        <h3>
                          {t("noData")}
                        </h3>
                      </div>
                    </div>
                  </div>,
                showMore: false
              })
            }
          }).catch(() => {
            this.setState({
              loading: false,
              content: false
            })
          });
      })
  }
  setRoutesFormApi(page) {

    let routes = []
    let temp = page.pread_crumb
    for (let index = 0; index < 3; index++) {
      routes.push({
        name: temp.menu_name,
        name_en: temp.menu_name_en,
        link: temp.slug
      })
      if (temp.pread_crumb) {
        temp = temp.pread_crumb
      } else {
        return routes.reverse();
      }
    }
  }
  filterByYear = (year) => {
    this.setState({
      activities: filter.filterByYear(this.props.activities, year, this.state.lastSelectedCategory),
      lastSelectedYear: year,
    })
    setTimeout(() => {
      this.CheckSubjuctNoData()
    }, 1);
  }
  filterByCategory = (catgeory) => {
    this.setState({
      activities: filter.filterByCategory(this.props.activities, catgeory, this.state.lastSelectedYear),
      lastSelectedCategory: catgeory,
    })
    setTimeout(() => {
      this.CheckSubjuctNoData()
    }, 1);
  }
  CheckSubjuctNoData = () => {
    if (this.state.activities.length == 0) {
      this.setState({
        noData:
          <div className="notFound-container">
            <div className="notFound-content text-center">
              <div className="inner">
                <h3>
                  {t("noData")}
                </h3>
              </div>
            </div>
          </div>,
        showMore: false
      })
    } else {
      this.setState({
        noData: '',
        showMore: true
      })
    }
  }
  /**
   * loop through acts and if user already registered
   * show a modal with event basic data
   * @param activityId
   */
  showIsRegisteredModal = (activityId) => {
    const { activities } = this.props;
    activities.map(item => {
      if (item.id === activityId) {
        if (item.is_registered === true) {
          this.setState({ ...this.state, showActivityDataModal: true, activityInModal: item });
        }
      }
    })
  };

  /**
   * register the user in an activity
   * @param activity
   */
  handleActivityRegister = (activity) => {
    if (this.props.user.is_authenticated) {
      this.props.actions.registerInActivity(activity)
        .then(() => {
          this.setState({ ...this.state, showActivityDataModal: true, activityInModal: activity });
        }).catch(() => {

        });
    } else {
      this.props.toggleModal();
    }
  };

  /**
   * close the activity modal
   */
  closeModal = () => this.setState({ ...this.state, showActivityDataModal: false });

  render() {
    return (
      this.props.page &&
      <div className="motamaken-container">
        <ImgHeader page={this.props.page} />
        <BreadCrumb
          routes={this.state.routes}
          filterbyYear={true}
          filterbySubject={true}
          filterByYearfun={this.filterByYear}
          filterByCategoryfun={this.filterByCategory} />
        <div className="motamaken-section-wrapper">
          <div className="motamaken-landing">
            <Container>
              <Row>
                <Modal show={this.state.showActivityDataModal} size="lg" aria-labelledby="example-modal-sizes-title-sm">
                  <Modal.Header closeButton onClick={this.closeModal} />
                  <Modal.Body >
                    <div className={"Authentication-container"}>
                      <Row className="justify-content-center">
                        <Col xs={"auto"}>
                          <h4><Translate text="successfullyRegistered" /></h4>
                        </Col>
                        <Col xs={12}>
                          <span className={'col-sm-12'}><Translate text="NumberRegistered" /></span>
                          <div className={'col-sm-12'}>{this.state.activityInModal.number_of_user}</div>
                          <span className={'col-sm-12'}><Translate text="DateActivity" /></span>
                          <div className={'col-sm-12'}>{moment(this.state.activityInModal.start_date).format('dddd Do MMMM YYYY')}</div>

                        </Col>
                      </Row>
                    </div>
                  </Modal.Body>
                </Modal>
                {this.state.loading && <div><p><Translate text="Loading" /> ...</p></div>}

                {/* activities array is no sent in the first place */}
                {/* {!this.state.activities &&
                  <div className="notFound-content text-center">
                    <div className="inner">
                      <h3>{t('noItem')}</h3>
                    </div>
                  </div>
                } */}

                {this.state.activities &&
                  this.state.activities.map((obj) =>
                    <Col key={obj.id} lg={4} md={6} xs={12} className="mb-4">
                      <SectionCard
                        newscard={obj}
                        handleActivityRegister={this.handleActivityRegister}
                        showIsRegisteredModal={this.showIsRegisteredModal}
                        url={this.props.match.url}
                      />
                    </Col>
                  )
                }
                {this.state.noData}
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

MotamakenSection.propTypes = {
  actions: PropTypes.object.isRequired,
  activities: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired,
  navigationActions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    activities: state.motamaken.activities,
    user: state.user,
    page: state.navigation.page
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    navigationActions: bindActionCreators(navigationActions, dispatch)
  };
}



export default connect(mapStateToProps, mapDispatchToProps)(MotamakenSection)
