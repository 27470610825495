import {GET_COMPLAIN_STATUS} from '../../../../constants/actionTypes';
import { apiFetch } from '../../../../utils/http.helpers';
import { BASE_URL } from '../../../../constants/config';

export function getComplainStatus (complainNo){
    return function (dispatch) {
      return apiFetch(BASE_URL+'/api/eshtaky/complaint/check_status?complaint_number_constant=' + complainNo, {
        method: "GET",
        // mode: "cors",
        withCredentials: true,
        headers: {
          "Accept": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('_cpaJwt')
        }
      })
        .then(response => response.json())
        .then(res => {
            // console.log('Res in fetch status', res);
            dispatch({ type: GET_COMPLAIN_STATUS.SUCCESS, payload: res });
        }).catch((error) => {
            throw error;
        });
    };
  }