import React from 'react';
import ImgHeader from '../GlobalComponent/ImgHeader/ImgHeader';
import BreadCrumb from '../GlobalComponent/BreadCrumb/BreadCrumb';
import { Container, Row, Col } from "react-bootstrap";
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux';
import * as navigationAction from '../../actions/navigation';
import { BASE_URL } from '../../constants/config';
import ReactHtmlParser from 'react-html-parser';

class LandingPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            routes: [],
            pages: null,
            // id: props.match.params.id,
            form: null
        };
    }
    componentDidMount() {

        window.onpopstate = () => {
            this.props.navigationAction.getLandingPages(this.props.match.params.id).then(() => {
                this.setState({ ...this.state, form: this.props.landingPages, routes: this.setRoutesFormApi(this.props.landingPages) });
            }
            )
        }
        this.props.navigationAction.getLandingPages(this.props.match.params.id).then(() => {
            this.setState({ ...this.state, form: this.props.landingPages, routes: this.setRoutesFormApi(this.props.landingPages) });
        })

    }

    showForm(form) {


        if (form.need_open === 1) {
            this.setState({ ...this.state, form: form, routes: this.setRoutesFormApi(form) });
            this.props.history.push(`/${form.slug}`)
        }
    }

    BreadCrumbClick(value) {

        this.props.navigationAction.getLandingPages(value.split('/')[1]).then(() => {
            this.setState({ ...this.state, form: this.props.landingPages, routes: this.setRoutesFormApi(this.props.landingPages) });
        })

    }

    setRoutesFormApi(page) {
        if (!page) {
            this.props.history.push("/")
            window.location.reload(false)
        }
        let routes = []
        let temp = page.pread_crumb
        for (let index = 0; index < 10; index++) {
            routes.push({
                name: temp.menu_name,
                name_en: temp.menu_name_en,
                link: temp.slug
            })
            if (temp.pread_crumb) {
                temp = temp.pread_crumb
            } else {
                return routes.reverse();
            }
        }
    }

    render() {
        // console.log("state", this.state)
        // console.log("props", this.props)
        return (
            this.state && this.state.form !== null &&
            <div className="landing-container" >
                <ImgHeader page={this.state.form} />
                {/* <div onClick={() => this.BreadCrumbClick()} > */}
                <BreadCrumb getId={(value) => this.BreadCrumbClick(value)} routes={this.state.routes} filterbyYear={false} filterbySubject={false} />
                {/* </div> */}
                <Container>
                    {this.state && this.state.form.child_menu.length == 0 && <Row className="landing-row justify-content-center">
                        <Col xs={12} sm={10} lg={10} className="m-3 landing-gird"


                        >

                            <h3>{this.props.lng == "ar" ? this.state.form.menu_name : this.state.form.menu_name_en}</h3>
                            <span>{this.props.lng === 'ar' ? this.state.form.card_description : this.state.form.card_description_en}</span>
                            <div>
                                {ReactHtmlParser(this.props.lng == "ar" ? this.state.form.content : this.state.form.content_en)}
                            </div>
                        </Col>
                    </Row>}
                    <Row className="landing-row justify-content-center">
                        {this.state && this.state.form.child_menu.map((value, index) => {
                            return (
                                <Col xs={12} sm={6} lg={4} key={index} className="mb-3 landing-gird">

                                    {value.target == "_blank" ?
                                        <a href={`${value.slug}`} target={value.target}>
                                            <div className="landing-card" style={{ height: value.card_height }}>
                                                <ul>
                                                    <li>


                                                        {value.image && <img src={BASE_URL + "/uploads/" + value.image} alt={BASE_URL + "/uploads/" + value.image} style={{ width: "100%" }} />}
                                                    </li>
                                                    <li>
                                                        {
                                                            value.video &&
                                                            JSON.parse(value.video)[0] &&
                                                            JSON.parse(value.video)[0].download_link &&
                                                            <video width="100%" height="300" controls autoPlay={true}>
                                                                <source src={BASE_URL + '/uploads/' + JSON.parse(value.video)[0].download_link}></source>
                                                            </video>
                                                        }
                                                    </li>
                                                    <li><h3>{this.props.lng == "ar" ? value.menu_name : value.menu_name_en}</h3></li>

                                                    <li>

                                                        <span style={{ wordBreak: "break-word" }}>{this.props.lng === 'ar' ? value.card_description : value.card_description_en}</span>
                                                    </li>
                                                    <li>
                                                        <div className="landing-card--body">
                                                            <div className="author-wrapper">
                                                                <span>
                                                                    {
                                                                        value.icon &&
                                                                        <img style={{ maxWidth: '50px' }} src={BASE_URL + '/uploads/' + value.icon}></img>
                                                                    }
                                                                </span>
                                                            </div>

                                                            {
                                                                value.time &&
                                                                <span> <i className="icon-calendar"></i>{value.time.split(' ')[0]}</span>
                                                            }
                                                            {
                                                                value.time &&
                                                                <span> <i className="icon-clock"></i>{value.time.split(' ')[1]}</span>
                                                            }
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </a>
                                        :
                                        <div style={{ height: value.card_height }} onClick={() => this.showForm(value)} className={value.icon ? "landing-card icon" : "landing-card"}>
                                            {
                                                value.image !== null &&
                                                <img style={{ maxWidth: '300px' }} src={BASE_URL + '/uploads/' + value.image}></img>
                                            }
                                            {
                                                value.video &&
                                                JSON.parse(value.video)[0] &&
                                                JSON.parse(value.video)[0].download_link &&
                                                <video width="100%" height="300" controls autoPlay={true}>
                                                    <source src={BASE_URL + '/uploads/' + JSON.parse(value.video)[0].download_link}></source>
                                                </video>
                                            }
                                            <h3>{this.props.lng == "ar" ? value.menu_name : value.menu_name_en}</h3>
                                            <span style={{ wordBreak: "break-word" }}>{this.props.lng === 'ar' ? value.card_description : value.card_description_en}</span>
                                            <div className="landing-card--body">
                                                <div className="author-wrapper">
                                                    <span>
                                                        {
                                                            value.icon &&
                                                            <img style={{ maxWidth: '50px' }} src={BASE_URL + '/uploads/' + value.icon}></img>
                                                        }
                                                    </span>
                                                </div>

                                                {
                                                    value.time &&
                                                    <span> <i className="icon-calendar"></i>{value.time.split(' ')[0]}</span>
                                                }
                                                {
                                                    value.time &&
                                                    <span> <i className="icon-clock"></i>{value.time.split(' ')[1]}</span>
                                                }
                                            </div>
                                        </div>
                                    }
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </div >
        )
    }
}

LandingPage.propTypes = {
    lng: PropTypes.string.isRequired,
    landingPages: PropTypes.array.isRequired,
    navigationAction: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};
function mapStateToProps(state) {
    return {
        lng: state.user.lng,
        landingPages: state.navigation.landingPages
    };
}

function mapDispatchToProps(dispatch) {
    return {
        navigationAction: bindActionCreators(navigationAction, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(LandingPage)