import { createActionSet } from '../utils/action.helpers';

// custompage actions
export const SAVE_CURRENT_FORM = 'SAVE_CURRENT_FORM';
export const SAVE_CURRENT_FORM_STR = 'SAVE_CURRENT_FORM_STR';


///home page actions
export const GET_HOME_PAGE = 'GET_HOME_PAGE';
export const GET_HOME_PAGE_ALERT = 'GET_HOME_PAGE_ALERT';

export const SAVE_FUEL_SAVINGS = 'SAVE_FUEL_SAVINGS';
export const CALCULATE_FUEL_SAVINGS = 'CALCULATE_FUEL_SAVINGS';
export const GET_MOTAMKN_LANDING_DATA = createActionSet('GET_MOTAMKN_LANDING_DATA');
export const GET_MOTAMKN_ACTIVITIES_DATA = createActionSet('GET_MOTAMKN_ACTIVITIES_DATA');
export const GET_MOTAMKN_SINGLE_ACTIVITY_DATA = createActionSet('GET_MOTAMKN_SINGLE_ACTIVITY_DATA');
export const USER_REGISTER_IN_ACTIVITY = createActionSet('USER_REGISTER_IN_ACTIVITY');
export const USER_LOGIN = createActionSet('USER_LOGIN');
export const USER_REGISTER = createActionSet('USER_REGISTER');
export const REGISTER_ERROR = createActionSet('REGISTER_ERROR');
export const USER_LOGOUT = createActionSet('USER_LOGOUT');
export const LOAD_USER_DATA = createActionSet('LOAD_USER_DATA');
export const RESITER_VERIFICATION = createActionSet('RESITER_VERIFICATION');

//Initial website data structure*******************************
export const GET_NAVBAR_LINKS = createActionSet('GET_NAVBAR_LINKS');
//end Initial website data structure*******************************

export const GET_COMPARE_AND_RATE_LANDING_DATA = createActionSet('GET_COMPARE_AND_RATE_LANDING_DATA');
export const GET_COMPARE_AND_RATE_ITEM_DATA = createActionSet('GET_COMPARE_AND_RATE_ITEM_DATA');
export const GET_COMPARE_AND_RATE_ITEM_SIBLING = createActionSet('GET_COMPARE_AND_RATE_ITEM_SIBLING');
export const GET_COMPARE_AND_RATE_COMPARIOSN = createActionSet('GET_COMPARE_AND_RATE_COMPARIOSN');
export const CHANGE_USER_LNG = createActionSet('CHANGE_USER_LNG');
export const GET_RECOGNIZE_SUBJECTS = createActionSet('GET_TAARF_SUBJECTS');
/**
 * //////////////// Complain action types /////////////////////////
 */
export const CREATE_NEW_COMPLAIN = createActionSet('CREATE_NEW_COMPLAIN');
export const CREATE_NEW_ERSHEDNA_SHAKWA = createActionSet('CREATE_NEW_ERSHEDNA_SHAKWA');
export const GET_ERSHEDNA_SECTIONS = createActionSet('GET_ERSHEDNA_SECTIONS');
export const GET_ERSHEDNA_PROBLEMS = createActionSet('GET_ERSHEDNA_PROBLEMS');
export const GET_ERSHEDNA_ORGANIZATIONS = createActionSet('GET_ERSHEDNA_ORGANIZATIONS');
export const GET_CASES_OF_COMPLAINS = createActionSet('GET_CASES_OF_COMPLAINS');
export const GET_COMPLAIN_STATUS = createActionSet('GET_COMPLAIN_STATUS');
export const REMOVE_GUIDE_US_PROPS = createActionSet('REMOVE_GUIDE_US_PROPS');
export const GET_HEAR_YOU_TITLE_PARAGRAPH = createActionSet('GET_HEAR_YOU_TITLE_PARAGRAPH');
export const GET_HEAR_YOU_DESCRIPTION_PARAGRAPH = createActionSet('GET_HEAR_YOU_DESCRIPTION_PARAGRAPH');
export const GET_HEAR_YOU_COMPLAINT_PARAGRAPH = createActionSet('GET_HEAR_YOU_COMPLAINT_PARAGRAPH');
export const GET_DESCRIPTION_COMPLAINTS = createActionSet('GET_DESCRIPTION_COMPLAINTS');
export const GET_ACTIVITY_TYPE = 'GET_ACTIVITY_TYPE';


/*/////////////////////////////////////////*/
/**
 * //////////////// Complain action types /////////////////////////
 */
export const GET_PROFILE_DATA = createActionSet('GET_PROFILE_DATA');
/*/////////////////////////////////////////*/

export const GET_TAKEkNOWLEDGE_SUBJECTS = createActionSet('GET_TAKEkNOWLEDGE_SUBJECTS');
export const GET_QUIZES_DATA = createActionSet('GET_QUIZES_DATA');
export const GET_NEWS_DATA = createActionSet('GET_NEWS_DATA');
export const GET_DATA_OF_DATA = createActionSet('GET_DATA_OF_DATA');
export const GET_ANNUAL_REPORTS = createActionSet('GET_ANNUAL_REPORTS');
export const GET_MEDIA_INTERVIEW = createActionSet('GET_MEDIA_INTERVIEW');
export const GET_ABOUT_DATA = createActionSet('GET_ABOUT_DATA');
export const GET_SPEC_DATA = createActionSet('GET_SPEC_DATA');
export const GET_SINGLE_NEWS_DATA = createActionSet('GET_SINGLE_NEWS_DATA');
export const GET_WORLD_DAYS_DATA = createActionSet('GET_WORLD_DAYS_DATA');
export const GET_WORLD_DAY_PAGE = createActionSet('GET_WORLD_DAY_PAGE');
export const GET_comprehensive_Guide_DATA = createActionSet('GET_comprehensive_Guide_DATA');
export const GET_ATTACHMENTS = createActionSet('GET_ATTACHMENTS');
export const GET_GUIDE_BOOK_DATA = createActionSet('GET_GUIDE_BOOK_DATA');
export const GET_GUIDE_BOOK_CHILDREN = createActionSet('GET_GUIDE_BOOK_CHILDREN');
export const GET_GUIDE_BOOK_CATEGORY_CONTENT = createActionSet('GET_GUIDE_BOOK_CATEGORY_CONTENT');
export const POST_POST_FORM = createActionSet('POST_POST_FORM');
export const GET_IMP_LINKS_DATA = createActionSet('GET_IMP_LINKS_DATA');
export const GET_COMMITTEES_DATA = createActionSet('GET_COMMITTEES_DATA');
export const GET_CONTRACTS_DATA = createActionSet('GET_CONTRACTS_DATA');
export const GET_CONSUMER_AWARDS = createActionSet('GET_CONSUMER_AWARDS');
export const GET_ERSHDNA_CATEGORY = createActionSet('GET_ERSHDNA_CATEGORY');
export const GET_ERSHDNA_SUBS = createActionSet('GET_ERSHDNA_SUBS');
export const GET_RECOGNIZE_ARRAY = createActionSet('GET_RECOGNIZE_ARRAY');
export const GET_ORGANIZE_PARAGRAPH = createActionSet('GET_ORGANIZE_PARAGRAPH');

export const GET_ERSHDNA_CATEGORIES = createActionSet('GET_ERSHDNA_CATEGORIES');
export const GET_NAVIGATION_PAGES = createActionSet('GET_NAVIGATION_PAGES');
export const GET_LANDING_PAGES = createActionSet('GET_LANDING_PAGES');
export const GET_PAGE_INFO = createActionSet('GET_PAGE_INFO');
export const GET_BUILDER_PAGE = createActionSet('GET_BUILDER_PAGE');



///notused
export const GET_CONSUMER_OFFICE_DATA = createActionSet('GET_CONSUMER_OFFICE_DATA');
export const GET_CONSUMER_CONSULT_DATA = createActionSet('GET_CONSUMER_CONSULT_DATA');

export const GET_FILTER_ARRAY = createActionSet('GET_FILTER_ARRAY');

//********Contact Us Actions */
export const POST_SENDAMESSAGE = createActionSet('POST_SENDAMESSAGE');
export const GET_CONTACTINFO = createActionSet('GET_CONTACTINFO');


//// Higher Management Members
export const GET_MEMBERS = 'GET_MEMBERS';

///consumer consultant actions
export const GET_CONSUMER_DATA = 'GET_CONSUMER_DATA';
export const GET_CONSUMER_DATA_BY_ID = 'GET_CONSUMER_DATA_BY_ID';

///compare and rate actions
export const GET_ALL_MULTI_CATEGORIES_WITH_EACH_SUB = 'GET_ALL_MULTI_CATEGORIES_WITH_EACH_SUB';
export const GET_MULTI_CATEGORIES_BY_ID_WITH_EACH_SUB = 'GET_MULTI_CATEGORIES_BY_ID_WITH_EACH_SUB';
export const ADD_OBJETCS_COMPARE_LIST = 'ADD_OBJECTS_COMPARE_LIST'
export const COMPARE_OBJECTS = 'COMPARE_OBJECTS';
export const REMOVE_TO_BE_COMPARED_OBJECTS = 'REMOVE_TO_BE_COMPARED_OBJECTS';
export const REMOVE_SPECIFIC_OBJECT_FROM_TO_BE_COMPARED_OBJECTS = 'REMOVE_SPECIFIC_OBJECT_FROM_TO_BE_COMPARED_OBJECTS';
export const ADD_BUTTON_ID_OF_ADDED_OBJECT_TO_COMPARISION = 'ADD_BUTTON_ID_OF_ADDED_OBJECT_TO_COMPARISION';
export const REMOVE_BUTTON_ID_OF_ADDED_OBJECT_TO_COMPARISION = 'REMOVE_BUTTON_ID_OF_ADDED_OBJECT_TO_COMPARISION';
export const REMOVE_DIABLE_OBJECT_COMPARE_BTN = 'REMOVE_DIABLE_OBJECT_COMPARE_BTN';
export const REMOVE_SPECIFIC_OBJECT_FROM_COMPARISON_LIST = 'REMOVE_SPECIFIC_OBJECT_FROM_COMPARISON_LIST';
export const SET_FIRST_COLLAPSE_INDEX = 'SET_FIRST_COLLAPSE_INDEX';
export const SET_FIRST_COLLAPSE_ID = 'SET_FIRST_COLLAPSE_ID';
export const SET_SECOND_COLLAPSE_INDEX = 'SET_SECOND_COLLAPSE_INDEX';
export const SET_SECOND_COLLAPSE_ID = 'SET_SECOND_COLLAPSE_ID';
export const GET_GENERAL_TIPS = 'GET_GENERAL_TIPS';
export const GET_PREVIOUS_EVALUATIONS = 'GET_PREVIOUS_EVALUATIONS';

/// platforms and applications
export const GET_APPLICATIONS = 'GET_APPLICATIONS';
export const GET_APPLICATION_BY_ID = 'GET_APPLICATION_BY_ID';
