import React from 'react';
import './adPopUp.scss';
// import { Link } from 'react-router-dom';
import { Row, Col, Carousel } from 'react-bootstrap';
import { apiFetch } from '../../../utils/http.helpers';
import { BASE_URL } from '../../../constants/config';

class AdPopUp extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            index: 0,
            show: true,
            hover: false,
            ads: []
        }
    }

    handleSelect = (selectedIndex) => {
        this.setState({ index: selectedIndex })
    }

    onHover = () => {
        this.setState({
            hover: !this.state.hover
        })
    }
    handleShow = () => {
        this.setState({ show: true })
    }
    handleClose = () => {
        this.setState({ show: false })
    }

    componentDidMount() {
        apiFetch(BASE_URL + '/api/SiteAdver/getSiteAdver', {
            method: "GET",
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(res => {
                this.setState({ ads: res })
            })
    }

    render() {
        return (
            this.state.ads.length > 0 &&
            <div className={"adPopUp-conatiner " + (this.state.hover && "hover_on")} dir="rtl">
                <Row className="cpa-ads-content">
                    <Col xs={4} className="p-0">
                        <div className="cpa-ads-header" onMouseEnter={this.onHover}>
                            <div className="inner">
                                <i className="icon-right-arrow"></i>
                            </div>
                        </div>
                    </Col>
                    <Col xs={8} className="p-0" >
                        <div className="cpa-ads-content" onMouseLeave={this.onHover}>
                            <Carousel controls={false} indicators={true}>
                                {this.state.ads.map((value, index) => {
                                    return (
                                        <Carousel.Item key={index}>
                                            <a href={value.link}>
                                                <img
                                                    src={BASE_URL + '/uploads/' + value.image}
                                                    alt="First slide"
                                                />
                                            </a>
                                        </Carousel.Item>
                                    )
                                })}
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default AdPopUp;