import React from 'react';
import ImgHeader from '../GlobalComponent/ImgHeader/ImgHeader';
import { Container } from "react-bootstrap";
import ReactHtmlParser from 'react-html-parser';

class FAQ extends React.Component {
    constructor(){
        super();
        this.state={
            content: `<p>
            <h3>روابط هامة</h3>
            <ul>
            <li><a href="https://cpa.org.sa/?page_id=48">جمعية حماية المستهلك</a></li>
            <li><a href="http://eservices.mci.gov.sa/Eservices/balagh.htm">وزارة التجارة والاستثمار</a></li>
            <li><a href="http://www.sama.gov.sa/ar-sa/ConsumerProtection/Pages/Complaint.aspx">مؤسسة النقد العربي السعودي</a></li>
            <li><a href="https://portalservices.citc.gov.sa/E-Services/Complaint/LandingScreen.aspx">هيئة الاتصالات وتقنية المعلومات</a></li>
            </ul>
            </p>`,
            page:{
              name:'روابط مهمة',
              name_en:'Important Links'
            }
        }
    }
  render() {
    return (
        <div>
            <ImgHeader page={this.state.page} />
            <Container>
                {ReactHtmlParser(this.state.content)}
            </Container>
        </div>
    )
  }
}

export default FAQ;
