import { GET_comprehensive_Guide_DATA, GET_ATTACHMENTS } from '../../../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from '../../initialState';

export default function comprehensiveGuideReducer(state = initialState.programs_and_activities.comprehensive_guide, action) {
  switch (action.type) {
    case GET_comprehensive_Guide_DATA.SUCCESS:
      return objectAssign({}, state, { comprehensiveGuideArray: action.payload });
    case GET_ATTACHMENTS.SUCCESS:
      return objectAssign({}, state, { attachments: action.payload })
    default:
      return state;
  }
}