import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import Translate from '../../../../GlobalComponent/translate/translate';
import { t } from 'i18next';
import PropTypes from "prop-types";
import * as actions from '../../../../../actions/program-and-activities/programs/legal_advocay/followComplain';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { USER_LOGOUT } from "../../../../../constants/actionTypes";
import { userLogout } from "../../../../../utils/auth.helpers";
import { store } from '../../../../../index';
import ReactHtmlParser from 'react-html-parser';

// import CpaNotifier from '../../../../GlobalComponent/cpaNotifer/cpaNotifer';

class FollowComplaint extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ComplaintNumber: null,
            showStatus: false,
            notify: "",
            status: null,
            status_en: null,
            admin_response: null,
            loading: false,
            errMsg: null,
        };
    }

    componentDidMount() {
        this.props.match.params.id ?
            this.setState({ ComplaintNumber: this.props.match.params.id }) : null
    }

    logout = () => {
        userLogout();
        store.dispatch({ type: USER_LOGOUT.SUCCESS, payload: {} });
    };
    handleSubmit = event => {
        event.preventDefault();
        if (this.props.user.is_authenticated) {
            this.setState({ loading: true })
            if (this.state.ComplaintNumber !== null && this.state.ComplaintNumber !== "") {
                this.props.actions.getComplainStatus(this.state.ComplaintNumber)
                    .then(() => {
                        this.setState({
                            ...this.state,
                            loading: false,
                            showStatus: true,
                            status: this.props.status,
                            status_en: this.props.status_en,
                            admin_response: this.props.admin_response
                        });
                        if (this.props.lng == 'ar') {
                            this.setState({
                                status: this.props.status
                            }, () => {
                                if (this.props.status.includes('Invalid') || this.props.status.includes('Expired')) {
                                    this.setState({
                                        status: "أنتهي الوقت المسموح,برجاء اعادة تسجيل الدخول",
                                        status_en: "Timeout, please log in again"
                                    })
                                    this.logout();
                                }
                            })
                        }
                    }).catch(() => {

                    });
            } else {
                this.setState({ loading: false, showStatus: true, status: <Translate text="pleasetypecomplaintnumber" /> })
            }
        } else {
            this.setState({ loading: false, showStatus: true, status: t('notAuth') })
        }
    }
    handleChange = event => {
        event.preventDefault();
        const { name, value } = event.target;
        if (value.substring(0, 3).toLowerCase() === 'cpa') {
            if (parseInt(value.substring(3, value.length)) >= 0) {
                this.setState({
                    errMsg: null
                })
            } else {
                if (this.props.lng === 'ar') {
                    this.setState({
                        errMsg: 'الرجاء إدخال رقم الشكوى بالشكل الصحيح ، على سبيل المثال: "cpa1" حيث يكون "cpa" هو الرمز و "1" هو رقم الشكوى'
                    })
                } else {
                    this.setState({
                        errMsg: 'Please enter the complain number in the right form, ex: "cpa1" where "cpa" is the code and "1" is the complaint number'
                    })
                }
            }
        } else {
            if (this.props.lng === 'ar') {
                this.setState({
                    errMsg: 'الرجاء إدخال رقم الشكوى بالشكل الصحيح ، على سبيل المثال: "cpa1" حيث يكون "cpa" هو الرمز و "1" هو رقم الشكوى'
                })
            } else {
                this.setState({
                    errMsg: 'Please enter the complain number in the right form, ex: "cpa1" where "cpa" is the code and "1" is the complaint number'
                })
            }
        }
        this.setState({
            [name]: value
        })
    }
    render() {
        // console.log("prop", this.props)
        //Data of each select will be found in folder of data in cpa-project
        return (

            <div className="guideUs-container">
                <div className="choose-case height-auto">
                    <form>
                        <Row>

                            <h5 className="pr-4">
                                {this.props.lng === 'ar' ? 'تابع شكواك' : 'Track your complaint'}
                            </h5>

                        </Row>
                        <Row className="justify-content-center">

                            <form className="col-12" >
                                <Row>
                                    <Col xs={12}>
                                        <div className="form-group row">
                                            <label htmlFor="inputPassword" className="col-lg-2 col-xs-6 col-form-label">
                                                {this.props.lng === 'ar' ? 'رقم الشكوى' : 'Complaint Number'}<span Style="color:red">*</span>
                                            </label>
                                            <div className="col-lg-10 col-xs-12">
                                                <div className="form-input">
                                                    <i className="icon-menu-about"></i>
                                                    <input
                                                        value={this.state.ComplaintNumber}
                                                        name="ComplaintNumber"
                                                        placeholder={this.props.lng === 'ar' ? 'رقم الشكوى' : 'Complaint Number'}
                                                        onChange={this.handleChange}
                                                        pattern="[0-9]"
                                                        type="text" required />
                                                </div>
                                                <span Style="color: red;">{this.state.errMsg}</span>
                                            </div>
                                        </div>
                                    </Col>
                                    {
                                        this.props.user.is_authenticated ?
                                            <Col xs={12} className="submit-row">
                                                <button
                                                    className={this.state.ComplaintNumber === null || this.state.ComplaintNumber === "" || !this.props.user.is_authenticated ? "btn submit-cpa-btn disabled" : "btn submit-cpa-btn"}
                                                    onClick={this.handleSubmit}
                                                    disabled={this.state.ComplaintNumber == null || this.state.ComplaintNumber == "" || !this.props.user.is_authenticated}
                                                    type="submit">{this.props.lng === 'ar' ? 'تابع شكواك' : 'Track your complaint'}
                                                </button>
                                            </Col>
                                            :
                                            <Col xs={12} className="submit-row-error">
                                                <span>{this.props.lng === 'ar' ? 'برجاء تسجيل الدخول أولا' : 'please login first'}</span>
                                            </Col>
                                    }
                                </Row>
                            </form>
                        </Row>
                        {this.state.loading && <div><p>Loading ...</p></div>}
                        <Row className={"justify-content-center " + (this.state.showStatus ? "" : "d-none")}>
                            <Col xs={12} md={12} lg={10}>
                                <div className="notFound-container">
                                    <div className={`notFound-content ${this.props.lng === 'ar' ? 'text-right' : 'text-left'}`}>
                                        <div className="inner">
                                            <h3>
                                                <span>{this.props.lng === 'ar' ? 'الحاله : ' : 'Status : '}</span>

                                            </h3>
                                            <h4>
                                                {this.props.lng === 'ar' ? this.state.status : this.state.status_en}

                                            </h4>
                                            <h3>{this.props.lng === 'ar' ? 'استجابه المشرف ' : 'Admin Response'}</h3>

                                            <p>
                                                {this.state.admin_response ? (ReactHtmlParser(this.state.admin_response)) : `${this.props.lng === 'ar' ? 'سيتم الرد قريبا ' : 'we will response soon'}`} </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </div>
            </div >
        )
    }
}
FollowComplaint.propTypes = {
    actions: PropTypes.object.isRequired,
    status: PropTypes.array.isRequired,
    status_en: PropTypes.array.isRequired,
    admin_response: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    lng: PropTypes.string.isRequired,
    match: PropTypes.object,
    'match.params': PropTypes.object,
    'match.params.id': PropTypes.string,
};
function mapStateToProps(state) {
    return {
        status: state.follow_complain.status,
        status_en: state.follow_complain.status_en,
        admin_response: state.follow_complain.admin_response,
        user: state.user,
        lng: state.user.lng
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FollowComplaint))