import React from 'react';
// import Translate from '../translate/translate';
import PropTypes from "prop-types";
import { BASE_URL } from '../../../constants/config';

const CpaDownloading = props => {
    return (
        <div className="download-control">
            {/* download button */}
            {
                props.fileToDownload &&
                props.fileToDownload != "[]" &&
                <a style={{ cursor: "pointer" }} href={BASE_URL + '/uploads/' + JSON.parse(props.fileToDownload)[0].download_link} className="resp-sharing-button__link"
                    target="_blank" rel="noopener noreferrer" aria-label="Share on Twitter">
                    <div className="resp-sharing-button resp-sharing-button--download resp-sharing-button--small"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
                        <svg xmlns="http://www.w3.org/2000/svg" fontSize="2rem" width="35.577" height="37.816" viewBox="0 0 35.577 37.816">
                            <g id="_724933" data-name="724933" transform="translate(-15 1)">
                                <g id="Group_194" data-name="Group 194" transform="translate(23.835)">
                                    <g id="Group_193" data-name="Group 193">
                                        <path id="Path_351" data-name="Path 351" d="M145.805,16.325a1.116,1.116,0,0,0-1.018-.656H140.31V1.119A1.12,1.12,0,0,0,139.191,0h-4.477a1.12,1.12,0,0,0-1.119,1.119v14.55h-4.477a1.119,1.119,0,0,0-.842,1.856l7.835,8.954a1.117,1.117,0,0,0,1.683,0l7.835-8.954A1.116,1.116,0,0,0,145.805,16.325Z" transform="translate(-127.998)" fill="#fff" stroke="#6be2b7" />
                                    </g>
                                </g>
                                <g id="Group_196" data-name="Group 196" transform="translate(16 24.623)">
                                    <g id="Group_195" data-name="Group 195">
                                        <path id="Path_352" data-name="Path 352" d="M45.1,352v6.715H20.477V352H16v8.954a2.238,2.238,0,0,0,2.238,2.238h29.1a2.236,2.236,0,0,0,2.238-2.238V352Z" transform="translate(-16 -352)" fill="#fff" stroke="#6be2b7" />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    </div>
                </a>
            }

            {/* print button */}
            {/* <a style={{ cursor: "pointer" }} onClick={() => { window.print() }} className="resp-sharing-button__link"
                target="_blank" rel="noopener noreferrer" aria-label="Share on Twitter">
                <div className="resp-sharing-button resp-sharing-button--print resp-sharing-button--small"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
                    <svg xmlns="http://www.w3.org/2000/svg" id="printer" width="32.616" height="34.316" fontSize="2rem" viewBox="0 0 32.616 34.316">
                        <g id="Group_55" data-name="Group 55" transform="translate(0)">
                            <path id="Path_100" data-name="Path 100" d="M39.495,7.034h-.882V5.071A5.078,5.078,0,0,0,33.542,0H22.9a5.078,5.078,0,0,0-5.071,5.071V7.034h-.8A5.078,5.078,0,0,0,11.95,12.1v8.179a5.078,5.078,0,0,0,5.071,5.071h.8v6.429a2.537,2.537,0,0,0,2.532,2.532H36.074a2.537,2.537,0,0,0,2.532-2.532V25.354h.889a5.078,5.078,0,0,0,5.071-5.071V12.1A5.078,5.078,0,0,0,39.495,7.034ZM19.738,5.071A3.157,3.157,0,0,1,22.888,1.92h10.64a3.157,3.157,0,0,1,3.151,3.151V7.034H19.738ZM36.686,31.8a.616.616,0,0,1-.612.612H20.349a.616.616,0,0,1-.612-.612v-10.6H36.686Zm5.96-11.507a3.157,3.157,0,0,1-3.151,3.151h-.882V21.194h1.266a.96.96,0,0,0,0-1.92H16.409a.96.96,0,0,0,0,1.92h1.415v2.247h-.8a3.157,3.157,0,0,1-3.151-3.151V12.112a3.157,3.157,0,0,1,3.151-3.151H39.495a3.157,3.157,0,0,1,3.151,3.151Z" transform="translate(-11.95)" fill="#6be2b7" />
                            <path id="Path_101" data-name="Path 101" d="M141.61,339.32h12.226a.96.96,0,0,0,0-1.92H141.61a.96.96,0,1,0,0,1.92Z" transform="translate(-131.497 -313.404)" fill="#6be2b7" />
                            <path id="Path_102" data-name="Path 102" d="M154.843,392.6H142.61a.96.96,0,0,0,0,1.92h12.226a.96.96,0,0,0,.007-1.92Z" transform="translate(-132.426 -364.678)" fill="#6be2b7" />
                            <path id="Path_103" data-name="Path 103" d="M360.959,151.9H359.01a.96.96,0,1,0,0,1.92h1.949a.96.96,0,1,0,0-1.92Z" transform="translate(-333.435 -141.097)" fill="#6be2b7" />
                        </g>
                    </svg>
                </div>
                </div>
            </a> */}
        </div>
    );
}

CpaDownloading.propTypes = {
    url: PropTypes.string,
    fileToDownload: PropTypes.string
}
export default CpaDownloading;
