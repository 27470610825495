import React from 'react';
import { withRouter } from 'react-router-dom';
import './IconCard.scss';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import Translate from '../translate/translate';
import { BASE_URL } from '../../../constants/config';

class IconCard extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            read_quntity: "less",
            img_url: BASE_URL + '/uploads/',
            contentLength: 0
        }
    }

    renderMoreOrLessBtn(content) {
        if (content.length > 555) {
            return (
                <div className={"show_controller " + (this.props.show_more ? "" : "d-none")}>
                    <span onClick={this.readQuntity}><Translate text={this.state.read_quntity} /></span>
                </div>
            )
        }
    }

    readQuntity = () => {
        this.state.read_quntity == "more" ? this.setState({ read_quntity: "less" }) : this.setState({ read_quntity: "more" });
    }
    render() {
        return (
            <div className="icon-card">
                {
                    this.props.match.path !== '/about-us' &&
                    <div className="card-img">
                        <img style={{ maxWidth: '500px' }} src={BASE_URL + '/uploads/' + this.props.card.image}></img>
                    </div>
                }
                <div className="card-head">
                    <h3>{this.props.lng == "ar" ? this.props.card.title : this.props.card.title_en}</h3>
                </div>
                <div id="myDiv" className={"card-desc " + (this.state.read_quntity == "less" ? "height-auto" : "more")}>
                    <p>{ReactHtmlParser(this.props.lng == "ar" ? this.props.card.description : this.props.card.description_en)}</p>
                </div>
                {this.renderMoreOrLessBtn(this.props.card.description)}
            </div>
        )
    }
}
IconCard.propTypes = {
    card: PropTypes.object,
    show_more: PropTypes.bool,
    lng: PropTypes.string.isRequired,
    match: PropTypes.object,
    'match.path': PropTypes.string,
};
function mapStateToProps(state) {
    return {
        lng: state.user.lng,
    };
}

export default withRouter(connect(mapStateToProps)(IconCard))
